//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
//#endregion

export interface Arquivo {
  //#region ODataApiGen Properties
  NomeDoArquivo?: string;
  FormatoDoArquivo?: string;
  TipoDoArquivo?: string;
  AcaoArquivo?: string;
  UrlArquivo?: string;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  //#endregion
}