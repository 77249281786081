import {
	HttpClient,
	HttpErrorResponse,
	HttpHeaders,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { environment } from "../../../environments/environment";
import { APIResponse } from "../../models";

const httpOptions = {
	headers: new HttpHeaders({
		"Content-Type": "application/json",
		accept: "*/*",
	}),
};

@Injectable({
	providedIn: "root",
})
export class AlterarSenhaService {
	constructor(private http: HttpClient) {}

	changePass(password: string, idUsuario: number): Observable<APIResponse> {
		return this.http.post<APIResponse>(
			`${environment.apiUrl}/Usuario/alterar-senha`,
			{
				Senha: password,
				IdUsuario: idUsuario,
				EmailLogado: "string",
			},
			httpOptions
		);
	}

	changePassUsuario(password: string): Observable<APIResponse> {
		return this.http.post<APIResponse>(
			`${environment.apiUrl}/Usuario/alterar-senha-usuario`,
			{
				Senha: password,
			},
			httpOptions
		);
	}

	private handleError(err: HttpErrorResponse) {
		console.log(err);
		let errorMessage = "";
		if (err.error instanceof ErrorEvent) {
			errorMessage = `An error occurred: ${err.error.message}`;
		} else {
			errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
		}
		console.error(errorMessage);
		return throwError(err);
	}
}
