//#region ODataApiGen Imports
//#endregion

export enum EnumTipoPeriodo {
  //#region ODataApiGen Members
  FATURAMENTO = 1,
  SIP = 2,
  RPC = 3,
  PRODUCAO = 4,
  //#endregion
}
