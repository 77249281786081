//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { ComissaoService } from './comissao.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const ComissaoServiceEntitySetConfig = {
  name: 'Comissao',
  entityType: 'Uniodonto.Domain.Entities.Comissao',
  service: ComissaoService
} as EntitySetConfig;
//#endregion