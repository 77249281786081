//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { GrupoAtoOdontologico } from './grupoatoodontologico.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const GrupoAtoOdontologicoEntityConfig = {
  name: 'GrupoAtoOdontologico',
  keys: [{name: 'Id'}],
  fields: {
    GrupoId: {type: 'Edm.Int32'},
    AtoOdontologicoId: {type: 'Edm.Int32'},
    OperadoraId: {type: 'Edm.Int32'},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'},
    Grupo: {type: 'Uniodonto.Domain.Entities.Grupo', navigation: true, referentials: [{property: 'GrupoId', referencedProperty: 'Id'}]},
    AtoOdontologico: {type: 'Uniodonto.Domain.Entities.AtoOdontologico', navigation: true, referentials: [{property: 'AtoOdontologicoId', referencedProperty: 'Id'}]},
    Operadora: {type: 'Uniodonto.Domain.Entities.Operadora', navigation: true, referentials: [{property: 'OperadoraId', referencedProperty: 'Id'}]}
  }
} as StructuredTypeConfig<GrupoAtoOdontologico>;
//#endregion