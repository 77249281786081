import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { environment } from "../../../environments/environment";

import { catchError, map, tap } from "rxjs/operators";

import { Injectable } from "@angular/core";

import { APIResponse } from "../../models";
import {
  ContratoModel,
  GrupoFamiliarModel,
  ContratoGrupoFamiliarModel,
  ProdutoDoContratoLinhaModel,
  ProdutoDoContratoModel,
  VinculoDocumentoContratoModel,
} from "../../models/entities";
import { CreateUpdateSequenciaCommand } from "../../models/command/CreateUpdateSequenciaCommand";

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
  }),
};

@Injectable({
  providedIn: "root",
})
export class ContratoService {
  public log: String;

  constructor(private http: HttpClient) { }

  buscar_todos(): Observable<any[]> {
    return this.http
      .get<any[]>(`${environment.apiUrl}/contrato/buscar-todos`)
      .pipe(
        tap((data) => "Ok"),
        catchError(this.handleError)
      );
  }

  obter_produtos(id: number): Observable<any> {
    return this.http.get<any[]>(`${environment.apiUrl}/contrato/produtos/${id}`)
  }

  obter_documentos(id: number): Observable<any[]> {
    return this.http
      .get<any[]>(`${environment.apiUrl}/contrato/documentos/${id}`)
      .pipe(
        tap((data) => "Ok"),
        catchError(this.handleError)
      );
  }

  obter_gruposFamiliares(id: number): Observable<any[]> {
    return this.http
      .get<any[]>(`${environment.apiUrl}/contrato/gruposFamiliares/${id}`)
      .pipe(
        tap((data) => "Ok"),
        catchError(this.handleError)
      );
  }

  buscar_por_id(id: number): Observable<any> {
    return this.http
      .get<any[]>(`${environment.apiUrl}/contrato/buscar-por-id/${id}`)
      .pipe(
        tap((data) => "Ok"),
        catchError(this.handleError)
      );
  }

  obter_modalidades(): Observable<any> {
    return this.http
      .get<any[]>(`${environment.apiUrl}/contrato/modalidades`)
      .pipe(
        tap((data) => "Ok"),
        catchError(this.handleError)
      );
  }

  obter_titulares(id: number): Observable<any> {
    return this.http
      .get<any[]>(`${environment.apiUrl}/contrato/buscar-titulares-por-contrato-id?contratoId=${id}`)
      .pipe(
        tap((data) => "Ok"),
        catchError(this.handleError)
      );
  }

  obter_dependentes(id: number): Observable<any> {
    return this.http
      .get<any[]>(`${environment.apiUrl}/titular/buscar-dependente-por-titular-id?titularId=${id}`)
      .pipe(
        tap((data) => "Ok"),
        catchError(this.handleError)
      );
  }

  obter_tipos(): Observable<any> {
    return this.http
      .get<any[]>(`${environment.apiUrl}/contrato/tipos`)
      .pipe(
        tap((data) => "Ok"),
        catchError(this.handleError)
      );
  }

  obter_origens(): Observable<any> {
    return this.http
      .get<any[]>(`${environment.apiUrl}/contrato/origems`)
      .pipe(
        tap((data) => "Ok"),
        catchError(this.handleError)
      );
  }

  obter_nucleos(): Observable<any> {
    return this.http
      .get<any[]>(`${environment.apiUrl}/nucleo/buscar-todos`)
      .pipe(
        tap((data) => "Ok"),
        catchError(this.handleError)
      );
  }

  obter_grupos(): Observable<any> {
    return this.http
      .get<any[]>(`${environment.apiUrl}/GrupoFamiliar/buscar-todos`)
      .pipe(
        tap((data) => "Ok"),
        catchError(this.handleError)
      );
  }

  obter_TipoContratoColetivo(): Observable<any> {
    return this.http
      .get<any[]>(`${environment.apiUrl}/contrato/tipoContratoColetivo`)
      .pipe(
        tap((data) => "Ok"),
        catchError(this.handleError)
      );
  }

  obter_procedimentosReembolso(): Observable<any> {
    return this.http
      .get<any[]>(`${environment.apiUrl}/Reembolso/buscar-todos`)
      .pipe(
        tap((data) => "Ok"),
        catchError(this.handleError)
      );
  }



  deletarContrato(id: number): Observable<any> {
    return this.http
      .delete<any[]>(`${environment.apiUrl}/Contrato/remover-contrato?id=` + id)
      .pipe(
        tap((data) => "Ok"),
        catchError(this.handleError)
      );
  }

  /** obter umma lista de contratos por cliente id */
  getByClienteId(clienteId: number): Observable<any[]> {
    return this.http
      .get<any[]>(`${environment.apiUrl}/contrato/buscar-por-cliente?clienteId=${clienteId}`)
      .pipe(
        tap((data) => "Ok"),
        catchError(this.handleError)
      );
  }

  /** criar um novo contrato */
  create(contrato: any): Observable<APIResponse> {
    return this.http
      .post<APIResponse>(
        `${environment.apiUrl}/contrato/criar-contrato`,
        contrato,
        httpOptions
      )
      .pipe(
        tap((resp) => "Ok"),
        catchError(this.handleError)
      );
  }

  definirSequencia(command: CreateUpdateSequenciaCommand): Observable<APIResponse> {
    return this.http.post<APIResponse>(`${environment.apiUrl}/contrato/definir-sequencia`, command, httpOptions);
  }

  /** criar um novo contrato */
  atualizarContrato(command: any): Observable<APIResponse> {
    return this.http
      .post<APIResponse>(
        `${environment.apiUrl}/contrato/editar-contrato`,
        command,
        httpOptions
      )
      .pipe(
        tap((resp) => "Ok"),
        catchError(this.handleError)
      );
  }

  /** obter dados por número de documento */
  getByDocumento(documento: any): Observable<any[]> {
    return this.http
      .get<any[]>(
        `${environment.apiUrl}/Cliente/buscar-por-documento?documento=${documento}`
      )
      .pipe(
        tap((data) => "Ok"),
        catchError(this.handleError)
      );
  }

  /** obter dados de um contrato pelo Id */
  getByContratoId(IdContrato: any): Observable<any[]> {
    return this.http
      .get<any[]>(
        `${environment.apiUrl}/Contrato/buscar-titulares-por-contrato-id?contratoId=${IdContrato}`
      )
      .pipe(
        tap((data) => "Ok"),
        catchError(this.handleError)
      );
  }

  getTitularContrato(Documento: any): Observable<any[]> {
    return this.http
      .get<any[]>(
        `${environment.apiUrl}/Titular/buscar-por-documento?documento=${Documento}`
      )
      .pipe(
        tap((data) => "Ok"),
        catchError(this.handleError)
      );
  }

  obter_atosDoProdutoDoContrato(produtoId: number, contratoId: number): Observable<any[]> {
    return this.http
      .get<any[]>(`${environment.apiUrl}/Contrato/produto/${produtoId}/preco-ato/${contratoId}`)
      .pipe(
        tap((data) => "Ok"),
        catchError(this.handleError)
      );
  }

  _create_contratoProduco_AtoPreco(command: any): Observable<APIResponse> {
    return this.http
      .post<APIResponse>(`${environment.apiUrl}/Contrato/produto/vincular-preco-ato`,
        command,
        httpOptions
      )
      .pipe(
        tap((data) => "Ok"),
        catchError(this.handleError)
      );
  }

  _update_contratoProduco_AtoPreco(command: any): Observable<APIResponse> {
    return this.http
      .put<APIResponse>(`${environment.apiUrl}/Contrato/produto/vincular-preco-ato`,
        command,
        httpOptions
      )
      .pipe(
        tap((data) => "Ok"),
        catchError(this.handleError)
      );
  }

  create_contratoProduco_AtoPreco(command: any): Observable<APIResponse> {
    return this.http
      .post<APIResponse>(
        `${environment.apiUrl}/Contrato/produto/vincular-preco-ato`,
        command,
        httpOptions
      )
      .pipe(
        tap((resp) => "Ok"),
        catchError(this.handleError)
      );
  }

  update_contratoProduco_AtoPreco(command: any): Observable<APIResponse> {
    return this.http
      .put<APIResponse>(
        `${environment.apiUrl}/Contrato/produto/vincular-preco-ato`,
        command,
        httpOptions
      )
      .pipe(
        tap((resp) => "Ok"),
        catchError(this.handleError)
      );
  }

  /** vincular uma linha de produto ao contrato */
  vincularProduto(
    produto: any
  ): Observable<APIResponse> {
    return this.http
      .post<APIResponse>(
        `${environment.apiUrl}/Contrato/vincular-produto`,
        produto,
        httpOptions
      )
      .pipe(
        tap((resp) => "Ok"),
        catchError(this.handleError)
      );
  }

  /** vincular uma linha de produto ao contrato */
  editarProduto(
    produto: any
  ): Observable<APIResponse> {
    return this.http
      .post<APIResponse>(
        `${environment.apiUrl}/Contrato/editar-produto-do-contrato`,
        produto,
        httpOptions
      )
      .pipe(
        tap((resp) => "Ok"),
        catchError(this.handleError)
      );
  }

  /** excluir produto do contrato */
  excluirProduto(
    contratoProdutoId: number,
  ): Observable<APIResponse> {
    return this.http
      .delete<APIResponse>(
        `${environment.apiUrl}/Contrato/deletar-produto-contrato?contratoProdutoId=${contratoProdutoId}`,
        httpOptions
      );
  }

  /** vincular documento ao contrato */
  vincularDocumento(
    documentos: VinculoDocumentoContratoModel
  ): Observable<APIResponse> {
    return this.http
      .post<APIResponse>(
        `${environment.apiUrl}/Contrato/vincular-arquivo`,
        documentos,
        httpOptions
      )
      .pipe(
        tap((data) => console.log(JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  /** excluir documento do contrato */
  excluirArquivo(
    documentoId: number,
    contratoId: number
  ): Observable<APIResponse> {
    return this.http
      .delete<APIResponse>(
        `${environment.apiUrl}/Contrato/deletar-arquivo-contrato?documentoId=${documentoId}&contratoId=${contratoId}`,
        httpOptions
      )
      .pipe(
        tap((data) => console.log(JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  /** vincular grupo familiar ao contrato */
  vincularGrupoFamiliar(
    grupo: any
  ): Observable<APIResponse> {
    return this.http
      .post<APIResponse>(
        `${environment.apiUrl}/Contrato/vincular-grupo-familiar`,
        grupo,
        httpOptions
      )
      .pipe(
        tap((resp) => "OK"),
        catchError(this.handleError)
      );
  }

  /** atualiza informações do produto do contrato */
  atualizarProduto(
    produto: ProdutoDoContratoModel,
    contratoId: number
  ): Observable<APIResponse> {
    return this.http
      .post<APIResponse>(
        `${environment.apiUrl}/Contrato/editar-produto-do-contrato?contratoId=${contratoId}`,
        produto,
        httpOptions
      )
      .pipe(
        tap((resp) => console.log(JSON.stringify(resp))),
        catchError(this.handleError)
      );
  }

  /** excluir grupo familiar do contrato */
  excluirGrupoFamiliar(grupoId, contratoId): Observable<APIResponse> {
    return this.http
      .delete<APIResponse>(
        `${environment.apiUrl}/Contrato/deletar-grupo-familiar-contrato?grupoFamiliarId=${grupoId}&contratoId=${contratoId}`
      )
      .pipe(
        tap((data) => console.log("Grupo excluido")),
        catchError(this.handleError)
      );
  }


  buscar_contratos_operadora(): Observable<any[]> {
    return this.http
      .get<any[]>(`${environment.apiUrl}/contrato/clientes-contrato-por-operadora`)
      .pipe(
        tap((data) => "Ok"),
        catchError(this.handleError)
      );
  }

  buscar_contratos_cliente(id: any): Observable<any[]> {
    return this.http
      .get<any[]>(`${environment.apiUrl}/contrato/lista-por-operadora`)
      .pipe(
        tap((data) => "Ok"),
        catchError(this.handleError)
      );
  }

  buscar_contratos_operadora_async(): Observable<any[]> {
    return this.http
      .get<any[]>(`${environment.apiUrl}/contrato/contrato-por-operadora`)
      .pipe(
        tap((data) => "Ok"),
        catchError(this.handleError)
      );
  }

  contratos_disponiveis_associar(contratoId): Observable<any[]> {
    return this.http
      .get<any[]>(`${environment.apiUrl}/Contrato/${contratoId}/disponiveis/associar`)
      .pipe(
        catchError(this.handleError)
      );
  }


  dessasociar_filho(
    contratoId: number
  ): Observable<APIResponse> {
    return this.http
      .post<APIResponse>(
        `${environment.apiUrl}/Contrato/dessasociar-filho/${contratoId}`,
        httpOptions
      )
      .pipe(
        tap((resp) => console.log(JSON.stringify(resp))),
        catchError(this.handleError)
      );
  }

  getSubContrato(contratoId: number): Observable<APIResponse> {
    const Id = {'Id':contratoId}
    return this.http.get<APIResponse>(`${environment.apiUrl}/Contrato/${contratoId}/subContratos` )
      .pipe(
        catchError(this.handleError)
      );
  }


  /** handle de erros */
  private handleError(err: HttpErrorResponse) {
    return throwError(err);
  }
}
