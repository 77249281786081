//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { ControleAcesso } from './controleacesso.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const ControleAcessoEntityConfig = {
  name: 'ControleAcesso',
  keys: [{name: 'Id'}],
  fields: {
    NomeGrupo: {type: 'Edm.String'},
    TipoCadastro: {type: 'Edm.String'},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'}
  }
} as StructuredTypeConfig<ControleAcesso>;
//#endregion