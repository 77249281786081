//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { LogTrocaSenhaV2Service } from './logtrocasenhav2.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const LogTrocaSenhaV2ServiceEntitySetConfig = {
  name: 'LogTrocaSenhaV2',
  entityType: 'Uniodonto.Domain.Entities.LogTrocaSenha',
  service: LogTrocaSenhaV2Service
} as EntitySetConfig;
//#endregion