//#region ODataApiGen ODataImports
import {
  EnumTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { SituacaoAutorizacao } from './situacaoautorizacao.enum';
//#endregion

//#region ODataApiGen EnumTypeConfig
export const SituacaoAutorizacaoConfig = {
  name: 'SituacaoAutorizacao',
  members: SituacaoAutorizacao,
  fields: {
    AT: {value: 0, annotations: [{"term":"Default.Description","string":"Autorizado totalmente"}]},
    AP: {value: 1, annotations: [{"term":"Default.Description","string":"Autorizado parcialmente"}]},
    NE: {value: 2, annotations: [{"term":"Default.Description","string":"Negado"}]},
    EA: {value: 3, annotations: [{"term":"Default.Description","string":"Em an\u00E1lise"}]},
    CA: {value: 4, annotations: [{"term":"Default.Description","string":"Cancelado"}]}
  }
} as EnumTypeConfig<SituacaoAutorizacao>;
//#endregion