//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { ContratoProduto } from './contratoproduto.entity';
import { Operadora } from './operadora.entity';
import { SubContratoProduto } from './subcontratoproduto.entity';
//#endregion

export interface ReajusteContratual {
  //#region ODataApiGen Properties
  SubContratoProdutoId?: number;
  ContratoProdutoListaId?: number;
  ValorMensalidade: number;
  ValorMensalidadeAgregado: number;
  ValorTaxaInscricao: number;
  ValorTaxaInscricaoDependente: number;
  ValorTaxaInscricaoAgregado: number;
  ValorSegundaViaCarteirinha: number;
  ValorSegundaViaCarteirinhaDependente: number;
  ValorSegundaViaCarteirinhaAgregado: number;
  ValorMensalidadeDependente: number;
  ValorMensalidadeOld: number;
  ValorMensalidadeAgregadoOld: number;
  ValorTaxaInscricaoOld: number;
  ValorTaxaInscricaoDependenteOld: number;
  ValorTaxaInscricaoAgregadoOld: number;
  ValorSegundaViaCarteirinhaOld: number;
  ValorSegundaViaCarteirinhaDependenteOld: number;
  ValorSegundaViaCarteirinhaAgregadoOld: number;
  ValorMensalidadeDependenteOld: number;
  DataInicioReajuste: Date;
  PercentualReajuste: number;
  Executado: boolean;
  Observacao?: string;
  OperadoraId?: number;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  ContratoProdutoLista?: ContratoProduto;
  SubContratoProduto?: SubContratoProduto;
  Operadora?: Operadora;
  //#endregion
}