//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { DentistaEspecialidades } from './dentistaespecialidades.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const DentistaEspecialidadesEntityConfig = {
  name: 'DentistaEspecialidades',
  keys: [{name: 'Id'}],
  fields: {
    EspecialidadeId: {type: 'Edm.Int32'},
    Tipo: {type: 'Uniodonto.Domain.Model.Enum.EnumDentistaEspecialidadesTipo', nullable: false},
    DataInicio: {type: 'Edm.DateTimeOffset', nullable: false},
    DataFim: {type: 'Edm.DateTimeOffset', nullable: false},
    DentistaId: {type: 'Edm.Int32'},
    OperadoraId: {type: 'Edm.Int32'},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'},
    Especialidade: {type: 'Uniodonto.Domain.Entities.Especialidade', navigation: true, referentials: [{property: 'EspecialidadeId', referencedProperty: 'Id'}]},
    Dentista: {type: 'Uniodonto.Domain.Entities.Dentista', navigation: true, referentials: [{property: 'DentistaId', referencedProperty: 'Id'}]},
    Operadora: {type: 'Uniodonto.Domain.Entities.Operadora', navigation: true, referentials: [{property: 'OperadoraId', referencedProperty: 'Id'}]}
  }
} as StructuredTypeConfig<DentistaEspecialidades>;
//#endregion