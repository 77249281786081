//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Operadora } from './operadora.entity';
import { AtoOdontologico } from './atoodontologico.entity';
import { Produto } from './produto.entity';
//#endregion

export interface ProdutoServicosAdicionais {
  //#region ODataApiGen Properties
  OperadoraId?: number;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  Produto?: Produto;
  AtoOdontologico?: AtoOdontologico;
  Operadora?: Operadora;
  //#endregion
}