export enum FormMode {
    Empty = 0,
    New = 1,
    Edit = 2,
    Delete = 3
}


export enum TipoContato {
    PessoaFisica = 1,
    PessoaJuridica = 2
}

export enum AprovarGuia {

    Sim = 1,
    Não = 2
}