//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { ContratoProdutoAtoPrecoV2Service } from './contratoprodutoatoprecov2.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const ContratoProdutoAtoPrecoV2ServiceEntitySetConfig = {
  name: 'ContratoProdutoAtoPrecoV2',
  entityType: 'Uniodonto.Domain.Entities.ContratoProdutoAtoPreco',
  service: ContratoProdutoAtoPrecoV2Service
} as EntitySetConfig;
//#endregion