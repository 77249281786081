//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { CidadeService } from './cidade.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const CidadeServiceEntitySetConfig = {
  name: 'Cidade',
  entityType: 'Uniodonto.Domain.Entities.Cidade',
  service: CidadeService
} as EntitySetConfig;
//#endregion