//#region ODataApiGen Imports
//#endregion

export enum EnumDentistaEspecialidadesTipo {
  //#region ODataApiGen Members
  Autorizado = 1,
  NaoAutorizado = 2,
  Especialista = 3,
  //#endregion
}
