//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumTipoDocumento } from '../Model/Enum/enumtipodocumento.enum';
import { Operadora } from './operadora.entity';
import { Guia } from './guia.entity';
//#endregion

export interface GuiaAnexo {
  //#region ODataApiGen Properties
  DocumentoUrl?: string;
  DocumentoNome?: string;
  TipoImagem: EnumTipoDocumento;
  GuiaId?: number;
  UFastEnviado: boolean;
  OperadoraId?: number;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  Guia?: Guia;
  Operadora?: Operadora;
  //#endregion
}