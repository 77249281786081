//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { CEP } from './cep.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const CEPEntityConfig = {
  name: 'CEP',
  keys: [{name: 'Id'}],
  fields: {
    CEPCode: {type: 'Edm.String'},
    CEPRua: {type: 'Edm.String'},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'},
    Bairro: {type: 'Uniodonto.Domain.Entities.Bairro', navigation: true}
  }
} as StructuredTypeConfig<CEP>;
//#endregion