//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Job } from './job.entity';
import { Operadora } from './operadora.entity';
import { Clinica } from './clinica.entity';
//#endregion

export interface LogJob {
  //#region ODataApiGen Properties
  Mensagem?: string;
  Sucesso: boolean;
  JobId?: number;
  DataInicialExecucao?: Date;
  DataFinalExecucao?: Date;
  OperadoraId?: number;
  ClinicaId?: number;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  Job?: Job;
  Operadora?: Operadora;
  Clinica?: Clinica;
  //#endregion
}