//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { CEPService } from './cep.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const CEPServiceEntitySetConfig = {
  name: 'CEP',
  entityType: 'Uniodonto.Domain.Entities.CEP',
  service: CEPService
} as EntitySetConfig;
//#endregion