//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumTipoPessoa } from '../Model/Enum/enumtipopessoa.enum';
import { EnumStatusIntegracao } from '../Model/Enum/enumstatusintegracao.enum';
import { Contrato } from './contrato.entity';
import { PreEspelho } from './preespelho.entity';
import { Operadora } from './operadora.entity';
import { Subcliente } from './subcliente.entity';
import { SubContrato } from './subcontrato.entity';
import { Participante } from './participante.entity';
import { Negociacao } from './negociacao.entity';
import { Usuario } from './usuario.entity';
import { ClienteDocumento } from './clientedocumento.entity';
//#endregion

export interface Cliente {
  //#region ODataApiGen Properties
  TipoPessoa: EnumTipoPessoa;
  CNPJCPFNum?: string;
  TipoCliente?: string;
  CNPJ?: string;
  CPF?: string;
  NomeCliente?: string;
  NomeFantasia?: string;
  RamoAtividade?: string;
  InscricaoEstadual?: string;
  InscricaoMunicipal?: string;
  ParticipanteId?: number;
  OptanteSimplesNacional: boolean;
  FaturamentoAgrupado: boolean;
  IntegradoERP: boolean;
  StatusIntegracaoERP: EnumStatusIntegracao;
  OperadoraId?: number;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  Participante?: Participante;
  Negociacoes?: Negociacao[];
  ClienteDocumentos?: ClienteDocumento[];
  Contratos?: Contrato[];
  PreEspelhos?: PreEspelho[];
  SubClientes?: Subcliente[];
  SubContratos?: SubContrato[];
  Usuarios?: Usuario[];
  Operadora?: Operadora;
  //#endregion
}