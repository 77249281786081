//#region ODataApiGen ODataImports
import {
  EnumTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumTipoAtendimentoGuia } from './enumtipoatendimentoguia.enum';
//#endregion

//#region ODataApiGen EnumTypeConfig
export const EnumTipoAtendimentoGuiaConfig = {
  name: 'EnumTipoAtendimentoGuia',
  members: EnumTipoAtendimentoGuia,
  fields: {
    TRATAMENTO_ODONTOLOGICO: {value: 1, annotations: [{"term":"Default.Description","string":"Tratamento odontol\u00F3gico"}]},
    EXAME_RADIOLOGICO: {value: 2, annotations: [{"term":"Default.Description","string":"Exame radiol\u00F3gico"}]},
    ORTODONTIA: {value: 3, annotations: [{"term":"Default.Description","string":"Ortodontia"}]},
    URGENCIA_EMERGENCIA: {value: 4, annotations: [{"term":"Default.Description","string":"Urg\u00EAncia / Emerg\u00EAncia"}]},
    AUDITORIA: {value: 5, annotations: [{"term":"Default.Description","string":"Auditoria"}]}
  }
} as EnumTypeConfig<EnumTipoAtendimentoGuia>;
//#endregion