import { Location } from "@angular/common";
import {
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	Renderer2,
} from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { ODataServiceFactory } from "angular-odata";
import { NgxSpinnerService } from "ngx-spinner";
import { take } from "rxjs/operators";
import appSettings from "../../config/app-settings";
import { AlterarSenhaComponent } from "../../core/auth/alterar-senha.component";
import { AuthService } from "../../core/auth/auth.service";
import { MenusControleAcessoHandleService } from "../../services/entities/menus-controle-acesso-handle/menus-controle-acesso-handle.service";
import { Beneficiario } from "../../uniodonto";
import { HeaderService } from "./header.service";

@Component({
	selector: "header",
	templateUrl: "./header.component.html",
	styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnDestroy, OnInit {
	@Input() appSidebarTwo;
	@Output() appSidebarEndToggled = new EventEmitter<boolean>();
	@Output() appSidebarMobileToggled = new EventEmitter<boolean>();
	@Output() appSidebarEndMobileToggled = new EventEmitter<boolean>();

	appSettings = appSettings;

	user: string;
	clientes = [];
	benUsers;

	/**
	 * Gestão de acessos, roles e entidade logada
	 */
	userRolesList = [];
	userOperadorasList = [];
	userClinicasList = [];

	userRoleSelected = "";
	userRoleSelectedHeader = "";
	userEntitySelectedId = 0;
	userEntitySelectedName = "";

	beneficiarioName = "";
	dashId: number;
	isMobile: boolean;

	constructor(
		private renderer: Renderer2,
		private router: Router,
		// private formBuilder: FormBuilder,
		public titleService: Title,
		// private eRef: ElementRef,
		// public benefSrv: BeneficiarioService,
		public _authService: AuthService,
		// public _userService: UsuarioService,
		private ngxSpinner: NgxSpinnerService,
		// config: NgbPopoverConfig,
		private Location: Location,
		private menusControleAcessoHandleService: MenusControleAcessoHandleService,
		private factory: ODataServiceFactory,
		private headerService: HeaderService
	) {}

	ngOnInit() {
		this.getBeneficiarioV2();
		this.headerService.changeRole.subscribe((role) => {
			this.onChangeRole(role);
		});
	}

	redirectUser(path: string) {
		switch (path) {
			case "/beneficiario/login":
				this.router.navigate(["/beneficiario/login"]);
				break;

			// default:
			//   this.router.navigate(["/login"]);
			//   break;
		}
	}

	getBeneficiarioV2() {
		this.benUsers = JSON.parse(localStorage.getItem("ben_users"));
		if (
			this._authService.currentRole === "BENEFICIARIO" &&
			this._authService.isTokenExpired() === false
		) {
			if (!this.benUsers) {
				new Promise<void>((resolve, reject) => {
					this.factory
						.entitySet<Beneficiario>("Beneficiario")
						.entities()
						.query((q) => {
							q.expand({
								Participante: {
									select: [],
									expand: {
										Enderecos: { select: [] },
										Contatos: { select: [] },
									},
								},
							});
						})
						.fetch()
						.subscribe(
							({ entities }) => {
								localStorage.setItem("ben_users", JSON.stringify(entities));
								this.benUsers = JSON.parse(localStorage.getItem("ben_users"));
								this.clientes = this.benUsers;
								this.beneficiarioName = this.benUsers[0].BeneficiarioNome;
								resolve();
								this.reloadUserAcess();
							},
							(error) => {
								reject(error);
							}
						)
						.add();
				});
			} else {
				this.clientes = this.benUsers;
				this.beneficiarioName = this.benUsers[0].BeneficiarioNome;
				this.reloadUserAcess();
			}
		} else {
			this.reloadUserAcess();
		}
	}

	reloadUserAcess(): void {
		const user = this._authService.userData;

		if (!user) {
			this.userRoleSelected = "";
			this.user = "";
			this.redirectUser(this.Location.path());

			return;
		}

		this.userRolesList = user["Roles"];
		this.userClinicasList = user["Clinicas"];
		this.userOperadorasList = user["Operadoras"];
		this.user = user.Nome;

		this.userRoleSelected = this._authService.currentRole;
		this.userRoleSelectedHeader = "BENEFICIARIO";
		switch (this.userRoleSelected) {
			case "OPERADORA":
				let operadora = user.Operadoras?.find(
					(operadora) => operadora.Id == this._authService.currentRoleKey
				);
				this.userEntitySelectedId = operadora.Id;
				this.userEntitySelectedName = this.adicionarReticencias(operadora.Nome);
				break;

			case "CLINICA":
				let clinica = user.Clinicas?.find(
					(clinica) => clinica.Id == this._authService.currentRoleKey
				);
				this.userEntitySelectedId = clinica.Id;
				this.userEntitySelectedName = this.adicionarReticencias(clinica.Nome);
				break;

			case "BENEFICIARIO":
				this.userEntitySelectedId = user.BeneficiarioId;
				this.userEntitySelectedName = this.adicionarReticencias(
					this.beneficiarioName
				);
				this.onChangeDash();
				break;

			case "CLIENTE":
				this.userEntitySelectedId = user.ClienteId;
				this.userEntitySelectedName = this.adicionarReticencias(user.Nome);
				break;
		}

		if (
			this.userRoleSelected == null ||
			this.userRoleSelected == "" ||
			this.userEntitySelectedId == null ||
			this.userEntitySelectedId == 0
		) {
			this.userRoleSelected = "";
			this._authService.signOut();
		}
		this.menusControleAcessoHandleService.initialize();
	}

	adicionarReticencias(string) {
		if (string.length > 25) {
			return string.slice(0, 25) + "...";
		} else {
			return string;
		}
	}

	onChangeDash() {
		this.headerService.atualizarCarteirinha(this.dashId);
	}

	onChangeRole(role: string): void {
		this.ngxSpinner.show();
		this._authService
			.novoToken_WithRole(role, null)
			.pipe(take(1))
			.subscribe((response) => {
				this.router.navigate(["/"]);
				if (this._authService.currentRole === "BENEFICIARIO") {
					this.getBeneficiarioV2();
				} else {
					this.reloadUserAcess();
				}
			})
			.add(() => {
				this.ngxSpinner.hide();
			});
	}

	onChangeEntityRole(id: number, name?): void {
		if (name) {
			this.dashId = id;
			this.beneficiarioName = name;
		}
		this.ngxSpinner.show();

		this._authService
			.novoToken_WithRole(this.userRoleSelected, id)
			.pipe(take(1))
			.subscribe(() => {
				this.reloadUserAcess();
				this.router.navigate(["/"]);
			})
			.add(() => {
				this.ngxSpinner.hide();
			});
	}

	onLogout() {
		let loginURL = this._authService.loginURL;
		this._authService.signOut();
		this.router.navigate([loginURL]);
	}

	toggleAppSidebarMobile() {
		this.appSidebarMobileToggled.emit(true);
	}

	toggleAppSidebarEnd() {
		this.appSidebarEndToggled.emit(true);
	}

	toggleAppSidebarEndMobile() {
		this.appSidebarEndMobileToggled.emit(true);
	}

	toggleAppTopMenuMobile() {
		this.appSettings.appTopMenuMobileToggled =
			!this.appSettings.appTopMenuMobileToggled;
	}

	toggleAppHeaderMegaMenuMobile() {
		this.appSettings.appHeaderMegaMenuMobileToggled =
			!this.appSettings.appHeaderMegaMenuMobileToggled;
	}

	ngOnDestroy() {
		this.appSettings.appTopMenuMobileToggled = false;
		this.appSettings.appHeaderMegaMenuMobileToggled = false;
	}

	changePass() {
		AlterarSenhaComponent.open();
	}
}
