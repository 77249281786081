//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AtoOdontologicoService } from './atoodontologico.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const AtoOdontologicoServiceEntitySetConfig = {
  name: 'AtoOdontologico',
  entityType: 'Uniodonto.Domain.Entities.AtoOdontologico',
  service: AtoOdontologicoService
} as EntitySetConfig;
//#endregion