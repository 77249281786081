//#region ODataApiGen Imports
//#endregion

export enum EnumModuloPortal {
  //#region ODataApiGen Members
  OPERADORA = 0,
  CLINICA = 1,
  BENEFICIARIO = 2,
  CLIENTE = 3,
  //#endregion
}
