//#region ODataApiGen Imports
//#endregion

export enum EnumTipoProduto {
  //#region ODataApiGen Members
  EMPRESARIAL = 0,
  ADESAO = 1,
  INDIVIDUAL = 2,
  FAMILIAR = 3,
  //#endregion
}
