//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Repasse } from './repasse.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const RepasseEntityConfig = {
  name: 'Repasse',
  keys: [{name: 'Id'}],
  fields: {
    Vinculo: {type: 'Edm.Boolean', nullable: false},
    DataRecebimento: {type: 'Edm.DateTimeOffset', nullable: false},
    DataConciliacao: {type: 'Edm.DateTimeOffset', nullable: false},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'}
  }
} as StructuredTypeConfig<Repasse>;
//#endregion