//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { TRMAtoOperadora } from './trmatooperadora.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const TRMAtoOperadoraEntityConfig = {
  name: 'TRMAtoOperadora',
  keys: [{name: 'Id'}],
  fields: {
    AtoOdontologicoId: {type: 'Edm.Int32'},
    QuantidadeMeses: {type: 'Edm.Int32', nullable: false},
    OperadoraId: {type: 'Edm.Int32'},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'},
    AtoOdontologico: {type: 'Uniodonto.Domain.Entities.AtoOdontologico', navigation: true, referentials: [{property: 'AtoOdontologicoId', referencedProperty: 'Id'}]},
    Operadora: {type: 'Uniodonto.Domain.Entities.Operadora', navigation: true, referentials: [{property: 'OperadoraId', referencedProperty: 'Id'}]}
  }
} as StructuredTypeConfig<TRMAtoOperadora>;
//#endregion