//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Operadora } from './operadora.entity';
import { Clinica } from './clinica.entity';
import { Dentista } from './dentista.entity';
//#endregion

export interface ClinicaDentistas {
  //#region ODataApiGen Properties
  DentistaId?: number;
  ClinicaId?: number;
  OperadoraId?: number;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  Dentista?: Dentista;
  Clinica?: Clinica;
  Operadora?: Operadora;
  //#endregion
}