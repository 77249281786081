import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { PreEspelho } from '../Uniodonto/Domain/Entities/preespelho.entity';
import { FaturamentoMovimentacoes } from '../Uniodonto/Domain/Entities/faturamentomovimentacoes.entity';
import { Produto } from '../Uniodonto/Domain/Entities/produto.entity';
//#endregion

@Injectable()
export class FaturamentoMovimentacoesService extends ODataEntitySetService<FaturamentoMovimentacoes> {
  constructor(client: ODataClient) {
    super(client, 'FaturamentoMovimentacoes', 'Uniodonto.Domain.Entities.FaturamentoMovimentacoes');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public preEspelho(key: EntityKey<FaturamentoMovimentacoes>): ODataNavigationPropertyResource<PreEspelho> { 
    return this.entity(key).navigationProperty<PreEspelho>('PreEspelho'); 
  }
  public fetchPreEspelho(key: EntityKey<FaturamentoMovimentacoes>, options?: ODataQueryArgumentsOptions<PreEspelho>) {
    return this.fetchNavigationProperty<PreEspelho>(
      this.preEspelho(key), 
      'entity', options) as Observable<ODataEntity<PreEspelho>>;
  }
  public setPreEspelhoAsPreEspelho(key: EntityKey<FaturamentoMovimentacoes>, target: ODataEntityResource<ODataEntity<PreEspelho>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.preEspelho(key).reference()
      .set(target, {etag});
  }
  public unsetPreEspelhoAsPreEspelho(key: EntityKey<FaturamentoMovimentacoes>, {target, etag}: {target?: ODataEntityResource<ODataEntity<PreEspelho>>, etag?: string} = {}): Observable<any> {
    return this.preEspelho(key).reference()
      .unset({etag});
  }
  public produto(key: EntityKey<FaturamentoMovimentacoes>): ODataNavigationPropertyResource<Produto> { 
    return this.entity(key).navigationProperty<Produto>('Produto'); 
  }
  public fetchProduto(key: EntityKey<FaturamentoMovimentacoes>, options?: ODataQueryArgumentsOptions<Produto>) {
    return this.fetchNavigationProperty<Produto>(
      this.produto(key), 
      'entity', options) as Observable<ODataEntity<Produto>>;
  }
  public setProdutoAsProduto(key: EntityKey<FaturamentoMovimentacoes>, target: ODataEntityResource<ODataEntity<Produto>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.produto(key).reference()
      .set(target, {etag});
  }
  public unsetProdutoAsProduto(key: EntityKey<FaturamentoMovimentacoes>, {target, etag}: {target?: ODataEntityResource<ODataEntity<Produto>>, etag?: string} = {}): Observable<any> {
    return this.produto(key).reference()
      .unset({etag});
  }
  //#endregion
}
