//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { StatusJobV2Service } from './statusjobv2.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const StatusJobV2ServiceEntitySetConfig = {
  name: 'StatusJobV2',
  entityType: 'Uniodonto.Domain.Entities.StatusJob',
  service: StatusJobV2Service
} as EntitySetConfig;
//#endregion