//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumStatusAuditoria } from '../Model/Enum/enumstatusauditoria.enum';
import { EnumStatusProcedimento } from '../Model/Enum/enumstatusprocedimento.enum';
import { EnumStatusLote } from '../Model/Enum/enumstatuslote.enum';
import { Operadora } from './operadora.entity';
import { Clinica } from './clinica.entity';
import { Guia } from './guia.entity';
import { BatchIntegrationLog } from './batchintegrationlog.entity';
//#endregion

export interface Lote {
  //#region ODataApiGen Properties
  Prefixo?: string;
  StatusProcedimento: EnumStatusProcedimento;
  StatusLote: EnumStatusLote;
  Auditor?: string;
  StatusAuditoriaAdministrativa: EnumStatusAuditoria;
  StatusAuditoriaTenica: EnumStatusAuditoria;
  ClinicaId?: number;
  DataExecucaoERP: Date;
  DataConclusao?: Date;
  DataEnvio?: Date;
  OperadoraId?: number;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  Guias?: Guia[];
  BatchIntegrationLogs?: BatchIntegrationLog[];
  Clinica?: Clinica;
  Operadora?: Operadora;
  //#endregion
}