//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
//#endregion

export interface HistoricoRejeitadosProcessamentoSIB {
  //#region ODataApiGen Properties
  Hash?: string;
  NumeroProtocolo?: string;
  NomeArquivo?: string;
  CCO?: string;
  CodigoBeneficiario?: string;
  CodigoTipoMovimento?: string;
  TipoMovimento?: string;
  CodigoCampo?: string;
  DescricaoCampo?: string;
  ValorCampo?: string;
  CodigoErro?: string;
  MensagemErro?: string;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  //#endregion
}