//#region ODataApiGen ODataImports
import {
  EnumTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumTipoGuia } from './enumtipoguia.enum';
//#endregion

//#region ODataApiGen EnumTypeConfig
export const EnumTipoGuiaConfig = {
  name: 'EnumTipoGuia',
  members: EnumTipoGuia,
  fields: {
    NORMAL: {value: 0},
    REEMBOLSO: {value: 1}
  }
} as EnumTypeConfig<EnumTipoGuia>;
//#endregion