//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { FaturamentoMovimentacoesService } from './faturamentomovimentacoes.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const FaturamentoMovimentacoesServiceEntitySetConfig = {
  name: 'FaturamentoMovimentacoes',
  entityType: 'Uniodonto.Domain.Entities.FaturamentoMovimentacoes',
  service: FaturamentoMovimentacoesService
} as EntitySetConfig;
//#endregion