//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { ProfissionalSolicitanteService } from './profissionalsolicitante.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const ProfissionalSolicitanteServiceEntitySetConfig = {
  name: 'ProfissionalSolicitante',
  entityType: 'Uniodonto.Domain.Entities.ProfissionalSolicitante',
  service: ProfissionalSolicitanteService
} as EntitySetConfig;
//#endregion