//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumMetodoPagamento } from '../Model/Enum/enummetodopagamento.enum';
import { Cliente } from './cliente.entity';
import { Contrato } from './contrato.entity';
import { PreEspelhoDetalhe } from './preespelhodetalhe.entity';
import { Operadora } from './operadora.entity';
import { Subcliente } from './subcliente.entity';
import { Titular } from './titular.entity';
import { SubContratoProduto } from './subcontratoproduto.entity';
import { Indice } from './indice.entity';
//#endregion

export interface SubContrato {
  //#region ODataApiGen Properties
  Codigo?: string;
  Titulo?: string;
  ContratoId?: number;
  ClienteId?: number;
  FaturamentoConjunto: boolean;
  DataInicioVigencia: Date;
  DataFimVigencia: Date;
  Prazo: number;
  RenovacaoAutomatica: boolean;
  ExigePermanenciaMinima: boolean;
  MesesPermanenciaMinima?: number;
  Individual: boolean;
  IndiceId?: number;
  MotivoBaixaCancelamento?: string;
  DataUltimaRenovacao?: Date;
  MetodoPagamento: EnumMetodoPagamento;
  CobrancaEmitida: boolean;
  DataEncerramento: Date;
  OperadoraId?: number;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  Contrato?: Contrato;
  Indice?: Indice;
  Cliente?: Cliente;
  Titulares?: Titular[];
  SubContratoProdutos?: SubContratoProduto[];
  PreEspelhoDetalhes?: PreEspelhoDetalhe[];
  SubClientes?: Subcliente[];
  Operadora?: Operadora;
  //#endregion
}