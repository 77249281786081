//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Beneficiario } from './beneficiario.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const BeneficiarioEntityConfig = {
  name: 'Beneficiario',
  keys: [{name: 'Id'}],
  fields: {
    BeneficiarioCpf: {type: 'Edm.String'},
    BeneficiarioCns: {type: 'Edm.String'},
    BeneficiarioNome: {type: 'Edm.String'},
    BeneficiarioNomeMae: {type: 'Edm.String'},
    BeneficiarioNomePai: {type: 'Edm.String'},
    BeneficiarioDataNascimento: {type: 'Edm.DateTimeOffset', nullable: false},
    BeneficiarioIdade: {type: 'Edm.Int32', nullable: false},
    BeneficiarioSexo: {type: 'Edm.String'},
    ParticipanteId: {type: 'Edm.Int32'},
    CCO: {type: 'Edm.String'},
    Intercambio: {type: 'Edm.Boolean', nullable: false},
    OperadoraId: {type: 'Edm.Int32'},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'},
    Participante: {type: 'Uniodonto.Domain.Entities.Participante', navigation: true, referentials: [{property: 'ParticipanteId', referencedProperty: 'Id'}]},
    Titulares: {type: 'Uniodonto.Domain.Entities.Titular', collection: true, navigation: true},
    Dependentes: {type: 'Uniodonto.Domain.Entities.Dependente', collection: true, navigation: true},
    Operadora: {type: 'Uniodonto.Domain.Entities.Operadora', navigation: true, referentials: [{property: 'OperadoraId', referencedProperty: 'Id'}]}
  }
} as StructuredTypeConfig<Beneficiario>;
//#endregion