//#region ODataApiGen ODataImports

//#region ODataApiGen Imports
import { Beneficiario } from "./beneficiario.entity";
import { Carteira } from "./carteira.entity";
import { ContratoProduto } from "./contratoproduto.entity";
import { Dependente } from "./dependente.entity";
import { MotivoDesligamento } from "./motivodesligamento.entity";
import { Operadora } from "./operadora.entity";
import { PerfilCarencia } from "./perfilcarencia.entity";
import { SubContrato } from "./subcontrato.entity";
//#endregion

export interface Titular {
	//#region ODataApiGen Properties
	Ativo: boolean;
	TitularCodigoExterno?: string;
	Codigo?: string;
	TitularSetor?: string;
	TitularObservacoes?: string;
	TitularEmpresa?: string;
	TitularUnidade?: string;
	TitularOutrasInformacoes?: string;
	TitularEnviarDmed: boolean;
	TitularBloquearLancamentos: boolean;
	TitularCountEmissoesDeCartao: number;
	TitularDataInclusao: Date;
	TitularDataDesligamento?: Date;
	DataFinalAtendimento?: Date;
	BeneficiarioId?: number;
	TaxaInscricaoPaga: boolean;
	DataPagamentoTaxaInscricao?: Date;
	MotivoDesligamentoId?: number;
	TitularPossuiCarencia: boolean;
	ContratoProdutoId?: number;
	PerfilCarenciaId?: number;
	Inadimplente: boolean;
	DataDe: Date;
	DataAte?: Date;
	CCO?: string;
	SubContratoId?: number;
	RepasseOperadoraId?: number;
	Intercambio: boolean;
	IntercambioEmergencia: boolean;
	OperadoraId?: number;
	Id: number;
	CriadoEm: Date;
	CriadoPor?: string;
	ModificadoEm?: Date;
	ModificadoPor?: string;
	Beneficiario?: Beneficiario;
	Dependentes?: Dependente[];
	Carteiras?: Carteira[];
	MotivoDesligamento?: MotivoDesligamento;
	ContratoProduto?: ContratoProduto;
	PerfilCarencia?: PerfilCarencia;
	SubContrato?: SubContrato;
	RepasseOperadora?: Operadora;
	Operadora?: Operadora;
	titular: any;
	//#endregion
}
