import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumFormacaoPrecoProduto } from '../Uniodonto/Domain/Model/Enum/enumformacaoprecoproduto.enum';
import { PreEspelhoDetalhe } from '../Uniodonto/Domain/Entities/preespelhodetalhe.entity';
import { PreEspelho } from '../Uniodonto/Domain/Entities/preespelho.entity';
import { Operadora } from '../Uniodonto/Domain/Entities/operadora.entity';
import { Orcamento } from '../Uniodonto/Domain/Entities/orcamento.entity';
import { Guia } from '../Uniodonto/Domain/Entities/guia.entity';
import { ValorProcedimentoTipoProduto } from '../Uniodonto/Domain/Entities/valorprocedimentotipoproduto.entity';
import { Procedimentos } from '../Uniodonto/Domain/Entities/procedimentos.entity';
//#endregion

@Injectable()
export class ValorProcedimentoTipoProdutoService extends ODataEntitySetService<ValorProcedimentoTipoProduto> {
  constructor(client: ODataClient) {
    super(client, 'ValorProcedimentoTipoProduto', 'Uniodonto.Domain.Entities.ValorProcedimentoTipoProduto');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public guia(key: EntityKey<ValorProcedimentoTipoProduto>): ODataNavigationPropertyResource<Guia> { 
    return this.entity(key).navigationProperty<Guia>('Guia'); 
  }
  public fetchGuia(key: EntityKey<ValorProcedimentoTipoProduto>, options?: ODataQueryArgumentsOptions<Guia>) {
    return this.fetchNavigationProperty<Guia>(
      this.guia(key), 
      'entity', options) as Observable<ODataEntity<Guia>>;
  }
  public setGuiaAsGuia(key: EntityKey<ValorProcedimentoTipoProduto>, target: ODataEntityResource<ODataEntity<Guia>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.guia(key).reference()
      .set(target, {etag});
  }
  public unsetGuiaAsGuia(key: EntityKey<ValorProcedimentoTipoProduto>, {target, etag}: {target?: ODataEntityResource<ODataEntity<Guia>>, etag?: string} = {}): Observable<any> {
    return this.guia(key).reference()
      .unset({etag});
  }
  public operadora(key: EntityKey<ValorProcedimentoTipoProduto>): ODataNavigationPropertyResource<Operadora> { 
    return this.entity(key).navigationProperty<Operadora>('Operadora'); 
  }
  public fetchOperadora(key: EntityKey<ValorProcedimentoTipoProduto>, options?: ODataQueryArgumentsOptions<Operadora>) {
    return this.fetchNavigationProperty<Operadora>(
      this.operadora(key), 
      'entity', options) as Observable<ODataEntity<Operadora>>;
  }
  public setOperadoraAsOperadora(key: EntityKey<ValorProcedimentoTipoProduto>, target: ODataEntityResource<ODataEntity<Operadora>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.operadora(key).reference()
      .set(target, {etag});
  }
  public unsetOperadoraAsOperadora(key: EntityKey<ValorProcedimentoTipoProduto>, {target, etag}: {target?: ODataEntityResource<ODataEntity<Operadora>>, etag?: string} = {}): Observable<any> {
    return this.operadora(key).reference()
      .unset({etag});
  }
  public orcamento(key: EntityKey<ValorProcedimentoTipoProduto>): ODataNavigationPropertyResource<Orcamento> { 
    return this.entity(key).navigationProperty<Orcamento>('Orcamento'); 
  }
  public fetchOrcamento(key: EntityKey<ValorProcedimentoTipoProduto>, options?: ODataQueryArgumentsOptions<Orcamento>) {
    return this.fetchNavigationProperty<Orcamento>(
      this.orcamento(key), 
      'entity', options) as Observable<ODataEntity<Orcamento>>;
  }
  public setOrcamentoAsOrcamento(key: EntityKey<ValorProcedimentoTipoProduto>, target: ODataEntityResource<ODataEntity<Orcamento>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.orcamento(key).reference()
      .set(target, {etag});
  }
  public unsetOrcamentoAsOrcamento(key: EntityKey<ValorProcedimentoTipoProduto>, {target, etag}: {target?: ODataEntityResource<ODataEntity<Orcamento>>, etag?: string} = {}): Observable<any> {
    return this.orcamento(key).reference()
      .unset({etag});
  }
  public preEspelho(key: EntityKey<ValorProcedimentoTipoProduto>): ODataNavigationPropertyResource<PreEspelho> { 
    return this.entity(key).navigationProperty<PreEspelho>('PreEspelho'); 
  }
  public fetchPreEspelho(key: EntityKey<ValorProcedimentoTipoProduto>, options?: ODataQueryArgumentsOptions<PreEspelho>) {
    return this.fetchNavigationProperty<PreEspelho>(
      this.preEspelho(key), 
      'entity', options) as Observable<ODataEntity<PreEspelho>>;
  }
  public setPreEspelhoAsPreEspelho(key: EntityKey<ValorProcedimentoTipoProduto>, target: ODataEntityResource<ODataEntity<PreEspelho>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.preEspelho(key).reference()
      .set(target, {etag});
  }
  public unsetPreEspelhoAsPreEspelho(key: EntityKey<ValorProcedimentoTipoProduto>, {target, etag}: {target?: ODataEntityResource<ODataEntity<PreEspelho>>, etag?: string} = {}): Observable<any> {
    return this.preEspelho(key).reference()
      .unset({etag});
  }
  public preEspelhoDetalhe(key: EntityKey<ValorProcedimentoTipoProduto>): ODataNavigationPropertyResource<PreEspelhoDetalhe> { 
    return this.entity(key).navigationProperty<PreEspelhoDetalhe>('PreEspelhoDetalhe'); 
  }
  public fetchPreEspelhoDetalhe(key: EntityKey<ValorProcedimentoTipoProduto>, options?: ODataQueryArgumentsOptions<PreEspelhoDetalhe>) {
    return this.fetchNavigationProperty<PreEspelhoDetalhe>(
      this.preEspelhoDetalhe(key), 
      'entity', options) as Observable<ODataEntity<PreEspelhoDetalhe>>;
  }
  public setPreEspelhoDetalheAsPreEspelhoDetalhe(key: EntityKey<ValorProcedimentoTipoProduto>, target: ODataEntityResource<ODataEntity<PreEspelhoDetalhe>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.preEspelhoDetalhe(key).reference()
      .set(target, {etag});
  }
  public unsetPreEspelhoDetalheAsPreEspelhoDetalhe(key: EntityKey<ValorProcedimentoTipoProduto>, {target, etag}: {target?: ODataEntityResource<ODataEntity<PreEspelhoDetalhe>>, etag?: string} = {}): Observable<any> {
    return this.preEspelhoDetalhe(key).reference()
      .unset({etag});
  }
  public procedimento(key: EntityKey<ValorProcedimentoTipoProduto>): ODataNavigationPropertyResource<Procedimentos> { 
    return this.entity(key).navigationProperty<Procedimentos>('Procedimento'); 
  }
  public fetchProcedimento(key: EntityKey<ValorProcedimentoTipoProduto>, options?: ODataQueryArgumentsOptions<Procedimentos>) {
    return this.fetchNavigationProperty<Procedimentos>(
      this.procedimento(key), 
      'entity', options) as Observable<ODataEntity<Procedimentos>>;
  }
  public setProcedimentosAsProcedimento(key: EntityKey<ValorProcedimentoTipoProduto>, target: ODataEntityResource<ODataEntity<Procedimentos>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.procedimento(key).reference()
      .set(target, {etag});
  }
  public unsetProcedimentosAsProcedimento(key: EntityKey<ValorProcedimentoTipoProduto>, {target, etag}: {target?: ODataEntityResource<ODataEntity<Procedimentos>>, etag?: string} = {}): Observable<any> {
    return this.procedimento(key).reference()
      .unset({etag});
  }
  //#endregion
}
