//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Contrato } from './contrato.entity';
import { Operadora } from './operadora.entity';
import { PerfilCobranca } from './perfilcobranca.entity';
import { Produto } from './produto.entity';
import { PerfilCarencia } from './perfilcarencia.entity';
//#endregion

export interface ContratoProduto {
  //#region ODataApiGen Properties
  ContratoId?: number;
  ProdutoId?: number;
  PerfilCobrancaId?: number;
  VigenciaDoProduto: Date;
  PerfilCarenciaId?: number;
  ValorMensalidade: number;
  ValorMensalidadeAgregado: number;
  ValorTaxaInscricao: number;
  ValorTaxaInscricaoDependente: number;
  ValorTaxaInscricaoAgregado: number;
  ValorSegundaViaCarteirinha: number;
  ValorSegundaViaCarteirinhaDependente: number;
  ValorSegundaViaCarteirinhaAgregado: number;
  ValorMensalidadeDependente: number;
  UltimaAtualizacaoReajuste?: Date;
  ModalidadeContratacao?: string;
  PercentualPatrocinio?: number;
  NomeDivulgacao?: string;
  Intercambio: boolean;
  IntercambioEmergencia: boolean;
  OperadoraId?: number;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  Contrato?: Contrato;
  Produto?: Produto;
  PerfilCobranca?: PerfilCobranca;
  PerfilCarencia?: PerfilCarencia;
  Operadora?: Operadora;
  //#endregion
}