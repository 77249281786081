import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Operadora } from '../Uniodonto/Domain/Entities/operadora.entity';
import { ReajusteContratual } from '../Uniodonto/Domain/Entities/reajustecontratual.entity';
import { SubContrato } from '../Uniodonto/Domain/Entities/subcontrato.entity';
import { SubContratoProduto } from '../Uniodonto/Domain/Entities/subcontratoproduto.entity';
import { Produto } from '../Uniodonto/Domain/Entities/produto.entity';
//#endregion

@Injectable()
export class SubContratoProdutoService extends ODataEntitySetService<SubContratoProduto> {
  constructor(client: ODataClient) {
    super(client, 'SubContratoProduto', 'Uniodonto.Domain.Entities.SubContratoProduto');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public operadora(key: EntityKey<SubContratoProduto>): ODataNavigationPropertyResource<Operadora> { 
    return this.entity(key).navigationProperty<Operadora>('Operadora'); 
  }
  public fetchOperadora(key: EntityKey<SubContratoProduto>, options?: ODataQueryArgumentsOptions<Operadora>) {
    return this.fetchNavigationProperty<Operadora>(
      this.operadora(key), 
      'entity', options) as Observable<ODataEntity<Operadora>>;
  }
  public setOperadoraAsOperadora(key: EntityKey<SubContratoProduto>, target: ODataEntityResource<ODataEntity<Operadora>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.operadora(key).reference()
      .set(target, {etag});
  }
  public unsetOperadoraAsOperadora(key: EntityKey<SubContratoProduto>, {target, etag}: {target?: ODataEntityResource<ODataEntity<Operadora>>, etag?: string} = {}): Observable<any> {
    return this.operadora(key).reference()
      .unset({etag});
  }
  public produto(key: EntityKey<SubContratoProduto>): ODataNavigationPropertyResource<Produto> { 
    return this.entity(key).navigationProperty<Produto>('Produto'); 
  }
  public fetchProduto(key: EntityKey<SubContratoProduto>, options?: ODataQueryArgumentsOptions<Produto>) {
    return this.fetchNavigationProperty<Produto>(
      this.produto(key), 
      'entity', options) as Observable<ODataEntity<Produto>>;
  }
  public setProdutoAsProduto(key: EntityKey<SubContratoProduto>, target: ODataEntityResource<ODataEntity<Produto>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.produto(key).reference()
      .set(target, {etag});
  }
  public unsetProdutoAsProduto(key: EntityKey<SubContratoProduto>, {target, etag}: {target?: ODataEntityResource<ODataEntity<Produto>>, etag?: string} = {}): Observable<any> {
    return this.produto(key).reference()
      .unset({etag});
  }
  public reajustesContratuais(key: EntityKey<SubContratoProduto>): ODataNavigationPropertyResource<ReajusteContratual> { 
    return this.entity(key).navigationProperty<ReajusteContratual>('ReajustesContratuais'); 
  }
  public fetchReajustesContratuais(key: EntityKey<SubContratoProduto>, options?: ODataQueryArgumentsOptions<ReajusteContratual>) {
    return this.fetchNavigationProperty<ReajusteContratual>(
      this.reajustesContratuais(key), 
      'entities', options) as Observable<ODataEntities<ReajusteContratual>>;
  }
  public addReajusteContratualToReajustesContratuais(key: EntityKey<SubContratoProduto>, target: ODataEntityResource<ODataEntities<ReajusteContratual>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.reajustesContratuais(key).reference()
      .add(target);
  }
  public removeReajusteContratualFromReajustesContratuais(key: EntityKey<SubContratoProduto>, {target, etag}: {target?: ODataEntityResource<ODataEntities<ReajusteContratual>>, etag?: string} = {}): Observable<any> {
    return this.reajustesContratuais(key).reference()
      .remove(target);
  }
  public subContrato(key: EntityKey<SubContratoProduto>): ODataNavigationPropertyResource<SubContrato> { 
    return this.entity(key).navigationProperty<SubContrato>('SubContrato'); 
  }
  public fetchSubContrato(key: EntityKey<SubContratoProduto>, options?: ODataQueryArgumentsOptions<SubContrato>) {
    return this.fetchNavigationProperty<SubContrato>(
      this.subContrato(key), 
      'entity', options) as Observable<ODataEntity<SubContrato>>;
  }
  public setSubContratoAsSubContrato(key: EntityKey<SubContratoProduto>, target: ODataEntityResource<ODataEntity<SubContrato>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.subContrato(key).reference()
      .set(target, {etag});
  }
  public unsetSubContratoAsSubContrato(key: EntityKey<SubContratoProduto>, {target, etag}: {target?: ODataEntityResource<ODataEntity<SubContrato>>, etag?: string} = {}): Observable<any> {
    return this.subContrato(key).reference()
      .unset({etag});
  }
  //#endregion
}
