//#region ODataApiGen Imports
//#endregion

export enum EnumStatusRecursoProcedimento {
  //#region ODataApiGen Members
  AGUARDANDO_RECURSO_DENTISTA = 1,
  AGUARDANDO_ANALISE_AUDITOR = 2,
  ACEITO_AUDITOR = 3,
  RECUSADO_AUDITOR = 4,
  //#endregion
}
