//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { PreEspelhoService } from './preespelho.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const PreEspelhoServiceEntitySetConfig = {
  name: 'PreEspelho',
  entityType: 'Uniodonto.Domain.Entities.PreEspelho',
  service: PreEspelhoService
} as EntitySetConfig;
//#endregion