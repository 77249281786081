//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Endereco } from './endereco.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const EnderecoEntityConfig = {
  name: 'Endereco',
  keys: [{name: 'Id'}],
  fields: {
    EnderecoNumero: {type: 'Edm.String'},
    EnderecoRua: {type: 'Edm.String'},
    EnderecoComplemento: {type: 'Edm.String'},
    EnderecoCaixaPostal: {type: 'Edm.String'},
    TipoEndereco: {type: 'Edm.String'},
    CEPId: {type: 'Edm.Int32'},
    CEPCodigo: {type: 'Edm.String'},
    ParticipanteId: {type: 'Edm.Int32'},
    OperadoraId: {type: 'Edm.Int32'},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'},
    CEP: {type: 'Uniodonto.Domain.Entities.CEP', navigation: true, referentials: [{property: 'CEPId', referencedProperty: 'Id'}]},
    EnderecosBeneficiario: {type: 'Uniodonto.Domain.Entities.EnderecoBeneficiario', collection: true, navigation: true},
    Participante: {type: 'Uniodonto.Domain.Entities.Participante', navigation: true, referentials: [{property: 'ParticipanteId', referencedProperty: 'Id'}]},
    Operadora: {type: 'Uniodonto.Domain.Entities.Operadora', navigation: true, referentials: [{property: 'OperadoraId', referencedProperty: 'Id'}]}
  }
} as StructuredTypeConfig<Endereco>;
//#endregion