//#region ODataApiGen Imports
//#endregion

export enum EnumTipoOrcamento {
  //#region ODataApiGen Members
  NORMAL = 0,
  REEMBOLSO = 1,
  INTERCAMBIO = 2,
  //#endregion
}
