//#region ODataApiGen ODataImports
import {
  EnumTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumStatusLote } from './enumstatuslote.enum';
//#endregion

//#region ODataApiGen EnumTypeConfig
export const EnumStatusLoteConfig = {
  name: 'EnumStatusLote',
  members: EnumStatusLote,
  fields: {
    SEM_LOTE: {value: 0, annotations: [{"term":"Default.Description","string":"S/L"}]},
    LOTE_CRIADO: {value: 1, annotations: [{"term":"Default.Description","string":"Lote Criado"}]},
    LOTE_ENVIADO: {value: 2, annotations: [{"term":"Default.Description","string":"Lote Enviado"}]},
    LOTE_EM_ANALISE: {value: 3, annotations: [{"term":"Default.Description","string":"Lote em An\u00E1lise"}]},
    LOTE_GLOSA: {value: 4, annotations: [{"term":"Default.Description","string":"Lote com Glosa"}]},
    LOTE_CONCLUIDO: {value: 5, annotations: [{"term":"Default.Description","string":"Lote Concluido"}]},
    LOTE_ENVIADO_PARA_FILA_ERP: {value: 6, annotations: [{"term":"Default.Description","string":"Lote enviado para fila ERP"}]},
    LOTE_REJEITADO_ERP: {value: 7, annotations: [{"term":"Default.Description","string":"Lote rejeitado pelo ERP"}]},
    LOTE_EXECUTADO_ERP: {value: 8, annotations: [{"term":"Default.Description","string":"Lote executado pelo ERP"}]}
  }
} as EnumTypeConfig<EnumStatusLote>;
//#endregion