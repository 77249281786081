//#region ODataApiGen ODataImports
import {
  EnumTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumTipoProduto } from './enumtipoproduto.enum';
//#endregion

//#region ODataApiGen EnumTypeConfig
export const EnumTipoProdutoConfig = {
  name: 'EnumTipoProduto',
  members: EnumTipoProduto,
  fields: {
    EMPRESARIAL: {value: 0},
    ADESAO: {value: 1},
    INDIVIDUAL: {value: 2},
    FAMILIAR: {value: 3}
  }
} as EnumTypeConfig<EnumTipoProduto>;
//#endregion