//#region ODataApiGen ODataImports
import {
  EnumTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumTipoComissao } from './enumtipocomissao.enum';
//#endregion

//#region ODataApiGen EnumTypeConfig
export const EnumTipoComissaoConfig = {
  name: 'EnumTipoComissao',
  members: EnumTipoComissao,
  fields: {
    INDIVIDUAL: {value: 1, annotations: [{"term":"Default.Description","string":"Individual"}]},
    COLETIVO: {value: 2, annotations: [{"term":"Default.Description","string":"Coletivo"}]}
  }
} as EnumTypeConfig<EnumTipoComissao>;
//#endregion