//#region ODataApiGen ODataImports
import {
  EnumTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumStatusCarteira } from './enumstatuscarteira.enum';
//#endregion

//#region ODataApiGen EnumTypeConfig
export const EnumStatusCarteiraConfig = {
  name: 'EnumStatusCarteira',
  members: EnumStatusCarteira,
  fields: {
    undefined: {value: 0, annotations: [{"term":"Default.Description","string":"N\u00E3o definido"}]},
    EMITIDO: {value: 1, annotations: [{"term":"Default.Description","string":"Emitido"}]},
    CANCELADO: {value: 2, annotations: [{"term":"Default.Description","string":"Cancelado"}]},
    CARTEIRA_EXPIRADO: {value: 3, annotations: [{"term":"Default.Description","string":"CarteiraExpirada"}]},
    AGUARDANDO_EMISSAO: {value: 4, annotations: [{"term":"Default.Description","string":"Aguardando Emiss\u00E3o"}]},
    CARTEIRA_EXPIRADA_BENEFICIARIOPRODUTO: {value: 5, annotations: [{"term":"Default.Description","string":"BeneficiarioProdutosExpirado"}]}
  }
} as EnumTypeConfig<EnumStatusCarteira>;
//#endregion