//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { ImportacaoBeneficiario } from './importacaobeneficiario.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const ImportacaoBeneficiarioEntityConfig = {
  name: 'ImportacaoBeneficiario',
  keys: [{name: 'Id'}],
  fields: {
    IsTitular: {type: 'Edm.Boolean', nullable: false},
    DocumentoTitularBeneficiario: {type: 'Edm.String'},
    BeneficiarioCns: {type: 'Edm.String'},
    BeneficiarioCpf: {type: 'Edm.String'},
    BeneficiarioSexo: {type: 'Edm.String'},
    BeneficiarioDataNascimento: {type: 'Edm.DateTimeOffset'},
    BeneficiarioIdade: {type: 'Edm.Int32'},
    BeneficiarioNome: {type: 'Edm.String'},
    BeneficiarioNomeMae: {type: 'Edm.String'},
    BeneficiarioNomePai: {type: 'Edm.String'},
    CodigoCarteira: {type: 'Edm.String'},
    CCO: {type: 'Edm.String'},
    TitularCodigoExterno: {type: 'Edm.String'},
    TitularSetor: {type: 'Edm.String'},
    TitularObservacoes: {type: 'Edm.String'},
    TitularUnidade: {type: 'Edm.String'},
    TitularOutrasInformacoes: {type: 'Edm.String'},
    TitularPerfilSemCarencia: {type: 'Edm.Boolean', nullable: false},
    TitularEnviarDmed: {type: 'Edm.Boolean', nullable: false},
    TitularBloquearLancamentos: {type: 'Edm.Boolean', nullable: false},
    TitularDataInclusao: {type: 'Edm.DateTimeOffset'},
    ContatoObservacao: {type: 'Edm.String'},
    ContatoNome: {type: 'Edm.String'},
    ContatoEmail: {type: 'Edm.String'},
    ContatoTelefone: {type: 'Edm.String'},
    ContatoCelular: {type: 'Edm.String'},
    EnderecoCep: {type: 'Edm.String'},
    EnderecoRua: {type: 'Edm.String'},
    EnderecoBairro: {type: 'Edm.String'},
    EnderecoCidade: {type: 'Edm.String'},
    EnderecoUf: {type: 'Edm.String'},
    TipoEndereco: {type: 'Edm.String'},
    IbgeCodigo: {type: 'Edm.String'},
    EnderecoNumero: {type: 'Edm.String'},
    EnderecoComplemento: {type: 'Edm.String'},
    UfIbge: {type: 'Edm.String'},
    UfNome: {type: 'Edm.String'},
    EnderecoCaixaPostal: {type: 'Edm.String'},
    RegistroCompleto: {type: 'Edm.Boolean', nullable: false},
    TitularId: {type: 'Edm.Int32'},
    BeneficiarioId: {type: 'Edm.Int32'},
    EnderecoId: {type: 'Edm.Int32'},
    GrupoFamiliarId: {type: 'Edm.Int32'},
    ContratoId: {type: 'Edm.Int32'},
    DarBaixa: {type: 'Edm.Boolean', nullable: false},
    DataDesligamento: {type: 'Edm.DateTimeOffset'},
    DataFimAtendimento: {type: 'Edm.DateTimeOffset'},
    MotivoDesligamentoId: {type: 'Edm.Int32'},
    QuantidadeErros: {type: 'Edm.Int32', nullable: false},
    PerfilCarenciaId: {type: 'Edm.Int32'},
    Log: {type: 'Edm.String'},
    Status: {type: 'Uniodonto.Domain.Model.Enum.EnumStatusImportacaoBeneficiario', nullable: false},
    JobId: {type: 'Edm.String'},
    SubContratoId: {type: 'Edm.Int32'},
    ContratoOperadoraRepasseId: {type: 'Edm.Int32'},
    ContratoProdutoId: {type: 'Edm.Int32'},
    OperadoraId: {type: 'Edm.Int32'},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'},
    Titular: {type: 'Uniodonto.Domain.Entities.Titular', navigation: true, referentials: [{property: 'TitularId', referencedProperty: 'Id'}]},
    Beneficiario: {type: 'Uniodonto.Domain.Entities.Beneficiario', navigation: true, referentials: [{property: 'BeneficiarioId', referencedProperty: 'Id'}]},
    Endereco: {type: 'Uniodonto.Domain.Entities.Endereco', navigation: true, referentials: [{property: 'EnderecoId', referencedProperty: 'Id'}]},
    GrupoFamiliar: {type: 'Uniodonto.Domain.Entities.GrupoFamiliar', navigation: true, referentials: [{property: 'GrupoFamiliarId', referencedProperty: 'Id'}]},
    Contrato: {type: 'Uniodonto.Domain.Entities.Contrato', navigation: true, referentials: [{property: 'ContratoId', referencedProperty: 'Id'}]},
    MotivoDesligamento: {type: 'Uniodonto.Domain.Entities.MotivoDesligamento', navigation: true, referentials: [{property: 'MotivoDesligamentoId', referencedProperty: 'Id'}]},
    PerfilCarencia: {type: 'Uniodonto.Domain.Entities.PerfilCarencia', navigation: true, referentials: [{property: 'PerfilCarenciaId', referencedProperty: 'Id'}]},
    SubContrato: {type: 'Uniodonto.Domain.Entities.SubContrato', navigation: true, referentials: [{property: 'SubContratoId', referencedProperty: 'Id'}]},
    ContratoOperadoraRepasse: {type: 'Uniodonto.Domain.Entities.ContratoOperadoraRepasse', navigation: true, referentials: [{property: 'ContratoOperadoraRepasseId', referencedProperty: 'Id'}]},
    ContratoProduto: {type: 'Uniodonto.Domain.Entities.ContratoProduto', navigation: true, referentials: [{property: 'ContratoProdutoId', referencedProperty: 'Id'}]},
    Operadora: {type: 'Uniodonto.Domain.Entities.Operadora', navigation: true, referentials: [{property: 'OperadoraId', referencedProperty: 'Id'}]}
  }
} as StructuredTypeConfig<ImportacaoBeneficiario>;
//#endregion