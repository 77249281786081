//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { UsuarioClinica } from './usuarioclinica.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const UsuarioClinicaEntityConfig = {
  name: 'UsuarioClinica',
  keys: [{name: 'Id'}],
  fields: {
    Id: {type: 'Edm.Int32', nullable: false},
    UsuarioId: {type: 'Edm.Int32'},
    ClinicaId: {type: 'Edm.Int32'},
    ControleAcessoId: {type: 'Edm.Int32'},
    DentistaId: {type: 'Edm.Int32'},
    OperadoraId: {type: 'Edm.Int32'},
    Usuario: {type: 'Uniodonto.Domain.Entities.Usuario', navigation: true, referentials: [{property: 'UsuarioId', referencedProperty: 'Id'}]},
    Clinica: {type: 'Uniodonto.Domain.Entities.Clinica', navigation: true, referentials: [{property: 'ClinicaId', referencedProperty: 'Id'}]},
    ControleAcesso: {type: 'Uniodonto.Domain.Entities.ControleAcesso', navigation: true, referentials: [{property: 'ControleAcessoId', referencedProperty: 'Id'}]},
    Dentista: {type: 'Uniodonto.Domain.Entities.Dentista', navigation: true, referentials: [{property: 'DentistaId', referencedProperty: 'Id'}]},
    Operadora: {type: 'Uniodonto.Domain.Entities.Operadora', navigation: true, referentials: [{property: 'OperadoraId', referencedProperty: 'Id'}]}
  }
} as StructuredTypeConfig<UsuarioClinica>;
//#endregion