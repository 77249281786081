//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { LogJobV2Service } from './logjobv2.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const LogJobV2ServiceEntitySetConfig = {
  name: 'LogJobV2',
  entityType: 'Uniodonto.Domain.Entities.LogJob',
  service: LogJobV2Service
} as EntitySetConfig;
//#endregion