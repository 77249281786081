import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumStatusFaturamento } from '../Uniodonto/Domain/Model/Enum/enumstatusfaturamento.enum';
import { PreEspelhoDetalhe } from '../Uniodonto/Domain/Entities/preespelhodetalhe.entity';
import { PreEspelho } from '../Uniodonto/Domain/Entities/preespelho.entity';
import { Operadora } from '../Uniodonto/Domain/Entities/operadora.entity';
import { Dependente } from '../Uniodonto/Domain/Entities/dependente.entity';
import { SubContrato } from '../Uniodonto/Domain/Entities/subcontrato.entity';
import { ValorProcedimentoTipoProduto } from '../Uniodonto/Domain/Entities/valorprocedimentotipoproduto.entity';
import { Produto } from '../Uniodonto/Domain/Entities/produto.entity';
//#endregion

@Injectable()
export class PreEspelhoDetalheService extends ODataEntitySetService<PreEspelhoDetalhe> {
  constructor(client: ODataClient) {
    super(client, 'PreEspelhoDetalhe', 'Uniodonto.Domain.Entities.PreEspelhoDetalhe');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public dependente(key: EntityKey<PreEspelhoDetalhe>): ODataNavigationPropertyResource<Dependente> { 
    return this.entity(key).navigationProperty<Dependente>('Dependente'); 
  }
  public fetchDependente(key: EntityKey<PreEspelhoDetalhe>, options?: ODataQueryArgumentsOptions<Dependente>) {
    return this.fetchNavigationProperty<Dependente>(
      this.dependente(key), 
      'entity', options) as Observable<ODataEntity<Dependente>>;
  }
  public setDependenteAsDependente(key: EntityKey<PreEspelhoDetalhe>, target: ODataEntityResource<ODataEntity<Dependente>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.dependente(key).reference()
      .set(target, {etag});
  }
  public unsetDependenteAsDependente(key: EntityKey<PreEspelhoDetalhe>, {target, etag}: {target?: ODataEntityResource<ODataEntity<Dependente>>, etag?: string} = {}): Observable<any> {
    return this.dependente(key).reference()
      .unset({etag});
  }
  public operadora(key: EntityKey<PreEspelhoDetalhe>): ODataNavigationPropertyResource<Operadora> { 
    return this.entity(key).navigationProperty<Operadora>('Operadora'); 
  }
  public fetchOperadora(key: EntityKey<PreEspelhoDetalhe>, options?: ODataQueryArgumentsOptions<Operadora>) {
    return this.fetchNavigationProperty<Operadora>(
      this.operadora(key), 
      'entity', options) as Observable<ODataEntity<Operadora>>;
  }
  public setOperadoraAsOperadora(key: EntityKey<PreEspelhoDetalhe>, target: ODataEntityResource<ODataEntity<Operadora>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.operadora(key).reference()
      .set(target, {etag});
  }
  public unsetOperadoraAsOperadora(key: EntityKey<PreEspelhoDetalhe>, {target, etag}: {target?: ODataEntityResource<ODataEntity<Operadora>>, etag?: string} = {}): Observable<any> {
    return this.operadora(key).reference()
      .unset({etag});
  }
  public operadoraRepasse(key: EntityKey<PreEspelhoDetalhe>): ODataNavigationPropertyResource<Operadora> { 
    return this.entity(key).navigationProperty<Operadora>('OperadoraRepasse'); 
  }
  public fetchOperadoraRepasse(key: EntityKey<PreEspelhoDetalhe>, options?: ODataQueryArgumentsOptions<Operadora>) {
    return this.fetchNavigationProperty<Operadora>(
      this.operadoraRepasse(key), 
      'entity', options) as Observable<ODataEntity<Operadora>>;
  }
  public setOperadoraAsOperadoraRepasse(key: EntityKey<PreEspelhoDetalhe>, target: ODataEntityResource<ODataEntity<Operadora>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.operadoraRepasse(key).reference()
      .set(target, {etag});
  }
  public unsetOperadoraAsOperadoraRepasse(key: EntityKey<PreEspelhoDetalhe>, {target, etag}: {target?: ODataEntityResource<ODataEntity<Operadora>>, etag?: string} = {}): Observable<any> {
    return this.operadoraRepasse(key).reference()
      .unset({etag});
  }
  public preEspelho(key: EntityKey<PreEspelhoDetalhe>): ODataNavigationPropertyResource<PreEspelho> { 
    return this.entity(key).navigationProperty<PreEspelho>('PreEspelho'); 
  }
  public fetchPreEspelho(key: EntityKey<PreEspelhoDetalhe>, options?: ODataQueryArgumentsOptions<PreEspelho>) {
    return this.fetchNavigationProperty<PreEspelho>(
      this.preEspelho(key), 
      'entity', options) as Observable<ODataEntity<PreEspelho>>;
  }
  public setPreEspelhoAsPreEspelho(key: EntityKey<PreEspelhoDetalhe>, target: ODataEntityResource<ODataEntity<PreEspelho>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.preEspelho(key).reference()
      .set(target, {etag});
  }
  public unsetPreEspelhoAsPreEspelho(key: EntityKey<PreEspelhoDetalhe>, {target, etag}: {target?: ODataEntityResource<ODataEntity<PreEspelho>>, etag?: string} = {}): Observable<any> {
    return this.preEspelho(key).reference()
      .unset({etag});
  }
  public produto(key: EntityKey<PreEspelhoDetalhe>): ODataNavigationPropertyResource<Produto> { 
    return this.entity(key).navigationProperty<Produto>('Produto'); 
  }
  public fetchProduto(key: EntityKey<PreEspelhoDetalhe>, options?: ODataQueryArgumentsOptions<Produto>) {
    return this.fetchNavigationProperty<Produto>(
      this.produto(key), 
      'entity', options) as Observable<ODataEntity<Produto>>;
  }
  public setProdutoAsProduto(key: EntityKey<PreEspelhoDetalhe>, target: ODataEntityResource<ODataEntity<Produto>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.produto(key).reference()
      .set(target, {etag});
  }
  public unsetProdutoAsProduto(key: EntityKey<PreEspelhoDetalhe>, {target, etag}: {target?: ODataEntityResource<ODataEntity<Produto>>, etag?: string} = {}): Observable<any> {
    return this.produto(key).reference()
      .unset({etag});
  }
  public subContrato(key: EntityKey<PreEspelhoDetalhe>): ODataNavigationPropertyResource<SubContrato> { 
    return this.entity(key).navigationProperty<SubContrato>('SubContrato'); 
  }
  public fetchSubContrato(key: EntityKey<PreEspelhoDetalhe>, options?: ODataQueryArgumentsOptions<SubContrato>) {
    return this.fetchNavigationProperty<SubContrato>(
      this.subContrato(key), 
      'entity', options) as Observable<ODataEntity<SubContrato>>;
  }
  public setSubContratoAsSubContrato(key: EntityKey<PreEspelhoDetalhe>, target: ODataEntityResource<ODataEntity<SubContrato>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.subContrato(key).reference()
      .set(target, {etag});
  }
  public unsetSubContratoAsSubContrato(key: EntityKey<PreEspelhoDetalhe>, {target, etag}: {target?: ODataEntityResource<ODataEntity<SubContrato>>, etag?: string} = {}): Observable<any> {
    return this.subContrato(key).reference()
      .unset({etag});
  }
  public valorProcedimentoTipoProdutos(key: EntityKey<PreEspelhoDetalhe>): ODataNavigationPropertyResource<ValorProcedimentoTipoProduto> { 
    return this.entity(key).navigationProperty<ValorProcedimentoTipoProduto>('ValorProcedimentoTipoProdutos'); 
  }
  public fetchValorProcedimentoTipoProdutos(key: EntityKey<PreEspelhoDetalhe>, options?: ODataQueryArgumentsOptions<ValorProcedimentoTipoProduto>) {
    return this.fetchNavigationProperty<ValorProcedimentoTipoProduto>(
      this.valorProcedimentoTipoProdutos(key), 
      'entities', options) as Observable<ODataEntities<ValorProcedimentoTipoProduto>>;
  }
  public addValorProcedimentoTipoProdutoToValorProcedimentoTipoProdutos(key: EntityKey<PreEspelhoDetalhe>, target: ODataEntityResource<ODataEntities<ValorProcedimentoTipoProduto>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.valorProcedimentoTipoProdutos(key).reference()
      .add(target);
  }
  public removeValorProcedimentoTipoProdutoFromValorProcedimentoTipoProdutos(key: EntityKey<PreEspelhoDetalhe>, {target, etag}: {target?: ODataEntityResource<ODataEntities<ValorProcedimentoTipoProduto>>, etag?: string} = {}): Observable<any> {
    return this.valorProcedimentoTipoProdutos(key).reference()
      .remove(target);
  }
  //#endregion
}
