//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumStatusLoteJob } from '../Model/Enum/enumstatuslotejob.enum';
import { EnumTipoLoteJob } from '../Model/Enum/enumtipolotejob.enum';
import { StatusJob } from './statusjob.entity';
//#endregion

export interface Job {
  //#region ODataApiGen Properties
  NomeJob?: string;
  DescricaoJob?: string;
  RecorrenciaJob?: string;
  StatusJobId?: number;
  StatusLoteJob: EnumStatusLoteJob;
  TipoLoteJob: EnumTipoLoteJob;
  JobCommand?: string;
  JobResult?: string;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  StatusJob?: StatusJob;
  //#endregion
}