//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Cidade } from './cidade.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const CidadeEntityConfig = {
  name: 'Cidade',
  keys: [{name: 'Id'}],
  fields: {
    CidadeIbgeCod: {type: 'Edm.String'},
    CidadeNome: {type: 'Edm.String'},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'},
    Estado: {type: 'Uniodonto.Domain.Entities.Estado', navigation: true}
  }
} as StructuredTypeConfig<Cidade>;
//#endregion