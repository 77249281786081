//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { RacaService } from './raca.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const RacaServiceEntitySetConfig = {
  name: 'Raca',
  entityType: 'Uniodonto.Domain.Entities.Raca',
  service: RacaService
} as EntitySetConfig;
//#endregion