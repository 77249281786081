//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { GrupoFamiliarContratoV2Service } from './grupofamiliarcontratov2.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const GrupoFamiliarContratoV2ServiceEntitySetConfig = {
  name: 'GrupoFamiliarContratoV2',
  entityType: 'Uniodonto.Domain.Entities.GrupoFamiliarContrato',
  service: GrupoFamiliarContratoV2Service
} as EntitySetConfig;
//#endregion