//#region ODataApiGen ODataImports
import {
  EnumTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumTipoPrecoContratoProdutoAto } from './enumtipoprecocontratoprodutoato.enum';
//#endregion

//#region ODataApiGen EnumTypeConfig
export const EnumTipoPrecoContratoProdutoAtoConfig = {
  name: 'EnumTipoPrecoContratoProdutoAto',
  members: EnumTipoPrecoContratoProdutoAto,
  fields: {
    INDEFINIDO: {value: 0},
    QUANTIDADE_US: {value: 1},
    VALOR_PROCEDIMENTO: {value: 2},
    VALOR_US: {value: 3},
    QUANTIDADEVALOR_US: {value: 4}
  }
} as EnumTypeConfig<EnumTipoPrecoContratoProdutoAto>;
//#endregion