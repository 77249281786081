//#region ODataApiGen ODataImports
import {
  EnumTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumProcessamentoRetornoSIB } from './enumprocessamentoretornosib.enum';
//#endregion

//#region ODataApiGen EnumTypeConfig
export const EnumProcessamentoRetornoSIBConfig = {
  name: 'EnumProcessamentoRetornoSIB',
  members: EnumProcessamentoRetornoSIB,
  fields: {
    NAO_PROCESSADO: {value: 1, annotations: [{"term":"Default.Description","string":"N\u00E3o Processado"}]},
    EM_PROCESSAMENTO: {value: 2, annotations: [{"term":"Default.Description","string":"Em Processamento"}]},
    PROCESSADO_COM_SUCESSO: {value: 3, annotations: [{"term":"Default.Description","string":"Processado com Sucesso"}]},
    PROCESSADO_COM_ERRO: {value: 4, annotations: [{"term":"Default.Description","string":"Processado com Erro"}]}
  }
} as EnumTypeConfig<EnumProcessamentoRetornoSIB>;
//#endregion