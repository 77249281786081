//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { ContratoProduto } from './contratoproduto.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const ContratoProdutoEntityConfig = {
  name: 'ContratoProduto',
  keys: [{name: 'Id'}],
  fields: {
    ContratoId: {type: 'Edm.Int32'},
    ProdutoId: {type: 'Edm.Int32'},
    PerfilCobrancaId: {type: 'Edm.Int32'},
    VigenciaDoProduto: {type: 'Edm.DateTimeOffset', nullable: false},
    PerfilCarenciaId: {type: 'Edm.Int32'},
    ValorMensalidade: {type: 'Edm.Decimal', nullable: false},
    ValorMensalidadeAgregado: {type: 'Edm.Decimal', nullable: false},
    ValorTaxaInscricao: {type: 'Edm.Decimal', nullable: false},
    ValorTaxaInscricaoDependente: {type: 'Edm.Decimal', nullable: false},
    ValorTaxaInscricaoAgregado: {type: 'Edm.Decimal', nullable: false},
    ValorSegundaViaCarteirinha: {type: 'Edm.Decimal', nullable: false},
    ValorSegundaViaCarteirinhaDependente: {type: 'Edm.Decimal', nullable: false},
    ValorSegundaViaCarteirinhaAgregado: {type: 'Edm.Decimal', nullable: false},
    ValorMensalidadeDependente: {type: 'Edm.Decimal', nullable: false},
    UltimaAtualizacaoReajuste: {type: 'Edm.DateTimeOffset'},
    ModalidadeContratacao: {type: 'Edm.String'},
    PercentualPatrocinio: {type: 'Edm.Decimal'},
    NomeDivulgacao: {type: 'Edm.String'},
    Intercambio: {type: 'Edm.Boolean', nullable: false},
    IntercambioEmergencia: {type: 'Edm.Boolean', nullable: false},
    OperadoraId: {type: 'Edm.Int32'},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'},
    Contrato: {type: 'Uniodonto.Domain.Entities.Contrato', navigation: true, referentials: [{property: 'ContratoId', referencedProperty: 'Id'}]},
    Produto: {type: 'Uniodonto.Domain.Entities.Produto', navigation: true, referentials: [{property: 'ProdutoId', referencedProperty: 'Id'}]},
    PerfilCobranca: {type: 'Uniodonto.Domain.Entities.PerfilCobranca', navigation: true, referentials: [{property: 'PerfilCobrancaId', referencedProperty: 'Id'}]},
    PerfilCarencia: {type: 'Uniodonto.Domain.Entities.PerfilCarencia', navigation: true, referentials: [{property: 'PerfilCarenciaId', referencedProperty: 'Id'}]},
    Operadora: {type: 'Uniodonto.Domain.Entities.Operadora', navigation: true, referentials: [{property: 'OperadoraId', referencedProperty: 'Id'}]}
  }
} as StructuredTypeConfig<ContratoProduto>;
//#endregion