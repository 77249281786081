//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Guia } from './guia.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const GuiaEntityConfig = {
  name: 'Guia',
  keys: [{name: 'Id'}],
  fields: {
    NumeroGuia: {type: 'Edm.String'},
    Senha: {type: 'Edm.String'},
    ValidadeSenha: {type: 'Edm.DateTimeOffset', nullable: false},
    OrcamentoId: {type: 'Edm.Int32'},
    CarteiraId: {type: 'Edm.Int32'},
    ClinicaId: {type: 'Edm.Int32'},
    DataAnalise: {type: 'Edm.DateTimeOffset', nullable: false},
    DataAprovacao: {type: 'Edm.DateTimeOffset', nullable: false},
    DataExecucao: {type: 'Edm.DateTimeOffset', nullable: false},
    DataConclusao: {type: 'Edm.DateTimeOffset', nullable: false},
    UsuarioId: {type: 'Edm.Int32'},
    Ativa: {type: 'Edm.Boolean', nullable: false},
    LoteId: {type: 'Edm.Int32'},
    IsExecutado: {type: 'Edm.Boolean', nullable: false},
    IsAnalisado: {type: 'Edm.Boolean', nullable: false},
    StatusGuia: {type: 'Uniodonto.Domain.Model.Enum.EnumStatusGuia', nullable: false},
    TipoGuia: {type: 'Uniodonto.Domain.Model.Enum.EnumTipoGuia', nullable: false},
    GuiaPaga: {type: 'Edm.Boolean'},
    ReembolsoId: {type: 'Edm.Int32'},
    Observacao: {type: 'Edm.String'},
    TipoAtendimentoGuia: {type: 'Uniodonto.Domain.Model.Enum.EnumTipoAtendimentoGuia', nullable: false},
    UFastGuiaId: {type: 'Edm.String'},
    UFastAutorizadora: {type: 'Edm.String', maxLength: 3},
    ProfissionalSolicitanteId: {type: 'Edm.Int32'},
    UFastStatus: {type: 'Uniodonto.Domain.Model.Results.UFast.Enum.SituacaoAutorizacao'},
    OperadoraId: {type: 'Edm.Int32'},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'},
    Orcamento: {type: 'Uniodonto.Domain.Entities.Orcamento', navigation: true, referentials: [{property: 'OrcamentoId', referencedProperty: 'Id'}]},
    Carteira: {type: 'Uniodonto.Domain.Entities.Carteira', navigation: true, referentials: [{property: 'CarteiraId', referencedProperty: 'Id'}]},
    Usuario: {type: 'Uniodonto.Domain.Entities.Usuario', navigation: true, referentials: [{property: 'UsuarioId', referencedProperty: 'Id'}]},
    Procedimentos: {type: 'Uniodonto.Domain.Entities.Procedimentos', collection: true, navigation: true},
    Lote: {type: 'Uniodonto.Domain.Entities.Lote', navigation: true, referentials: [{property: 'LoteId', referencedProperty: 'Id'}]},
    Clinica: {type: 'Uniodonto.Domain.Entities.Clinica', navigation: true, referentials: [{property: 'ClinicaId', referencedProperty: 'Id'}]},
    Reembolso: {type: 'Uniodonto.Domain.Entities.Reembolso', navigation: true, referentials: [{property: 'ReembolsoId', referencedProperty: 'Id'}]},
    ValorProcedimentoTipoProdutos: {type: 'Uniodonto.Domain.Entities.ValorProcedimentoTipoProduto', collection: true, navigation: true},
    GuiaAnexos: {type: 'Uniodonto.Domain.Entities.GuiaAnexo', collection: true, navigation: true},
    ProfissionalSolicitante: {type: 'Uniodonto.Domain.Entities.ProfissionalSolicitante', navigation: true, referentials: [{property: 'ProfissionalSolicitanteId', referencedProperty: 'Id'}]},
    GuiaHistorico: {type: 'Uniodonto.Domain.Entities.GuiaHistorico', collection: true, navigation: true},
    Operadora: {type: 'Uniodonto.Domain.Entities.Operadora', navigation: true, referentials: [{property: 'OperadoraId', referencedProperty: 'Id'}]}
  }
} as StructuredTypeConfig<Guia>;
//#endregion