//#region ODataApiGen ODataImports
import {
  EnumTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumTipoPreEspelho } from './enumtipopreespelho.enum';
//#endregion

//#region ODataApiGen EnumTypeConfig
export const EnumTipoPreEspelhoConfig = {
  name: 'EnumTipoPreEspelho',
  members: EnumTipoPreEspelho,
  fields: {
    Faturamento: {value: 1, annotations: [{"term":"Default.Description","string":"Faturamento"}]},
    Cobrança: {value: 2, annotations: [{"term":"Default.Description","string":"Cobran\u00E7a"}]}
  }
} as EnumTypeConfig<EnumTipoPreEspelho>;
//#endregion