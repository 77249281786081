//#region ODataApiGen Imports
//#endregion

export enum EnumStatusIntegracao {
  //#region ODataApiGen Members
  NAO_INTEGRADO = 1,
  INTEGRADO = 2,
  FALHA_NA_INTEGRACAO = 3,
  //#endregion
}
