//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { GrauInstrucaoService } from './grauinstrucao.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const GrauInstrucaoServiceEntitySetConfig = {
  name: 'GrauInstrucao',
  entityType: 'Uniodonto.Domain.Entities.GrauInstrucao',
  service: GrauInstrucaoService
} as EntitySetConfig;
//#endregion