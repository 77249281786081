//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { PreEspelhoDetalheService } from './preespelhodetalhe.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const PreEspelhoDetalheServiceEntitySetConfig = {
  name: 'PreEspelhoDetalhe',
  entityType: 'Uniodonto.Domain.Entities.PreEspelhoDetalhe',
  service: PreEspelhoDetalheService
} as EntitySetConfig;
//#endregion