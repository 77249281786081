import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Operadora } from '../Uniodonto/Domain/Entities/operadora.entity';
import { UsuarioOperadora } from '../Uniodonto/Domain/Entities/usuariooperadora.entity';
import { Clinica } from '../Uniodonto/Domain/Entities/clinica.entity';
//#endregion

@Injectable()
export class UsuarioOperadoraV2Service extends ODataEntitySetService<UsuarioOperadora> {
  constructor(client: ODataClient) {
    super(client, 'UsuarioOperadoraV2', 'Uniodonto.Domain.Entities.UsuarioOperadora');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public clinica(key: EntityKey<UsuarioOperadora>): ODataNavigationPropertyResource<Clinica> { 
    return this.entity(key).navigationProperty<Clinica>('Clinica'); 
  }
  public fetchClinica(key: EntityKey<UsuarioOperadora>, options?: ODataQueryArgumentsOptions<Clinica>) {
    return this.fetchNavigationProperty<Clinica>(
      this.clinica(key), 
      'entity', options) as Observable<ODataEntity<Clinica>>;
  }
  public setClinicaAsClinica(key: EntityKey<UsuarioOperadora>, target: ODataEntityResource<ODataEntity<Clinica>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.clinica(key).reference()
      .set(target, {etag});
  }
  public unsetClinicaAsClinica(key: EntityKey<UsuarioOperadora>, {target, etag}: {target?: ODataEntityResource<ODataEntity<Clinica>>, etag?: string} = {}): Observable<any> {
    return this.clinica(key).reference()
      .unset({etag});
  }
  public operadora(key: EntityKey<UsuarioOperadora>): ODataNavigationPropertyResource<Operadora> { 
    return this.entity(key).navigationProperty<Operadora>('Operadora'); 
  }
  public fetchOperadora(key: EntityKey<UsuarioOperadora>, options?: ODataQueryArgumentsOptions<Operadora>) {
    return this.fetchNavigationProperty<Operadora>(
      this.operadora(key), 
      'entity', options) as Observable<ODataEntity<Operadora>>;
  }
  public setOperadoraAsOperadora(key: EntityKey<UsuarioOperadora>, target: ODataEntityResource<ODataEntity<Operadora>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.operadora(key).reference()
      .set(target, {etag});
  }
  public unsetOperadoraAsOperadora(key: EntityKey<UsuarioOperadora>, {target, etag}: {target?: ODataEntityResource<ODataEntity<Operadora>>, etag?: string} = {}): Observable<any> {
    return this.operadora(key).reference()
      .unset({etag});
  }
  //#endregion
}
