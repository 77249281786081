//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { ProdutosService } from './produtos.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const ProdutosServiceEntitySetConfig = {
  name: 'Produtos',
  entityType: 'Uniodonto.Domain.Entities.Produto',
  service: ProdutosService
} as EntitySetConfig;
//#endregion