//#region ODataApiGen ODataImports
import {
  EnumTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumTipoPeriodo } from './enumtipoperiodo.enum';
//#endregion

//#region ODataApiGen EnumTypeConfig
export const EnumTipoPeriodoConfig = {
  name: 'EnumTipoPeriodo',
  members: EnumTipoPeriodo,
  fields: {
    FATURAMENTO: {value: 1, annotations: [{"term":"Default.Description","string":"Faturamento"}]},
    SIP: {value: 2, annotations: [{"term":"Default.Description","string":"SIP"}]},
    RPC: {value: 3, annotations: [{"term":"Default.Description","string":"RPC"}]},
    PRODUCAO: {value: 4, annotations: [{"term":"Default.Description","string":"Produ\u00E7\u00E3o"}]}
  }
} as EnumTypeConfig<EnumTipoPeriodo>;
//#endregion