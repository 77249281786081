//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { ContratoProdutoService } from './contratoproduto.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const ContratoProdutoServiceEntitySetConfig = {
  name: 'ContratoProduto',
  entityType: 'Uniodonto.Domain.Entities.ContratoProduto',
  service: ContratoProdutoService
} as EntitySetConfig;
//#endregion