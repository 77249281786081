//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Operadora } from './operadora.entity';
import { Clinica } from './clinica.entity';
//#endregion

export interface ClinicaDocumento {
  //#region ODataApiGen Properties
  DocumentoTipo?: string;
  DocumentoNomeOriginal?: string;
  DocumentoNomeUnico?: string;
  DocumentoAutor?: string;
  DocumentoUrl?: string;
  ClinicaId?: number;
  OperadoraId?: number;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  Clinica?: Clinica;
  Operadora?: Operadora;
  //#endregion
}