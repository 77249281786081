//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Procedimentos } from './procedimentos.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const ProcedimentosEntityConfig = {
  name: 'Procedimentos',
  keys: [{name: 'Id'}],
  fields: {
    OrcamentoId: {type: 'Edm.Int32'},
    GuiaId: {type: 'Edm.Int32'},
    AtoOdontologicoId: {type: 'Edm.Int32'},
    CodigoProcedimento: {type: 'Edm.Int32', nullable: false},
    NomeProcedimento: {type: 'Edm.String'},
    DominioId: {type: 'Edm.Int32'},
    DominioNome: {type: 'Edm.String'},
    DominioCodigo: {type: 'Edm.String'},
    UsQuantidade: {type: 'Edm.Decimal', nullable: false},
    UsValor: {type: 'Edm.Decimal', nullable: false},
    ProfissionalId: {type: 'Edm.Int32'},
    ProfissionalNome: {type: 'Edm.String'},
    AprovadorTecnico: {type: 'Edm.String'},
    AprovacaoTecAutomatica: {type: 'Edm.Boolean', nullable: false},
    DataAprovacaoTecnica: {type: 'Edm.DateTimeOffset', nullable: false},
    AprovadorAdministrativo: {type: 'Edm.String'},
    AprovacaoAdmAutomatica: {type: 'Edm.Boolean', nullable: false},
    DataAprovacaoAdministrativa: {type: 'Edm.DateTimeOffset', nullable: false},
    Observacao: {type: 'Edm.String'},
    IsExecutado: {type: 'Edm.Boolean', nullable: false},
    IsAnalisado: {type: 'Edm.Boolean', nullable: false},
    FacesProcedimento: {type: 'Edm.String'},
    AutorizacaoAdm: {type: 'Edm.Boolean', nullable: false},
    AutorizadorAdministrativo: {type: 'Edm.String'},
    DataAutorizacaoAdm: {type: 'Edm.DateTimeOffset', nullable: false},
    AutorizacaoTecnica: {type: 'Edm.Boolean', nullable: false},
    AutorizadorTecnico: {type: 'Edm.String'},
    DataAutorizacaoTecnica: {type: 'Edm.DateTimeOffset', nullable: false},
    PossuiCooparticipacao: {type: 'Edm.Boolean', nullable: false},
    QuantidadeCooparticipacao: {type: 'Edm.Decimal', nullable: false},
    ValorCooparticipacao: {type: 'Edm.Decimal', nullable: false},
    StatusGuia: {type: 'Uniodonto.Domain.Model.Enum.EnumStatusProcedimentoGuia', nullable: false},
    StatusPlano: {type: 'Uniodonto.Domain.Model.Enum.EnumStatusProcedimentoPlano', nullable: false},
    StatusAuditoriaTecnica: {type: 'Uniodonto.Domain.Model.Enum.EnumStatusAuditoria', nullable: false},
    StatusAuditoriaAdministrativa: {type: 'Uniodonto.Domain.Model.Enum.EnumStatusAuditoria', nullable: false},
    PossuiRecursos: {type: 'Edm.Boolean', nullable: false},
    UFastSequencialProcedimento: {type: 'Edm.Int32'},
    TemRestricao: {type: 'Edm.Boolean', nullable: false},
    DataExecucao: {type: 'Edm.DateTimeOffset'},
    TipoAcao: {type: 'Uniodonto.Domain.Model.Enum.EnumTipoAcao', nullable: false},
    UsQuantidadeIntercambio: {type: 'Edm.Decimal', nullable: false},
    UsValorIntercambio: {type: 'Edm.Decimal', nullable: false},
    UsValorIntercambioUFAST: {type: 'Edm.Decimal', nullable: false},
    Substituicao: {type: 'Edm.Boolean', nullable: false},
    ProcedimentoSubstituidoId: {type: 'Edm.Int32'},
    IdLegado: {type: 'Edm.String'},
    OperadoraId: {type: 'Edm.Int32'},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'},
    Orcamento: {type: 'Uniodonto.Domain.Entities.Orcamento', navigation: true, referentials: [{property: 'OrcamentoId', referencedProperty: 'Id'}]},
    Guia: {type: 'Uniodonto.Domain.Entities.Guia', navigation: true, referentials: [{property: 'GuiaId', referencedProperty: 'Id'}]},
    Dominio: {type: 'Uniodonto.Domain.Entities.Dominio', navigation: true, referentials: [{property: 'DominioId', referencedProperty: 'Id'}]},
    AtoOdontologico: {type: 'Uniodonto.Domain.Entities.AtoOdontologico', navigation: true, referentials: [{property: 'AtoOdontologicoId', referencedProperty: 'Id'}]},
    ProcedimentoImagens: {type: 'Uniodonto.Domain.Entities.ProcedimentoImagens', collection: true, navigation: true},
    ProcedimentoRecursosHistoricos: {type: 'Uniodonto.Domain.Entities.ProcedimentoRecursosHistorico', collection: true, navigation: true},
    ValorProcedimentoTipoProdutos: {type: 'Uniodonto.Domain.Entities.ValorProcedimentoTipoProduto', collection: true, navigation: true},
    ProcedimentoFaces: {type: 'Uniodonto.Domain.Entities.ProcedimentoFaces', collection: true, navigation: true},
    Glosas: {type: 'Uniodonto.Domain.Entities.ProcedimentoGlosa', collection: true, navigation: true},
    Observacoes: {type: 'Uniodonto.Domain.Entities.GuiaObservacao', collection: true, navigation: true},
    Operadora: {type: 'Uniodonto.Domain.Entities.Operadora', navigation: true, referentials: [{property: 'OperadoraId', referencedProperty: 'Id'}]}
  }
} as StructuredTypeConfig<Procedimentos>;
//#endregion