//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { HistoricoRejeitadosProcessamentoSIB } from './historicorejeitadosprocessamentosib.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const HistoricoRejeitadosProcessamentoSIBEntityConfig = {
  name: 'HistoricoRejeitadosProcessamentoSIB',
  keys: [{name: 'Id'}],
  fields: {
    Hash: {type: 'Edm.String'},
    NumeroProtocolo: {type: 'Edm.String'},
    NomeArquivo: {type: 'Edm.String'},
    CCO: {type: 'Edm.String'},
    CodigoBeneficiario: {type: 'Edm.String'},
    CodigoTipoMovimento: {type: 'Edm.String'},
    TipoMovimento: {type: 'Edm.String'},
    CodigoCampo: {type: 'Edm.String'},
    DescricaoCampo: {type: 'Edm.String'},
    ValorCampo: {type: 'Edm.String'},
    CodigoErro: {type: 'Edm.String'},
    MensagemErro: {type: 'Edm.String'},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'}
  }
} as StructuredTypeConfig<HistoricoRejeitadosProcessamentoSIB>;
//#endregion