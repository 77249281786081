//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { LinkV2Service } from './linkv2.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const LinkV2ServiceEntitySetConfig = {
  name: 'LinkV2',
  entityType: 'Uniodonto.Domain.Entities.Link',
  service: LinkV2Service
} as EntitySetConfig;
//#endregion