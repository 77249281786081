//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { MotivoDesligamento } from './motivodesligamento.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const MotivoDesligamentoEntityConfig = {
  name: 'MotivoDesligamento',
  keys: [{name: 'Id'}],
  fields: {
    Descricao: {type: 'Edm.String'},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'}
  }
} as StructuredTypeConfig<MotivoDesligamento>;
//#endregion