//#region ODataApiGen Imports
//#endregion

export enum EnumStatusLote {
  //#region ODataApiGen Members
  SEM_LOTE = 0,
  LOTE_CRIADO = 1,
  LOTE_ENVIADO = 2,
  LOTE_EM_ANALISE = 3,
  LOTE_GLOSA = 4,
  LOTE_CONCLUIDO = 5,
  LOTE_ENVIADO_PARA_FILA_ERP = 6,
  LOTE_REJEITADO_ERP = 7,
  LOTE_EXECUTADO_ERP = 8,
  //#endregion
}
