//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { TRMAtoOperadoraV2Service } from './trmatooperadorav2.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const TRMAtoOperadoraV2ServiceEntitySetConfig = {
  name: 'TRMAtoOperadoraV2',
  entityType: 'Uniodonto.Domain.Entities.TRMAtoOperadora',
  service: TRMAtoOperadoraV2Service
} as EntitySetConfig;
//#endregion