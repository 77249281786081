//#region ODataApiGen ODataImports
import {
  EnumTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumAreaGeografica } from './enumareageografica.enum';
//#endregion

//#region ODataApiGen EnumTypeConfig
export const EnumAreaGeograficaConfig = {
  name: 'EnumAreaGeografica',
  members: EnumAreaGeografica,
  fields: {
    MUNICIPAL: {value: 1},
    ESTADUAL: {value: 2},
    GRUPO_ESTADOS: {value: 3},
    GRUPO_MUNICIPIOS: {value: 4},
    NACIONAL: {value: 5}
  }
} as EnumTypeConfig<EnumAreaGeografica>;
//#endregion