//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Clinica } from './clinica.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const ClinicaEntityConfig = {
  name: 'Clinica',
  keys: [{name: 'Id'}],
  fields: {
    NucleoId: {type: 'Edm.Int32'},
    Tipo: {type: 'Uniodonto.Domain.Model.Enum.EnumClinicaTipo', nullable: false},
    Ativo: {type: 'Edm.Boolean', nullable: false},
    DataInativacao: {type: 'Edm.DateTimeOffset', nullable: false},
    TipoDeClinica: {type: 'Uniodonto.Domain.Model.Enum.EnumTipoDeClinica'},
    CNES: {type: 'Edm.String'},
    CNPJ: {type: 'Edm.String'},
    RazaoSocial: {type: 'Edm.String'},
    NomeFantasia: {type: 'Edm.String'},
    ResponsavelCPF: {type: 'Edm.String'},
    ResponsavelNome: {type: 'Edm.String'},
    ReponsavelCRO: {type: 'Edm.String'},
    UFCRO: {type: 'Edm.String'},
    InscricaoISS: {type: 'Edm.String'},
    MatriculaESocial: {type: 'Edm.String'},
    CNS: {type: 'Edm.String'},
    TipoProfissional: {type: 'Uniodonto.Domain.Model.Enum.EnumTipoProfissional'},
    CPF: {type: 'Edm.String'},
    NomeMae: {type: 'Edm.String'},
    RG: {type: 'Edm.String'},
    OrgaoEmissor: {type: 'Edm.String'},
    ProfissionalIdade: {type: 'Edm.Int32', nullable: false},
    DataNascimento: {type: 'Edm.DateTimeOffset', nullable: false},
    SexoResponsavel: {type: 'Edm.String'},
    EstadoCivilResponsavel: {type: 'Uniodonto.Domain.Model.Enum.EnumEstadoCivil', nullable: false},
    ValorUS: {type: 'Edm.Decimal', nullable: false},
    DataConvite: {type: 'Edm.DateTimeOffset', nullable: false},
    DataAssinaturaContrato: {type: 'Edm.DateTimeOffset', nullable: false},
    DataRecebimentoContrato: {type: 'Edm.DateTimeOffset', nullable: false},
    DataRecebimentoDocumentacao: {type: 'Edm.DateTimeOffset', nullable: false},
    DataInicioCredenciamento: {type: 'Edm.DateTimeOffset', nullable: false},
    DataTerminoCredenciamento: {type: 'Edm.DateTimeOffset'},
    AtendeEmergencia: {type: 'Edm.Boolean', nullable: false},
    Atendimento24Horas: {type: 'Edm.Boolean', nullable: false},
    FeriadosNacionais: {type: 'Edm.Boolean', nullable: false},
    FeriadosEstaduais: {type: 'Edm.Boolean', nullable: false},
    FeriadosMunicipais: {type: 'Edm.Boolean', nullable: false},
    ProviderType: {type: 'Edm.String'},
    RelacaoOperadora: {type: 'Edm.String'},
    TipoContratualizacao: {type: 'Edm.String'},
    DisponibilidadeServico: {type: 'Edm.String'},
    PossuiEstacionamento: {type: 'Edm.Boolean', nullable: false},
    PossuiAcessibilidade: {type: 'Edm.Boolean', nullable: false},
    SingularIntercambio: {type: 'Edm.String'},
    CodigoCBO: {type: 'Edm.String'},
    IntegradoERP: {type: 'Edm.Boolean', nullable: false},
    StatusIntegracaoERP: {type: 'Uniodonto.Domain.Model.Enum.EnumStatusIntegracao', nullable: false},
    GrupoFornecedorERPId: {type: 'Edm.Int32'},
    RacaId: {type: 'Edm.Int32'},
    GrauInstrucaoId: {type: 'Edm.Int32'},
    NumeroISS: {type: 'Edm.String'},
    PisPasep: {type: 'Edm.String'},
    NomeSocial: {type: 'Edm.String'},
    NomedoPai: {type: 'Edm.String'},
    PaisNascimento: {type: 'Edm.String'},
    EstadoNascimento: {type: 'Edm.String'},
    CidadeNascimento: {type: 'Edm.String'},
    IBGENascimento: {type: 'Edm.String'},
    CodigoCargo: {type: 'Edm.String'},
    CodigoFuncao: {type: 'Edm.String'},
    Site: {type: 'Edm.String'},
    TipoSite: {type: 'Edm.String'},
    EnderecoURLSite: {type: 'Edm.String'},
    TipoPgtoBanco: {type: 'Edm.String'},
    NumeroBanco: {type: 'Edm.Int32', nullable: false},
    NomeBanco: {type: 'Edm.String'},
    AgenciaBanco: {type: 'Edm.String'},
    ContaBanco: {type: 'Edm.String'},
    OperadoraId: {type: 'Edm.Int32'},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'},
    Nucleo: {type: 'Uniodonto.Domain.Entities.Nucleo', navigation: true, referentials: [{property: 'NucleoId', referencedProperty: 'Id'}]},
    HorarioAtendimento: {type: 'Uniodonto.Domain.Entities.HorarioAtendimento', collection: true, navigation: true},
    Profissionais: {type: 'Uniodonto.Domain.Entities.ClinicaDentistas', collection: true, navigation: true},
    Negociacoes: {type: 'Uniodonto.Domain.Entities.Negociacao', collection: true, navigation: true},
    Documentos: {type: 'Uniodonto.Domain.Entities.ClinicaDocumento', collection: true, navigation: true},
    ClinicaContatos: {type: 'Uniodonto.Domain.Entities.ClinicaContato', collection: true, navigation: true},
    ClinicaEnderecos: {type: 'Uniodonto.Domain.Entities.ClinicaEnderecos', collection: true, navigation: true},
    ClinicaEspecialidadeEAtendimentos: {type: 'Uniodonto.Domain.Entities.ClinicaEspecialidadeEAtendimento', collection: true, navigation: true},
    UsuarioClinicas: {type: 'Uniodonto.Domain.Entities.UsuarioClinica', collection: true, navigation: true},
    Guias: {type: 'Uniodonto.Domain.Entities.Guia', collection: true, navigation: true},
    GrupoFornecedorERP: {type: 'Uniodonto.Domain.Entities.GrupoFornecedorERP', navigation: true, referentials: [{property: 'GrupoFornecedorERPId', referencedProperty: 'Id'}]},
    Raca: {type: 'Uniodonto.Domain.Entities.Raca', navigation: true, referentials: [{property: 'RacaId', referencedProperty: 'Id'}]},
    GrauInstrucao: {type: 'Uniodonto.Domain.Entities.GrauInstrucao', navigation: true, referentials: [{property: 'GrauInstrucaoId', referencedProperty: 'Id'}]},
    DependenteClinicas: {type: 'Uniodonto.Domain.Entities.DependenteClinica', collection: true, navigation: true},
    ClinicaMidiaSociais: {type: 'Uniodonto.Domain.Entities.ClinicaMidiaSociais', collection: true, navigation: true},
    BloqueioPrestador: {type: 'Uniodonto.Domain.Entities.BloqueioPrestador', collection: true, navigation: true},
    Operadora: {type: 'Uniodonto.Domain.Entities.Operadora', navigation: true, referentials: [{property: 'OperadoraId', referencedProperty: 'Id'}]}
  }
} as StructuredTypeConfig<Clinica>;
//#endregion