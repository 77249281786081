//#region ODataApiGen Imports
//#endregion

export enum EnumStatusProcedimentoGuia {
  //#region ODataApiGen Members
  Nenhum = 0,
  AguardandoAutorizacao = 10,
  Autorizado = 20,
  NaoAutorizado = 30,
  Executado = 40,
  Cancelado = 50,
  Glosado = 60,
  Concluido = 999,
  //#endregion
}
