//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { GuiaProcedimentoImagemLegado } from './guiaprocedimentoimagemlegado.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const GuiaProcedimentoImagemLegadoEntityConfig = {
  name: 'GuiaProcedimentoImagemLegado',
  keys: [{name: 'Id'}],
  fields: {
    NumeroGuia: {type: 'Edm.String'},
    NumeroProcedimento: {type: 'Edm.String'},
    TipoImagem: {type: 'Edm.String'},
    TipoImagemEnum: {type: 'Uniodonto.Domain.Model.Enum.EnumTipoDocumento'},
    ImagemURL: {type: 'Edm.String'},
    OperadoraId: {type: 'Edm.Int32'},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'}
  }
} as StructuredTypeConfig<GuiaProcedimentoImagemLegado>;
//#endregion