//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Glosa } from './glosa.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const GlosaEntityConfig = {
  name: 'Glosa',
  keys: [{name: 'Id'}],
  fields: {
    CodigoANS: {type: 'Edm.String'},
    Descricao: {type: 'Edm.String'},
    Tipo: {type: 'Uniodonto.Domain.Model.Enum.EnumTipoGlosa', nullable: false},
    Grupo: {type: 'Uniodonto.Domain.Model.Enum.EnumGrupoGlosa', nullable: false},
    OperadoraId: {type: 'Edm.Int32'},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'},
    Operadora: {type: 'Uniodonto.Domain.Entities.Operadora', navigation: true, referentials: [{property: 'OperadoraId', referencedProperty: 'Id'}]}
  }
} as StructuredTypeConfig<Glosa>;
//#endregion