//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Pagamento } from './pagamento.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const PagamentoEntityConfig = {
  name: 'Pagamento',
  keys: [{name: 'Id'}],
  fields: {
    TipoPagamento: {type: 'Uniodonto.Domain.Model.Enum.EnumTipoPagamento', nullable: false},
    Banco: {type: 'Edm.String'},
    Agencia: {type: 'Edm.String'},
    ContaCorrente: {type: 'Edm.String'},
    ClinicaId: {type: 'Edm.Int32'},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'},
    Operadora: {type: 'Uniodonto.Domain.Entities.Operadora', navigation: true},
    Clinica: {type: 'Uniodonto.Domain.Entities.Clinica', navigation: true, referentials: [{property: 'ClinicaId', referencedProperty: 'Id'}]}
  }
} as StructuredTypeConfig<Pagamento>;
//#endregion