//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumTipoNegociacao } from '../Model/Enum/enumtiponegociacao.enum';
import { EnumStatusNegociacao } from '../Model/Enum/enumstatusnegociacao.enum';
import { Cliente } from './cliente.entity';
import { Contrato } from './contrato.entity';
import { Operadora } from './operadora.entity';
import { AtoOdontologico } from './atoodontologico.entity';
import { Especialidade } from './especialidade.entity';
//#endregion

export interface NegociacaoOperadora {
  //#region ODataApiGen Properties
  EnumTipoNegociacao: EnumTipoNegociacao;
  UnidadeServico?: string;
  EspecialidadeId?: number;
  AtoOdontologicoId?: number;
  ValorNegociado: number;
  ValorUS: number;
  QuantidadeUS: number;
  ValorIntercambio: number;
  ClienteId?: number;
  ContratoId?: number;
  Indice?: string;
  VigenciaDataInicio?: Date;
  VigenciaDataTermino?: Date;
  EnumStatusNegociacao: EnumStatusNegociacao;
  OperadoraId?: number;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  Especialidade?: Especialidade;
  AtoOdontologico?: AtoOdontologico;
  Cliente?: Cliente;
  Contrato?: Contrato;
  Operadora?: Operadora;
  //#endregion
}