//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Cliente } from './cliente.entity';
import { Contrato } from './contrato.entity';
import { Operadora } from './operadora.entity';
import { Titular } from './titular.entity';
import { SubContrato } from './subcontrato.entity';
//#endregion

export interface Subcliente {
  //#region ODataApiGen Properties
  MesesPermanenciaMinima: number;
  DataPermanenciaMinima: Date;
  DataInicial: Date;
  FaturamentoConjunto: boolean;
  TitularId?: number;
  ClienteId?: number;
  SubContratoId?: number;
  ContratoId?: number;
  OperadoraId?: number;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  Titular?: Titular;
  Cliente?: Cliente;
  SubContrato?: SubContrato;
  Contrato?: Contrato;
  Operadora?: Operadora;
  //#endregion
}