//#region ODataApiGen ODataImports
import {
  EnumTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumTipoDocumento } from './enumtipodocumento.enum';
//#endregion

//#region ODataApiGen EnumTypeConfig
export const EnumTipoDocumentoConfig = {
  name: 'EnumTipoDocumento',
  members: EnumTipoDocumento,
  fields: {
    FotoInicial: {value: 1, annotations: [{"term":"Default.Description","string":"Foto inicial"}]},
    FotoFinal: {value: 2, annotations: [{"term":"Default.Description","string":"Foto final"}]},
    RadiografiaInicial: {value: 3, annotations: [{"term":"Default.Description","string":"Radiografia inicial"}]},
    RadiografiaFinal: {value: 4, annotations: [{"term":"Default.Description","string":"Radiografia final"}]},
    Laudo: {value: 5, annotations: [{"term":"Default.Description","string":"Laudo"}]},
    GuiaDigitalizada: {value: 6, annotations: [{"term":"Default.Description","string":"Guia Digitalizada"}]}
  }
} as EnumTypeConfig<EnumTipoDocumento>;
//#endregion