//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { ProcedimentoImagens } from './procedimentoimagens.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const ProcedimentoImagensEntityConfig = {
  name: 'ProcedimentoImagens',
  keys: [{name: 'Id'}],
  fields: {
    ImagemUrl: {type: 'Edm.String'},
    TipoImagem: {type: 'Uniodonto.Domain.Model.Enum.EnumTipoDocumento', nullable: false},
    ImagemNome: {type: 'Edm.String'},
    ProcedimentoId: {type: 'Edm.Int32', nullable: false},
    UFastEnviado: {type: 'Edm.Boolean', nullable: false},
    OperadoraId: {type: 'Edm.Int32'},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'},
    Procedimento: {type: 'Uniodonto.Domain.Entities.Procedimentos', navigation: true},
    Operadora: {type: 'Uniodonto.Domain.Entities.Operadora', navigation: true, referentials: [{property: 'OperadoraId', referencedProperty: 'Id'}]}
  }
} as StructuredTypeConfig<ProcedimentoImagens>;
//#endregion