//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Operadora } from './operadora.entity';
import { AtoOdontologico } from './atoodontologico.entity';
//#endregion

export interface AtoOdontologicoFilhos {
  //#region ODataApiGen Properties
  AtoOdontologicoPaiId?: number;
  AtoOdontologicoFilhoId?: number;
  OperadoraId?: number;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  AtoOdontologicoPai?: AtoOdontologico;
  AtoOdontologicoFilho?: AtoOdontologico;
  Operadora?: Operadora;
  //#endregion
}