import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { environment } from "../../environments/environment";
import { catchError, tap } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import {
  CEPRequestModel,
  CEPResponseModel,
} from "../models/entities/viacep.model";

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
  }),
};

@Injectable({
  providedIn: "root",
})
export class ViaCEPService {
  public log: String;

  constructor(private http: HttpClient) {}

  /*
    getByCEP(cep : any): Observable<any[]> {
        return this.http.get<any[]>('https://viacep.com.br/ws/${cep}/json/').pipe(
            tap(data => console.log('cep obtidos')),
            catchError(this.handleError)
        );
      };
      
      
      buscarCep(cep: string): Observable<any[] >{
        return this.http.get<any[]>(`${environment.apiUrl}/Endereco?cep=` + cep).pipe(
            tap(data => console.log('All: ' + JSON.stringify(data))),
            catchError(this.handleError)
        );
    };
    };
    */

  buscarCep(cep: any): Observable<any> {
    return this.http
      .get<any>(
        `${environment.apiUrl}/Endereco/buscar-cep?cep=${cep}`,
        httpOptions
      )
      .pipe(
        tap((resp) => console.log(JSON.stringify(resp))),
        catchError(this.handleError)
      );
  }

  /** cepObject = { CepCode: '18000000 } */
  getByCEP(cepObject: CEPRequestModel): Observable<CEPResponseModel> {
    return this.http
      .post<CEPResponseModel>(
        `${environment.apiUrl}/Endereco/buscar-cep`,
        cepObject,
        httpOptions
      )
  }

  private handleError(err: HttpErrorResponse) {
    let errorMessage = "";
    if (err.error instanceof ErrorEvent) {
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.error(errorMessage);
    return throwError(errorMessage);
  }
}
