//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Corretor } from './corretor.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const CorretorEntityConfig = {
  name: 'Corretor',
  keys: [{name: 'Id'}],
  fields: {
    Documento: {type: 'Edm.String'},
    Nome: {type: 'Edm.String'},
    NomeFantasia: {type: 'Edm.String'},
    RamoAtividade: {type: 'Edm.String'},
    InscricaoEstadual: {type: 'Edm.String'},
    InscricaoMunicipal: {type: 'Edm.String'},
    OptanteSimplesNacional: {type: 'Edm.Boolean', nullable: false},
    TipoCorretor: {type: 'Edm.String'},
    TipoEntidadeId: {type: 'Edm.Int32'},
    ParticipanteId: {type: 'Edm.Int32'},
    OperadoraId: {type: 'Edm.Int32'},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'},
    TipoEntidade: {type: 'Uniodonto.Domain.Entities.TipoEntidade', navigation: true, referentials: [{property: 'TipoEntidadeId', referencedProperty: 'Id'}]},
    Participante: {type: 'Uniodonto.Domain.Entities.Participante', navigation: true, referentials: [{property: 'ParticipanteId', referencedProperty: 'Id'}]},
    Operadora: {type: 'Uniodonto.Domain.Entities.Operadora', navigation: true, referentials: [{property: 'OperadoraId', referencedProperty: 'Id'}]}
  }
} as StructuredTypeConfig<Corretor>;
//#endregion