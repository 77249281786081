import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { NgModule, Optional, SkipSelf } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";
import { AlterarSenhaComponent } from "./auth/alterar-senha.component";
import { AuthInterceptor } from "./auth/auth.interceptor";
import { AuthService } from "./auth/auth.service";
import { ValidacaoSenhaComponent } from "./auth/validacao-senha.component";

@NgModule({
	declarations: [AlterarSenhaComponent, ValidacaoSenhaComponent],
	imports: [HttpClientModule, CommonModule, ReactiveFormsModule],
	providers: [
		AuthService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptor,
			multi: true,
		},
	],
})
export class CoreModule {
	/**
	 * Constructor
	 *
	 * @param {DomSanitizer} _domSanitizer
	 * @param {MatIconRegistry} _matIconRegistry
	 * @param parentModule
	 */
	constructor(
		private _domSanitizer: DomSanitizer,
		@Optional() @SkipSelf() parentModule?: CoreModule
	) {
		// Do not allow multiple injections
		if (parentModule) {
			throw new Error(
				"CoreModule has already been loaded. Import this module in the AppModule only."
			);
		}
	}
}
