//#region ODataApiGen ODataImports
import {
  EnumTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumTipoPerfilCarencia } from './enumtipoperfilcarencia.enum';
//#endregion

//#region ODataApiGen EnumTypeConfig
export const EnumTipoPerfilCarenciaConfig = {
  name: 'EnumTipoPerfilCarencia',
  members: EnumTipoPerfilCarencia,
  fields: {
    TODOS: {value: 1},
    ESPECIALIDADE: {value: 2},
    ATO: {value: 3}
  }
} as EnumTypeConfig<EnumTipoPerfilCarencia>;
//#endregion