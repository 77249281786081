import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, take, tap } from "rxjs/operators";
// import { TreeviewItem, TreeviewConfig } from 'ngx-treeview';
import { APIResponse } from "../../models";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { NotifierService } from "angular-notifier";
import { VinculoControleAcesso } from "../../models/entities/vinculo_controleAcesso.model";

// import { ProcedimentoModel } from 'src/app/models/entities/ProcedimentoModel';

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
  }),
};

@Injectable({
  providedIn: "root",
})
export class ControleAcessoService {
  private notifier: NotifierService;
  public log: String;

  constructor(private http: HttpClient, notifier: NotifierService) {
    this.notifier = notifier;
  }

  // getMock(): TreeviewItem[] {
  //     const listCooperativa = new TreeviewItem({
  //       text: 'Modulo Cooperativa', value:999 , children: [
  //         new TreeviewItem({
  //           text: 'Cadastros', value: 998,  checked: false, children: [
  //             { text: 'Especialidades', value: 1 , checked: false },
  //             { text: 'Atos Odontológicos', value: 2 , checked: false },
  //             { text: 'Perfil de Carência', value: 3 , checked: false },
  //             { text: 'Prestadores', value: 4 , checked: false },
  //             { text: 'Negociações', value: 5 , checked: false },
  //             { text: 'Motivos Glosas/Rejeição', value: 6, checked: false  },
  //             { text: 'Clientes', value: 7 , checked: false },
  //             { text: 'Corpo Clinico', value: 8 , checked: false },
  //             { text: 'Horários de Atendimento', value: 9 , checked: false },
  //             { text: 'Operadoras', value: 10, checked: false  },
  //             { text: 'Negociações da Operadora', value: 11 , checked: false },
  //             { text: 'Usuário', value: 12, checked: false },
  //             { text: 'Permissões', value: 13 , checked: false},
  //             { text: 'Código de Regularização', value: 26 , checked: false},
  //             { text: 'Controle de Acesso', value: 27 , checked: false}
  //         ]
  //       }),
  //       new TreeviewItem({
  //         text: 'Operacional', value: 997, children: [
  //           { text: 'Gestão de Beneficiários', value: 14, checked: false },
  //           { text: 'Administração de Produtos', value: 15, checked: false },
  //           { text: 'Administração de Contratos', value: 16, checked: false },
  //           { text: 'Dentistas', value: 17, checked: false },
  //           { text: 'Guias de Atendimento', value: 18, checked: false},
  //           { text: 'Lotes de Guias', value: 19, checked: false},
  //           { text: 'Bloqueio de Clinica e de Profissional', value: 20,checked: false}
  //       ]
  //     })
  //   ]

  //   });
  //   const listClinica = new TreeviewItem({
  //         text: 'Módulo Clínica', value: 996, checked: false,children: [
  //           new TreeviewItem({
  //             text: 'Adminstração', value: 995, children: [
  //               { text: 'Matriz de Negociação', value: 22 ,checked: false},
  //               { text: 'Corpo Clinico', value: 22 ,checked: false},
  //               { text: 'Horários de Atendimento', value: 23,checked: false},

  //           ]
  //         }),
  //         new TreeviewItem({
  //           text: 'Atendimento', value: 994, children: [
  //             { text: 'Planos de Tratamento', value: 24 ,checked: false},
  //             { text: 'Minhas Guias de Atendimento', value: 25, checked: false }

  //         ]
  //       })
  //     ]
  //   });
  //   listClinica.correctChecked(); // need this to make 'Vegetable' node to change checked value from true to false
  //   return [listCooperativa, listClinica];
  // }

  public showNotification(type: string, message: string): void {
    this.notifier.notify(type, message);
  }

  getAll(): Observable<Object> {
    return this.http
      .get<Object>(`${environment.apiUrl}/ControleAcesso/buscar-todos`)
      .pipe(
        // tap((data) => console.log("Menus obtidas")),
        catchError(this.handleError)
      );
  }

  getMenuDefault(): Observable<Object> {
    return this.http.get<Object>(`${environment.apiUrl}/Menu/default`).pipe(
      // tap((data) => console.log("Menus obtidas")),
      catchError(this.handleError)
    );
  }

  getMenuSelected(id: number): Observable<Object> {
    return this.http
      .get<Object>(`${environment.apiUrl}/Menu/controleAcesso/${id}`)
      .pipe(
        // tap((data) => console.log("Menus obtidas")),
        catchError(this.handleError)
      );
  }

  getMenu(id: number): Observable<Object> {
    return this.http
      .get<Object>(`${environment.apiUrl}/Auth/menu/${id}`)
      .pipe(
        // tap((data) => console.log("Menus obtidas")),
        catchError(this.handleError)
      );
  }

  buscar_todos(): Observable<any[]> {
    return this.http
      .get<any[]>(`${environment.apiUrl}/ControleAcesso/buscar-todos`)
      .pipe(
        // tap((data) => console.log("Controles obtidas")),
        catchError(this.handleError)
      ) as any;
  }

  buscar_por_id(id: number): Observable<any> {
    return this.http
      .get<any>(`${environment.apiUrl}/ControleAcesso/buscar-por-id?id=${id}`)
      .pipe(
        // tap((data) => console.log("Controle obtido")),
        catchError(this.handleError)
      );
  }

  vincularControleAcesso(
    vinculoControleAcesso: VinculoControleAcesso
  ): Observable<APIResponse> {
    return this.http
      .post<APIResponse>(
        `${environment.apiUrl}/ControleAcesso/vincular-controleAcesso`,
        vinculoControleAcesso,
        httpOptions
      )
      .pipe(
        // tap((resp) => console.log(JSON.stringify(resp))),
        catchError(this.handleError)
      ) as any;
  }

  createcontroleAcessoMenus(menus): Observable<APIResponse> {
    return this.http
      .post<APIResponse>(
        `${environment.apiUrl}/ControleAcesso/criar-controleAcessoMenus`,
        menus,
        httpOptions
      )
      .pipe(
        // tap((resp) => console.log(JSON.stringify(resp))),
        catchError(this.handleError)
      ) as any;
  }

  createControleAcesso(ControleAcesso: any): Observable<APIResponse> {
    return this.http
      .post<APIResponse>(
        `${environment.apiUrl}/ControleAcesso/criar-controleAcesso`,
        ControleAcesso,
        httpOptions
      )
      .pipe(
        // tap((resp) => console.log(JSON.stringify(resp))),
        catchError(this.handleError)
      ) as any;
  }

  updateControleAcesso(ControleAcesso: any): Observable<APIResponse> {
    return this.http
      .put<APIResponse>(
        `${environment.apiUrl}/ControleAcesso/editar-controleAcesso`,
        ControleAcesso,
        httpOptions
      )
      .pipe(
        // tap((resp) => console.log(JSON.stringify(resp))),
        catchError(this.handleError)
      ) as any;
  }

  excluirControleAcesso(controleAcessoId: number): Observable<APIResponse> {
    return this.http
      .delete<APIResponse>(
        `${environment.apiUrl}/ControleAcesso/deletar-controleAcesso?controleAcessoId=${controleAcessoId}`,
        httpOptions
      )
      .pipe(
        // tap((data) => console.log(JSON.stringify(data))),
        catchError(this.handleError)
      ) as any;
  }

  private handleError(err: HttpErrorResponse) {
    let errorMessage = "";
    if (err.error instanceof ErrorEvent) {
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.error(errorMessage);
    return throwError(errorMessage);
  }
}
