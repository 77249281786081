//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { UserIdentity } from './useridentity.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const UserIdentityEntityConfig = {
  name: 'UserIdentity',
  keys: [{name: 'Id'}],
  fields: {
    Id: {type: 'Edm.String', nullable: false},
    UserName: {type: 'Edm.String'},
    NormalizedUserName: {type: 'Edm.String'},
    Email: {type: 'Edm.String'},
    NormalizedEmail: {type: 'Edm.String'},
    EmailConfirmed: {type: 'Edm.Boolean', nullable: false},
    PasswordHash: {type: 'Edm.String'},
    SecurityStamp: {type: 'Edm.String'},
    ConcurrencyStamp: {type: 'Edm.String'},
    PhoneNumber: {type: 'Edm.String'},
    PhoneNumberConfirmed: {type: 'Edm.Boolean', nullable: false},
    TwoFactorEnabled: {type: 'Edm.Boolean', nullable: false},
    LockoutEnd: {type: 'Edm.DateTimeOffset'},
    LockoutEnabled: {type: 'Edm.Boolean', nullable: false},
    AccessFailedCount: {type: 'Edm.Int32', nullable: false}
  }
} as StructuredTypeConfig<UserIdentity>;
//#endregion