//#region ODataApiGen ODataImports
import {
  SchemaConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumTipoPessoaConfig } from './enumtipopessoa.enum.config';
import { EnumStatusContratoConfig } from './enumstatuscontrato.enum.config';
import { EnumStatusIntegracaoConfig } from './enumstatusintegracao.enum.config';
import { EnumAreaGeograficaConfig } from './enumareageografica.enum.config';
import { EnumRegulamentacaoProdutoConfig } from './enumregulamentacaoproduto.enum.config';
import { EnumTipoProdutoConfig } from './enumtipoproduto.enum.config';
import { EnumTipoContratoConfig } from './enumtipocontrato.enum.config';
import { EnumClinicaTipoConfig } from './enumclinicatipo.enum.config';
import { EnumTipoDeClinicaConfig } from './enumtipodeclinica.enum.config';
import { EnumTipoProfissionalConfig } from './enumtipoprofissional.enum.config';
import { EnumEstadoCivilConfig } from './enumestadocivil.enum.config';
import { EnumTipoNegociacaoConfig } from './enumtiponegociacao.enum.config';
import { EnumStatusNegociacaoConfig } from './enumstatusnegociacao.enum.config';
import { EnumTipoPrecoContratoProdutoAtoConfig } from './enumtipoprecocontratoprodutoato.enum.config';
import { EnumDentistaEspecialidadesTipoConfig } from './enumdentistaespecialidadestipo.enum.config';
import { EnumTipoOrcamentoConfig } from './enumtipoorcamento.enum.config';
import { EnumOrigemConfig } from './enumorigem.enum.config';
import { EnumStatusProcedimentoGuiaConfig } from './enumstatusprocedimentoguia.enum.config';
import { EnumStatusProcedimentoPlanoConfig } from './enumstatusprocedimentoplano.enum.config';
import { EnumTipoDocumentoConfig } from './enumtipodocumento.enum.config';
import { EnumStatusAuditoriaConfig } from './enumstatusauditoria.enum.config';
import { EnumTipoGlosaConfig } from './enumtipoglosa.enum.config';
import { EnumGrupoGlosaConfig } from './enumgrupoglosa.enum.config';
import { EnumStatusRecursoProcedimentoConfig } from './enumstatusrecursoprocedimento.enum.config';
import { EnumFormacaoPrecoProdutoConfig } from './enumformacaoprecoproduto.enum.config';
import { EnumStatusFaturamentoConfig } from './enumstatusfaturamento.enum.config';
import { EnumTipoPreEspelhoConfig } from './enumtipopreespelho.enum.config';
import { EnumTipoPerfilCarenciaConfig } from './enumtipoperfilcarencia.enum.config';
import { EnumStatusImportacaoBeneficiarioConfig } from './enumstatusimportacaobeneficiario.enum.config';
import { EnumMetodoPagamentoConfig } from './enummetodopagamento.enum.config';
import { EnumTipoFaturamentoConfig } from './enumtipofaturamento.enum.config';
import { EnumTipoAcaoConfig } from './enumtipoacao.enum.config';
import { EnumStatusProcedimentoConfig } from './enumstatusprocedimento.enum.config';
import { EnumStatusLoteConfig } from './enumstatuslote.enum.config';
import { EnumStatusGuiaConfig } from './enumstatusguia.enum.config';
import { EnumTipoGuiaConfig } from './enumtipoguia.enum.config';
import { EnumTipoAtendimentoGuiaConfig } from './enumtipoatendimentoguia.enum.config';
import { EnumTipoSiteConfig } from './enumtiposite.enum.config';
import { EnumTipoContratacaoConfig } from './enumtipocontratacao.enum.config';
import { EnumTipoPagamentoConfig } from './enumtipopagamento.enum.config';
import { EnumTipoComissaoConfig } from './enumtipocomissao.enum.config';
import { EnumTipoEntidadeConfig } from './enumtipoentidade.enum.config';
import { EnumStatusCarteiraConfig } from './enumstatuscarteira.enum.config';
import { EnumStatusLoteJobConfig } from './enumstatuslotejob.enum.config';
import { EnumTipoLoteJobConfig } from './enumtipolotejob.enum.config';
import { EnumTipoPeriodoConfig } from './enumtipoperiodo.enum.config';
import { EnumProcessamentoRetornoSIBConfig } from './enumprocessamentoretornosib.enum.config';
import { EnumEntidadesIntegradasERPConfig } from './enumentidadesintegradaserp.enum.config';
import { EnumNumberSeqRefConfig } from './enumnumberseqref.enum.config';
import { EnumModuloPortalConfig } from './enummoduloportal.enum.config';
//#endregion

//#region ODataApiGen SchemaConfig
export const EnumSchema = {
  namespace: 'Uniodonto.Domain.Model.Enum',
  enums: [EnumTipoPessoaConfig,
    EnumStatusContratoConfig,
    EnumStatusIntegracaoConfig,
    EnumAreaGeograficaConfig,
    EnumRegulamentacaoProdutoConfig,
    EnumTipoProdutoConfig,
    EnumTipoContratoConfig,
    EnumClinicaTipoConfig,
    EnumTipoDeClinicaConfig,
    EnumTipoProfissionalConfig,
    EnumEstadoCivilConfig,
    EnumTipoNegociacaoConfig,
    EnumStatusNegociacaoConfig,
    EnumTipoPrecoContratoProdutoAtoConfig,
    EnumDentistaEspecialidadesTipoConfig,
    EnumTipoOrcamentoConfig,
    EnumOrigemConfig,
    EnumStatusProcedimentoGuiaConfig,
    EnumStatusProcedimentoPlanoConfig,
    EnumTipoDocumentoConfig,
    EnumStatusAuditoriaConfig,
    EnumTipoGlosaConfig,
    EnumGrupoGlosaConfig,
    EnumStatusRecursoProcedimentoConfig,
    EnumFormacaoPrecoProdutoConfig,
    EnumStatusFaturamentoConfig,
    EnumTipoPreEspelhoConfig,
    EnumTipoPerfilCarenciaConfig,
    EnumStatusImportacaoBeneficiarioConfig,
    EnumMetodoPagamentoConfig,
    EnumTipoFaturamentoConfig,
    EnumTipoAcaoConfig,
    EnumStatusProcedimentoConfig,
    EnumStatusLoteConfig,
    EnumStatusGuiaConfig,
    EnumTipoGuiaConfig,
    EnumTipoAtendimentoGuiaConfig,
    EnumTipoSiteConfig,
    EnumTipoContratacaoConfig,
    EnumTipoPagamentoConfig,
    EnumTipoComissaoConfig,
    EnumTipoEntidadeConfig,
    EnumStatusCarteiraConfig,
    EnumStatusLoteJobConfig,
    EnumTipoLoteJobConfig,
    EnumTipoPeriodoConfig,
    EnumProcessamentoRetornoSIBConfig,
    EnumEntidadesIntegradasERPConfig,
    EnumNumberSeqRefConfig,
    EnumModuloPortalConfig],
  entities: [],
  callables: [],
  containers: []
} as SchemaConfig;
//#endregion