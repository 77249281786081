//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { SubContrato } from './subcontrato.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const SubContratoEntityConfig = {
  name: 'SubContrato',
  keys: [{name: 'Id'}],
  fields: {
    Codigo: {type: 'Edm.String'},
    Titulo: {type: 'Edm.String'},
    ContratoId: {type: 'Edm.Int32'},
    ClienteId: {type: 'Edm.Int32'},
    FaturamentoConjunto: {type: 'Edm.Boolean', nullable: false},
    DataInicioVigencia: {type: 'Edm.DateTimeOffset', nullable: false},
    DataFimVigencia: {type: 'Edm.DateTimeOffset', nullable: false},
    Prazo: {type: 'Edm.Int32', nullable: false},
    RenovacaoAutomatica: {type: 'Edm.Boolean', nullable: false},
    ExigePermanenciaMinima: {type: 'Edm.Boolean', nullable: false},
    MesesPermanenciaMinima: {type: 'Edm.Int32'},
    Individual: {type: 'Edm.Boolean', nullable: false},
    IndiceId: {type: 'Edm.Int32'},
    MotivoBaixaCancelamento: {type: 'Edm.String'},
    DataUltimaRenovacao: {type: 'Edm.DateTimeOffset'},
    MetodoPagamento: {type: 'Uniodonto.Domain.Model.Enum.EnumMetodoPagamento', nullable: false},
    CobrancaEmitida: {type: 'Edm.Boolean', nullable: false},
    DataEncerramento: {type: 'Edm.DateTimeOffset', nullable: false},
    OperadoraId: {type: 'Edm.Int32'},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'},
    Contrato: {type: 'Uniodonto.Domain.Entities.Contrato', navigation: true, referentials: [{property: 'ContratoId', referencedProperty: 'Id'}]},
    Indice: {type: 'Uniodonto.Domain.Entities.Indice', navigation: true, referentials: [{property: 'IndiceId', referencedProperty: 'Id'}]},
    Cliente: {type: 'Uniodonto.Domain.Entities.Cliente', navigation: true, referentials: [{property: 'ClienteId', referencedProperty: 'Id'}]},
    Titulares: {type: 'Uniodonto.Domain.Entities.Titular', collection: true, navigation: true},
    SubContratoProdutos: {type: 'Uniodonto.Domain.Entities.SubContratoProduto', collection: true, navigation: true},
    PreEspelhoDetalhes: {type: 'Uniodonto.Domain.Entities.PreEspelhoDetalhe', collection: true, navigation: true},
    SubClientes: {type: 'Uniodonto.Domain.Entities.Subcliente', collection: true, navigation: true},
    Operadora: {type: 'Uniodonto.Domain.Entities.Operadora', navigation: true, referentials: [{property: 'OperadoraId', referencedProperty: 'Id'}]}
  }
} as StructuredTypeConfig<SubContrato>;
//#endregion