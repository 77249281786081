import {  HttpClient,  HttpHeaders,  HttpErrorResponse,  HttpRequest,} from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError, map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { APIResponse } from '../../models';
import {   CorretoraModel} from '../../models/entities';
import { NotifierService } from 'angular-notifier';
// import { putSubContratoCommand } from '../../components/subContratante/commands/putSubContratoCommand.model';
// import { updateProdutoSubContratoCommand } from '../../components/subContratante/commands/updateProdutoSubContratoCommand.model';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
  body: undefined
};

@Injectable({
  providedIn: 'root',
})
export class SubContratoService {
  public log: String;
  private notifier: NotifierService;

  constructor(private http: HttpClient, notifier: NotifierService) {
    this.notifier = notifier;
  }

  createSubContrato(command: any): Observable<APIResponse> {
    return this.http
      .post<APIResponse>(
        `${environment.apiUrl}/SubContrato/criar`,
        command,
        httpOptions
      )
      .pipe(
        tap((resp) => "Ok"),
        catchError(this.handleError)
      );
  }

  UpdateSubContrato(command: any): Observable<APIResponse> {
    return this.http
      .put<APIResponse>(
        `${environment.apiUrl}/SubContrato/editar`,
        command,
        httpOptions
      )
      .pipe(
        tap((resp) => "Ok"),
        catchError(this.handleError)
      );
  }

  UpdateprodutoSubContrato(command: any): Observable<APIResponse> {
    return this.http
      .put<APIResponse>(
        `${environment.apiUrl}/SubContrato/produto/editar`,
        command,
        httpOptions
      );
  }


  getSubContratoV2(Id: number): Observable<any[]> {
    let IdValor= {Id};
   // return this.http.post<any[]>(`${environment.apiUrl}/SubContratoV2?$filter=ContratoId eq ${Id}`,IdValor,httpOptions)
   return this.http.get<any[]>(`${environment.odataUrl}/SubContrato?$expand=Cliente,Indice,Cliente&&$filter=ContratoId eq ${Id}`,httpOptions)
    .pipe(
        catchError(this.handleError)
    );
  };


  getProdutoSubContratoV2(Id: number): Observable<any[]> {
    let IdValor= {Id};
  // return this.http.post<any[]>(`${environment.apiUrl}/SubContratoV2?$filter=ContratoId eq ${Id}`,IdValor,httpOptions)
   return this.http.get<any[]>(`${environment.odataUrl}/SubContratoProduto?expand=Produto,PerfilCarencia,SubContrato($expand=Cliente,Indice) && $filter=SubContratoId eq ${Id}`,httpOptions)
    .pipe(
        tap(data => console.log('Subcliente obtidos')),
        catchError(this.handleError)
    );
  };

  deleteProdutoSubcontrato(subContratoId: number): Observable<any[]> {
    let payload= { "Id": subContratoId}
    httpOptions.body = payload;
    return this.http.delete<any[]>(`${environment.apiUrl}/SubContrato/produto/excluir`,httpOptions)
    .pipe(
        tap(data => console.log('Subcliente obtidos')),
        catchError(this.handleError)
    );
  };

    
  deleteSubContrato(subContratoId: number): Observable<any[]> {
    let id= { "SubContratoId": subContratoId}
    return this.http.post<any[]>(`${environment.apiUrl}/SubContrato/excluir`,id,httpOptions)
    .pipe(
        tap(data => console.log('Subcliente obtidos')),
        catchError(this.handleError)
    );
  };


  detailsSubContrato( Id : number ): Observable<APIResponse> {
    let IdValor= {Id};
  //return this.http.get<APIResponse>(`${environment.apiUrl}/ReajusteContratualV2?$select=*&$orderby=Id%20desc&$top=1 && $filter=SubContratoProdutoId eq ${Id}`, httpOptions)
    return this.http.get<APIResponse>(`${environment.apiUrl}/ReajusteContratualV2?$select=*&$orderby=Id%20desc&$top=1 && $filter=SubContratoProdutoId eq ${Id}`, httpOptions)
        .pipe(
            catchError(this.handleError)
        );
  }

  historySubContrato(  Id : number  ): Observable<any[]> {
    return this.http
      .get<any[]>(`${environment.apiUrl}/ReajusteContratualV2?$filter=SubContratoProdutoId eq ${Id} && $orderby=Id asc`,httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }



  getSubContratoV2Faturamento(): Observable<any[]> {
   // return this.http.post<any[]>(`${environment.apiUrl}/SubContratoV2?$filter=ContratoId eq ${Id}`,IdValor,httpOptions)
   return this.http.post<any[]>(`${environment.apiUrl}/SubContratoV2?$filter=faturamentoConjunto eq false`,httpOptions)
    .pipe(
        tap(data => console.log('Subcliente obtidos')),
        catchError(this.handleError)
    );
  };


  



  private handleError(err: HttpErrorResponse) {
    return throwError(err);
  }
}
