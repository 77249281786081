//#region ODataApiGen Imports
//#endregion

export enum EnumStatusImportacaoBeneficiario {
  //#region ODataApiGen Members
  REGISTRO_COMPLETO = 1,
  REGISTRO_INCOMPLETO = 2,
  REGISTRO_ERRO = 3,
  REGISTRO_IMPORTANDO = 4,
  //#endregion
}
