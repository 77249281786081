//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
//#endregion

export interface ConsolidadoProcessamentoSIB {
  //#region ODataApiGen Properties
  Hash?: string;
  NumeroProtocolo?: string;
  NomeArquivo?: string;
  QuantidadeRegistrosInclusao: number;
  QuantidadeProcessadosInclusao: number;
  QuantidadeRejeitadosInclusao: number;
  PercentualAcertoInclusao: number;
  QuantidadeRegistrosRetificacao: number;
  QuantidadeProcessadosRetificacao: number;
  QuantidadeRejeitadosRetificacao: number;
  PercentualAcertoRetificacao: number;
  QuantidadeRegistrosMudancaContratual: number;
  QuantidadeProcessadosMudancaContratual: number;
  QuantidadeRejeitadosMudancaContratual: number;
  PercentualAcertoMudancaContratual: number;
  QuantidadeRegistrosReativacao: number;
  QuantidadeProcessadosReativacao: number;
  QuantidadeRejeitadosReativacao: number;
  PercentualAcertoReativacao: number;
  QuantidadeRegistrosCancelamento: number;
  QuantidadeProcessadosCancelamento: number;
  QuantidadeRejeitadosCancelamento: number;
  PercentualAcertoCancelamento: number;
  QuantidadeRegistrosSemMovimentacao: number;
  QuantidadeProcessadosSemMovimentacao: number;
  QuantidadeRejeitadosSemMovimentacao: number;
  PercentualAcertoSemMovimentacao: number;
  QuantidadeRegistrosTotal: number;
  QuantidadeProcessadosTotal: number;
  QuantidadeRejeitadosTotal: number;
  PercentualAcertoTotal: number;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  //#endregion
}