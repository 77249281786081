//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Comissionamento } from './comissionamento.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const ComissionamentoEntityConfig = {
  name: 'Comissionamento',
  keys: [{name: 'Id'}],
  fields: {
    Nome: {type: 'Edm.String'},
    Descricao: {type: 'Edm.String'},
    EnumTipoComissao: {type: 'Uniodonto.Domain.Model.Enum.EnumTipoComissao', nullable: false},
    OperadoraId: {type: 'Edm.Int32'},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'},
    ConfiguracoesComissionamento: {type: 'Uniodonto.Domain.Entities.ConfiguracaoComissionamento', collection: true, navigation: true},
    Operadora: {type: 'Uniodonto.Domain.Entities.Operadora', navigation: true, referentials: [{property: 'OperadoraId', referencedProperty: 'Id'}]}
  }
} as StructuredTypeConfig<Comissionamento>;
//#endregion