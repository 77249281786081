import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { CEAAccess } from '../models';

import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class UserService {
    private assignorGroupListSubject: BehaviorSubject<CEAAccess[]>;
    public assignorGroupList: Observable<CEAAccess>[];

    constructor(private http: HttpClient) { }

    getAll() {
        return this.http.get<CEAAccess[]>(`${environment.apiUrl}/auth`);
    }
}