import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';


import { environment } from '../../environments/environment';
import { User } from '../models';
import { CEAAccess } from '../models/CEAAccess';
import { APIResponse } from '../models';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentAccessSubject: BehaviorSubject<CEAAccess>;
    public currentAccess: Observable<CEAAccess>;

    constructor(private http: HttpClient) {
        this.currentAccessSubject = new BehaviorSubject<CEAAccess>(JSON.parse(localStorage.getItem('currentAccess')));
        this.currentAccess = this.currentAccessSubject.asObservable();
    }

    public get currentAccessValue(): CEAAccess {
        return this.currentAccessSubject.value;
    }

    logout() {
        // remove user from local storage to log user out
        // localStorage.removeItem('currentAccess');
        // this.currentAccessSubject.next(null);
    }

}
