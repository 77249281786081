import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { AuthUtils } from './auth.utils';
import { environment } from '../../../environments/environment';
import { AuthReponseModel, AuthUserTokenModel } from './auth.response.model';
import jwt_decode from 'jwt-decode';
import { MenusControleAcessoHandleService } from '../../services/entities/menus-controle-acesso-handle/menus-controle-acesso-handle.service';
import { Router } from '@angular/router';
import { Usuario } from '../../uniodonto';
import { CriarUsuarioExternoCommand } from '../../models/command/CriarUsuarioExternoCommand';
import { RecuperarSenhaCommand } from '../../models/command/RecuperarSenhaCommand';
import { ResetarSenhaCommand } from '../../models/command/ResetarSenhaCommand';
import { LoginRequest } from '../../models/command/LoginRequest';

@Injectable()
export class AuthService {
  // Private
  private _authenticated: boolean;

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(
    private _httpClient: HttpClient,
    private router: Router,
    ) {
    // Set the defaults
    this._authenticated = false;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for access token
   */
  set accessToken(token: string) {
    localStorage.setItem('access_token', token);
    localStorage.setItem('decoded_token', JSON.stringify(jwt_decode(token)));
  }

  get accessToken(): string {
    return localStorage.getItem('access_token');
  }

  set menu(menu: string) {
    localStorage.setItem('menu', JSON.stringify(menu));
  }

  get menu(): any {
    return JSON.parse(localStorage.getItem('menu'));
  }

  // /**
  //  * Setter & getter for claims
  //  */
  set userClaims(claims: any) {
    localStorage.setItem('userClaims', claims);
  }

  get userClaims(): any {
    return JSON.parse(localStorage.getItem('userClaims'));
  }

  /**
   * Setter & getter for roles
   */
  set userRoles(roles: any) {
    localStorage.setItem('userRoles', roles);
  }

  get userRoles(): any {
    let roles = JSON.parse(localStorage.getItem('decoded_token'));
    return roles;
  }

  set userToken(userToken: AuthUserTokenModel) {
    localStorage.setItem('user_id', userToken.Id);
    localStorage.setItem('user_email', userToken.Email);
  }

  get userId(): string {
    return localStorage.getItem('user_id');
  }

  /**
   * Setter & getter for access token
   */
  set userData(userData: any) {
    localStorage.setItem('user', userData);
  }

  get userData(): any {
    return JSON.parse(localStorage.getItem('user'));
  }

  get currentRole(): string {
    let token = JSON.parse(localStorage.getItem('decoded_token'));
    return token?.role;
  }

  get currentRoleKey(): string {
    let token = JSON.parse(localStorage.getItem('decoded_token'));
    return token?.role_key;
  }

  get tokenExp(): number {
    let token = JSON.parse(localStorage.getItem('decoded_token'));
    return +token?.exp;
  }

  get operadora() {
    let user: Usuario = this.userData;
    let operadoraId = (this.currentRole=="CLINICA") ?
      user.UsuarioClinicas.find(x => x.ClinicaId == +this.currentRoleKey).Clinica.OperadoraId :
      +this.currentRoleKey;

      return user.OperadoraUsuarios.find(x => x.OperadoraId == operadoraId).Operadora;
  }

  get loginURL() {
    let module = this.currentRole?.toLocaleLowerCase() ?? 'beneficiario';
    return `/${module}/login`;
  }


  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Sign in
   *
   * @param credentials
   */
  signIn(command: LoginRequest): Observable<any> {
    // Throw error, if the user is already logged in
    if (this._authenticated) {
      return throwError('User is already logged in.');
    }

    return this._httpClient
      .post<any>(`${environment.apiUrl}/Auth/login`, command, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json-patch+json',
        }),
      })
      .pipe(
        switchMap((response) => {
          this.applyResponse(response);
          return of(response);
        })
      );
  }

  forgotAccess(command: RecuperarSenhaCommand): Observable<any> {
    // Throw error, if the user is already logged in
    if (this._authenticated) {
      return throwError('User is already logged in.');
    }

    return this._httpClient
      .post<any>(`${environment.apiUrl}/Auth/recuperar-senha`, command, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json-patch+json',
        }),
      })
  }

  resetAccess(command: ResetarSenhaCommand): Observable<any> {
    // Throw error, if the user is already logged in
    if (this._authenticated) {
      return throwError('User is already logged in.');
    }

    return this._httpClient
      .post<any>(`${environment.apiUrl}/Auth/resetar-senha`, command, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json-patch+json',
        }),
      });
  }

  firstAccess(commnand: CriarUsuarioExternoCommand): Observable<any> {
    // Throw error, if the user is already logged in
    if (this._authenticated) {
      return throwError('User is already logged in.');
    }

    return this._httpClient
      .post<any>(`${environment.apiUrl}/Auth/criar-usuario-externo`, commnand, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json-patch+json',
        }),
      });
  }

  applyResponse(response) {
    // localStorage.clear();
    this.accessToken = response.Token;
    this.userData = JSON.stringify(response.Usuario);
    this._authenticated = true;
    this.menu = response.Menu;
  }

  /**
   * Novo token c/ role + id
   *
   * @param role
   * @param id
   */
  // novoToken(role: string, id: number): Observable<any> {

  //   const body$ = {
  //     'Value': id,
  //     'Role': role
  //   };

  //   // Throw error, if the user is already logged in
  //   if (!this._authenticated) {
  //     return throwError('Vocẽ precisar estar logado para alterar o token.');
  //   }

  //   return this._httpClient
  //     .post<any>(`${environment.apiUrl}/Auth/new-token`, body$, {
  //       headers: new HttpHeaders({
  //         'Content-Type': 'application/json-patch+json',
  //       }),
  //     })
  //     .pipe(
  //       switchMap((response) => {
  //         this.applyResponse(response);
  //         return of(response);
  //       })
  //     );
  // }

  /**
   * Novo token somente role
   *
   * @param role
   * @param id
   */
  novoToken_WithRole(role: string , value : number): Observable<any> {
    let body$;

    value ? body$ = {
      'Role': role,
      'Value' : value
    } : body$ = {
      'Role': role,
    };

    if (this.isTokenExpired()) {
      localStorage.clear();
      window.location.reload();
      this.router.navigate(["login"]);
    }

    return this._httpClient
      .post<any>(`${environment.apiUrl}/Auth/new-token`, body$, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json-patch+json',
        }),
      })
      .pipe(
        switchMap((response) => {
          this.applyResponse(response);
          return of(response);
        })
      );
  }

  /**
   * Sign in using the access token
   */
  signInUsingToken(): Observable<any> {
    // Renew token
    return this._httpClient
      .get(`${environment.apiUrl}/Usuario/buscar-por-id?id=${1}`)
      .pipe(
        catchError(() => {
          // Return false
          return of(false);
        }),
        switchMap((response: any) => {
          // Store the access token in the local storage
          this.accessToken = this.accessToken;

          // Set the authenticated flag to true
          this._authenticated = true;

          // Return true
          return of(true);
        })
      );
  }

  /**
   * Sign out
   */
  signOut(): Observable<any> {
    // Remove the access token from the local storage
    localStorage.removeItem('access_token');
    localStorage.clear();

    // Set the authenticated flag to false
    this._authenticated = false;

    // Return the observable
    return of(true);
  }

  public isTokenExpired(): boolean {


    if (this.tokenExp == null) {
      return true;
    }

    const date = new Date(0);
    date.setUTCSeconds(this.tokenExp);

    if (date === null) {
      return true;
    }

    // Check if the token is expired
    return !(date.valueOf() > new Date().valueOf());
  }

  /**
   * Check the authentication status
   */
  check(): Observable<boolean> {
    // Check if the user is logged in
    if (this._authenticated) {
      return of(true);
    }

    // Check the access token availability
    if (!this.accessToken) {
      return of(false);
    }

    // Check the access token expire date
    if (AuthUtils.isTokenExpired(this.accessToken)) {
      return of(false);
    }

    return of(true);

    // If the access token exists and it didn't expire, sign in using it
    // return this.signInUsingToken();
  }
}
