//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { HistoricoAtivacaoInativacaoOperadoraV2Service } from './historicoativacaoinativacaooperadorav2.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const HistoricoAtivacaoInativacaoOperadoraV2ServiceEntitySetConfig = {
  name: 'HistoricoAtivacaoInativacaoOperadoraV2',
  entityType: 'Uniodonto.Domain.Entities.HistoricoAtivacaoInativacaoOperadora',
  service: HistoricoAtivacaoInativacaoOperadoraV2Service
} as EntitySetConfig;
//#endregion