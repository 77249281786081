//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Operadora } from './operadora.entity';
import { Dentista } from './dentista.entity';
import { Especialidade } from './especialidade.entity';
//#endregion

export interface DentistaFormacaoEHabilidade {
  //#region ODataApiGen Properties
  DentistaId?: number;
  EspecialidadeId?: number;
  Curso?: string;
  Grau?: string;
  Instituicao?: string;
  DataConclusao?: string;
  AnexoNomeArquivo?: string;
  AnexoURL?: string;
  OperadoraId?: number;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  Dentista?: Dentista;
  Especialidade?: Especialidade;
  Operadora?: Operadora;
  //#endregion
}