//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumTipoPessoa } from '../Model/Enum/enumtipopessoa.enum';
import { EnumStatusContrato } from '../Model/Enum/enumstatuscontrato.enum';
import { EnumMetodoPagamento } from '../Model/Enum/enummetodopagamento.enum';
import { Cliente } from './cliente.entity';
import { ContratoProduto } from './contratoproduto.entity';
import { PreEspelho } from './preespelho.entity';
import { Operadora } from './operadora.entity';
import { Subcliente } from './subcliente.entity';
import { Titular } from './titular.entity';
import { ContratoProdutoAtoPreco } from './contratoprodutoatopreco.entity';
import { SubContrato } from './subcontrato.entity';
import { ContratoOperadoraRepasse } from './contratooperadorarepasse.entity';
import { TipoModalidadeContrato } from './tipomodalidadecontrato.entity';
import { Nucleo } from './nucleo.entity';
import { Negociacao } from './negociacao.entity';
import { Indice } from './indice.entity';
import { Reembolso } from './reembolso.entity';
import { OrigemCompraContrato } from './origemcompracontrato.entity';
import { TipoContratoColetivo } from './tipocontratocoletivo.entity';
import { Comissionamento } from './comissionamento.entity';
import { Corretor } from './corretor.entity';
import { ContratoDocumento } from './contratodocumento.entity';
//#endregion

export interface Contrato {
  //#region ODataApiGen Properties
  Titulo?: string;
  CONTRATO_TIPO_CONTRATO: string;
  TipoPessoa: EnumTipoPessoa;
  TipoModalidadeId: number;
  Ativo: boolean;
  CONTRATO_NUCLEO?: number;
  ClienteId?: number;
  OrigemCompraId: number;
  TipoContratoColetivoId?: number;
  PrazoContrato: number;
  QtdMesesPermanenciaMinima: number;
  CodigoContrato?: string;
  RenovacaoAutomatica: boolean;
  PossuiCooparticipacao: boolean;
  CobrarCooparticipacao: boolean;
  DataInicial: Date;
  DataFinal: Date;
  DataEncerramento: Date;
  DataPermanenciaMinima: Date;
  DataFimAtendimento?: Date;
  MotivoEncerramentoContrato?: string;
  StatusContratoId: EnumStatusContrato;
  AceitaReembolso: boolean;
  ReembolsoId?: number;
  CorretorId?: number;
  ComissionamentoId?: number;
  IndiceId?: number;
  ContratoPaiId?: number;
  Intercambio: boolean;
  QuantidadeMesesRenovacaoAutomatica?: number;
  DataUltimaRenovacao?: Date;
  MetodoPagamento: EnumMetodoPagamento;
  CobrancaEmitida: boolean;
  OperadoraId?: number;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  TipoModalidade?: TipoModalidadeContrato;
  Nucleo?: Nucleo;
  Cliente?: Cliente;
  OrigemCompra?: OrigemCompraContrato;
  TipoContratoColetivo?: TipoContratoColetivo;
  Reembolso?: Reembolso;
  Comissionamento?: Comissionamento;
  Corretor?: Corretor;
  TitularModels?: Titular[];
  ProdutosDoContrato?: ContratoProduto[];
  DocumentosDoContrato?: ContratoDocumento[];
  Negociacoes?: Negociacao[];
  ContratoOperadoraRepasses?: ContratoOperadoraRepasse[];
  Indice?: Indice;
  PreEspelhos?: PreEspelho[];
  SubClientes?: Subcliente[];
  SubContratos?: SubContrato[];
  ContratoProdutoAtosPrecos?: ContratoProdutoAtoPreco[];
  Operadora?: Operadora;
  //#endregion
}