//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AtoDominio } from './atodominio.entity';
import { DominioDenteAfetado } from './dominiodenteafetado.entity';
//#endregion

export interface Dominio {
  //#region ODataApiGen Properties
  DominioCodigo?: string;
  DominioNome?: string;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  AtosDominios?: AtoDominio[];
  DominiosDentesAfetados?: DominioDenteAfetado[];
  //#endregion
}