//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { BeneficiarioV2Service } from './beneficiariov2.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const BeneficiarioV2ServiceEntitySetConfig = {
  name: 'BeneficiarioV2',
  entityType: 'Uniodonto.Domain.Entities.Beneficiario',
  service: BeneficiarioV2Service
} as EntitySetConfig;
//#endregion