//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { PerfilCobranca } from './perfilcobranca.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const PerfilCobrancaEntityConfig = {
  name: 'PerfilCobranca',
  keys: [{name: 'Id'}],
  fields: {
    ProdutoId: {type: 'Edm.Int32'},
    Codigo: {type: 'Edm.String'},
    NomeDivulgacao: {type: 'Edm.String'},
    OperadoraId: {type: 'Edm.Int32'},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'},
    Produto: {type: 'Uniodonto.Domain.Entities.Produto', navigation: true, referentials: [{property: 'ProdutoId', referencedProperty: 'Id'}]},
    Operadora: {type: 'Uniodonto.Domain.Entities.Operadora', navigation: true, referentials: [{property: 'OperadoraId', referencedProperty: 'Id'}]}
  }
} as StructuredTypeConfig<PerfilCobranca>;
//#endregion