//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Operadora } from './operadora.entity';
import { Procedimentos } from './procedimentos.entity';
import { Glosa } from './glosa.entity';
//#endregion

export interface ProcedimentoGlosa {
  //#region ODataApiGen Properties
  ProcedimentoId: number;
  GlosaId?: number;
  GlosaDescricao?: string;
  OperadoraId?: number;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  Glosa?: Glosa;
  Procedimento?: Procedimentos;
  Operadora?: Operadora;
  //#endregion
}