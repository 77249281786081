import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumModuloPortal } from '../Uniodonto/Domain/Model/Enum/enummoduloportal.enum';
import { Operadora } from '../Uniodonto/Domain/Entities/operadora.entity';
import { Link } from '../Uniodonto/Domain/Entities/link.entity';
//#endregion

@Injectable()
export class LinkV2Service extends ODataEntitySetService<Link> {
  constructor(client: ODataClient) {
    super(client, 'LinkV2', 'Uniodonto.Domain.Entities.Link');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public operadora(key: EntityKey<Link>): ODataNavigationPropertyResource<Operadora> { 
    return this.entity(key).navigationProperty<Operadora>('Operadora'); 
  }
  public fetchOperadora(key: EntityKey<Link>, options?: ODataQueryArgumentsOptions<Operadora>) {
    return this.fetchNavigationProperty<Operadora>(
      this.operadora(key), 
      'entity', options) as Observable<ODataEntity<Operadora>>;
  }
  public setOperadoraAsOperadora(key: EntityKey<Link>, target: ODataEntityResource<ODataEntity<Operadora>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.operadora(key).reference()
      .set(target, {etag});
  }
  public unsetOperadoraAsOperadora(key: EntityKey<Link>, {target, etag}: {target?: ODataEntityResource<ODataEntity<Operadora>>, etag?: string} = {}): Observable<any> {
    return this.operadora(key).reference()
      .unset({etag});
  }
  //#endregion
}
