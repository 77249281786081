//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { GrupoFornecedorERPService } from './grupofornecedorerp.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const GrupoFornecedorERPServiceEntitySetConfig = {
  name: 'GrupoFornecedorERP',
  entityType: 'Uniodonto.Domain.Entities.GrupoFornecedorERP',
  service: GrupoFornecedorERPService
} as EntitySetConfig;
//#endregion