//#region ODataApiGen Imports
//#endregion

export enum EnumTipoPerfilCarencia {
  //#region ODataApiGen Members
  TODOS = 1,
  ESPECIALIDADE = 2,
  ATO = 3,
  //#endregion
}
