//#region ODataApiGen ODataImports
import {
  EnumTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumStatusNegociacao } from './enumstatusnegociacao.enum';
//#endregion

//#region ODataApiGen EnumTypeConfig
export const EnumStatusNegociacaoConfig = {
  name: 'EnumStatusNegociacao',
  members: EnumStatusNegociacao,
  fields: {
    Habilitado: {value: 0},
    Desabilitado: {value: 1}
  }
} as EnumTypeConfig<EnumStatusNegociacao>;
//#endregion