//#region ODataApiGen ODataImports
import {
  EnumTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumRegulamentacaoProduto } from './enumregulamentacaoproduto.enum';
//#endregion

//#region ODataApiGen EnumTypeConfig
export const EnumRegulamentacaoProdutoConfig = {
  name: 'EnumRegulamentacaoProduto',
  members: EnumRegulamentacaoProduto,
  fields: {
    NAO_INFORMADO: {value: 0},
    REGULAMENTADO: {value: 1},
    ADAPTADO: {value: 2},
    NAO_REGULAMENTADO: {value: 3}
  }
} as EnumTypeConfig<EnumRegulamentacaoProduto>;
//#endregion