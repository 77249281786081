//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumStatusProcedimentoGuia } from '../Model/Enum/enumstatusprocedimentoguia.enum';
import { EnumStatusProcedimentoPlano } from '../Model/Enum/enumstatusprocedimentoplano.enum';
import { EnumStatusAuditoria } from '../Model/Enum/enumstatusauditoria.enum';
import { EnumTipoAcao } from '../Model/Enum/enumtipoacao.enum';
import { Operadora } from './operadora.entity';
import { AtoOdontologico } from './atoodontologico.entity';
import { Orcamento } from './orcamento.entity';
import { Dominio } from './dominio.entity';
import { Guia } from './guia.entity';
import { ValorProcedimentoTipoProduto } from './valorprocedimentotipoproduto.entity';
import { ProcedimentoGlosa } from './procedimentoglosa.entity';
import { ProcedimentoRecursosHistorico } from './procedimentorecursoshistorico.entity';
import { GuiaObservacao } from './guiaobservacao.entity';
import { ProcedimentoImagens } from './procedimentoimagens.entity';
import { ProcedimentoFaces } from './procedimentofaces.entity';
//#endregion

export interface Procedimentos {
  //#region ODataApiGen Properties
  OrcamentoId?: number;
  GuiaId?: number;
  AtoOdontologicoId?: number;
  CodigoProcedimento: number;
  NomeProcedimento?: string;
  DominioId?: number;
  DominioNome?: string;
  DominioCodigo?: string;
  UsQuantidade: number;
  UsValor: number;
  ProfissionalId?: number;
  ProfissionalNome?: string;
  AprovadorTecnico?: string;
  AprovacaoTecAutomatica: boolean;
  DataAprovacaoTecnica: Date;
  AprovadorAdministrativo?: string;
  AprovacaoAdmAutomatica: boolean;
  DataAprovacaoAdministrativa: Date;
  Observacao?: string;
  IsExecutado: boolean;
  IsAnalisado: boolean;
  FacesProcedimento?: string;
  AutorizacaoAdm: boolean;
  AutorizadorAdministrativo?: string;
  DataAutorizacaoAdm: Date;
  AutorizacaoTecnica: boolean;
  AutorizadorTecnico?: string;
  DataAutorizacaoTecnica: Date;
  PossuiCooparticipacao: boolean;
  QuantidadeCooparticipacao: number;
  ValorCooparticipacao: number;
  StatusGuia: EnumStatusProcedimentoGuia;
  StatusPlano: EnumStatusProcedimentoPlano;
  StatusAuditoriaTecnica: EnumStatusAuditoria;
  StatusAuditoriaAdministrativa: EnumStatusAuditoria;
  PossuiRecursos: boolean;
  UFastSequencialProcedimento?: number;
  TemRestricao: boolean;
  DataExecucao?: Date;
  TipoAcao: EnumTipoAcao;
  UsQuantidadeIntercambio: number;
  UsValorIntercambio: number;
  UsValorIntercambioUFAST: number;
  Substituicao: boolean;
  ProcedimentoSubstituidoId?: number;
  IdLegado?: string;
  OperadoraId?: number;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  Orcamento?: Orcamento;
  Guia?: Guia;
  Dominio?: Dominio;
  AtoOdontologico?: AtoOdontologico;
  ProcedimentoImagens?: ProcedimentoImagens[];
  ProcedimentoRecursosHistoricos?: ProcedimentoRecursosHistorico[];
  ValorProcedimentoTipoProdutos?: ValorProcedimentoTipoProduto[];
  ProcedimentoFaces?: ProcedimentoFaces[];
  Glosas?: ProcedimentoGlosa[];
  Observacoes?: GuiaObservacao[];
  Operadora?: Operadora;
  //#endregion
}