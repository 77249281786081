//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumStatusCarteira } from '../Model/Enum/enumstatuscarteira.enum';
import { ContratoProduto } from './contratoproduto.entity';
import { Operadora } from './operadora.entity';
import { Beneficiario } from './beneficiario.entity';
import { Titular } from './titular.entity';
import { Dependente } from './dependente.entity';
import { SubContrato } from './subcontrato.entity';
import { MotivoSegundaVia } from './motivosegundavia.entity';
//#endregion

export interface Carteira {
  //#region ODataApiGen Properties
  CodigoCarteira?: string;
  BeneficiarioId?: number;
  ContratoProdutoId?: number;
  MotivoId?: number;
  ETitular: boolean;
  TitularId?: number;
  Status: EnumStatusCarteira;
  Versao: number;
  Ativo: boolean;
  DataExpiracao?: Date;
  CobrarSegundaVia: boolean;
  PagamentoEfetuado: boolean;
  DependenteId?: number;
  SubContratoId?: number;
  Intercambio: boolean;
  OperadoraId?: number;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  Beneficiario?: Beneficiario;
  ContratoProduto?: ContratoProduto;
  Motivo?: MotivoSegundaVia;
  Titular?: Titular;
  Dependente?: Dependente;
  SubContrato?: SubContrato;
  Operadora?: Operadora;
  //#endregion
}