//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { LoteV2Service } from './lotev2.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const LoteV2ServiceEntitySetConfig = {
  name: 'LoteV2',
  entityType: 'Uniodonto.Domain.Entities.Lote',
  service: LoteV2Service
} as EntitySetConfig;
//#endregion