import {
	HttpClient,
	HttpErrorResponse,
	HttpHeaders,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { environment } from "../../../environments/environment";

import { NotifierService } from "angular-notifier";
import { APIListResponse, APIResponse } from "../../models";

const httpOptions = {
	headers: new HttpHeaders({
		"Content-Type": "application/json",
	}),
};

@Injectable({
	providedIn: "root",
})
export class BeneficiarioService {
	public log: String;

	constructor(private http: HttpClient, private notifier: NotifierService) {}
	public showNotification(type: string, message: string): void {
		this.notifier.notify(type, message);
	}

	getById(id: any): Observable<any[]> {
		return this.http.get<any[]>(
			`${environment.apiUrl}/beneficiario/buscar-por-id?id=${id}`
		);
	}

	getByCPF(cpf: any): Observable<any[]> {
		return this.http
			.get<any[]>(
				` ${environment.apiUrl}/beneficiario/buscar-por-cpf?cpf=${cpf}`
			)
			.pipe(catchError(this.handleError));
	}

	getDadosCNSByCPF(cpf: any): Observable<any[]> {
		let query = { value: cpf, filtro: 1, operadoraDestino: "001" };

		return this.http.get<any[]>(
			`${environment.apiUrl}/portal/consultaCNS?Filtro=${query.filtro}&Value=${query.value}&OperadoraDestino=${query.operadoraDestino}`
		);
	}

	getByBeneficiarioCPF(cpf: any, contratoId: any): Observable<APIResponse> {
		return this.http.post<APIResponse>(
			` ${environment.apiUrl}/beneficiario/procurar-beneficiarios-por-contrato`,
			{ Cpf: cpf, ContratoId: contratoId }
		);
	}

	SearchBeneficiario(model: any): Observable<any[]> {
		let url = ` ${environment.apiUrl}/Beneficiario/procurar-benefciarios`;
		return this.http.post<any[]>(url, model, httpOptions);
	}

	getProcedures(): Observable<APIResponse> {
		return this.http.get<APIResponse>(
			`${environment.apiUrl}/Portal/meus-tratamentos`
		);
	}

	getHistoricoAtendimentoById(idBeneficiario: number): Observable<any[]> {
		return this.http
			.get<any[]>(
				` ${environment.apiUrl}/Beneficiario/historico-atendimento?idBeneficiario=${idBeneficiario}`
			)
			.pipe(catchError(this.handleError));
	}

	getAll(): Observable<any[]> {
		return this.http
			.get<any[]>(`${environment.apiUrl}/beneficiario/buscar-todos`)
			.pipe(catchError(this.handleError));
	}

	create(beneficiario): Observable<APIResponse> {
		return this.http
			.post<APIResponse>(
				`${environment.apiUrl}/beneficiario/criar-beneficiario`,
				beneficiario,
				httpOptions
			)
			.pipe(catchError(this.handleError));
	}

	desligamentoBeneficiario(beneficiario): Observable<APIResponse> {
		return this.http
			.post<APIResponse>(
				`${environment.apiUrl}/beneficiario/dar-baixar-beneficiario`,
				beneficiario,
				httpOptions
			)
			.pipe(catchError(this.handleError));
	}

	updateBeneficiario(beneficiario): Observable<APIResponse> {
		return this.http.post<APIResponse>(
			`${environment.apiUrl}/beneficiario/editar-beneficiario`,
			beneficiario,
			httpOptions
		);
	}

	deleteBeneficiario(IdBeneficiario: number): Observable<APIResponse> {
		return this.http
			.delete<APIResponse>(
				`${environment.apiUrl}/beneficiario/deletar-beneficiario?id=${IdBeneficiario}`,
				httpOptions
			)
			.pipe(catchError(this.handleError));
	}

	createContato(
		contatos: Array<any>,
		documento: string
	): Observable<APIResponse> {
		let enderecosRequest = { Contatos: contatos };
		return this.http
			.post<APIResponse>(
				`${environment.apiUrl}/contato/criar-contato-por-documento?documento=${documento}`,
				enderecosRequest,
				httpOptions
			)
			.pipe(catchError(this.handleError));
	}

	updateContato(
		contatos: any
		// documento: string
	): Observable<APIResponse> {
		let enderecosRequest = { contatos };
		return this.http
			.post<APIResponse>(
				`${environment.apiUrl}/Beneficiario/editar-contato`,
				contatos,
				httpOptions
			)
			.pipe(catchError(this.handleError));
	}

	deleteContato(IdContato: number): Observable<APIResponse> {
		return this.http
			.delete<APIResponse>(
				`${environment.apiUrl}/Beneficiario/excluir-contato?id=${IdContato}`,
				httpOptions
			)
			.pipe(catchError(this.handleError));
	}

	createEndereco(
		documento: string,
		enderecos: Array<any>
	): Observable<APIResponse> {
		let enderecosRequest = { Enderecos: enderecos };
		return this.http
			.post<APIResponse>(
				`${environment.apiUrl}/Endereco/criar-endereco-por-documento?documento=${documento}`,
				enderecosRequest,
				httpOptions
			)
			.pipe(catchError(this.handleError));
	}

	updateEndereco(endereco: any): Observable<APIResponse> {
		// let enderecosRequest = { 'Enderecos': enderecos };
		return this.http
			.put<APIResponse>(
				`${environment.apiUrl}/Beneficiario/editar-endereco`,
				endereco,
				httpOptions
			)
			.pipe(catchError(this.handleError));
	}

	deleteEndereco(IdEndereco: number): Observable<APIResponse> {
		return this.http
			.delete<APIResponse>(
				`${environment.apiUrl}/Endereco/remover-endereco?id=${IdEndereco}`,
				httpOptions
			)
			.pipe(catchError(this.handleError));
	}

	exportFile() {
		return this.http.get(`${environment.apiUrl}/Beneficiario`, {
			responseType: "blob" as "json",
		});
	}

	importarArquivo(fileToUpload: any) {
		let input = new FormData();
		input.append("formFile", fileToUpload);

		return this.http.post(`${environment.apiUrl}/Beneficiario/importar`, input);
	}

	baixarModeloImportacao(): Observable<any> {
		return this.http
			.get<APIResponse>(
				`${environment.apiUrl}/ImportarBeneficiario/exportar-planilha-beneficiario-titular`,
				{ responseType: "blob" as "json" }
			)
			.pipe(catchError(this.handleError));
	}

	validarImportacao(file: File): Observable<APIListResponse> {
		const payload = new FormData();
		payload.append("formFile", file);
		const url = `${environment.apiUrl}/ImportarBeneficiario/importar-planilha-beneficiario-titular`;

		return this.http.post<APIListResponse>(url, payload);
	}

	salvarImportados(payload: any): Observable<APIResponse> {
		const url = `${environment.apiUrl}/ImportarBeneficiario/salvar-importacao`;

		return this.http
			.post<APIListResponse>(url, payload)
			.pipe(catchError(this.handleError));
	}

	carregarDadosImportação() {
		return this.http
			.get<any[]>(`${environment.apiUrl}/ImportarBeneficiario/buscar-todos`)
			.pipe(catchError(this.handleError));
	}

	update(model): Observable<APIResponse> {
		return this.http
			.post<APIResponse>(
				`${environment.apiUrl}/ImportarBeneficiario/atualizar`,
				model,
				httpOptions
			)
			.pipe(catchError(this.handleError));
	}

	updateContactImport(model): Observable<APIResponse> {
		return this.http
			.post<APIResponse>(
				`${environment.apiUrl}/ImportarBeneficiario/atualizar-contato`,
				model,
				httpOptions
			)
			.pipe(catchError(this.handleError));
	}

	updateAddressImport(model: any): Observable<APIResponse> {
		return this.http
			.post<APIResponse>(
				`${environment.apiUrl}/ImportarBeneficiario/atualizar-endereco`,
				model,
				httpOptions
			)
			.pipe(catchError(this.handleError));
	}

	deleteImport(id: number) {
		let url = environment.apiUrl + "/ImportarBeneficiario/deletar";
		return this.http
			.post(url, { Id: id }, httpOptions)
			.pipe(catchError(this.handleError));
	}

	getByBeneficiaryByCPF(model: any): Observable<APIResponse> {
		return this.http
			.post<APIResponse>(
				`${environment.apiUrl}/portal/buscar-beneficiario-por-cpf`,
				model,
				httpOptions
			)
			.pipe(catchError(this.handleError));
	}

	generateTxtFile(model: any): Observable<APIResponse> {
		return this.http.post<APIResponse>(
			`${environment.apiUrl}/beneficiario/retorna-arquivo-txt`,
			model,
			{ responseType: "blob" as "json" }
		);
	}

	motivoDesligamentoAll() {
		return this.http
			.get<any[]>(`${environment.apiUrl}/MotivoDesligamento/buscar-todos`)
			.pipe(catchError(this.handleError));
	}

	relatorioMovimentacao(command: any): Observable<APIResponse> {
		return this.http.post<APIResponse>(
			`${environment.apiUrl}/beneficiario/relatorio-movimentacao`,
			command,
			httpOptions
		);
	}

	private handleError(err: HttpErrorResponse) {
		return throwError(err);
		/*let errorMessage = "";
      if (err.error instanceof ErrorEvent) {
        errorMessage = `An error occurred: ${err.error.message}`;
      } else {
        errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
      }
      console.error(errorMessage);
      return throwError(errorMessage);*/
	}

	createContatoParticipante(participanteId): Observable<APIResponse> {
		return this.http
			.post<APIResponse>(
				`${environment.apiUrl}/Contato/criar-contato-participante-id`,
				participanteId,
				httpOptions
			)
			.pipe(
				tap((resp) =>
					console.log("success", "Contato cadastrado com sucesso!")
				),
				catchError(this.handleError)
			);
	}

	cUpdateEndereco(dadosEndereco: any): Observable<APIResponse> {
		return this.http
			.post<APIResponse>(
				`${environment.apiUrl}/Endereco/editar-Endereco`,
				dadosEndereco,
				httpOptions
			)
			.pipe(
				tap((resp) =>
					console.log("success", "Endereco atualizado com sucesso!")
				),
				catchError(this.handleError)
			);
	}

	createEnderecoParticipante(dadosEndereco: any): Observable<APIResponse> {
		return this.http.post<APIResponse>(
			`${environment.apiUrl}/Endereco/criar-endereco-participante-id`,
			dadosEndereco,
			httpOptions
		);
	}

	Update(contato: any): Observable<APIResponse> {
		return this.http
			.post<APIResponse>(
				`${environment.apiUrl}/Contato/editar-contato`,
				contato,
				httpOptions
			)
			.pipe(
				tap((resp) =>
					console.log("success", "Contato atualizado com sucesso!")
				),
				catchError(this.handleError)
			);
	}

	getAllCarencia(): Observable<any[]> {
		return this.http
			.get<any[]>(`${environment.apiUrl}/perfilcarencia/buscar-todos`)
			.pipe(
				tap((data) => "Ok"),
				catchError(this.handleError)
			);
	}
}
