//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Usuario } from './usuario.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const UsuarioEntityConfig = {
  name: 'Usuario',
  keys: [{name: 'Id'}],
  fields: {
    Nome: {type: 'Edm.String'},
    Email: {type: 'Edm.String'},
    Senha: {type: 'Edm.String'},
    Documento: {type: 'Edm.String'},
    GrupoUsuario: {type: 'Edm.Int32', nullable: false},
    ParticipanteId: {type: 'Edm.Int32'},
    RoleDefault: {type: 'Edm.String'},
    EmailAzure: {type: 'Edm.String'},
    BeneficiarioId: {type: 'Edm.Int32'},
    ClienteId: {type: 'Edm.Int32'},
    Ativo: {type: 'Edm.Boolean', nullable: false},
    PermitirEdicaoCarteirinha: {type: 'Edm.Boolean', nullable: false},
    TokenResetSenha: {type: 'Edm.String'},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'},
    Participante: {type: 'Uniodonto.Domain.Entities.Participante', navigation: true, referentials: [{property: 'ParticipanteId', referencedProperty: 'Id'}]},
    UserIdentity: {type: 'Uniodonto.Domain.Entities.Account.UserIdentity', navigation: true},
    UsuarioOperadoras: {type: 'Uniodonto.Domain.Entities.UsuarioOperadora', collection: true, navigation: true},
    OperadoraUsuarios: {type: 'Uniodonto.Domain.Entities.OperadoraUsuarios', collection: true, navigation: true},
    UsuarioClinicas: {type: 'Uniodonto.Domain.Entities.UsuarioClinica', collection: true, navigation: true},
    Beneficiario: {type: 'Uniodonto.Domain.Entities.Beneficiario', navigation: true, referentials: [{property: 'BeneficiarioId', referencedProperty: 'Id'}]},
    Cliente: {type: 'Uniodonto.Domain.Entities.Cliente', navigation: true, referentials: [{property: 'ClienteId', referencedProperty: 'Id'}]}
  }
} as StructuredTypeConfig<Usuario>;
//#endregion