//#region ODataApiGen Imports
//#endregion

export enum EnumFormacaoPrecoProduto {
  //#region ODataApiGen Members
  PRE_PAGAMENTO = 1,
  POS_PAGAMENTO = 2,
  MISTO = 3,
  //#endregion
}
