export class UniodontoMasks {
  public CPF: string;
  public RG: any[] = [];
  public CNS: any[] = [];
  public ISS: any[] = [];
  public CNES: any[] = [];
  public CRO: any[] = [];
  public CNPJ: string;
  public ESOCIAL: any[] = [];
  public CEP: any[] = [];
  public HORAS: any[] = [];
  public TELEFONE: string;
  public CELULAR: string;
  public TELEFONE0800: any[] = [];
  public CODIGOPROCEDIMENTO: any[] = [];
  public DATA: any[] = [];
  public CodigoRegularizacao: any[] = [];
  public CODIGOCARTEIRA: any[] = [];

  constructor() {

    this.ISS = [
      /\d/,
      '.',
      /\d/,
      /\d/,
      /\d/,
      '.',
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
    ];

    this.CNES = [
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ];

    this.RG = [
      /\d/,
      /\d/,
      '.',
      /\d/,
      /\d/,
      /\d/,
      '.',
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
    ];

    this.HORAS = [/\d/, /\d/, ':', /\d/, /\d/];

    this.CEP = [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];

    this.CRO = [
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ];

    this.ESOCIAL = [
      /\d/,
      /\d/,
      '.',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      '.',
      /\d/,
      '.',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/
    ];

    this.CNS = [
      /\d/,
      /\d/,
      /\d/,
      ' ',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      ' ',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      ' ',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ];

   

    

    this.TELEFONE0800 = [
      '(',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      ')',
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ];

    this.CODIGOPROCEDIMENTO = [

      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ];

    this.DATA = [
      /\d/,
      /\d/,
      '/',
      /\d/,
      /\d/,
      '/',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ];

    this.CodigoRegularizacao = [
      /\d/,
      /\d/
    ];

    this.CODIGOCARTEIRA = [
      /\d/,
      /\d/,
      /\d/,
      ' ',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      ' ',
      /\d/,
      /\d/,
      /\d/,
    ];

    this.TELEFONE = "(00)0000-0000";
    this.CELULAR  = "(00)00000-0000";
    this.CNPJ     = "00.000.000/0000-00"
    this.CPF      = "000.000.000-00"
  }
}
