//#region ODataApiGen ODataImports
import {
  EnumTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumTipoLoteJob } from './enumtipolotejob.enum';
//#endregion

//#region ODataApiGen EnumTypeConfig
export const EnumTipoLoteJobConfig = {
  name: 'EnumTipoLoteJob',
  members: EnumTipoLoteJob,
  fields: {
    ReajusteContrato: {value: 1, annotations: [{"term":"Default.Description","string":"Reajuste Contrato"}]},
    PreEspelhoFaturamento: {value: 2, annotations: [{"term":"Default.Description","string":"Pr\u00E9-espelho Faturamento"}]},
    PreEspelhoCobranca: {value: 3, annotations: [{"term":"Default.Description","string":"Pr\u00E9-espelho Cobran\u00E7a"}]}
  }
} as EnumTypeConfig<EnumTipoLoteJob>;
//#endregion