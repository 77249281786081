//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumStatusRecursoProcedimento } from '../Model/Enum/enumstatusrecursoprocedimento.enum';
import { Operadora } from './operadora.entity';
import { Procedimentos } from './procedimentos.entity';
import { Glosa } from './glosa.entity';
//#endregion

export interface ProcedimentoRecursosHistorico {
  //#region ODataApiGen Properties
  ProcedimentoId: number;
  Observacao?: string;
  FilaTecnica: boolean;
  GlosaId?: number;
  StatusRecursoProcedimento: EnumStatusRecursoProcedimento;
  OperadoraId?: number;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  Glosa?: Glosa;
  Procedimento?: Procedimentos;
  Operadora?: Operadora;
  //#endregion
}