//#region ODataApiGen Imports
//#endregion

export enum EnumTipoNegociacao {
  //#region ODataApiGen Members
  undefined = 0,
  ValorUS = 1,
  QuantidadeUS = 2,
  Padrao = 3,
  //#endregion
}
