//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumStatusImportacaoBeneficiario } from '../Model/Enum/enumstatusimportacaobeneficiario.enum';
import { ContratoProduto } from './contratoproduto.entity';
import { Contrato } from './contrato.entity';
import { Operadora } from './operadora.entity';
import { Beneficiario } from './beneficiario.entity';
import { Titular } from './titular.entity';
import { Endereco } from './endereco.entity';
import { SubContrato } from './subcontrato.entity';
import { ContratoOperadoraRepasse } from './contratooperadorarepasse.entity';
import { MotivoDesligamento } from './motivodesligamento.entity';
import { PerfilCarencia } from './perfilcarencia.entity';
import { GrupoFamiliar } from './grupofamiliar.entity';
//#endregion

export interface ImportacaoBeneficiario {
  //#region ODataApiGen Properties
  IsTitular: boolean;
  DocumentoTitularBeneficiario?: string;
  BeneficiarioCns?: string;
  BeneficiarioCpf?: string;
  BeneficiarioSexo?: string;
  BeneficiarioDataNascimento?: Date;
  BeneficiarioIdade?: number;
  BeneficiarioNome?: string;
  BeneficiarioNomeMae?: string;
  BeneficiarioNomePai?: string;
  CodigoCarteira?: string;
  CCO?: string;
  TitularCodigoExterno?: string;
  TitularSetor?: string;
  TitularObservacoes?: string;
  TitularUnidade?: string;
  TitularOutrasInformacoes?: string;
  TitularPerfilSemCarencia: boolean;
  TitularEnviarDmed: boolean;
  TitularBloquearLancamentos: boolean;
  TitularDataInclusao?: Date;
  ContatoObservacao?: string;
  ContatoNome?: string;
  ContatoEmail?: string;
  ContatoTelefone?: string;
  ContatoCelular?: string;
  EnderecoCep?: string;
  EnderecoRua?: string;
  EnderecoBairro?: string;
  EnderecoCidade?: string;
  EnderecoUf?: string;
  TipoEndereco?: string;
  IbgeCodigo?: string;
  EnderecoNumero?: string;
  EnderecoComplemento?: string;
  UfIbge?: string;
  UfNome?: string;
  EnderecoCaixaPostal?: string;
  RegistroCompleto: boolean;
  TitularId?: number;
  BeneficiarioId?: number;
  EnderecoId?: number;
  GrupoFamiliarId?: number;
  ContratoId?: number;
  DarBaixa: boolean;
  DataDesligamento?: Date;
  DataFimAtendimento?: Date;
  MotivoDesligamentoId?: number;
  QuantidadeErros: number;
  PerfilCarenciaId?: number;
  Log?: string;
  Status: EnumStatusImportacaoBeneficiario;
  JobId?: string;
  SubContratoId?: number;
  ContratoOperadoraRepasseId?: number;
  ContratoProdutoId?: number;
  OperadoraId?: number;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  Titular?: Titular;
  Beneficiario?: Beneficiario;
  Endereco?: Endereco;
  GrupoFamiliar?: GrupoFamiliar;
  Contrato?: Contrato;
  MotivoDesligamento?: MotivoDesligamento;
  PerfilCarencia?: PerfilCarencia;
  SubContrato?: SubContrato;
  ContratoOperadoraRepasse?: ContratoOperadoraRepasse;
  ContratoProduto?: ContratoProduto;
  Operadora?: Operadora;
  //#endregion
}