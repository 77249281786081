//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Participante } from './participante.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const ParticipanteEntityConfig = {
  name: 'Participante',
  keys: [{name: 'Id'}],
  fields: {
    NomeParticipante: {type: 'Edm.String'},
    CNPJ: {type: 'Edm.String'},
    CPF: {type: 'Edm.String'},
    OperadoraId: {type: 'Edm.Int32'},
    ClinicaId: {type: 'Edm.Int32'},
    Intercambio: {type: 'Edm.Boolean', nullable: false},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'},
    Enderecos: {type: 'Uniodonto.Domain.Entities.Endereco', collection: true, navigation: true},
    Contatos: {type: 'Uniodonto.Domain.Entities.Contato', collection: true, navigation: true},
    Cliente: {type: 'Uniodonto.Domain.Entities.Cliente', navigation: true},
    Usuario: {type: 'Uniodonto.Domain.Entities.Usuario', navigation: true},
    Beneficiario: {type: 'Uniodonto.Domain.Entities.Beneficiario', navigation: true},
    Dentista: {type: 'Uniodonto.Domain.Entities.Dentista', navigation: true},
    Operadora: {type: 'Uniodonto.Domain.Entities.Operadora', navigation: true, referentials: [{property: 'OperadoraId', referencedProperty: 'Id'}]},
    Clinica: {type: 'Uniodonto.Domain.Entities.Clinica', navigation: true, referentials: [{property: 'ClinicaId', referencedProperty: 'Id'}]}
  }
} as StructuredTypeConfig<Participante>;
//#endregion