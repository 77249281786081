//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Contrato } from './contrato.entity';
import { GrupoFamiliar } from './grupofamiliar.entity';
//#endregion

export interface GrupoFamiliarContrato {
  //#region ODataApiGen Properties
  NomeDependente?: string;
  ContratoId?: number;
  GrupoFamiliarId?: number;
  EAgregado: boolean;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  Contrato?: Contrato;
  GrupoFamiliar?: GrupoFamiliar;
  //#endregion
}