import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
	NgbActiveModal,
	NgbModal,
	NgbModalOptions,
	NgbModalRef,
} from "@ng-bootstrap/ng-bootstrap";
import { AppInjector } from "../../app.module";
import { CustomValidators } from "../../helpers/custom-validators";
import { NotificationService } from "../../services";
import { AlterarSenhaService } from "../../services/entities/alterar-senha.service";

@Component({
	selector: "app-alterar-senha",
	templateUrl: "./alterar-senha.component.html",
})
export class AlterarSenhaComponent implements OnInit {
	alterarSenhaForm: FormGroup;
	submitted = false;
	passFiledTextType: boolean[] = [];

	constructor(
		private formBuilder: FormBuilder,
		public activeModal: NgbActiveModal,
		private notification: NotificationService,
		private alterarSenhaService: AlterarSenhaService
	) {}

	ngOnInit() {
		let validator = Validators.compose([
			Validators.required,
			// check whether the entered password has a number
			CustomValidators.patternValidator(/\d/, {
				hasNumber: true,
			}),
			// check whether the entered password has upper case letter
			CustomValidators.patternValidator(/[A-Z]/, {
				hasCapitalCase: true,
			}),
			// check whether the entered password has a lower case letter
			CustomValidators.patternValidator(/[a-z]/, {
				hasSmallCase: true,
			}),
			// check whether the entered password has a special character
			CustomValidators.patternValidator(
				/[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
				{
					hasSpecialCharacters: true,
				}
			),
			Validators.minLength(8),
		]);

		this.alterarSenhaForm = this.formBuilder.group(
			{
				Senha: [null, validator],
				ConfirmarSenha: [null],
			},
			{ validators: CustomValidators.passwordMatchValidator }
		);
	}

	togglePassFiledTextType(id: number) {
		this.passFiledTextType[id] = !this.passFiledTextType[id];
	}

	static open(): NgbModalRef {
		let modal: NgbModalRef;
		let modalService = AppInjector.get(NgbModal);

		const options: NgbModalOptions = {
			size: "md",
			fullscreen: "md",
		};

		modal = modalService.open(AlterarSenhaComponent, options);

		return modal;
	}

	alterarSenha() {
		const password = this.alterarSenhaForm.get("Senha").value;
		const confirmpassword = this.alterarSenhaForm.get("ConfirmarSenha").value;

		if (password === confirmpassword) {
			this.submitted = true;
			this.alterarSenhaService.changePassUsuario(password).subscribe(
				(result) => {
					this.notification.show(result);
					this.submitted = false;
					this.close();
				},
				(err) => {
					this.notification.show(err);
					this.submitted = false;
				}
			);
		} else {
			this.notification.show("As senhas devem ser iguais");
		}
	}

	close() {
		this.activeModal.dismiss();
	}
}
