//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumNumberSeqRef } from '../Model/Enum/enumnumberseqref.enum';
import { Operadora } from './operadora.entity';
//#endregion

export interface NumberSequence {
  //#region ODataApiGen Properties
  Reference: EnumNumberSeqRef;
  ReferenceId?: number;
  NextVal: number;
  Mask?: string;
  OperadoraId?: number;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  Operadora?: Operadora;
  //#endregion
}