//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { ProcessamentoRetornoSIB } from './processamentoretornosib.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const ProcessamentoRetornoSIBEntityConfig = {
  name: 'ProcessamentoRetornoSIB',
  keys: [{name: 'Id'}],
  fields: {
    Processado: {type: 'Edm.Boolean', nullable: false},
    Resultado: {type: 'Edm.String'},
    EnumProcessamentoRetornoSIB: {type: 'Uniodonto.Domain.Model.Enum.EnumProcessamentoRetornoSIB', nullable: false},
    ArquivoId: {type: 'Edm.Int32'},
    OperadoraId: {type: 'Edm.Int32'},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'},
    Arquivo: {type: 'Uniodonto.Domain.Entities.Arquivo', navigation: true, referentials: [{property: 'ArquivoId', referencedProperty: 'Id'}]},
    Operadora: {type: 'Uniodonto.Domain.Entities.Operadora', navigation: true, referentials: [{property: 'OperadoraId', referencedProperty: 'Id'}]}
  }
} as StructuredTypeConfig<ProcessamentoRetornoSIB>;
//#endregion