//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { ValorProcedimentoTipoProduto } from './valorprocedimentotipoproduto.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const ValorProcedimentoTipoProdutoEntityConfig = {
  name: 'ValorProcedimentoTipoProduto',
  keys: [{name: 'Id'}],
  fields: {
    OrcamentoId: {type: 'Edm.Int32'},
    ProcedimentoId: {type: 'Edm.Int32', nullable: false},
    GuiaId: {type: 'Edm.Int32'},
    TipoProdutoId: {type: 'Uniodonto.Domain.Model.Enum.EnumFormacaoPrecoProduto', nullable: false},
    ValorUS: {type: 'Edm.Decimal', nullable: false},
    QuantidadeUS: {type: 'Edm.Decimal'},
    Sucesso: {type: 'Edm.Boolean', nullable: false},
    PreEspelhoDetalheId: {type: 'Edm.Int32'},
    PreEspelhoId: {type: 'Edm.Int32'},
    OperadoraId: {type: 'Edm.Int32'},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'},
    PreEspelhoDetalhe: {type: 'Uniodonto.Domain.Entities.PreEspelhoDetalhe', navigation: true, referentials: [{property: 'PreEspelhoDetalheId', referencedProperty: 'Id'}]},
    PreEspelho: {type: 'Uniodonto.Domain.Entities.PreEspelho', navigation: true, referentials: [{property: 'PreEspelhoId', referencedProperty: 'Id'}]},
    Orcamento: {type: 'Uniodonto.Domain.Entities.Orcamento', navigation: true, referentials: [{property: 'OrcamentoId', referencedProperty: 'Id'}]},
    Procedimento: {type: 'Uniodonto.Domain.Entities.Procedimentos', navigation: true},
    Guia: {type: 'Uniodonto.Domain.Entities.Guia', navigation: true, referentials: [{property: 'GuiaId', referencedProperty: 'Id'}]},
    Operadora: {type: 'Uniodonto.Domain.Entities.Operadora', navigation: true, referentials: [{property: 'OperadoraId', referencedProperty: 'Id'}]}
  }
} as StructuredTypeConfig<ValorProcedimentoTipoProduto>;
//#endregion