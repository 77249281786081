//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Subcliente } from './subcliente.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const SubclienteEntityConfig = {
  name: 'Subcliente',
  keys: [{name: 'Id'}],
  fields: {
    MesesPermanenciaMinima: {type: 'Edm.Int32', nullable: false},
    DataPermanenciaMinima: {type: 'Edm.DateTimeOffset', nullable: false},
    DataInicial: {type: 'Edm.DateTimeOffset', nullable: false},
    FaturamentoConjunto: {type: 'Edm.Boolean', nullable: false},
    TitularId: {type: 'Edm.Int32'},
    ClienteId: {type: 'Edm.Int32'},
    SubContratoId: {type: 'Edm.Int32'},
    ContratoId: {type: 'Edm.Int32'},
    OperadoraId: {type: 'Edm.Int32'},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'},
    Titular: {type: 'Uniodonto.Domain.Entities.Titular', navigation: true, referentials: [{property: 'TitularId', referencedProperty: 'Id'}]},
    Cliente: {type: 'Uniodonto.Domain.Entities.Cliente', navigation: true, referentials: [{property: 'ClienteId', referencedProperty: 'Id'}]},
    SubContrato: {type: 'Uniodonto.Domain.Entities.SubContrato', navigation: true, referentials: [{property: 'SubContratoId', referencedProperty: 'Id'}]},
    Contrato: {type: 'Uniodonto.Domain.Entities.Contrato', navigation: true, referentials: [{property: 'ContratoId', referencedProperty: 'Id'}]},
    Operadora: {type: 'Uniodonto.Domain.Entities.Operadora', navigation: true, referentials: [{property: 'OperadoraId', referencedProperty: 'Id'}]}
  }
} as StructuredTypeConfig<Subcliente>;
//#endregion