import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumMetodoPagamento } from '../Uniodonto/Domain/Model/Enum/enummetodopagamento.enum';
import { PreEspelhoDetalhe } from '../Uniodonto/Domain/Entities/preespelhodetalhe.entity';
import { Operadora } from '../Uniodonto/Domain/Entities/operadora.entity';
import { Subcliente } from '../Uniodonto/Domain/Entities/subcliente.entity';
import { SubContrato } from '../Uniodonto/Domain/Entities/subcontrato.entity';
import { SubContratoProduto } from '../Uniodonto/Domain/Entities/subcontratoproduto.entity';
//#endregion

@Injectable()
export class SubContratoService extends ODataEntitySetService<SubContrato> {
  constructor(client: ODataClient) {
    super(client, 'SubContrato', 'Uniodonto.Domain.Entities.SubContrato');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public operadora(key: EntityKey<SubContrato>): ODataNavigationPropertyResource<Operadora> { 
    return this.entity(key).navigationProperty<Operadora>('Operadora'); 
  }
  public fetchOperadora(key: EntityKey<SubContrato>, options?: ODataQueryArgumentsOptions<Operadora>) {
    return this.fetchNavigationProperty<Operadora>(
      this.operadora(key), 
      'entity', options) as Observable<ODataEntity<Operadora>>;
  }
  public setOperadoraAsOperadora(key: EntityKey<SubContrato>, target: ODataEntityResource<ODataEntity<Operadora>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.operadora(key).reference()
      .set(target, {etag});
  }
  public unsetOperadoraAsOperadora(key: EntityKey<SubContrato>, {target, etag}: {target?: ODataEntityResource<ODataEntity<Operadora>>, etag?: string} = {}): Observable<any> {
    return this.operadora(key).reference()
      .unset({etag});
  }
  public preEspelhoDetalhes(key: EntityKey<SubContrato>): ODataNavigationPropertyResource<PreEspelhoDetalhe> { 
    return this.entity(key).navigationProperty<PreEspelhoDetalhe>('PreEspelhoDetalhes'); 
  }
  public fetchPreEspelhoDetalhes(key: EntityKey<SubContrato>, options?: ODataQueryArgumentsOptions<PreEspelhoDetalhe>) {
    return this.fetchNavigationProperty<PreEspelhoDetalhe>(
      this.preEspelhoDetalhes(key), 
      'entities', options) as Observable<ODataEntities<PreEspelhoDetalhe>>;
  }
  public addPreEspelhoDetalheToPreEspelhoDetalhes(key: EntityKey<SubContrato>, target: ODataEntityResource<ODataEntities<PreEspelhoDetalhe>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.preEspelhoDetalhes(key).reference()
      .add(target);
  }
  public removePreEspelhoDetalheFromPreEspelhoDetalhes(key: EntityKey<SubContrato>, {target, etag}: {target?: ODataEntityResource<ODataEntities<PreEspelhoDetalhe>>, etag?: string} = {}): Observable<any> {
    return this.preEspelhoDetalhes(key).reference()
      .remove(target);
  }
  public subClientes(key: EntityKey<SubContrato>): ODataNavigationPropertyResource<Subcliente> { 
    return this.entity(key).navigationProperty<Subcliente>('SubClientes'); 
  }
  public fetchSubClientes(key: EntityKey<SubContrato>, options?: ODataQueryArgumentsOptions<Subcliente>) {
    return this.fetchNavigationProperty<Subcliente>(
      this.subClientes(key), 
      'entities', options) as Observable<ODataEntities<Subcliente>>;
  }
  public addSubclienteToSubClientes(key: EntityKey<SubContrato>, target: ODataEntityResource<ODataEntities<Subcliente>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.subClientes(key).reference()
      .add(target);
  }
  public removeSubclienteFromSubClientes(key: EntityKey<SubContrato>, {target, etag}: {target?: ODataEntityResource<ODataEntities<Subcliente>>, etag?: string} = {}): Observable<any> {
    return this.subClientes(key).reference()
      .remove(target);
  }
  public subContratoProdutos(key: EntityKey<SubContrato>): ODataNavigationPropertyResource<SubContratoProduto> { 
    return this.entity(key).navigationProperty<SubContratoProduto>('SubContratoProdutos'); 
  }
  public fetchSubContratoProdutos(key: EntityKey<SubContrato>, options?: ODataQueryArgumentsOptions<SubContratoProduto>) {
    return this.fetchNavigationProperty<SubContratoProduto>(
      this.subContratoProdutos(key), 
      'entities', options) as Observable<ODataEntities<SubContratoProduto>>;
  }
  public addSubContratoProdutoToSubContratoProdutos(key: EntityKey<SubContrato>, target: ODataEntityResource<ODataEntities<SubContratoProduto>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.subContratoProdutos(key).reference()
      .add(target);
  }
  public removeSubContratoProdutoFromSubContratoProdutos(key: EntityKey<SubContrato>, {target, etag}: {target?: ODataEntityResource<ODataEntities<SubContratoProduto>>, etag?: string} = {}): Observable<any> {
    return this.subContratoProdutos(key).reference()
      .remove(target);
  }
  //#endregion
}
