//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { ValorProcedimentoTipoProdutoService } from './valorprocedimentotipoproduto.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const ValorProcedimentoTipoProdutoServiceEntitySetConfig = {
  name: 'ValorProcedimentoTipoProduto',
  entityType: 'Uniodonto.Domain.Entities.ValorProcedimentoTipoProduto',
  service: ValorProcedimentoTipoProdutoService
} as EntitySetConfig;
//#endregion