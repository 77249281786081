//#region ODataApiGen Imports
//#endregion

export enum EnumTipoAtendimentoGuia {
  //#region ODataApiGen Members
  TRATAMENTO_ODONTOLOGICO = 1,
  EXAME_RADIOLOGICO = 2,
  ORTODONTIA = 3,
  URGENCIA_EMERGENCIA = 4,
  AUDITORIA = 5,
  //#endregion
}
