//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Especialidade } from './especialidade.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const EspecialidadeEntityConfig = {
  name: 'Especialidade',
  keys: [{name: 'Id'}],
  fields: {
    Nome: {type: 'Edm.String'},
    Codigo: {type: 'Edm.String'},
    CodigoRPSWeb: {type: 'Edm.String'},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'},
    AtoOdontologicos: {type: 'Uniodonto.Domain.Entities.AtoOdontologico', collection: true, navigation: true},
    Negociacoes: {type: 'Uniodonto.Domain.Entities.Negociacao', collection: true, navigation: true},
    ClinicaEspecialidadeEAtendimentos: {type: 'Uniodonto.Domain.Entities.ClinicaEspecialidadeEAtendimento', collection: true, navigation: true}
  }
} as StructuredTypeConfig<Especialidade>;
//#endregion