//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Operadora } from './operadora.entity';
import { Beneficiario } from './beneficiario.entity';
import { Carteira } from './carteira.entity';
import { Titular } from './titular.entity';
import { MotivoDesligamento } from './motivodesligamento.entity';
import { GrupoFamiliar } from './grupofamiliar.entity';
//#endregion

export interface Dependente {
  //#region ODataApiGen Properties
  Codigo?: string;
  BeneficiarioId?: number;
  TitularId?: number;
  Ativo: boolean;
  DependenteCodigoExterno?: string;
  DependenteSetor?: string;
  DependenteObservacoes?: string;
  DependenteEmpresa?: string;
  DependenteUnidade?: string;
  DependenteOutrasInformacoes?: string;
  DependenteEnviarDmed: boolean;
  DependenteBloquearLancamentos: boolean;
  DependenteDataInclusao: Date;
  DependenteDataDesligamento?: Date;
  TaxaInscricaoPaga: boolean;
  DataFinalAtendimento?: Date;
  MotivoDesligamentoId?: number;
  CCO?: string;
  RepasseOperadoraId?: number;
  Intercambio: boolean;
  IntercambioEmergencia: boolean;
  GrupoFamiliarId?: number;
  OperadoraId?: number;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  Beneficiario?: Beneficiario;
  Titular?: Titular;
  MotivoDesligamento?: MotivoDesligamento;
  Carteiras?: Carteira[];
  RepasseOperadora?: Operadora;
  GrupoFamiliar?: GrupoFamiliar;
  Operadora?: Operadora;
  //#endregion
}