//#region ODataApiGen Imports
//#endregion

export enum EnumEstadoCivil {
  //#region ODataApiGen Members
  Solteiro = 1,
  Casado = 2,
  Divorciado = 3,
  Viuvo = 4,
  //#endregion
}
