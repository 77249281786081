//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumStatusGuia } from '../Model/Enum/enumstatusguia.enum';
import { EnumTipoAtendimentoGuia } from '../Model/Enum/enumtipoatendimentoguia.enum';
//#endregion

export interface GuiaLegado {
  //#region ODataApiGen Properties
  NumeroGuia?: string;
  ContratoId?: string;
  NomeCliente?: string;
  NomeSubcliente?: string;
  Senha?: string;
  ValidadeSenha?: Date;
  ClinicaCNPJ?: string;
  ClinicaId?: number;
  DataAprovacao?: Date;
  DataExecucao?: Date;
  CarteiraId?: string;
  StatusGuia?: string;
  StatusGuiaEnum?: EnumStatusGuia;
  TipoAtendimentoGuia?: string;
  TipoAtendimentoGuiaEnum?: EnumTipoAtendimentoGuia;
  CPFProfissionalSolicitante?: string;
  NomeProfissionalSolicitante?: string;
  CROProfissionalSolicitante?: string;
  CPFProfissionalExecutante?: string;
  NomeProfissionalExecutante?: string;
  CROProfissionalExecutante?: string;
  UFastGuiaId?: string;
  CPF?: string;
  OperadoraId?: number;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  //#endregion
}