//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
//#endregion

export interface Estado {
  //#region ODataApiGen Properties
  EstadoIBGECod?: string;
  EstadoNome?: string;
  EstadoSigla?: string;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  //#endregion
}