import { AtoOdontologicoModel, AtoOdontologico_ListaDominio } from '../../models/entities';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';

import { APIResponse, APIResponseMensagem } from '../../models';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class AtoOdontologicoService {
  public log: String;

  constructor(
    private http: HttpClient
  ) { }

  getAll(): Observable<any[]> {
    return this.http.get<any[]>(`${environment.apiUrl}/atoodontologico/buscar-todos`).pipe(
      tap(data => 'Ok'),
      catchError(this.handleError)
    );
  };

  getAllByProduto(produtoId: any): Observable<any[]> {
    return this.http.get<any[]>(`${environment.apiUrl}/atoodontologico/produto/${produtoId}`).pipe(
      tap(data => 'Ok'),
      catchError(this.handleError)
    );
  };

  getByEspecialidade(especialidade_id: number): Observable<any[]> {
    return this.http.get<any[]>(`${environment.apiUrl}/atoodontologico/lista-por-especialidade?especialidade_id=${especialidade_id}`).pipe(
      tap(data => 'Ok'),
      catchError(this.handleError)
    );
  };

  create(atoOdontologico: AtoOdontologico_ListaDominio): Observable<APIResponse> {
    return this.http.post<APIResponse>(`${environment.apiUrl}/atoodontologico/criar-ato`, atoOdontologico, httpOptions)
      .pipe(
        tap(resp => 'Ok'),
        catchError(this.handleError)
      );

  }

  update(atoOdontologico: AtoOdontologico_ListaDominio): Observable<APIResponse> {
    return this.http.put<APIResponse>(`${environment.apiUrl}/atoodontologico/editar-ato`, atoOdontologico, httpOptions)
      .pipe(
        tap(resp => console.log(JSON.stringify(resp))),
        catchError(this.handleError)
      );
  }

  delete(id: any): Observable<any> {
    return this.http.delete<any>(`${environment.apiUrl}/Atoodontologico/deletar-ato?id=${id}`).pipe(
      tap(data => console.log('ato deletado')),
      catchError(this.handleError)
    );
  };

  getCoberturasByProdutoId(produtoId: number): Observable<AtoOdontologicoModel[]> {
    return this.http.get<APIResponse>(`${environment.apiUrl}/Atoodontologico/plano-tratamento/${produtoId}`)
      .pipe(
        catchError(_ => throwError(_)),
        map(res => <AtoOdontologicoModel[]>res.data)
      );
  }

  getCoberturasByOrcamento(orcamentoId: number): Observable<AtoOdontologicoModel[]> {
    return this.http.get<APIResponse>(`${environment.apiUrl}/Atoodontologico/orcamento/${orcamentoId}`)
      .pipe(
        map(res => <AtoOdontologicoModel[]>res.data)
      );
  }
 
  validarConflitosOdontologicos(atosOdontologicosId: number[]) {
    const payload = { atosOdontologicosId };
    return this.http.post<APIResponseMensagem>(
      `${environment.apiUrl}/Atoodontologico/validar-conflitos`,
      payload,
      httpOptions)
      .pipe(
        catchError(_ => throwError(_)),
      )
  }

  validarCarenciaAto(atoOdontologicoId: number, carteiraId: number) {
    const payload = { atoOdontologicoId, carteiraId };
    return this.http.post<APIResponse>(
      `${environment.apiUrl}/Atoodontologico/validar-carencia`,
      payload,
      httpOptions)
      .pipe(
        catchError(_ => throwError(_)),
      )
  }

  validarRestricaoIdade(atoOdontologicoId: number, carteiraId) {
    const payload = { atoOdontologicoId, carteiraId };
    return this.http.post<APIResponse>(
      `${environment.apiUrl}/Atoodontologico/validar-idade`,
      payload,
      httpOptions)
      .pipe(
        catchError(this.handleError),
      )

  }

  getById(idAto: number): Observable<any[]> {
    return this.http
      .get<any[]>(
        `${environment.apiUrl}/Atoodontologico/BuscarPorId?id=${idAto}`
      )
      .pipe(
        tap((data) => console.log("Ato obtido")),
        catchError(this.handleError)
      );
  }



  private handleError(err: HttpErrorResponse) {
    console.error(err);
    return throwError(err);
  }

}
