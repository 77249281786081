//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumTipoPessoa } from '../Model/Enum/enumtipopessoa.enum';
import { EnumRegulamentacaoProduto } from '../Model/Enum/enumregulamentacaoproduto.enum';
import { EnumFormacaoPrecoProduto } from '../Model/Enum/enumformacaoprecoproduto.enum';
import { ContratoProduto } from './contratoproduto.entity';
import { Operadora } from './operadora.entity';
import { ContratoProdutoAtoPreco } from './contratoprodutoatopreco.entity';
import { AreaGeografica } from './areageografica.entity';
import { TipoProduto } from './tipoproduto.entity';
import { TipoContratacaoProduto } from './tipocontratacaoproduto.entity';
import { TipoAdesaoProduto } from './tipoadesaoproduto.entity';
import { CondicaoVinculoProduto } from './condicaovinculoproduto.entity';
import { TipoPagamentoProduto } from './tipopagamentoproduto.entity';
import { FatorModeradorProduto } from './fatormoderadorproduto.entity';
import { ProdutoServicosAdicionais } from './produtoservicosadicionais.entity';
import { ProdutoServicosECobertura } from './produtoservicosecobertura.entity';
//#endregion

export interface Produto {
  //#region ODataApiGen Properties
  ProdutoNome?: string;
  ProdutoDescricao?: string;
  ProdutoPossuiRegANS: boolean;
  ProdutoRegANS?: string;
  DataRegistro: Date;
  ProdutoAntLei: boolean;
  ProdutoFatorModerador: boolean;
  ProdutoServAdd: boolean;
  ProdutoRelacaoOperadora?: string;
  ProdutoTipoContratualizacao?: string;
  ProdutoAtivo: boolean;
  RegulamentacaoProduto: EnumRegulamentacaoProduto;
  ProdutoTipoContratoId?: number;
  TipoPessoa: EnumTipoPessoa;
  ProdutoTipoId?: number;
  ProdutoTipoContratacaoId?: number;
  ProdutoTipoAdesaoId?: number;
  ProdutoCondVinculoID?: number;
  ProdutoAreaGeoId?: number;
  ProdutoTipoPagId?: number;
  ProdutoFormaPreco: EnumFormacaoPrecoProduto;
  ProdutoTipoFatorId?: number;
  PaymentModality?: string;
  BeneficiarioIntercambio: boolean;
  Intercambio: boolean;
  IntercambioEmergencia: boolean;
  OperadoraId?: number;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  ProdutoTipo?: TipoProduto;
  ProdutoTipoContratacao?: TipoContratacaoProduto;
  ProdutoTipoAdesao?: TipoAdesaoProduto;
  ProdutoCondVinculo?: CondicaoVinculoProduto;
  ProdutoAreaGeo?: AreaGeografica;
  ProdutoTipoPag?: TipoPagamentoProduto;
  ProdutoTipoFator?: FatorModeradorProduto;
  ProdutoServicosAdicionais?: ProdutoServicosAdicionais[];
  ProdutoServicosECoberturas?: ProdutoServicosECobertura[];
  Produtos_Do_Contrato?: ContratoProduto[];
  Produtos?: Produto[];
  ContratoProdutoAtosPrecos?: ContratoProdutoAtoPreco[];
  Operadora?: Operadora;
  //#endregion
}