import { AtoOdontologico_ListaDominio, PerfilCarenciaModel } from '../../models/entities';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { APIResponse } from '../../models';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};

@Injectable({
    providedIn: 'root'
})
export class PerfilCarenciaService {
    public log: String

    constructor(
        private http: HttpClient
    ) { }

    getAll(): Observable<any[]> {
        return this.http.get<any[]>(`${environment.apiUrl}/perfilcarencia/buscar-todos`).pipe(
            tap(data => "Ok"),
            catchError(this.handleError)
        );
    };

    create(
        perfil: PerfilCarenciaModel
      ): Observable<APIResponse> {
        return this.http
          .post<APIResponse>(
            `${environment.apiUrl}/perfilcarencia/criar`,
            perfil,
            httpOptions
          )
          .pipe(
            tap((resp) => console.log(JSON.stringify(resp))),
            catchError(this.handleError)
          );
      }

      update(
        perfil: PerfilCarenciaModel
      ): Observable<APIResponse> {
        return this.http
          .put<APIResponse>(
            `${environment.apiUrl}/PerfilCarencia/alterar`,
            perfil,
            httpOptions
          )
          .pipe(
            tap((resp) => console.log(JSON.stringify(resp))),
            catchError(this.handleError)
          );
      }

      delete(id : number): Observable<APIResponse> {
        return this.http.delete<APIResponse>(`${environment.apiUrl}/PerfilCarencia/deletar-ato?id=${id}`).pipe(
            tap(data => console.log('perfil excluido')),
            catchError(this.handleError)
        );
    };

    private handleError(err: HttpErrorResponse) {

        let errorMessage = '';
        if (err.error instanceof ErrorEvent) {

            errorMessage = `An error occurred: ${err.error.message}`;
        } else {

            errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
        }
        console.error(errorMessage);
        return throwError(errorMessage);
    }

}