import { Component } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'date-editor-cell',
  template: `
    <ngb-datepicker (dateSelect)="onDateSelect($event)" style></ngb-datepicker>
  `
})
export class AgGridDatePickerComponent implements ICellEditorAngularComp {
  private params: any;

  public selectedDate: any;

  agInit(params: any): void {
    this.params = params;
  }

  getValue(): any {
      //console.info(this.selectedDate);
    return this.selectedDate;
  }

  isPopup(): boolean {
    return true;
  }

  onDateSelect(date: NgbDate) {
    
    var dia, mes;

    if(date.day.toString().length === 1)
        dia = "0" + date.day.toString();
    else
        dia = date.day.toString();

    if(date.month.toString().length === 1)
        mes = "0" + date.month.toString();
    else
        mes = date.month.toString();

    this.selectedDate = dia + "/" + mes + "/" + date.year;// { date: { year: date.year, month: date.month + 1, day: '02' } };
    this.params.api.stopEditing();
  }
}