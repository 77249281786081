//#region ODataApiGen Imports
//#endregion

export enum EnumTipoDeClinica {
  //#region ODataApiGen Members
  CREDENCIADA = 1,
  RADIOLOGIA = 2,
  OPERADORA = 3,
  PLANTAO = 4,
  //#endregion
}
