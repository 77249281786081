//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { IntegracaoEntidadePortalERPLog } from './integracaoentidadeportalerplog.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const IntegracaoEntidadePortalERPLogEntityConfig = {
  name: 'IntegracaoEntidadePortalERPLog',
  keys: [{name: 'Id'}],
  fields: {
    Mensagem: {type: 'Edm.String', nullable: false, maxLength: 255},
    Entidade: {type: 'Uniodonto.Domain.Model.Enum.EnumEntidadesIntegradasERP', nullable: false},
    EntidadeId: {type: 'Edm.Int32', nullable: false},
    Ativo: {type: 'Edm.Boolean', nullable: false},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'}
  }
} as StructuredTypeConfig<IntegracaoEntidadePortalERPLog>;
//#endregion