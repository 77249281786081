//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumStatusIntegracao } from '../Model/Enum/enumstatusintegracao.enum';
import { Operadora } from './operadora.entity';
import { CEP } from './cep.entity';
import { Clinica } from './clinica.entity';
//#endregion

export interface ClinicaEnderecos {
  //#region ODataApiGen Properties
  ClinicaId?: number;
  TipoEndereco?: string;
  CEPCode?: string;
  CEPId?: number;
  Rua?: string;
  Numero?: string;
  Complemento?: string;
  CaixaPostal?: string;
  Bairro?: string;
  Cidade?: string;
  UF?: string;
  IntegradoERP: boolean;
  StatusIntegracaoERP: EnumStatusIntegracao;
  OperadoraId?: number;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  Clinica?: Clinica;
  CEP?: CEP;
  Operadora?: Operadora;
  //#endregion
}