//#region ODataApiGen ODataImports
import {
  EnumTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumGrupoGlosa } from './enumgrupoglosa.enum';
//#endregion

//#region ODataApiGen EnumTypeConfig
export const EnumGrupoGlosaConfig = {
  name: 'EnumGrupoGlosa',
  members: EnumGrupoGlosa,
  fields: {
    Elegibilidade: {value: 1},
    Protocolo: {value: 2},
    Guia: {value: 3},
    Autorizacao_Solicitacao: {value: 4},
    Diagnostico: {value: 5},
    Regre_Valorizacao: {value: 6},
    Procedimento: {value: 7},
    Procedimentos_emSerie: {value: 8},
    Exames: {value: 9},
    Revisao_deGlosa: {value: 10},
    Mensagens_deSistema: {value: 11}
  }
} as EnumTypeConfig<EnumGrupoGlosa>;
//#endregion