//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { ClinicaContato } from './clinicacontato.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const ClinicaContatoEntityConfig = {
  name: 'ClinicaContato',
  keys: [{name: 'Id'}],
  fields: {
    ClinicaId: {type: 'Edm.Int32'},
    TipoContato: {type: 'Edm.String'},
    Nome: {type: 'Edm.String'},
    Email: {type: 'Edm.String'},
    Telefone: {type: 'Edm.String'},
    Celular: {type: 'Edm.String'},
    Cargo: {type: 'Edm.String'},
    Observacao: {type: 'Edm.String'},
    ContatoAutorizaEmail: {type: 'Edm.Boolean', nullable: false},
    ContatoAutorizaSms: {type: 'Edm.Boolean', nullable: false},
    DivulgaCredemciada: {type: 'Edm.Boolean', nullable: false},
    IntegradoERP: {type: 'Edm.Boolean', nullable: false},
    StatusIntegracaoERP: {type: 'Uniodonto.Domain.Model.Enum.EnumStatusIntegracao', nullable: false},
    OperadoraId: {type: 'Edm.Int32'},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'},
    Clinica: {type: 'Uniodonto.Domain.Entities.Clinica', navigation: true, referentials: [{property: 'ClinicaId', referencedProperty: 'Id'}]},
    Operadora: {type: 'Uniodonto.Domain.Entities.Operadora', navigation: true, referentials: [{property: 'OperadoraId', referencedProperty: 'Id'}]}
  }
} as StructuredTypeConfig<ClinicaContato>;
//#endregion