import { OrderByPipe } from "./services/entities/orderBy-pipe";
import { ExcelService } from "./services/entities/excel.service";

// Core Module

import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { NgxMaskModule, IConfig } from "ngx-mask";
import { VirtualScrollerModule } from "ngx-virtual-scroller";
import { NgxPaginationModule } from "ngx-pagination";
import { AutocompleteLibModule } from "angular-ng-autocomplete";
import { LoadingBarRouterModule } from "@ngx-loading-bar/router";
import { NgSelectModule } from "@ng-select/ng-select";
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

import { CoreModule } from "./core/core.module";
import { Title } from "@angular/platform-browser";
import { ErrorInterceptor, JwtInterceptor } from "./helpers";
import {
  FooterComponent,
  HeaderComponent,
  SidebarComponent,
  SidebarRightComponent,
  TopMenuComponent,
} from "./layout/index";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";

import { AgGridDatePickerComponent } from "./helpers/datapicker.component";
import { AgGridModule } from "ag-grid-angular";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CalendarModule } from "angular-calendar";
// import { ChartsModule } from "ng4-charts/ng4-charts";
import { HighlightJsModule } from "ngx-highlight-js";
import {
  CUSTOM_ELEMENTS_SCHEMA,
  Injector,
  NgModule,
  NO_ERRORS_SCHEMA,
} from "@angular/core";
//import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
//import { NgSlimScrollModule } from "ngx-slimscroll";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
// import { TextMaskModule } from "angular2-text-mask";

import { NotifierModule } from "angular-notifier";
//import { NgxCurrencyModule } from "ngx-currency";
import { CommonModule, registerLocaleData } from "@angular/common";
import br from "@angular/common/locales/br";
import { DisableControlDirective } from "./directives/DisableControlDirective";
import { BtnCellRenderDetailsComponent } from "./components/btn-cell-render-details/btn-cell-render-details.component";
import { NumerosOnlyDirective } from "./directives/numeros-only.directive";
// import { NgxAutocompleteModule } from '@bbortt/ngx-autocomplete';

import { PreventDoubleClickDirective } from "./directives/PreventDoubleClickDirective";
import { NumericCellEditor } from "./directives/numeric-cell-editor.component";
import { ODataModule } from "angular-odata";
import { uniodontoConfig, uniodontoModule } from "./uniodonto";


import { CurrencyMaskModule } from "ng2-currency-mask";
import { CallbackPipe } from "./helpers/callback.pipe";
import { MatMenuModule } from "@angular/material/menu";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { MaterialModule } from "./material.module";

registerLocaleData(br);

export const customCurrencyMaskConfig = {
  decimal: ",",
  precision: 2,
  prefix: "R$ ",
  thousands: ".",
};

export let AppInjector: Injector;
//export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  entryComponents: [AgGridDatePickerComponent],
  declarations: [
    OrderByPipe,
    NumericCellEditor,
    AgGridDatePickerComponent,
    AppComponent,
    HeaderComponent,
    SidebarComponent,
    SidebarRightComponent,
    TopMenuComponent,
    FooterComponent,
    DisableControlDirective,
    PreventDoubleClickDirective,
    BtnCellRenderDetailsComponent,
    NumerosOnlyDirective,
  ],

  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    CommonModule,
    NgxMaskModule.forRoot(),
    FormsModule,
    VirtualScrollerModule,
    NgxPaginationModule,
    ReactiveFormsModule,
    HighlightJsModule,
    HttpClientModule,
    AgGridModule.withComponents([
      BtnCellRenderDetailsComponent,
    ]),

    NotifierModule.withConfig({
      position: {
        horizontal: {
          position: 'middle',
          distance: 12,
        },
        vertical: {
          position: 'top',
          distance: 12,
          gap: 10,
        }
      }
    }),
    CoreModule,
    AutocompleteLibModule,
    ODataModule.forRoot(uniodontoConfig),
    uniodontoModule,
    NgbModule,
    LoadingBarRouterModule,
    NgSelectModule,
    CurrencyMaskModule,
    MatMenuModule,
    SweetAlert2Module.forRoot({
      provideSwal: () => import('sweetalert2/dist/sweetalert2.js')
    }),
    NgxDatatableModule,
    MaterialModule
  ],
  providers: [
    Title,
    ExcelService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  exports: [
    MaterialModule
  ],
})
export class AppModule {
  constructor(
    private router: Router,
    private titleService: Title,
    private route: ActivatedRoute,
    private injector: Injector
  ) {
    router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        const title =
          "Uniodonto | " + this.route.snapshot.firstChild.data["title"];
        this.titleService.setTitle(title);
      }
    });
    AppInjector = this.injector;
  }
}
