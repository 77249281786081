//#region ODataApiGen ODataImports
import {
  EnumTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumStatusAuditoria } from './enumstatusauditoria.enum';
//#endregion

//#region ODataApiGen EnumTypeConfig
export const EnumStatusAuditoriaConfig = {
  name: 'EnumStatusAuditoria',
  members: EnumStatusAuditoria,
  fields: {
    AGUARDANDO_LOTE_SER_ENVIADO: {value: 1, annotations: [{"term":"Default.Description","string":"Aguardando lote ser enviado"}]},
    EM_FILA_AUDITORIA_ADMINISTRATIVA: {value: 2, annotations: [{"term":"Default.Description","string":"Em fila auditoria administrativa"}]},
    EM_FILA_AUDITORIA_TECNICA: {value: 3, annotations: [{"term":"Default.Description","string":"Em fila auditoria t\u00E9cnica"}]},
    AGUARDANDO_AUDITORIAS_ADMINISTRATIVAS: {value: 4, annotations: [{"term":"Default.Description","string":"Aguardando auditoria administrativa"}]},
    AGUARDANDO_AUDITORIAS_TECNICAS: {value: 5, annotations: [{"term":"Default.Description","string":"Aguardando auditoria t\u00E9cnica"}]},
    APROVADO_ADMINISTRATIVAMENTE: {value: 6, annotations: [{"term":"Default.Description","string":"Aprovado administrativamente"}]},
    APROVADO_TECNICAMENTE: {value: 7, annotations: [{"term":"Default.Description","string":"Aprovado tecnicamente"}]},
    NAO_NECESSITA_AUDITORIA_ADMINISTRATIVA: {value: 8, annotations: [{"term":"Default.Description","string":"N\u00E3o necessita auditoria administrativa"}]},
    NAO_NECESSITA_AUDITORIA_TECNICA: {value: 9, annotations: [{"term":"Default.Description","string":"N\u00E3o necessita auditoria t\u00E9cnica"}]},
    EM_RECURSO_DENTISTA: {value: 10, annotations: [{"term":"Default.Description","string":"Em recurso com dentista"}]},
    EM_RECURSO_AUDITOR: {value: 11, annotations: [{"term":"Default.Description","string":"Em recurso com auditor"}]},
    GLOSADO: {value: 12, annotations: [{"term":"Default.Description","string":"Glosado"}]}
  }
} as EnumTypeConfig<EnumStatusAuditoria>;
//#endregion