//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AreaAtuacao } from './areaatuacao.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const AreaAtuacaoEntityConfig = {
  name: 'AreaAtuacao',
  keys: [{name: 'Id'}],
  fields: {
    Estado: {type: 'Edm.String'},
    EstadoNome: {type: 'Edm.String'},
    MunicipioNome: {type: 'Edm.String'},
    OperadoraId: {type: 'Edm.Int32'},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'},
    Operadora: {type: 'Uniodonto.Domain.Entities.Operadora', navigation: true, referentials: [{property: 'OperadoraId', referencedProperty: 'Id'}]}
  }
} as StructuredTypeConfig<AreaAtuacao>;
//#endregion