//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Operadora } from './operadora.entity';
//#endregion

export interface Reembolso {
  //#region ODataApiGen Properties
  Nome?: string;
  OperadoraId?: number;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  Operadora?: Operadora;
  //#endregion
}