//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
//#endregion

export interface HistoricoNaoIncluidosProcessamentoSIB {
  //#region ODataApiGen Properties
  Hash?: string;
  NumeroProtocolo?: string;
  NomeArquivo?: string;
  CCO?: string;
  CodigoBeneficiario?: string;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  //#endregion
}