//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { PeriodoV2Service } from './periodov2.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const PeriodoV2ServiceEntitySetConfig = {
  name: 'PeriodoV2',
  entityType: 'Uniodonto.Domain.Entities.Periodo',
  service: PeriodoV2Service
} as EntitySetConfig;
//#endregion