//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { BairroV2Service } from './bairrov2.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const BairroV2ServiceEntitySetConfig = {
  name: 'BairroV2',
  entityType: 'Uniodonto.Domain.Entities.Bairro',
  service: BairroV2Service
} as EntitySetConfig;
//#endregion