//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { GuiaLegado } from './guialegado.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const GuiaLegadoEntityConfig = {
  name: 'GuiaLegado',
  keys: [{name: 'Id'}],
  fields: {
    NumeroGuia: {type: 'Edm.String'},
    ContratoId: {type: 'Edm.String'},
    NomeCliente: {type: 'Edm.String'},
    NomeSubcliente: {type: 'Edm.String'},
    Senha: {type: 'Edm.String'},
    ValidadeSenha: {type: 'Edm.DateTimeOffset'},
    ClinicaCNPJ: {type: 'Edm.String'},
    ClinicaId: {type: 'Edm.Int32'},
    DataAprovacao: {type: 'Edm.DateTimeOffset'},
    DataExecucao: {type: 'Edm.DateTimeOffset'},
    CarteiraId: {type: 'Edm.String'},
    StatusGuia: {type: 'Edm.String'},
    StatusGuiaEnum: {type: 'Uniodonto.Domain.Model.Enum.EnumStatusGuia'},
    TipoAtendimentoGuia: {type: 'Edm.String'},
    TipoAtendimentoGuiaEnum: {type: 'Uniodonto.Domain.Model.Enum.EnumTipoAtendimentoGuia'},
    CPFProfissionalSolicitante: {type: 'Edm.String'},
    NomeProfissionalSolicitante: {type: 'Edm.String'},
    CROProfissionalSolicitante: {type: 'Edm.String'},
    CPFProfissionalExecutante: {type: 'Edm.String'},
    NomeProfissionalExecutante: {type: 'Edm.String'},
    CROProfissionalExecutante: {type: 'Edm.String'},
    UFastGuiaId: {type: 'Edm.String'},
    CPF: {type: 'Edm.String'},
    OperadoraId: {type: 'Edm.Int32'},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'}
  }
} as StructuredTypeConfig<GuiaLegado>;
//#endregion