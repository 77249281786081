import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Operadora } from '../Uniodonto/Domain/Entities/operadora.entity';
import { TRMAtoOperadora } from '../Uniodonto/Domain/Entities/trmatooperadora.entity';
import { GrupoAtoOdontologico } from '../Uniodonto/Domain/Entities/grupoatoodontologico.entity';
import { AtoOdontologico } from '../Uniodonto/Domain/Entities/atoodontologico.entity';
import { ContratoProdutoAtoPreco } from '../Uniodonto/Domain/Entities/contratoprodutoatopreco.entity';
//#endregion

@Injectable()
export class AtoOdontologicoService extends ODataEntitySetService<AtoOdontologico> {
  constructor(client: ODataClient) {
    super(client, 'AtoOdontologico', 'Uniodonto.Domain.Entities.AtoOdontologico');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public contratoProdutoAtosPrecos(key: EntityKey<AtoOdontologico>): ODataNavigationPropertyResource<ContratoProdutoAtoPreco> { 
    return this.entity(key).navigationProperty<ContratoProdutoAtoPreco>('ContratoProdutoAtosPrecos'); 
  }
  public fetchContratoProdutoAtosPrecos(key: EntityKey<AtoOdontologico>, options?: ODataQueryArgumentsOptions<ContratoProdutoAtoPreco>) {
    return this.fetchNavigationProperty<ContratoProdutoAtoPreco>(
      this.contratoProdutoAtosPrecos(key), 
      'entities', options) as Observable<ODataEntities<ContratoProdutoAtoPreco>>;
  }
  public addContratoProdutoAtoPrecoToContratoProdutoAtosPrecos(key: EntityKey<AtoOdontologico>, target: ODataEntityResource<ODataEntities<ContratoProdutoAtoPreco>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.contratoProdutoAtosPrecos(key).reference()
      .add(target);
  }
  public removeContratoProdutoAtoPrecoFromContratoProdutoAtosPrecos(key: EntityKey<AtoOdontologico>, {target, etag}: {target?: ODataEntityResource<ODataEntities<ContratoProdutoAtoPreco>>, etag?: string} = {}): Observable<any> {
    return this.contratoProdutoAtosPrecos(key).reference()
      .remove(target);
  }
  public grupoAtoOdontologicos(key: EntityKey<AtoOdontologico>): ODataNavigationPropertyResource<GrupoAtoOdontologico> { 
    return this.entity(key).navigationProperty<GrupoAtoOdontologico>('GrupoAtoOdontologicos'); 
  }
  public fetchGrupoAtoOdontologicos(key: EntityKey<AtoOdontologico>, options?: ODataQueryArgumentsOptions<GrupoAtoOdontologico>) {
    return this.fetchNavigationProperty<GrupoAtoOdontologico>(
      this.grupoAtoOdontologicos(key), 
      'entities', options) as Observable<ODataEntities<GrupoAtoOdontologico>>;
  }
  public addGrupoAtoOdontologicoToGrupoAtoOdontologicos(key: EntityKey<AtoOdontologico>, target: ODataEntityResource<ODataEntities<GrupoAtoOdontologico>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.grupoAtoOdontologicos(key).reference()
      .add(target);
  }
  public removeGrupoAtoOdontologicoFromGrupoAtoOdontologicos(key: EntityKey<AtoOdontologico>, {target, etag}: {target?: ODataEntityResource<ODataEntities<GrupoAtoOdontologico>>, etag?: string} = {}): Observable<any> {
    return this.grupoAtoOdontologicos(key).reference()
      .remove(target);
  }
  public operadora(key: EntityKey<AtoOdontologico>): ODataNavigationPropertyResource<Operadora> { 
    return this.entity(key).navigationProperty<Operadora>('Operadora'); 
  }
  public fetchOperadora(key: EntityKey<AtoOdontologico>, options?: ODataQueryArgumentsOptions<Operadora>) {
    return this.fetchNavigationProperty<Operadora>(
      this.operadora(key), 
      'entity', options) as Observable<ODataEntity<Operadora>>;
  }
  public setOperadoraAsOperadora(key: EntityKey<AtoOdontologico>, target: ODataEntityResource<ODataEntity<Operadora>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.operadora(key).reference()
      .set(target, {etag});
  }
  public unsetOperadoraAsOperadora(key: EntityKey<AtoOdontologico>, {target, etag}: {target?: ODataEntityResource<ODataEntity<Operadora>>, etag?: string} = {}): Observable<any> {
    return this.operadora(key).reference()
      .unset({etag});
  }
  public tRMAtoOperadoras(key: EntityKey<AtoOdontologico>): ODataNavigationPropertyResource<TRMAtoOperadora> { 
    return this.entity(key).navigationProperty<TRMAtoOperadora>('TRMAtoOperadoras'); 
  }
  public fetchTRMAtoOperadoras(key: EntityKey<AtoOdontologico>, options?: ODataQueryArgumentsOptions<TRMAtoOperadora>) {
    return this.fetchNavigationProperty<TRMAtoOperadora>(
      this.tRMAtoOperadoras(key), 
      'entities', options) as Observable<ODataEntities<TRMAtoOperadora>>;
  }
  public addTRMAtoOperadoraToTRMAtoOperadoras(key: EntityKey<AtoOdontologico>, target: ODataEntityResource<ODataEntities<TRMAtoOperadora>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.tRMAtoOperadoras(key).reference()
      .add(target);
  }
  public removeTRMAtoOperadoraFromTRMAtoOperadoras(key: EntityKey<AtoOdontologico>, {target, etag}: {target?: ODataEntityResource<ODataEntities<TRMAtoOperadora>>, etag?: string} = {}): Observable<any> {
    return this.tRMAtoOperadoras(key).reference()
      .remove(target);
  }
  //#endregion
}
