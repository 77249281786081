//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { JobV2Service } from './jobv2.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const JobV2ServiceEntitySetConfig = {
  name: 'JobV2',
  entityType: 'Uniodonto.Domain.Entities.Job',
  service: JobV2Service
} as EntitySetConfig;
//#endregion