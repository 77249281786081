//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Operadora } from './operadora.entity';
//#endregion

export interface Responsavel {
  //#region ODataApiGen Properties
  CPF?: string;
  RG?: string;
  Tipo?: string;
  Coordenador?: string;
  Nome?: string;
  Telefone?: string;
  Ramal?: string;
  DDD?: string;
  Fax?: string;
  CRO?: string;
  UFCRO?: string;
  Email?: string;
  AutorizaEmail: boolean;
  OperadoraId?: number;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  Operadora?: Operadora;
  //#endregion
}