//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { ContratoProduto } from './contratoproduto.entity';
import { Operadora } from './operadora.entity';
import { ReajusteContratual } from './reajustecontratual.entity';
import { SubContrato } from './subcontrato.entity';
import { Produto } from './produto.entity';
import { PerfilCarencia } from './perfilcarencia.entity';
//#endregion

export interface SubContratoProduto {
  //#region ODataApiGen Properties
  SubContratoId?: number;
  ProdutoId?: number;
  ContratoProdutoId?: number;
  PerfilCarenciaId?: number;
  ValorMensalidade: number;
  ValorMensalidadeDependente: number;
  ValorMensalidadeAgregado: number;
  ValorTaxaInscricao: number;
  ValorTaxaInscricaoDependente: number;
  ValorTaxaInscricaoAgregado: number;
  ValorSegundaViaCarteirinha: number;
  ValorSegundaViaCarteirinhaDependente: number;
  ValorSegundaViaCarteirinhaAgregado: number;
  UltimaAtualizacaoReajuste?: Date;
  OperadoraId?: number;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  ContratoProduto?: ContratoProduto;
  PerfilCarencia?: PerfilCarencia;
  SubContrato?: SubContrato;
  Produto?: Produto;
  ReajustesContratuais?: ReajusteContratual[];
  Operadora?: Operadora;
  //#endregion
}