//#region ODataApiGen ODataImports
import {
  ApiConfig,
  EDM_PARSERS
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EntitiesSchema } from './Uniodonto/Domain/Entities/entities.schema';
import { AccountSchema } from './Uniodonto/Domain/Entities/Account/account.schema';
import { EnumSchema } from './Uniodonto/Domain/Model/Enum/enum.schema';
import { EnumSchema as CrazyMerkle } from './Uniodonto/Domain/Model/Results/UFast/Enum/enum.schema';
import { DefaultSchema } from './Default/default.schema';
import { environment } from '../../environments/environment';
//#endregion

//#region ODataApiGen ApiConfig
export const uniodontoConfig = {
  serviceRootUrl: `${environment.odataUrl}`,
  name: 'uniodonto',
  version: '4.0',
  creation: new Date('2023-05-11T19:53:18.0927969-03:00'),
  schemas: [
    EntitiesSchema,
    AccountSchema,
    EnumSchema,
    CrazyMerkle,
    DefaultSchema
  ],
  parsers: EDM_PARSERS,
  options: {
    stringAsEnum: true
  }
} as ApiConfig;
//#endregion