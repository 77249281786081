//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Operadora } from './operadora.entity';
import { TRMAtoOperadora } from './trmatooperadora.entity';
import { GrupoAtoOdontologico } from './grupoatoodontologico.entity';
import { ContratoProdutoAtoPreco } from './contratoprodutoatopreco.entity';
import { Especialidade } from './especialidade.entity';
import { AtoDominio } from './atodominio.entity';
import { Negociacao } from './negociacao.entity';
import { AtoOdontologicoFilhos } from './atoodontologicofilhos.entity';
import { AtoRestricaoIdade } from './atorestricaoidade.entity';
//#endregion

export interface AtoOdontologico {
  //#region ODataApiGen Properties
  CodigoProcedimento?: string;
  NomeProcedimento?: string;
  QuantidadePadrao: number;
  RecorrenciaMinima: number;
  CodigoTabela?: string;
  EspecificacaoPadrao?: string;
  FaixaIdadeDe?: string;
  FaixaIdadeAte?: string;
  RN59: boolean;
  ROL: boolean;
  DescricaoProduto?: string;
  QuantidadeUs: number;
  EspecialidadeId?: number;
  TipoId: number;
  ObrigatoriedadeImagemInicial: boolean;
  ObrigatoriedadeImagemFinal: boolean;
  Intercambio: boolean;
  IntercambioEmergencia: boolean;
  OperadoraId?: number;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  Especialidade?: Especialidade;
  AtoDominios?: AtoDominio[];
  Negociacoes?: Negociacao[];
  AtoOdontologicoFilho?: AtoOdontologicoFilhos[];
  AtoOdontologicoPai?: AtoOdontologicoFilhos[];
  GrupoAtoOdontologicos?: GrupoAtoOdontologico[];
  TRMAtoOperadoras?: TRMAtoOperadora[];
  AtoRestricaoIdades?: AtoRestricaoIdade[];
  ContratoProdutoAtosPrecos?: ContratoProdutoAtoPreco[];
  Operadora?: Operadora;
  //#endregion
}