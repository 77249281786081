//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Operadora } from './operadora.entity';
import { PerfilCarencia } from './perfilcarencia.entity';
//#endregion

export interface OperadoraParametro {
  //#region ODataApiGen Properties
  QtdAnosExpiracaoCarteira?: number;
  PerfilCarenciaId?: number;
  AceitaInadimplentes?: boolean;
  PermiteAtoNaoCoberto?: boolean;
  PermiteAtosConflitantes?: boolean;
  AprovacaoAutomaticaGuias: boolean;
  ValidadeGuia: Date;
  Meses: number;
  PermiteAuditoriaSimultanea: boolean;
  AuditoriaAdmObrigatoria: boolean;
  AuditoriaTecnicaObrigatoria: boolean;
  PermiteSolicitarAutorizacao: boolean;
  BloquearCriacaoGuiasSemAnexo: boolean;
  BloquearExecucaoProcedimentoSemAnexo: boolean;
  OperadoraId?: number;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  PerfilCarencia?: PerfilCarencia;
  Operadora?: Operadora;
  //#endregion
}