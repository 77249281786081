//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { TitularV2Service } from './titularv2.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const TitularV2ServiceEntitySetConfig = {
  name: 'TitularV2',
  entityType: 'Uniodonto.Domain.Entities.Titular',
  service: TitularV2Service
} as EntitySetConfig;
//#endregion