import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumStatusCarteira } from '../Uniodonto/Domain/Model/Enum/enumstatuscarteira.enum';
import { Operadora } from '../Uniodonto/Domain/Entities/operadora.entity';
import { Carteira } from '../Uniodonto/Domain/Entities/carteira.entity';
import { Dependente } from '../Uniodonto/Domain/Entities/dependente.entity';
import { SubContrato } from '../Uniodonto/Domain/Entities/subcontrato.entity';
//#endregion

@Injectable()
export class CarteiraV2Service extends ODataEntitySetService<Carteira> {
  constructor(client: ODataClient) {
    super(client, 'CarteiraV2', 'Uniodonto.Domain.Entities.Carteira');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public dependente(key: EntityKey<Carteira>): ODataNavigationPropertyResource<Dependente> { 
    return this.entity(key).navigationProperty<Dependente>('Dependente'); 
  }
  public fetchDependente(key: EntityKey<Carteira>, options?: ODataQueryArgumentsOptions<Dependente>) {
    return this.fetchNavigationProperty<Dependente>(
      this.dependente(key), 
      'entity', options) as Observable<ODataEntity<Dependente>>;
  }
  public setDependenteAsDependente(key: EntityKey<Carteira>, target: ODataEntityResource<ODataEntity<Dependente>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.dependente(key).reference()
      .set(target, {etag});
  }
  public unsetDependenteAsDependente(key: EntityKey<Carteira>, {target, etag}: {target?: ODataEntityResource<ODataEntity<Dependente>>, etag?: string} = {}): Observable<any> {
    return this.dependente(key).reference()
      .unset({etag});
  }
  public operadora(key: EntityKey<Carteira>): ODataNavigationPropertyResource<Operadora> { 
    return this.entity(key).navigationProperty<Operadora>('Operadora'); 
  }
  public fetchOperadora(key: EntityKey<Carteira>, options?: ODataQueryArgumentsOptions<Operadora>) {
    return this.fetchNavigationProperty<Operadora>(
      this.operadora(key), 
      'entity', options) as Observable<ODataEntity<Operadora>>;
  }
  public setOperadoraAsOperadora(key: EntityKey<Carteira>, target: ODataEntityResource<ODataEntity<Operadora>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.operadora(key).reference()
      .set(target, {etag});
  }
  public unsetOperadoraAsOperadora(key: EntityKey<Carteira>, {target, etag}: {target?: ODataEntityResource<ODataEntity<Operadora>>, etag?: string} = {}): Observable<any> {
    return this.operadora(key).reference()
      .unset({etag});
  }
  public subContrato(key: EntityKey<Carteira>): ODataNavigationPropertyResource<SubContrato> { 
    return this.entity(key).navigationProperty<SubContrato>('SubContrato'); 
  }
  public fetchSubContrato(key: EntityKey<Carteira>, options?: ODataQueryArgumentsOptions<SubContrato>) {
    return this.fetchNavigationProperty<SubContrato>(
      this.subContrato(key), 
      'entity', options) as Observable<ODataEntity<SubContrato>>;
  }
  public setSubContratoAsSubContrato(key: EntityKey<Carteira>, target: ODataEntityResource<ODataEntity<SubContrato>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.subContrato(key).reference()
      .set(target, {etag});
  }
  public unsetSubContratoAsSubContrato(key: EntityKey<Carteira>, {target, etag}: {target?: ODataEntityResource<ODataEntity<SubContrato>>, etag?: string} = {}): Observable<any> {
    return this.subContrato(key).reference()
      .unset({etag});
  }
  //#endregion
}
