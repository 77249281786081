//#region ODataApiGen Imports
//#endregion

export enum EnumRegulamentacaoProduto {
  //#region ODataApiGen Members
  NAO_INFORMADO = 0,
  REGULAMENTADO = 1,
  ADAPTADO = 2,
  NAO_REGULAMENTADO = 3,
  //#endregion
}
