//#region ODataApiGen Imports
//#endregion

export enum EnumAreaGeografica {
  //#region ODataApiGen Members
  MUNICIPAL = 1,
  ESTADUAL = 2,
  GRUPO_ESTADOS = 3,
  GRUPO_MUNICIPIOS = 4,
  NACIONAL = 5,
  //#endregion
}
