//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { ProcessamentosRetornoSIBService } from './processamentosretornosib.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const ProcessamentosRetornoSIBServiceEntitySetConfig = {
  name: 'ProcessamentosRetornoSIB',
  entityType: 'Uniodonto.Domain.Entities.ProcessamentoRetornoSIB',
  service: ProcessamentosRetornoSIBService
} as EntitySetConfig;
//#endregion