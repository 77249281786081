//#region ODataApiGen ODataImports
import {
  EnumTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumOrigem } from './enumorigem.enum';
//#endregion

//#region ODataApiGen EnumTypeConfig
export const EnumOrigemConfig = {
  name: 'EnumOrigem',
  members: EnumOrigem,
  fields: {
    PORTAL: {value: 1, annotations: [{"term":"Default.Description","string":"Portal"}]},
    UFAST: {value: 2, annotations: [{"term":"Default.Description","string":"UFAST"}]}
  }
} as EnumTypeConfig<EnumOrigem>;
//#endregion