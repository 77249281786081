//#region ODataApiGen ODataImports
import {
  EnumTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumTipoContratacao } from './enumtipocontratacao.enum';
//#endregion

//#region ODataApiGen EnumTypeConfig
export const EnumTipoContratacaoConfig = {
  name: 'EnumTipoContratacao',
  members: EnumTipoContratacao,
  fields: {
    COLETIVO_EMPRESARIAL: {value: 0},
    COLETIVO_ADESAO: {value: 1}
  }
} as EnumTypeConfig<EnumTipoContratacao>;
//#endregion