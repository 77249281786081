//#region ODataApiGen Imports
//#endregion

export enum EnumTipoDocumento {
  //#region ODataApiGen Members
  FotoInicial = 1,
  FotoFinal = 2,
  RadiografiaInicial = 3,
  RadiografiaFinal = 4,
  Laudo = 5,
  GuiaDigitalizada = 6,
  //#endregion
}
