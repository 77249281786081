//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { DentistaV2Service } from './dentistav2.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const DentistaV2ServiceEntitySetConfig = {
  name: 'DentistaV2',
  entityType: 'Uniodonto.Domain.Entities.Dentista',
  service: DentistaV2Service
} as EntitySetConfig;
//#endregion