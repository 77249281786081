//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumStatusIntegracao } from '../Model/Enum/enumstatusintegracao.enum';
import { Operadora } from './operadora.entity';
import { Clinica } from './clinica.entity';
//#endregion

export interface ClinicaContato {
  //#region ODataApiGen Properties
  ClinicaId?: number;
  TipoContato?: string;
  Nome?: string;
  Email?: string;
  Telefone?: string;
  Celular?: string;
  Cargo?: string;
  Observacao?: string;
  ContatoAutorizaEmail: boolean;
  ContatoAutorizaSms: boolean;
  DivulgaCredemciada: boolean;
  IntegradoERP: boolean;
  StatusIntegracaoERP: EnumStatusIntegracao;
  OperadoraId?: number;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  Clinica?: Clinica;
  Operadora?: Operadora;
  //#endregion
}