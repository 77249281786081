//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Operadora } from './operadora.entity';
import { Clinica } from './clinica.entity';
import { LocalAtendimento } from './localatendimento.entity';
//#endregion

export interface HorarioAtendimento {
  //#region ODataApiGen Properties
  DiaDaSemana?: string;
  Ativado: boolean;
  HoraInicio: number;
  MinutoInicio: number;
  HoraTermino: number;
  MinutoTermino: number;
  ClinicaId?: number;
  LocalAtendimentoId?: number;
  BanheirosAcessiveis: boolean;
  Acessiblidade: boolean;
  OperadoraId?: number;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  Clinica?: Clinica;
  LocalAtendimento?: LocalAtendimento;
  Operadora?: Operadora;
  //#endregion
}