//#region ODataApiGen ODataImports
import {
  EnumTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumTipoEntidade } from './enumtipoentidade.enum';
//#endregion

//#region ODataApiGen EnumTypeConfig
export const EnumTipoEntidadeConfig = {
  name: 'EnumTipoEntidade',
  members: EnumTipoEntidade,
  fields: {
    PESSOAFISICA: {value: 1},
    PESSOAJURIDICA: {value: 2}
  }
} as EnumTypeConfig<EnumTipoEntidade>;
//#endregion