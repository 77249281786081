//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Operadora } from './operadora.entity';
import { HorarioAtendimento } from './horarioatendimento.entity';
//#endregion

export interface LocalAtendimento {
  //#region ODataApiGen Properties
  CEP?: string;
  Logradouro?: string;
  Bairro?: string;
  Cidade?: string;
  UF?: string;
  Numero?: string;
  Complemento?: string;
  DadosAdicionais?: string;
  OperadoraId?: number;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  HorarioAtendimentos?: HorarioAtendimento[];
  Operadora?: Operadora;
  //#endregion
}