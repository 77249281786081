import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';

import { catchError, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { APIListResponse, APIResponse } from '../../models';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};

@Injectable({
    providedIn: 'root'
})
export class ProdutoService {
    public log: String

    constructor(
        private http: HttpClient
    ) { }

    getAll(): Observable<any[]> {
        return this.http.get<any[]>(`${environment.apiUrl}/produto/buscar-todos`).pipe(
            tap(data => console.log('produtos obtidos')),
            catchError(this.handleError)
        );
    }

    getAllByContratoId(model: any): Observable<APIListResponse> {
      return this.http.post<APIListResponse>(`${environment.apiUrl}/produto/buscar-produtos-por-contrato-id`, model, httpOptions);
  }

    lista(): Observable<any[]> {
        return this.http.get<any[]>(`${environment.apiUrl}/produto/lista`).pipe(
            tap(data => "produtos obtidos"),
            catchError(this.handleError)
        );
    }

    getSubClasses() : Observable<any[]> {
        return this.http.get<any[]>(`${environment.apiUrl}/produto/subs`).pipe(
            tap(data => console.log('subclasses obtidas')),
            catchError(this.handleError)
        );
    }

    getChildsDoProduto(produto_id: number) : Observable<any[]> {
        return this.http.get<any[]>(`${environment.apiUrl}/produto/buscar-por-id?id=${produto_id}`).pipe(
            tap(data => console.log('childs obtidas')),
            catchError(this.handleError)
        );
    }


    create(produto: any): Observable<APIResponse> {
        return this.http.post<APIResponse>(`${environment.apiUrl}/produto/criar-produto`, produto, httpOptions)
            .pipe(
                tap(resp => console.log(JSON.stringify(resp))),
                catchError(this.handleError)
            );
    }

    update(produto: any): Observable<APIResponse> {
        return this.http.put<APIResponse>(`${environment.apiUrl}/produto/editar-produto`, produto, httpOptions)
            .pipe(
                tap(resp => console.log(JSON.stringify(resp))),
                catchError(this.handleError)
            );
    }

    delete(produtoId : number): Observable<APIResponse> {
      return this.http.delete<APIResponse>(`${environment.apiUrl}/Produto/remover-produto?id=` + produtoId , httpOptions)
          .pipe(
              tap(resp => console.log(JSON.stringify(resp))),
              catchError(this.handleErrorJson)
          );
    }


    private handleErrorJson(err : APIResponse) {
          console.log(err);

          return throwError(err);
    }

    private handleError(err: HttpErrorResponse) {

        let errorMessage = '';
        if (err.error instanceof ErrorEvent) {

            errorMessage = `An error occurred: ${err.error.message}`;
        } else {

            errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
        }
        console.error(errorMessage);
        return throwError(errorMessage);
    }

}
