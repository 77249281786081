//#region ODataApiGen ODataImports
import {
  SchemaConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { ArquivoEntityConfig } from './arquivo.entity.config';
import { ClienteEntityConfig } from './cliente.entity.config';
import { ContratoProdutoEntityConfig } from './contratoproduto.entity.config';
import { ContratoEntityConfig } from './contrato.entity.config';
import { DependenteGrupoFamiliarEntityConfig } from './dependentegrupofamiliar.entity.config';
import { PreEspelhoDetalheEntityConfig } from './preespelhodetalhe.entity.config';
import { PreEspelhoEntityConfig } from './preespelho.entity.config';
import { HistoricoAtivacaoInativacaoOperadoraEntityConfig } from './historicoativacaoinativacaooperadora.entity.config';
import { HistoricoConsultaLotePrestadoresRPSEntityConfig } from './historicoconsultaloteprestadoresrps.entity.config';
import { JobEntityConfig } from './job.entity.config';
import { LogJobEntityConfig } from './logjob.entity.config';
import { LogTrocaSenhaEntityConfig } from './logtrocasenha.entity.config';
import { OperadoraEntityConfig } from './operadora.entity.config';
import { PeriodoEntityConfig } from './periodo.entity.config';
import { ReajusteContratualEntityConfig } from './reajustecontratual.entity.config';
import { StatusJobEntityConfig } from './statusjob.entity.config';
import { SubclienteEntityConfig } from './subcliente.entity.config';
import { BeneficiarioEntityConfig } from './beneficiario.entity.config';
import { CarteiraEntityConfig } from './carteira.entity.config';
import { TitularEntityConfig } from './titular.entity.config';
import { GrupoFamiliarContratoEntityConfig } from './grupofamiliarcontrato.entity.config';
import { EnderecoEntityConfig } from './endereco.entity.config';
import { CEPEntityConfig } from './cep.entity.config';
import { BairroEntityConfig } from './bairro.entity.config';
import { CidadeEntityConfig } from './cidade.entity.config';
import { TRMAtoOperadoraEntityConfig } from './trmatooperadora.entity.config';
import { UsuarioOperadoraEntityConfig } from './usuariooperadora.entity.config';
import { GrupoEntityConfig } from './grupo.entity.config';
import { GrupoAtoOdontologicoEntityConfig } from './grupoatoodontologico.entity.config';
import { AtoOdontologicoEntityConfig } from './atoodontologico.entity.config';
import { ComissaoEntityConfig } from './comissao.entity.config';
import { ContratoProdutoAtoPrecoEntityConfig } from './contratoprodutoatopreco.entity.config';
import { DependenteEntityConfig } from './dependente.entity.config';
import { OrcamentoEntityConfig } from './orcamento.entity.config';
import { DominioEntityConfig } from './dominio.entity.config';
import { ClinicaEntityConfig } from './clinica.entity.config';
import { GuiaEntityConfig } from './guia.entity.config';
import { SubContratoEntityConfig } from './subcontrato.entity.config';
import { SubContratoProdutoEntityConfig } from './subcontratoproduto.entity.config';
import { ValorProcedimentoTipoProdutoEntityConfig } from './valorprocedimentotipoproduto.entity.config';
import { ProcedimentosEntityConfig } from './procedimentos.entity.config';
import { ProcedimentoGlosaEntityConfig } from './procedimentoglosa.entity.config';
import { LoteEntityConfig } from './lote.entity.config';
import { ContratoOperadoraRepasseEntityConfig } from './contratooperadorarepasse.entity.config';
import { ProcedimentoRecursosHistoricoEntityConfig } from './procedimentorecursoshistorico.entity.config';
import { ProcessamentoRetornoSIBEntityConfig } from './processamentoretornosib.entity.config';
import { HistoricoNaoIncluidosProcessamentoSIBEntityConfig } from './historiconaoincluidosprocessamentosib.entity.config';
import { HistoricoRejeitadosProcessamentoSIBEntityConfig } from './historicorejeitadosprocessamentosib.entity.config';
import { ConsolidadoProcessamentoSIBEntityConfig } from './consolidadoprocessamentosib.entity.config';
import { DentistaEntityConfig } from './dentista.entity.config';
import { GuiaObservacaoEntityConfig } from './guiaobservacao.entity.config';
import { IntegracaoEntidadePortalERPLogEntityConfig } from './integracaoentidadeportalerplog.entity.config';
import { NumberSequenceEntityConfig } from './numbersequence.entity.config';
import { GuiaLegadoEntityConfig } from './guialegado.entity.config';
import { ProcedimentoLegadoEntityConfig } from './procedimentolegado.entity.config';
import { GuiaProcedimentoImagemLegadoEntityConfig } from './guiaprocedimentoimagemlegado.entity.config';
import { ProfissionalSolicitanteEntityConfig } from './profissionalsolicitante.entity.config';
import { FaturamentoMovimentacoesEntityConfig } from './faturamentomovimentacoes.entity.config';
import { PerfilCobrancaEntityConfig } from './perfilcobranca.entity.config';
import { ProdutoEntityConfig } from './produto.entity.config';
import { GrupoFornecedorERPEntityConfig } from './grupofornecedorerp.entity.config';
import { AvisoEntityConfig } from './aviso.entity.config';
import { LinkEntityConfig } from './link.entity.config';
import { RacaEntityConfig } from './raca.entity.config';
import { GrauInstrucaoEntityConfig } from './grauinstrucao.entity.config';
import { ParticipanteEntityConfig } from './participante.entity.config';
import { EstadoEntityConfig } from './estado.entity.config';
import { EnderecoBeneficiarioEntityConfig } from './enderecobeneficiario.entity.config';
import { MotivoDesligamentoEntityConfig } from './motivodesligamento.entity.config';
import { TipoModalidadeContratoEntityConfig } from './tipomodalidadecontrato.entity.config';
import { NucleoEntityConfig } from './nucleo.entity.config';
import { ContatoEntityConfig } from './contato.entity.config';
import { RepasseEntityConfig } from './repasse.entity.config';
import { ResponsavelEntityConfig } from './responsavel.entity.config';
import { DocumentosEntityConfig } from './documentos.entity.config';
import { AreaGeograficaEntityConfig } from './areageografica.entity.config';
import { TipoProdutoEntityConfig } from './tipoproduto.entity.config';
import { HorarioAtendimentoEntityConfig } from './horarioatendimento.entity.config';
import { LocalAtendimentoEntityConfig } from './localatendimento.entity.config';
import { ClinicaDentistasEntityConfig } from './clinicadentistas.entity.config';
import { HabilidadesEntityConfig } from './habilidades.entity.config';
import { DentistaEspecialidadesEntityConfig } from './dentistaespecialidades.entity.config';
import { EspecialidadeEntityConfig } from './especialidade.entity.config';
import { AtoDominioEntityConfig } from './atodominio.entity.config';
import { DominioDenteAfetadoEntityConfig } from './dominiodenteafetado.entity.config';
import { NegociacaoEntityConfig } from './negociacao.entity.config';
import { AtoOdontologicoFilhosEntityConfig } from './atoodontologicofilhos.entity.config';
import { AtoRestricaoIdadeEntityConfig } from './atorestricaoidade.entity.config';
import { ClinicaEspecialidadeEAtendimentoEntityConfig } from './clinicaespecialidadeeatendimento.entity.config';
import { DentistaEspecialidadeEAtendimentoEntityConfig } from './dentistaespecialidadeeatendimento.entity.config';
import { DentistaFormacaoEHabilidadeEntityConfig } from './dentistaformacaoehabilidade.entity.config';
import { DentistaDocumentoEntityConfig } from './dentistadocumento.entity.config';
import { UsuarioClinicaEntityConfig } from './usuarioclinica.entity.config';
import { UsuarioEntityConfig } from './usuario.entity.config';
import { OperadoraUsuariosEntityConfig } from './operadorausuarios.entity.config';
import { ControleAcessoEntityConfig } from './controleacesso.entity.config';
import { ClinicaDocumentoEntityConfig } from './clinicadocumento.entity.config';
import { ClinicaContatoEntityConfig } from './clinicacontato.entity.config';
import { ClinicaEnderecosEntityConfig } from './clinicaenderecos.entity.config';
import { ProcedimentoImagensEntityConfig } from './procedimentoimagens.entity.config';
import { GlosaEntityConfig } from './glosa.entity.config';
import { IndiceEntityConfig } from './indice.entity.config';
import { PerfilCarenciaEntityConfig } from './perfilcarencia.entity.config';
import { PerfilCarenciaLinhaEntityConfig } from './perfilcarencialinha.entity.config';
import { OperadoraParametroEntityConfig } from './operadoraparametro.entity.config';
import { ImportacaoBeneficiarioEntityConfig } from './importacaobeneficiario.entity.config';
import { GrupoFamiliarEntityConfig } from './grupofamiliar.entity.config';
import { ProcedimentoFacesEntityConfig } from './procedimentofaces.entity.config';
import { DentistaReembolsoEntityConfig } from './dentistareembolso.entity.config';
import { BatchIntegrationLogEntityConfig } from './batchintegrationlog.entity.config';
import { ReembolsoEntityConfig } from './reembolso.entity.config';
import { GuiaAnexoEntityConfig } from './guiaanexo.entity.config';
import { GuiaHistoricoEntityConfig } from './guiahistorico.entity.config';
import { DependenteClinicaEntityConfig } from './dependenteclinica.entity.config';
import { ClinicaMidiaSociaisEntityConfig } from './clinicamidiasociais.entity.config';
import { BloqueioPrestadorEntityConfig } from './bloqueioprestador.entity.config';
import { TipoContratacaoProdutoEntityConfig } from './tipocontratacaoproduto.entity.config';
import { TipoAdesaoProdutoEntityConfig } from './tipoadesaoproduto.entity.config';
import { CondicaoVinculoProdutoEntityConfig } from './condicaovinculoproduto.entity.config';
import { TipoPagamentoProdutoEntityConfig } from './tipopagamentoproduto.entity.config';
import { FatorModeradorProdutoEntityConfig } from './fatormoderadorproduto.entity.config';
import { ProdutoServicosAdicionaisEntityConfig } from './produtoservicosadicionais.entity.config';
import { ProdutoServicosECoberturaEntityConfig } from './produtoservicosecobertura.entity.config';
import { PagamentoEntityConfig } from './pagamento.entity.config';
import { UfastEntityConfig } from './ufast.entity.config';
import { USPagamentoRepasseEntityConfig } from './uspagamentorepasse.entity.config';
import { AreaAtuacaoEntityConfig } from './areaatuacao.entity.config';
import { OperadoraDocumentoEntityConfig } from './operadoradocumento.entity.config';
import { NegociacaoOperadoraEntityConfig } from './negociacaooperadora.entity.config';
import { MotivoGlosaEntityConfig } from './motivoglosa.entity.config';
import { NucleoEnderecoEntityConfig } from './nucleoendereco.entity.config';
import { OrigemCompraContratoEntityConfig } from './origemcompracontrato.entity.config';
import { TipoContratoColetivoEntityConfig } from './tipocontratocoletivo.entity.config';
import { ComissionamentoEntityConfig } from './comissionamento.entity.config';
import { ConfiguracaoComissionamentoEntityConfig } from './configuracaocomissionamento.entity.config';
import { CorretorEntityConfig } from './corretor.entity.config';
import { TipoEntidadeEntityConfig } from './tipoentidade.entity.config';
import { ContratoDocumentoEntityConfig } from './contratodocumento.entity.config';
import { MotivoSegundaViaEntityConfig } from './motivosegundavia.entity.config';
import { ClienteDocumentoEntityConfig } from './clientedocumento.entity.config';
import { LotePrestadoresEntityConfig } from './loteprestadores.entity.config';
//#endregion

//#region ODataApiGen SchemaConfig
export const EntitiesSchema = {
  namespace: 'Uniodonto.Domain.Entities',
  enums: [],
  entities: [ArquivoEntityConfig,
    ClienteEntityConfig,
    ContratoProdutoEntityConfig,
    ContratoEntityConfig,
    DependenteGrupoFamiliarEntityConfig,
    PreEspelhoDetalheEntityConfig,
    PreEspelhoEntityConfig,
    HistoricoAtivacaoInativacaoOperadoraEntityConfig,
    HistoricoConsultaLotePrestadoresRPSEntityConfig,
    JobEntityConfig,
    LogJobEntityConfig,
    LogTrocaSenhaEntityConfig,
    OperadoraEntityConfig,
    PeriodoEntityConfig,
    ReajusteContratualEntityConfig,
    StatusJobEntityConfig,
    SubclienteEntityConfig,
    BeneficiarioEntityConfig,
    CarteiraEntityConfig,
    TitularEntityConfig,
    GrupoFamiliarContratoEntityConfig,
    EnderecoEntityConfig,
    CEPEntityConfig,
    BairroEntityConfig,
    CidadeEntityConfig,
    TRMAtoOperadoraEntityConfig,
    UsuarioOperadoraEntityConfig,
    GrupoEntityConfig,
    GrupoAtoOdontologicoEntityConfig,
    AtoOdontologicoEntityConfig,
    ComissaoEntityConfig,
    ContratoProdutoAtoPrecoEntityConfig,
    DependenteEntityConfig,
    OrcamentoEntityConfig,
    DominioEntityConfig,
    ClinicaEntityConfig,
    GuiaEntityConfig,
    SubContratoEntityConfig,
    SubContratoProdutoEntityConfig,
    ValorProcedimentoTipoProdutoEntityConfig,
    ProcedimentosEntityConfig,
    ProcedimentoGlosaEntityConfig,
    LoteEntityConfig,
    ContratoOperadoraRepasseEntityConfig,
    ProcedimentoRecursosHistoricoEntityConfig,
    ProcessamentoRetornoSIBEntityConfig,
    HistoricoNaoIncluidosProcessamentoSIBEntityConfig,
    HistoricoRejeitadosProcessamentoSIBEntityConfig,
    ConsolidadoProcessamentoSIBEntityConfig,
    DentistaEntityConfig,
    GuiaObservacaoEntityConfig,
    IntegracaoEntidadePortalERPLogEntityConfig,
    NumberSequenceEntityConfig,
    GuiaLegadoEntityConfig,
    ProcedimentoLegadoEntityConfig,
    GuiaProcedimentoImagemLegadoEntityConfig,
    ProfissionalSolicitanteEntityConfig,
    FaturamentoMovimentacoesEntityConfig,
    PerfilCobrancaEntityConfig,
    ProdutoEntityConfig,
    GrupoFornecedorERPEntityConfig,
    AvisoEntityConfig,
    LinkEntityConfig,
    RacaEntityConfig,
    GrauInstrucaoEntityConfig,
    ParticipanteEntityConfig,
    EstadoEntityConfig,
    EnderecoBeneficiarioEntityConfig,
    MotivoDesligamentoEntityConfig,
    TipoModalidadeContratoEntityConfig,
    NucleoEntityConfig,
    ContatoEntityConfig,
    RepasseEntityConfig,
    ResponsavelEntityConfig,
    DocumentosEntityConfig,
    AreaGeograficaEntityConfig,
    TipoProdutoEntityConfig,
    HorarioAtendimentoEntityConfig,
    LocalAtendimentoEntityConfig,
    ClinicaDentistasEntityConfig,
    HabilidadesEntityConfig,
    DentistaEspecialidadesEntityConfig,
    EspecialidadeEntityConfig,
    AtoDominioEntityConfig,
    DominioDenteAfetadoEntityConfig,
    NegociacaoEntityConfig,
    AtoOdontologicoFilhosEntityConfig,
    AtoRestricaoIdadeEntityConfig,
    ClinicaEspecialidadeEAtendimentoEntityConfig,
    DentistaEspecialidadeEAtendimentoEntityConfig,
    DentistaFormacaoEHabilidadeEntityConfig,
    DentistaDocumentoEntityConfig,
    UsuarioClinicaEntityConfig,
    UsuarioEntityConfig,
    OperadoraUsuariosEntityConfig,
    ControleAcessoEntityConfig,
    ClinicaDocumentoEntityConfig,
    ClinicaContatoEntityConfig,
    ClinicaEnderecosEntityConfig,
    ProcedimentoImagensEntityConfig,
    GlosaEntityConfig,
    IndiceEntityConfig,
    PerfilCarenciaEntityConfig,
    PerfilCarenciaLinhaEntityConfig,
    OperadoraParametroEntityConfig,
    ImportacaoBeneficiarioEntityConfig,
    GrupoFamiliarEntityConfig,
    ProcedimentoFacesEntityConfig,
    DentistaReembolsoEntityConfig,
    BatchIntegrationLogEntityConfig,
    ReembolsoEntityConfig,
    GuiaAnexoEntityConfig,
    GuiaHistoricoEntityConfig,
    DependenteClinicaEntityConfig,
    ClinicaMidiaSociaisEntityConfig,
    BloqueioPrestadorEntityConfig,
    TipoContratacaoProdutoEntityConfig,
    TipoAdesaoProdutoEntityConfig,
    CondicaoVinculoProdutoEntityConfig,
    TipoPagamentoProdutoEntityConfig,
    FatorModeradorProdutoEntityConfig,
    ProdutoServicosAdicionaisEntityConfig,
    ProdutoServicosECoberturaEntityConfig,
    PagamentoEntityConfig,
    UfastEntityConfig,
    USPagamentoRepasseEntityConfig,
    AreaAtuacaoEntityConfig,
    OperadoraDocumentoEntityConfig,
    NegociacaoOperadoraEntityConfig,
    MotivoGlosaEntityConfig,
    NucleoEnderecoEntityConfig,
    OrigemCompraContratoEntityConfig,
    TipoContratoColetivoEntityConfig,
    ComissionamentoEntityConfig,
    ConfiguracaoComissionamentoEntityConfig,
    CorretorEntityConfig,
    TipoEntidadeEntityConfig,
    ContratoDocumentoEntityConfig,
    MotivoSegundaViaEntityConfig,
    ClienteDocumentoEntityConfig,
    LotePrestadoresEntityConfig],
  callables: [],
  containers: []
} as SchemaConfig;
//#endregion