//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumTipoAcao } from '../Model/Enum/enumtipoacao.enum';
import { EnumStatusGuia } from '../Model/Enum/enumstatusguia.enum';
import { Operadora } from './operadora.entity';
import { Guia } from './guia.entity';
//#endregion

export interface GuiaHistorico {
  //#region ODataApiGen Properties
  GuiaId?: number;
  StatusGuia: EnumStatusGuia;
  TipoAcao: EnumTipoAcao;
  OperadoraId?: number;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  Guia?: Guia;
  Operadora?: Operadora;
  //#endregion
}