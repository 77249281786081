//#region ODataApiGen Imports
//#endregion

export enum EnumNumberSeqRef {
  //#region ODataApiGen Members
  NumeroGuia = 1,
  SeqContrato = 2,
  SubContrato = 3,
  PreEspelho = 4,
  PerfilCobranca = 5,
  //#endregion
}
