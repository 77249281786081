//#region ODataApiGen ODataImports
import {
  EnumTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumTipoPagamento } from './enumtipopagamento.enum';
//#endregion

//#region ODataApiGen EnumTypeConfig
export const EnumTipoPagamentoConfig = {
  name: 'EnumTipoPagamento',
  members: EnumTipoPagamento,
  fields: {
    Transferencia: {value: 1},
    boleto: {value: 2}
  }
} as EnumTypeConfig<EnumTipoPagamento>;
//#endregion