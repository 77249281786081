//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { UsuarioOperadora } from './usuariooperadora.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const UsuarioOperadoraEntityConfig = {
  name: 'UsuarioOperadora',
  keys: [{name: 'Id'}],
  fields: {
    UsuarioId: {type: 'Edm.Int32'},
    OperadoraId: {type: 'Edm.Int32'},
    IsTechnician: {type: 'Edm.Boolean', nullable: false},
    IsAdministrative: {type: 'Edm.Boolean', nullable: false},
    ClinicaId: {type: 'Edm.Int32'},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'},
    Usuario: {type: 'Uniodonto.Domain.Entities.Usuario', navigation: true, referentials: [{property: 'UsuarioId', referencedProperty: 'Id'}]},
    Operadora: {type: 'Uniodonto.Domain.Entities.Operadora', navigation: true, referentials: [{property: 'OperadoraId', referencedProperty: 'Id'}]},
    Clinica: {type: 'Uniodonto.Domain.Entities.Clinica', navigation: true, referentials: [{property: 'ClinicaId', referencedProperty: 'Id'}]}
  }
} as StructuredTypeConfig<UsuarioOperadora>;
//#endregion