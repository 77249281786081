//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { CidadeV2Service } from './cidadev2.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const CidadeV2ServiceEntitySetConfig = {
  name: 'CidadeV2',
  entityType: 'Uniodonto.Domain.Entities.Cidade',
  service: CidadeV2Service
} as EntitySetConfig;
//#endregion