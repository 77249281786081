//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Contrato } from './contrato.entity';
import { Operadora } from './operadora.entity';
import { Endereco } from './endereco.entity';
import { Clinica } from './clinica.entity';
import { Contato } from './contato.entity';
import { Responsavel } from './responsavel.entity';
import { NucleoEndereco } from './nucleoendereco.entity';
//#endregion

export interface Nucleo {
  //#region ODataApiGen Properties
  Nome?: string;
  EnderecoId?: number;
  ResponsavelId?: number;
  OperadoraId?: number;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  Endereco?: Endereco;
  Contatos?: Contato[];
  Responsavel?: Responsavel;
  Clinicas?: Clinica[];
  NucleoEnderecos?: NucleoEndereco[];
  Contratos?: Contrato[];
  Operadora?: Operadora;
  //#endregion
}