//#region ODataApiGen ODataImports
import {
  EnumTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumClinicaTipo } from './enumclinicatipo.enum';
//#endregion

//#region ODataApiGen EnumTypeConfig
export const EnumClinicaTipoConfig = {
  name: 'EnumClinicaTipo',
  members: EnumClinicaTipo,
  fields: {
    Clinica: {value: 1},
    Profissional: {value: 2}
  }
} as EnumTypeConfig<EnumClinicaTipo>;
//#endregion