//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
//#endregion

export interface Repasse {
  //#region ODataApiGen Properties
  Vinculo: boolean;
  DataRecebimento: Date;
  DataConciliacao: Date;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  //#endregion
}