//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Operadora } from './operadora.entity';
import { Comissionamento } from './comissionamento.entity';
//#endregion

export interface ConfiguracaoComissionamento {
  //#region ODataApiGen Properties
  ComissionamentoId?: number;
  PeriodoInicial: number;
  PeriodoFinal?: number;
  NumeroMinimoUsuarios?: number;
  NumeroMaximoUsuarios?: number;
  Porcentagem: number;
  ValorFixo?: number;
  ValorMensalidade: boolean;
  ValorInscricao: boolean;
  ValorCarteirinha: boolean;
  ValorRetido?: boolean;
  OperadoraId?: number;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  Comissionamento?: Comissionamento;
  Operadora?: Operadora;
  //#endregion
}