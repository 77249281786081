//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Cliente } from './cliente.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const ClienteEntityConfig = {
  name: 'Cliente',
  keys: [{name: 'Id'}],
  fields: {
    TipoPessoa: {type: 'Uniodonto.Domain.Model.Enum.EnumTipoPessoa', nullable: false},
    CNPJCPFNum: {type: 'Edm.String', maxLength: 14},
    TipoCliente: {type: 'Edm.String'},
    CNPJ: {type: 'Edm.String'},
    CPF: {type: 'Edm.String'},
    NomeCliente: {type: 'Edm.String'},
    NomeFantasia: {type: 'Edm.String'},
    RamoAtividade: {type: 'Edm.String'},
    InscricaoEstadual: {type: 'Edm.String'},
    InscricaoMunicipal: {type: 'Edm.String'},
    ParticipanteId: {type: 'Edm.Int32'},
    OptanteSimplesNacional: {type: 'Edm.Boolean', nullable: false},
    FaturamentoAgrupado: {type: 'Edm.Boolean', nullable: false},
    IntegradoERP: {type: 'Edm.Boolean', nullable: false},
    StatusIntegracaoERP: {type: 'Uniodonto.Domain.Model.Enum.EnumStatusIntegracao', nullable: false},
    OperadoraId: {type: 'Edm.Int32'},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'},
    Participante: {type: 'Uniodonto.Domain.Entities.Participante', navigation: true, referentials: [{property: 'ParticipanteId', referencedProperty: 'Id'}]},
    Negociacoes: {type: 'Uniodonto.Domain.Entities.Negociacao', collection: true, navigation: true},
    ClienteDocumentos: {type: 'Uniodonto.Domain.Entities.ClienteDocumento', collection: true, navigation: true},
    Contratos: {type: 'Uniodonto.Domain.Entities.Contrato', collection: true, navigation: true},
    PreEspelhos: {type: 'Uniodonto.Domain.Entities.PreEspelho', collection: true, navigation: true},
    SubClientes: {type: 'Uniodonto.Domain.Entities.Subcliente', collection: true, navigation: true},
    SubContratos: {type: 'Uniodonto.Domain.Entities.SubContrato', collection: true, navigation: true},
    Usuarios: {type: 'Uniodonto.Domain.Entities.Usuario', collection: true, navigation: true},
    Operadora: {type: 'Uniodonto.Domain.Entities.Operadora', navigation: true, referentials: [{property: 'OperadoraId', referencedProperty: 'Id'}]}
  }
} as StructuredTypeConfig<Cliente>;
//#endregion