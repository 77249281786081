//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumTipoPrecoContratoProdutoAto } from '../Model/Enum/enumtipoprecocontratoprodutoato.enum';
import { Contrato } from './contrato.entity';
import { Operadora } from './operadora.entity';
import { AtoOdontologico } from './atoodontologico.entity';
import { Produto } from './produto.entity';
//#endregion

export interface ContratoProdutoAtoPreco {
  //#region ODataApiGen Properties
  AtoOdontologicoId?: number;
  ProdutoId?: number;
  ContratoId?: number;
  Valor: number;
  ValorSecundarioQuantidade?: number;
  TipoPreco: EnumTipoPrecoContratoProdutoAto;
  OperadoraId?: number;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  AtoOdontologico?: AtoOdontologico;
  Produto?: Produto;
  Contrato?: Contrato;
  Operadora?: Operadora;
  //#endregion
}