import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Operadora } from '../Uniodonto/Domain/Entities/operadora.entity';
import { TRMAtoOperadora } from '../Uniodonto/Domain/Entities/trmatooperadora.entity';
import { UsuarioOperadora } from '../Uniodonto/Domain/Entities/usuariooperadora.entity';
import { Grupo } from '../Uniodonto/Domain/Entities/grupo.entity';
import { ContratoProdutoAtoPreco } from '../Uniodonto/Domain/Entities/contratoprodutoatopreco.entity';
import { SubContrato } from '../Uniodonto/Domain/Entities/subcontrato.entity';
import { ContratoOperadoraRepasse } from '../Uniodonto/Domain/Entities/contratooperadorarepasse.entity';
import { Produto } from '../Uniodonto/Domain/Entities/produto.entity';
//#endregion

@Injectable()
export class OperadoraV2Service extends ODataEntitySetService<Operadora> {
  constructor(client: ODataClient) {
    super(client, 'OperadoraV2', 'Uniodonto.Domain.Entities.Operadora');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public contratoOperadoraRepasses(key: EntityKey<Operadora>): ODataNavigationPropertyResource<ContratoOperadoraRepasse> { 
    return this.entity(key).navigationProperty<ContratoOperadoraRepasse>('ContratoOperadoraRepasses'); 
  }
  public fetchContratoOperadoraRepasses(key: EntityKey<Operadora>, options?: ODataQueryArgumentsOptions<ContratoOperadoraRepasse>) {
    return this.fetchNavigationProperty<ContratoOperadoraRepasse>(
      this.contratoOperadoraRepasses(key), 
      'entities', options) as Observable<ODataEntities<ContratoOperadoraRepasse>>;
  }
  public addContratoOperadoraRepasseToContratoOperadoraRepasses(key: EntityKey<Operadora>, target: ODataEntityResource<ODataEntities<ContratoOperadoraRepasse>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.contratoOperadoraRepasses(key).reference()
      .add(target);
  }
  public removeContratoOperadoraRepasseFromContratoOperadoraRepasses(key: EntityKey<Operadora>, {target, etag}: {target?: ODataEntityResource<ODataEntities<ContratoOperadoraRepasse>>, etag?: string} = {}): Observable<any> {
    return this.contratoOperadoraRepasses(key).reference()
      .remove(target);
  }
  public contratoProdutoAtoPrecos(key: EntityKey<Operadora>): ODataNavigationPropertyResource<ContratoProdutoAtoPreco> { 
    return this.entity(key).navigationProperty<ContratoProdutoAtoPreco>('ContratoProdutoAtoPrecos'); 
  }
  public fetchContratoProdutoAtoPrecos(key: EntityKey<Operadora>, options?: ODataQueryArgumentsOptions<ContratoProdutoAtoPreco>) {
    return this.fetchNavigationProperty<ContratoProdutoAtoPreco>(
      this.contratoProdutoAtoPrecos(key), 
      'entities', options) as Observable<ODataEntities<ContratoProdutoAtoPreco>>;
  }
  public addContratoProdutoAtoPrecoToContratoProdutoAtoPrecos(key: EntityKey<Operadora>, target: ODataEntityResource<ODataEntities<ContratoProdutoAtoPreco>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.contratoProdutoAtoPrecos(key).reference()
      .add(target);
  }
  public removeContratoProdutoAtoPrecoFromContratoProdutoAtoPrecos(key: EntityKey<Operadora>, {target, etag}: {target?: ODataEntityResource<ODataEntities<ContratoProdutoAtoPreco>>, etag?: string} = {}): Observable<any> {
    return this.contratoProdutoAtoPrecos(key).reference()
      .remove(target);
  }
  public grupos(key: EntityKey<Operadora>): ODataNavigationPropertyResource<Grupo> { 
    return this.entity(key).navigationProperty<Grupo>('Grupos'); 
  }
  public fetchGrupos(key: EntityKey<Operadora>, options?: ODataQueryArgumentsOptions<Grupo>) {
    return this.fetchNavigationProperty<Grupo>(
      this.grupos(key), 
      'entities', options) as Observable<ODataEntities<Grupo>>;
  }
  public addGrupoToGrupos(key: EntityKey<Operadora>, target: ODataEntityResource<ODataEntities<Grupo>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.grupos(key).reference()
      .add(target);
  }
  public removeGrupoFromGrupos(key: EntityKey<Operadora>, {target, etag}: {target?: ODataEntityResource<ODataEntities<Grupo>>, etag?: string} = {}): Observable<any> {
    return this.grupos(key).reference()
      .remove(target);
  }
  public produtos(key: EntityKey<Operadora>): ODataNavigationPropertyResource<Produto> { 
    return this.entity(key).navigationProperty<Produto>('Produtos'); 
  }
  public fetchProdutos(key: EntityKey<Operadora>, options?: ODataQueryArgumentsOptions<Produto>) {
    return this.fetchNavigationProperty<Produto>(
      this.produtos(key), 
      'entities', options) as Observable<ODataEntities<Produto>>;
  }
  public addProdutoToProdutos(key: EntityKey<Operadora>, target: ODataEntityResource<ODataEntities<Produto>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.produtos(key).reference()
      .add(target);
  }
  public removeProdutoFromProdutos(key: EntityKey<Operadora>, {target, etag}: {target?: ODataEntityResource<ODataEntities<Produto>>, etag?: string} = {}): Observable<any> {
    return this.produtos(key).reference()
      .remove(target);
  }
  public subContratos(key: EntityKey<Operadora>): ODataNavigationPropertyResource<SubContrato> { 
    return this.entity(key).navigationProperty<SubContrato>('SubContratos'); 
  }
  public fetchSubContratos(key: EntityKey<Operadora>, options?: ODataQueryArgumentsOptions<SubContrato>) {
    return this.fetchNavigationProperty<SubContrato>(
      this.subContratos(key), 
      'entities', options) as Observable<ODataEntities<SubContrato>>;
  }
  public addSubContratoToSubContratos(key: EntityKey<Operadora>, target: ODataEntityResource<ODataEntities<SubContrato>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.subContratos(key).reference()
      .add(target);
  }
  public removeSubContratoFromSubContratos(key: EntityKey<Operadora>, {target, etag}: {target?: ODataEntityResource<ODataEntities<SubContrato>>, etag?: string} = {}): Observable<any> {
    return this.subContratos(key).reference()
      .remove(target);
  }
  public tRMAtoOperadoras(key: EntityKey<Operadora>): ODataNavigationPropertyResource<TRMAtoOperadora> { 
    return this.entity(key).navigationProperty<TRMAtoOperadora>('TRMAtoOperadoras'); 
  }
  public fetchTRMAtoOperadoras(key: EntityKey<Operadora>, options?: ODataQueryArgumentsOptions<TRMAtoOperadora>) {
    return this.fetchNavigationProperty<TRMAtoOperadora>(
      this.tRMAtoOperadoras(key), 
      'entities', options) as Observable<ODataEntities<TRMAtoOperadora>>;
  }
  public addTRMAtoOperadoraToTRMAtoOperadoras(key: EntityKey<Operadora>, target: ODataEntityResource<ODataEntities<TRMAtoOperadora>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.tRMAtoOperadoras(key).reference()
      .add(target);
  }
  public removeTRMAtoOperadoraFromTRMAtoOperadoras(key: EntityKey<Operadora>, {target, etag}: {target?: ODataEntityResource<ODataEntities<TRMAtoOperadora>>, etag?: string} = {}): Observable<any> {
    return this.tRMAtoOperadoras(key).reference()
      .remove(target);
  }
  public usuarioOperadoras(key: EntityKey<Operadora>): ODataNavigationPropertyResource<UsuarioOperadora> { 
    return this.entity(key).navigationProperty<UsuarioOperadora>('UsuarioOperadoras'); 
  }
  public fetchUsuarioOperadoras(key: EntityKey<Operadora>, options?: ODataQueryArgumentsOptions<UsuarioOperadora>) {
    return this.fetchNavigationProperty<UsuarioOperadora>(
      this.usuarioOperadoras(key), 
      'entities', options) as Observable<ODataEntities<UsuarioOperadora>>;
  }
  public addUsuarioOperadoraToUsuarioOperadoras(key: EntityKey<Operadora>, target: ODataEntityResource<ODataEntities<UsuarioOperadora>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.usuarioOperadoras(key).reference()
      .add(target);
  }
  public removeUsuarioOperadoraFromUsuarioOperadoras(key: EntityKey<Operadora>, {target, etag}: {target?: ODataEntityResource<ODataEntities<UsuarioOperadora>>, etag?: string} = {}): Observable<any> {
    return this.usuarioOperadoras(key).reference()
      .remove(target);
  }
  //#endregion
}
