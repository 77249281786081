//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { FaturamentoMovimentacoes } from './faturamentomovimentacoes.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const FaturamentoMovimentacoesEntityConfig = {
  name: 'FaturamentoMovimentacoes',
  keys: [{name: 'Id'}],
  fields: {
    PreEspelhoId: {type: 'Edm.Int32'},
    ContratoId: {type: 'Edm.Int32'},
    ContratoProdutoId: {type: 'Edm.Int32'},
    ProdutoId: {type: 'Edm.Int32'},
    TitularesAdicionados: {type: 'Edm.Int32', nullable: false},
    AgregadosAdicionados: {type: 'Edm.Int32', nullable: false},
    DependentesAdicionados: {type: 'Edm.Int32', nullable: false},
    TitularesExcluidos: {type: 'Edm.Int32', nullable: false},
    AgregadosExcluidos: {type: 'Edm.Int32', nullable: false},
    DependentesExcluidos: {type: 'Edm.Int32', nullable: false},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'},
    PreEspelho: {type: 'Uniodonto.Domain.Entities.PreEspelho', navigation: true, referentials: [{property: 'PreEspelhoId', referencedProperty: 'Id'}]},
    ContratoProduto: {type: 'Uniodonto.Domain.Entities.ContratoProduto', navigation: true, referentials: [{property: 'ContratoProdutoId', referencedProperty: 'Id'}]},
    Contrato: {type: 'Uniodonto.Domain.Entities.Contrato', navigation: true, referentials: [{property: 'ContratoId', referencedProperty: 'Id'}]},
    Produto: {type: 'Uniodonto.Domain.Entities.Produto', navigation: true, referentials: [{property: 'ProdutoId', referencedProperty: 'Id'}]}
  }
} as StructuredTypeConfig<FaturamentoMovimentacoes>;
//#endregion