//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { ProcedimentoGlosaV2Service } from './procedimentoglosav2.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const ProcedimentoGlosaV2ServiceEntitySetConfig = {
  name: 'ProcedimentoGlosaV2',
  entityType: 'Uniodonto.Domain.Entities.ProcedimentoGlosa',
  service: ProcedimentoGlosaV2Service
} as EntitySetConfig;
//#endregion