//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Estado } from './estado.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const EstadoEntityConfig = {
  name: 'Estado',
  keys: [{name: 'Id'}],
  fields: {
    EstadoIBGECod: {type: 'Edm.String'},
    EstadoNome: {type: 'Edm.String'},
    EstadoSigla: {type: 'Edm.String'},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'}
  }
} as StructuredTypeConfig<Estado>;
//#endregion