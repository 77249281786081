//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { UsuarioOperadoraV2Service } from './usuariooperadorav2.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const UsuarioOperadoraV2ServiceEntitySetConfig = {
  name: 'UsuarioOperadoraV2',
  entityType: 'Uniodonto.Domain.Entities.UsuarioOperadora',
  service: UsuarioOperadoraV2Service
} as EntitySetConfig;
//#endregion