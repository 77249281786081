//#region ODataApiGen Imports
//#endregion

export enum EnumStatusFaturamento {
  //#region ODataApiGen Members
  CALCULANDO = 0,
  ERRO_CALCULO = 1,
  SEM_VALORES = 2,
  CRIADO = 3,
  EM_INTEGRACAO = 4,
  FATURADO = 5,
  PAGO = 6,
  REVERTIDO = 7,
  CANCELADO = 8,
  AGUARDANDO_PROCESSO_INTEGRACAO_FILA = 9,
  //#endregion
}
