//#region ODataApiGen ODataImports
import {
  EnumTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumStatusFaturamento } from './enumstatusfaturamento.enum';
//#endregion

//#region ODataApiGen EnumTypeConfig
export const EnumStatusFaturamentoConfig = {
  name: 'EnumStatusFaturamento',
  members: EnumStatusFaturamento,
  fields: {
    CALCULANDO: {value: 0, annotations: [{"term":"Default.Description","string":"Calculando"}]},
    ERRO_CALCULO: {value: 1, annotations: [{"term":"Default.Description","string":"Erro"}]},
    SEM_VALORES: {value: 2, annotations: [{"term":"Default.Description","string":"Sem valores"}]},
    CRIADO: {value: 3, annotations: [{"term":"Default.Description","string":"Criado"}]},
    EM_INTEGRACAO: {value: 4, annotations: [{"term":"Default.Description","string":"Em Integra\u00E7\u00E3o"}]},
    FATURADO: {value: 5, annotations: [{"term":"Default.Description","string":"Faturado"}]},
    PAGO: {value: 6, annotations: [{"term":"Default.Description","string":"Pago"}]},
    REVERTIDO: {value: 7, annotations: [{"term":"Default.Description","string":"Revertido"}]},
    CANCELADO: {value: 8, annotations: [{"term":"Default.Description","string":"Cancelado"}]},
    AGUARDANDO_PROCESSO_INTEGRACAO_FILA: {value: 9, annotations: [{"term":"Default.Description","string":"Aguardando Proc Integ Fila"}]}
  }
} as EnumTypeConfig<EnumStatusFaturamento>;
//#endregion