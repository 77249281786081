//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumStatusIntegracao } from '../Model/Enum/enumstatusintegracao.enum';
import { EnumClinicaTipo } from '../Model/Enum/enumclinicatipo.enum';
import { EnumTipoDeClinica } from '../Model/Enum/enumtipodeclinica.enum';
import { EnumTipoProfissional } from '../Model/Enum/enumtipoprofissional.enum';
import { EnumEstadoCivil } from '../Model/Enum/enumestadocivil.enum';
import { Operadora } from './operadora.entity';
import { Guia } from './guia.entity';
import { GrupoFornecedorERP } from './grupofornecedorerp.entity';
import { Raca } from './raca.entity';
import { GrauInstrucao } from './grauinstrucao.entity';
import { Nucleo } from './nucleo.entity';
import { HorarioAtendimento } from './horarioatendimento.entity';
import { ClinicaDentistas } from './clinicadentistas.entity';
import { Negociacao } from './negociacao.entity';
import { ClinicaEspecialidadeEAtendimento } from './clinicaespecialidadeeatendimento.entity';
import { UsuarioClinica } from './usuarioclinica.entity';
import { ClinicaDocumento } from './clinicadocumento.entity';
import { ClinicaContato } from './clinicacontato.entity';
import { ClinicaEnderecos } from './clinicaenderecos.entity';
import { DependenteClinica } from './dependenteclinica.entity';
import { ClinicaMidiaSociais } from './clinicamidiasociais.entity';
import { BloqueioPrestador } from './bloqueioprestador.entity';
//#endregion

export interface Clinica {
  //#region ODataApiGen Properties
  NucleoId?: number;
  Tipo: EnumClinicaTipo;
  Ativo: boolean;
  DataInativacao: Date;
  TipoDeClinica?: EnumTipoDeClinica;
  CNES?: string;
  CNPJ?: string;
  RazaoSocial?: string;
  NomeFantasia?: string;
  ResponsavelCPF?: string;
  ResponsavelNome?: string;
  ReponsavelCRO?: string;
  UFCRO?: string;
  InscricaoISS?: string;
  MatriculaESocial?: string;
  CNS?: string;
  TipoProfissional?: EnumTipoProfissional;
  CPF?: string;
  NomeMae?: string;
  RG?: string;
  OrgaoEmissor?: string;
  ProfissionalIdade: number;
  DataNascimento: Date;
  SexoResponsavel?: string;
  EstadoCivilResponsavel: EnumEstadoCivil;
  ValorUS: number;
  DataConvite: Date;
  DataAssinaturaContrato: Date;
  DataRecebimentoContrato: Date;
  DataRecebimentoDocumentacao: Date;
  DataInicioCredenciamento: Date;
  DataTerminoCredenciamento?: Date;
  AtendeEmergencia: boolean;
  Atendimento24Horas: boolean;
  FeriadosNacionais: boolean;
  FeriadosEstaduais: boolean;
  FeriadosMunicipais: boolean;
  ProviderType?: string;
  RelacaoOperadora?: string;
  TipoContratualizacao?: string;
  DisponibilidadeServico?: string;
  PossuiEstacionamento: boolean;
  PossuiAcessibilidade: boolean;
  SingularIntercambio?: string;
  CodigoCBO?: string;
  IntegradoERP: boolean;
  StatusIntegracaoERP: EnumStatusIntegracao;
  GrupoFornecedorERPId?: number;
  RacaId?: number;
  GrauInstrucaoId?: number;
  NumeroISS?: string;
  PisPasep?: string;
  NomeSocial?: string;
  NomedoPai?: string;
  PaisNascimento?: string;
  EstadoNascimento?: string;
  CidadeNascimento?: string;
  IBGENascimento?: string;
  CodigoCargo?: string;
  CodigoFuncao?: string;
  Site?: string;
  TipoSite?: string;
  EnderecoURLSite?: string;
  TipoPgtoBanco?: string;
  NumeroBanco: number;
  NomeBanco?: string;
  AgenciaBanco?: string;
  ContaBanco?: string;
  OperadoraId?: number;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  Nucleo?: Nucleo;
  HorarioAtendimento?: HorarioAtendimento[];
  Profissionais?: ClinicaDentistas[];
  Negociacoes?: Negociacao[];
  Documentos?: ClinicaDocumento[];
  ClinicaContatos?: ClinicaContato[];
  ClinicaEnderecos?: ClinicaEnderecos[];
  ClinicaEspecialidadeEAtendimentos?: ClinicaEspecialidadeEAtendimento[];
  UsuarioClinicas?: UsuarioClinica[];
  Guias?: Guia[];
  GrupoFornecedorERP?: GrupoFornecedorERP;
  Raca?: Raca;
  GrauInstrucao?: GrauInstrucao;
  DependenteClinicas?: DependenteClinica[];
  ClinicaMidiaSociais?: ClinicaMidiaSociais[];
  BloqueioPrestador?: BloqueioPrestador[];
  Operadora?: Operadora;
  //#endregion
}