//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { HorarioAtendimento } from './horarioatendimento.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const HorarioAtendimentoEntityConfig = {
  name: 'HorarioAtendimento',
  keys: [{name: 'Id'}],
  fields: {
    DiaDaSemana: {type: 'Edm.String'},
    Ativado: {type: 'Edm.Boolean', nullable: false},
    HoraInicio: {type: 'Edm.Int32', nullable: false},
    MinutoInicio: {type: 'Edm.Int32', nullable: false},
    HoraTermino: {type: 'Edm.Int32', nullable: false},
    MinutoTermino: {type: 'Edm.Int32', nullable: false},
    ClinicaId: {type: 'Edm.Int32'},
    LocalAtendimentoId: {type: 'Edm.Int32'},
    BanheirosAcessiveis: {type: 'Edm.Boolean', nullable: false},
    Acessiblidade: {type: 'Edm.Boolean', nullable: false},
    OperadoraId: {type: 'Edm.Int32'},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'},
    Clinica: {type: 'Uniodonto.Domain.Entities.Clinica', navigation: true, referentials: [{property: 'ClinicaId', referencedProperty: 'Id'}]},
    LocalAtendimento: {type: 'Uniodonto.Domain.Entities.LocalAtendimento', navigation: true, referentials: [{property: 'LocalAtendimentoId', referencedProperty: 'Id'}]},
    Operadora: {type: 'Uniodonto.Domain.Entities.Operadora', navigation: true, referentials: [{property: 'OperadoraId', referencedProperty: 'Id'}]}
  }
} as StructuredTypeConfig<HorarioAtendimento>;
//#endregion