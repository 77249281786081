//#region ODataApiGen ODataImports
import {
  EnumTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumTipoProfissional } from './enumtipoprofissional.enum';
//#endregion

//#region ODataApiGen EnumTypeConfig
export const EnumTipoProfissionalConfig = {
  name: 'EnumTipoProfissional',
  members: EnumTipoProfissional,
  fields: {
    Credenciado: {value: 1},
    Cooperado: {value: 2}
  }
} as EnumTypeConfig<EnumTipoProfissional>;
//#endregion