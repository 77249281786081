//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Contrato } from './contrato.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const ContratoEntityConfig = {
  name: 'Contrato',
  keys: [{name: 'Id'}],
  fields: {
    Titulo: {type: 'Edm.String'},
    TipoContratoId: {type: 'Edm.Int32', nullable: false},
    TipoPessoa: {type: 'Uniodonto.Domain.Model.Enum.EnumTipoPessoa', nullable: false},
    TipoModalidadeId: {type: 'Edm.Int32', nullable: false},
    Ativo: {type: 'Edm.Boolean', nullable: false},
    NucleoId: {type: 'Edm.Int32'},
    ClienteId: {type: 'Edm.Int32'},
    OrigemCompraId: {type: 'Edm.Int32', nullable: false},
    TipoContratoColetivoId: {type: 'Edm.Int32'},
    PrazoContrato: {type: 'Edm.Int32', nullable: false},
    QtdMesesPermanenciaMinima: {type: 'Edm.Int32', nullable: false},
    CodigoContrato: {type: 'Edm.String'},
    RenovacaoAutomatica: {type: 'Edm.Boolean', nullable: false},
    PossuiCooparticipacao: {type: 'Edm.Boolean', nullable: false},
    CobrarCooparticipacao: {type: 'Edm.Boolean', nullable: false},
    DataInicial: {type: 'Edm.DateTimeOffset', nullable: false},
    DataFinal: {type: 'Edm.DateTimeOffset', nullable: false},
    DataEncerramento: {type: 'Edm.DateTimeOffset', nullable: false},
    DataPermanenciaMinima: {type: 'Edm.DateTimeOffset', nullable: false},
    DataFimAtendimento: {type: 'Edm.DateTimeOffset'},
    MotivoEncerramentoContrato: {type: 'Edm.String'},
    StatusContratoId: {type: 'Uniodonto.Domain.Model.Enum.EnumStatusContrato', nullable: false},
    AceitaReembolso: {type: 'Edm.Boolean', nullable: false},
    ReembolsoId: {type: 'Edm.Int32'},
    CorretorId: {type: 'Edm.Int32'},
    ComissionamentoId: {type: 'Edm.Int32'},
    IndiceId: {type: 'Edm.Int32'},
    ContratoPaiId: {type: 'Edm.Int32'},
    Intercambio: {type: 'Edm.Boolean', nullable: false},
    QuantidadeMesesRenovacaoAutomatica: {type: 'Edm.Int32'},
    DataUltimaRenovacao: {type: 'Edm.DateTimeOffset'},
    MetodoPagamento: {type: 'Uniodonto.Domain.Model.Enum.EnumMetodoPagamento', nullable: false},
    CobrancaEmitida: {type: 'Edm.Boolean', nullable: false},
    OperadoraId: {type: 'Edm.Int32'},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'},
    TipoModalidade: {type: 'Uniodonto.Domain.Entities.TipoModalidadeContrato', navigation: true},
    Nucleo: {type: 'Uniodonto.Domain.Entities.Nucleo', navigation: true, referentials: [{property: 'NucleoId', referencedProperty: 'Id'}]},
    Cliente: {type: 'Uniodonto.Domain.Entities.Cliente', navigation: true, referentials: [{property: 'ClienteId', referencedProperty: 'Id'}]},
    OrigemCompra: {type: 'Uniodonto.Domain.Entities.OrigemCompraContrato', navigation: true},
    TipoContratoColetivo: {type: 'Uniodonto.Domain.Entities.TipoContratoColetivo', navigation: true, referentials: [{property: 'TipoContratoColetivoId', referencedProperty: 'Id'}]},
    Reembolso: {type: 'Uniodonto.Domain.Entities.Reembolso', navigation: true, referentials: [{property: 'ReembolsoId', referencedProperty: 'Id'}]},
    Comissionamento: {type: 'Uniodonto.Domain.Entities.Comissionamento', navigation: true, referentials: [{property: 'ComissionamentoId', referencedProperty: 'Id'}]},
    Corretor: {type: 'Uniodonto.Domain.Entities.Corretor', navigation: true, referentials: [{property: 'CorretorId', referencedProperty: 'Id'}]},
    TitularModels: {type: 'Uniodonto.Domain.Entities.Titular', collection: true, navigation: true},
    ProdutosDoContrato: {type: 'Uniodonto.Domain.Entities.ContratoProduto', collection: true, navigation: true},
    DocumentosDoContrato: {type: 'Uniodonto.Domain.Entities.ContratoDocumento', collection: true, navigation: true},
    Negociacoes: {type: 'Uniodonto.Domain.Entities.Negociacao', collection: true, navigation: true},
    ContratoOperadoraRepasses: {type: 'Uniodonto.Domain.Entities.ContratoOperadoraRepasse', collection: true, navigation: true},
    Indice: {type: 'Uniodonto.Domain.Entities.Indice', navigation: true, referentials: [{property: 'IndiceId', referencedProperty: 'Id'}]},
    PreEspelhos: {type: 'Uniodonto.Domain.Entities.PreEspelho', collection: true, navigation: true},
    SubClientes: {type: 'Uniodonto.Domain.Entities.Subcliente', collection: true, navigation: true},
    SubContratos: {type: 'Uniodonto.Domain.Entities.SubContrato', collection: true, navigation: true},
    ContratoProdutoAtosPrecos: {type: 'Uniodonto.Domain.Entities.ContratoProdutoAtoPreco', collection: true, navigation: true},
    Operadora: {type: 'Uniodonto.Domain.Entities.Operadora', navigation: true, referentials: [{property: 'OperadoraId', referencedProperty: 'Id'}]}
  }
} as StructuredTypeConfig<Contrato>;
//#endregion