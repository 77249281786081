//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Lote } from './lote.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const LoteEntityConfig = {
  name: 'Lote',
  keys: [{name: 'Id'}],
  fields: {
    Prefixo: {type: 'Edm.String'},
    StatusProcedimento: {type: 'Uniodonto.Domain.Model.Enum.EnumStatusProcedimento', nullable: false},
    StatusLote: {type: 'Uniodonto.Domain.Model.Enum.EnumStatusLote', nullable: false},
    Auditor: {type: 'Edm.String'},
    StatusAuditoriaAdministrativa: {type: 'Uniodonto.Domain.Model.Enum.EnumStatusAuditoria', nullable: false},
    StatusAuditoriaTenica: {type: 'Uniodonto.Domain.Model.Enum.EnumStatusAuditoria', nullable: false},
    ClinicaId: {type: 'Edm.Int32'},
    DataExecucaoERP: {type: 'Edm.DateTimeOffset', nullable: false},
    DataConclusao: {type: 'Edm.DateTimeOffset'},
    DataEnvio: {type: 'Edm.DateTimeOffset'},
    OperadoraId: {type: 'Edm.Int32'},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'},
    Guias: {type: 'Uniodonto.Domain.Entities.Guia', collection: true, navigation: true},
    BatchIntegrationLogs: {type: 'Uniodonto.Domain.Entities.BatchIntegrationLog', collection: true, navigation: true},
    Clinica: {type: 'Uniodonto.Domain.Entities.Clinica', navigation: true, referentials: [{property: 'ClinicaId', referencedProperty: 'Id'}]},
    Operadora: {type: 'Uniodonto.Domain.Entities.Operadora', navigation: true, referentials: [{property: 'OperadoraId', referencedProperty: 'Id'}]}
  }
} as StructuredTypeConfig<Lote>;
//#endregion