//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Dominio } from './dominio.entity';
//#endregion

export interface DominioDenteAfetado {
  //#region ODataApiGen Properties
  CodigoDente?: string;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  Dominio?: Dominio;
  //#endregion
}