//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { MotivoGlosa } from './motivoglosa.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const MotivoGlosaEntityConfig = {
  name: 'MotivoGlosa',
  keys: [{name: 'Id'}],
  fields: {
    Descricao: {type: 'Edm.String', maxLength: 50},
    OperadoraId: {type: 'Edm.Int32'},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'},
    Operadora: {type: 'Uniodonto.Domain.Entities.Operadora', navigation: true, referentials: [{property: 'OperadoraId', referencedProperty: 'Id'}]}
  }
} as StructuredTypeConfig<MotivoGlosa>;
//#endregion