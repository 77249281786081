//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { DominioDenteAfetado } from './dominiodenteafetado.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const DominioDenteAfetadoEntityConfig = {
  name: 'DominioDenteAfetado',
  keys: [{name: 'Id'}],
  fields: {
    CodigoDente: {type: 'Edm.String'},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'},
    Dominio: {type: 'Uniodonto.Domain.Entities.Dominio', navigation: true}
  }
} as StructuredTypeConfig<DominioDenteAfetado>;
//#endregion