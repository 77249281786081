import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Operadora } from '../Uniodonto/Domain/Entities/operadora.entity';
import { ReajusteContratual } from '../Uniodonto/Domain/Entities/reajustecontratual.entity';
import { SubContratoProduto } from '../Uniodonto/Domain/Entities/subcontratoproduto.entity';
//#endregion

@Injectable()
export class ReajusteContratualV2Service extends ODataEntitySetService<ReajusteContratual> {
  constructor(client: ODataClient) {
    super(client, 'ReajusteContratualV2', 'Uniodonto.Domain.Entities.ReajusteContratual');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public operadora(key: EntityKey<ReajusteContratual>): ODataNavigationPropertyResource<Operadora> { 
    return this.entity(key).navigationProperty<Operadora>('Operadora'); 
  }
  public fetchOperadora(key: EntityKey<ReajusteContratual>, options?: ODataQueryArgumentsOptions<Operadora>) {
    return this.fetchNavigationProperty<Operadora>(
      this.operadora(key), 
      'entity', options) as Observable<ODataEntity<Operadora>>;
  }
  public setOperadoraAsOperadora(key: EntityKey<ReajusteContratual>, target: ODataEntityResource<ODataEntity<Operadora>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.operadora(key).reference()
      .set(target, {etag});
  }
  public unsetOperadoraAsOperadora(key: EntityKey<ReajusteContratual>, {target, etag}: {target?: ODataEntityResource<ODataEntity<Operadora>>, etag?: string} = {}): Observable<any> {
    return this.operadora(key).reference()
      .unset({etag});
  }
  public subContratoProduto(key: EntityKey<ReajusteContratual>): ODataNavigationPropertyResource<SubContratoProduto> { 
    return this.entity(key).navigationProperty<SubContratoProduto>('SubContratoProduto'); 
  }
  public fetchSubContratoProduto(key: EntityKey<ReajusteContratual>, options?: ODataQueryArgumentsOptions<SubContratoProduto>) {
    return this.fetchNavigationProperty<SubContratoProduto>(
      this.subContratoProduto(key), 
      'entity', options) as Observable<ODataEntity<SubContratoProduto>>;
  }
  public setSubContratoProdutoAsSubContratoProduto(key: EntityKey<ReajusteContratual>, target: ODataEntityResource<ODataEntity<SubContratoProduto>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.subContratoProduto(key).reference()
      .set(target, {etag});
  }
  public unsetSubContratoProdutoAsSubContratoProduto(key: EntityKey<ReajusteContratual>, {target, etag}: {target?: ODataEntityResource<ODataEntity<SubContratoProduto>>, etag?: string} = {}): Observable<any> {
    return this.subContratoProduto(key).reference()
      .unset({etag});
  }
  //#endregion
}
