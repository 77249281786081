import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';

import { catchError, map, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { APIResponse } from '../../models';
import { TitularModel, Beneficiario_ListaEnderecoContato, TitularBeneficiarioModel, DependenteDoTitular } from '../../models/entities';
//import { Beneficiario_ListaEnderecoContato } from 'src/app/models/entities/Beneficiario_ListaEnderecoContato';

const httpOptions = {
  header: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})

export class GrupoFamiliarService {
  public log: String

  constructor(
    private http: HttpClient
  ) { }


  BuscaGruposFamiliares(model: any) {
    return this.http.post(
        `${environment.apiUrl}/GrupoFamiliar/buscar-por-contrato-id`, model)
      .pipe(
        tap((resp) => console.log('success', 'Grupo familiar obtido com sucesso!')
        ),
        catchError(this.handleError)
      );
  }

  private handleError(err: HttpErrorResponse) {
    let errorMessage = "";
    if (err.error instanceof ErrorEvent) {
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.error(errorMessage);
    return throwError(errorMessage);
  }
}
