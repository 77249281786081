//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Contato } from './contato.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const ContatoEntityConfig = {
  name: 'Contato',
  keys: [{name: 'Id'}],
  fields: {
    ContatoTipo: {type: 'Edm.String'},
    ContatoResponsavel: {type: 'Edm.String'},
    ContatoValor: {type: 'Edm.String'},
    Cargo: {type: 'Edm.String'},
    Observacao: {type: 'Edm.String'},
    ContatoAutorizaEmail: {type: 'Edm.Boolean', nullable: false},
    ContatoAutorizaSms: {type: 'Edm.Boolean', nullable: false},
    DivulgaCredenciada: {type: 'Edm.Boolean', nullable: false},
    ContatoAtualizadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    Nome: {type: 'Edm.String'},
    Email: {type: 'Edm.String'},
    Telefone: {type: 'Edm.String'},
    Celular: {type: 'Edm.String'},
    Telefone0800: {type: 'Edm.String'},
    ParticipanteId: {type: 'Edm.Int32'},
    NucleoId: {type: 'Edm.Int32'},
    IntegradoERP: {type: 'Edm.Boolean', nullable: false},
    StatusIntegracao: {type: 'Uniodonto.Domain.Model.Enum.EnumStatusIntegracao', nullable: false},
    OperadoraId: {type: 'Edm.Int32'},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'},
    Participante: {type: 'Uniodonto.Domain.Entities.Participante', navigation: true, referentials: [{property: 'ParticipanteId', referencedProperty: 'Id'}]},
    Nucleo: {type: 'Uniodonto.Domain.Entities.Nucleo', navigation: true, referentials: [{property: 'NucleoId', referencedProperty: 'Id'}]},
    Operadora: {type: 'Uniodonto.Domain.Entities.Operadora', navigation: true, referentials: [{property: 'OperadoraId', referencedProperty: 'Id'}]}
  }
} as StructuredTypeConfig<Contato>;
//#endregion