//#region ODataApiGen ODataImports
import {
  SchemaConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { UserIdentityEntityConfig } from './useridentity.entity.config';
//#endregion

//#region ODataApiGen SchemaConfig
export const AccountSchema = {
  namespace: 'Uniodonto.Domain.Entities.Account',
  enums: [],
  entities: [UserIdentityEntityConfig],
  callables: [],
  containers: []
} as SchemaConfig;
//#endregion