import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumStatusLoteJob } from '../Uniodonto/Domain/Model/Enum/enumstatuslotejob.enum';
import { EnumTipoLoteJob } from '../Uniodonto/Domain/Model/Enum/enumtipolotejob.enum';
import { Job } from '../Uniodonto/Domain/Entities/job.entity';
import { StatusJob } from '../Uniodonto/Domain/Entities/statusjob.entity';
//#endregion

@Injectable()
export class JobV2Service extends ODataEntitySetService<Job> {
  constructor(client: ODataClient) {
    super(client, 'JobV2', 'Uniodonto.Domain.Entities.Job');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public statusJob(key: EntityKey<Job>): ODataNavigationPropertyResource<StatusJob> { 
    return this.entity(key).navigationProperty<StatusJob>('StatusJob'); 
  }
  public fetchStatusJob(key: EntityKey<Job>, options?: ODataQueryArgumentsOptions<StatusJob>) {
    return this.fetchNavigationProperty<StatusJob>(
      this.statusJob(key), 
      'entity', options) as Observable<ODataEntity<StatusJob>>;
  }
  public setStatusJobAsStatusJob(key: EntityKey<Job>, target: ODataEntityResource<ODataEntity<StatusJob>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.statusJob(key).reference()
      .set(target, {etag});
  }
  public unsetStatusJobAsStatusJob(key: EntityKey<Job>, {target, etag}: {target?: ODataEntityResource<ODataEntity<StatusJob>>, etag?: string} = {}): Observable<any> {
    return this.statusJob(key).reference()
      .unset({etag});
  }
  //#endregion
}
