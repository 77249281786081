//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumStatusIntegracao } from '../Model/Enum/enumstatusintegracao.enum';
import { Operadora } from './operadora.entity';
import { Participante } from './participante.entity';
import { Nucleo } from './nucleo.entity';
//#endregion

export interface Contato {
  //#region ODataApiGen Properties
  ContatoTipo?: string;
  ContatoResponsavel?: string;
  ContatoValor?: string;
  Cargo?: string;
  Observacao?: string;
  ContatoAutorizaEmail: boolean;
  ContatoAutorizaSms: boolean;
  DivulgaCredenciada: boolean;
  ContatoAtualizadoEm: Date;
  Nome?: string;
  Email?: string;
  Telefone?: string;
  Celular?: string;
  Telefone0800?: string;
  ParticipanteId?: number;
  NucleoId?: number;
  IntegradoERP: boolean;
  StatusIntegracao: EnumStatusIntegracao;
  OperadoraId?: number;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  Participante?: Participante;
  Nucleo?: Nucleo;
  Operadora?: Operadora;
  //#endregion
}