//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AtoOdontologicoFilhos } from './atoodontologicofilhos.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const AtoOdontologicoFilhosEntityConfig = {
  name: 'AtoOdontologicoFilhos',
  keys: [{name: 'Id'}],
  fields: {
    AtoOdontologicoPaiId: {type: 'Edm.Int32'},
    AtoOdontologicoFilhoId: {type: 'Edm.Int32'},
    OperadoraId: {type: 'Edm.Int32'},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'},
    AtoOdontologicoPai: {type: 'Uniodonto.Domain.Entities.AtoOdontologico', navigation: true, referentials: [{property: 'AtoOdontologicoPaiId', referencedProperty: 'Id'}]},
    AtoOdontologicoFilho: {type: 'Uniodonto.Domain.Entities.AtoOdontologico', navigation: true, referentials: [{property: 'AtoOdontologicoFilhoId', referencedProperty: 'Id'}]},
    Operadora: {type: 'Uniodonto.Domain.Entities.Operadora', navigation: true, referentials: [{property: 'OperadoraId', referencedProperty: 'Id'}]}
  }
} as StructuredTypeConfig<AtoOdontologicoFilhos>;
//#endregion