//#region ODataApiGen ODataImports
import {
  NgModule
} from '@angular/core';//#endregion

//#region ODataApiGen Imports
import { ContainerService } from './Default/container.service';
import { ArquivoV2Service } from './Default/arquivov2.service';
import { ArquivoService } from './Default/arquivo.service';
import { ClienteV2Service } from './Default/clientev2.service';
import { ClienteService } from './Default/cliente.service';
import { ContratoProdutoService } from './Default/contratoproduto.service';
import { ContratoProdutoV2Service } from './Default/contratoprodutov2.service';
import { ContratoV2Service } from './Default/contratov2.service';
import { DependenteGrupoFamiliarV2Service } from './Default/dependentegrupofamiliarv2.service';
import { PreEspelhoDetalheService } from './Default/preespelhodetalhe.service';
import { PreEspelhoService } from './Default/preespelho.service';
import { HistoricoAtivacaoInativacaoOperadoraV2Service } from './Default/historicoativacaoinativacaooperadorav2.service';
import { HistoricoConsultaLotePrestadoresRPSV2Service } from './Default/historicoconsultaloteprestadoresrpsv2.service';
import { JobV2Service } from './Default/jobv2.service';
import { LogJobV2Service } from './Default/logjobv2.service';
import { LogTrocaSenhaV2Service } from './Default/logtrocasenhav2.service';
import { OperadoraV2Service } from './Default/operadorav2.service';
import { PeriodoV2Service } from './Default/periodov2.service';
import { ReajusteContratualV2Service } from './Default/reajustecontratualv2.service';
import { StatusJobV2Service } from './Default/statusjobv2.service';
import { SubclienteV2Service } from './Default/subclientev2.service';
import { BeneficiarioV2Service } from './Default/beneficiariov2.service';
import { BeneficiarioService } from './Default/beneficiario.service';
import { CarteiraV2Service } from './Default/carteirav2.service';
import { CarteiraService } from './Default/carteira.service';
import { TitularV2Service } from './Default/titularv2.service';
import { GrupoFamiliarContratoV2Service } from './Default/grupofamiliarcontratov2.service';
import { EnderecoV2Service } from './Default/enderecov2.service';
import { CEPV2Service } from './Default/cepv2.service';
import { CEPService } from './Default/cep.service';
import { BairroV2Service } from './Default/bairrov2.service';
import { BairroService } from './Default/bairro.service';
import { CidadeV2Service } from './Default/cidadev2.service';
import { CidadeService } from './Default/cidade.service';
import { TRMAtoOperadoraV2Service } from './Default/trmatooperadorav2.service';
import { UsuarioOperadoraV2Service } from './Default/usuariooperadorav2.service';
import { GrupoV2Service } from './Default/grupov2.service';
import { GrupoAtoOdontologicoService } from './Default/grupoatoodontologico.service';
import { AtoOdontologicoService } from './Default/atoodontologico.service';
import { TitularService } from './Default/titular.service';
import { ContratoService } from './Default/contrato.service';
import { ComissaoV2Service } from './Default/comissaov2.service';
import { ComissaoService } from './Default/comissao.service';
import { ContratoProdutoAtoPrecoV2Service } from './Default/contratoprodutoatoprecov2.service';
import { DependenteService } from './Default/dependente.service';
import { OrcamentoV2Service } from './Default/orcamentov2.service';
import { AtoOdontologicoV2Service } from './Default/atoodontologicov2.service';
import { DominioV2Service } from './Default/dominiov2.service';
import { ClinicaV2Service } from './Default/clinicav2.service';
import { GuiaV2Service } from './Default/guiav2.service';
import { SubContratoService } from './Default/subcontrato.service';
import { SubContratoProdutoService } from './Default/subcontratoproduto.service';
import { ValorProcedimentoTipoProdutoService } from './Default/valorprocedimentotipoproduto.service';
import { ProcedimentosV2Service } from './Default/procedimentosv2.service';
import { ProcedimentoGlosaV2Service } from './Default/procedimentoglosav2.service';
import { LoteV2Service } from './Default/lotev2.service';
import { ContratoOperadoraRepasseService } from './Default/contratooperadorarepasse.service';
import { ProcedimentoRecursosHistoricoService } from './Default/procedimentorecursoshistorico.service';
import { ProcessamentosRetornoSIBService } from './Default/processamentosretornosib.service';
import { HistoricoNaoIncluidosSIBService } from './Default/historiconaoincluidossib.service';
import { HistoricoRejeitadosSIBService } from './Default/historicorejeitadossib.service';
import { ConsolidadoProcessamentoSIBService } from './Default/consolidadoprocessamentosib.service';
import { DentistaV2Service } from './Default/dentistav2.service';
import { GuiaObservacaoService } from './Default/guiaobservacao.service';
import { IntegracaoEntidadePortalERPLogService } from './Default/integracaoentidadeportalerplog.service';
import { NumberSequenceService } from './Default/numbersequence.service';
import { GuiaLegadoService } from './Default/guialegado.service';
import { ProcedimentoLegadoService } from './Default/procedimentolegado.service';
import { GuiaProcedimentoImagemLegadoService } from './Default/guiaprocedimentoimagemlegado.service';
import { ProfissionalSolicitanteService } from './Default/profissionalsolicitante.service';
import { FaturamentoMovimentacoesService } from './Default/faturamentomovimentacoes.service';
import { PerfilCobrancaService } from './Default/perfilcobranca.service';
import { ProdutosService } from './Default/produtos.service';
import { GrupoFornecedorERPService } from './Default/grupofornecedorerp.service';
import { AvisoV2Service } from './Default/avisov2.service';
import { LinkV2Service } from './Default/linkv2.service';
import { RacaService } from './Default/raca.service';
import { GrauInstrucaoService } from './Default/grauinstrucao.service';
//#endregion

@NgModule({
  providers: [
//#region ODataApiGen Providers
    ContainerService,
    ArquivoV2Service,
    ArquivoService,
    ClienteV2Service,
    ClienteService,
    ContratoProdutoService,
    ContratoProdutoV2Service,
    ContratoV2Service,
    DependenteGrupoFamiliarV2Service,
    PreEspelhoDetalheService,
    PreEspelhoService,
    HistoricoAtivacaoInativacaoOperadoraV2Service,
    HistoricoConsultaLotePrestadoresRPSV2Service,
    JobV2Service,
    LogJobV2Service,
    LogTrocaSenhaV2Service,
    OperadoraV2Service,
    PeriodoV2Service,
    ReajusteContratualV2Service,
    StatusJobV2Service,
    SubclienteV2Service,
    BeneficiarioV2Service,
    BeneficiarioService,
    CarteiraV2Service,
    CarteiraService,
    TitularV2Service,
    GrupoFamiliarContratoV2Service,
    EnderecoV2Service,
    CEPV2Service,
    CEPService,
    BairroV2Service,
    BairroService,
    CidadeV2Service,
    CidadeService,
    TRMAtoOperadoraV2Service,
    UsuarioOperadoraV2Service,
    GrupoV2Service,
    GrupoAtoOdontologicoService,
    AtoOdontologicoService,
    TitularService,
    ContratoService,
    ComissaoV2Service,
    ComissaoService,
    ContratoProdutoAtoPrecoV2Service,
    DependenteService,
    OrcamentoV2Service,
    AtoOdontologicoV2Service,
    DominioV2Service,
    ClinicaV2Service,
    GuiaV2Service,
    SubContratoService,
    SubContratoProdutoService,
    ValorProcedimentoTipoProdutoService,
    ProcedimentosV2Service,
    ProcedimentoGlosaV2Service,
    LoteV2Service,
    ContratoOperadoraRepasseService,
    ProcedimentoRecursosHistoricoService,
    ProcessamentosRetornoSIBService,
    HistoricoNaoIncluidosSIBService,
    HistoricoRejeitadosSIBService,
    ConsolidadoProcessamentoSIBService,
    DentistaV2Service,
    GuiaObservacaoService,
    IntegracaoEntidadePortalERPLogService,
    NumberSequenceService,
    GuiaLegadoService,
    ProcedimentoLegadoService,
    GuiaProcedimentoImagemLegadoService,
    ProfissionalSolicitanteService,
    FaturamentoMovimentacoesService,
    PerfilCobrancaService,
    ProdutosService,
    GrupoFornecedorERPService,
    AvisoV2Service,
    LinkV2Service,
    RacaService,
    GrauInstrucaoService//#endregion
  ]
})
export class uniodontoModule { }