import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class FakeLists {

        public Contratos_ListaClientes: Array<any> = 
            [
                {
                    Id: 1,
                    CNPJ_CPF: "06.260.378/0001-00",
                    Nome: "Inove Tecnologia e Inovacao Empresarial Holding S.A.",
                    Tipo: "Empresarial"
                },
                {
                    Id: 2,
                    CNPJ_CPF: "06.260.399/0001-26",
                    Nome: "Smart System Projetos e Instalacoes LTDA",
                    Tipo: "Empresarial"
                },
                {
                    Id: 3,
                    CNPJ_CPF: "06.260.375/0001-77",
                    Nome: "Equimaq - Equipamentos e Moveis Para Escritorio Eireli",
                    Tipo: "Empresarial"
                },
                {
                    Id: 4,
                    CNPJ_CPF: "06.260.380/0001-80",
                    Nome: "Mattos Leao Gestao e Administracao Imobiliaria LTDA",
                    Tipo: "Empresarial"
                },
                {
                    Id: 5,
                    CNPJ_CPF: "06.260.378/0001-00",
                    Nome: "Inove Tecnologia e Inovacao Empresarial Holding S.A.",
                    Tipo: "Empresarial"
                },
                {
                    Id: 6,
                    CNPJ_CPF: "06.260.399/0001-26",
                    Nome: "Smart System Projetos e Instalacoes LTDA",
                    Tipo: "Empresarial"
                },
                {
                    Id: 7,
                    CNPJ_CPF: "06.260.375/0001-77",
                    Nome: "Equimaq - Equipamentos e Moveis Para Escritorio Eireli",
                    Tipo: "Empresarial"
                },
                {
                    Id: 8,
                    CNPJ_CPF: "06.260.380/0001-80",
                    Nome: "Mattos Leao Gestao e Administracao Imobiliaria LTDA",
                    Tipo: "Empresarial"
                },
                {
                    Id: 9,
                    CNPJ_CPF: "06.260.378/0001-00",
                    Nome: "Inove Tecnologia e Inovacao Empresarial Holding S.A.",
                    Tipo: "Empresarial"
                },
                {
                    Id: 10,
                    CNPJ_CPF: "06.260.399/0001-26",
                    Nome: "Smart System Projetos e Instalacoes LTDA",
                    Tipo: "Empresarial"
                },
                {
                    Id: 11,
                    CNPJ_CPF: "06.260.375/0001-77",
                    Nome: "Equimaq - Equipamentos e Moveis Para Escritorio Eireli",
                    Tipo: "Empresarial"
                },
                {
                    Id: 12,
                    CNPJ_CPF: "06.260.380/0001-80",
                    Nome: "Mattos Leao Gestao e Administracao Imobiliaria LTDA",
                    Tipo: "Empresarial"
                },
                {
                    Id: 11,
                    CNPJ_CPF: "06.260.378/0001-00",
                    Nome: "Inove Tecnologia e Inovacao Empresarial Holding S.A.",
                    Tipo: "Empresarial"
                },
                {
                    Id: 13,
                    CNPJ_CPF: "06.260.399/0001-26",
                    Nome: "Smart System Projetos e Instalacoes LTDA",
                    Tipo: "Empresarial"
                },
                {
                    Id: 14,
                    CNPJ_CPF: "06.260.375/0001-77",
                    Nome: "Equimaq - Equipamentos e Moveis Para Escritorio Eireli",
                    Tipo: "Empresarial"
                },
                {
                    Id: 15,
                    CNPJ_CPF: "06.260.380/0001-80",
                    Nome: "Mattos Leao Gestao e Administracao Imobiliaria LTDA",
                    Tipo: "Empresarial"
                }
        ];
            
        public Contratos_ListaProdutos: Array<any> = [
                {
                    id: 1,
                    produto: "Produto 1",
                    vigencia: "10/10/2020",
                    perfilCarencia: 20,
                    mensalidade: "300,00",
                    taxaInscricao: "110,00",
                    segundaViaCarteirinha: "20,00"
                }
        ]

        public Contratos_ListaDependentes: Array<any> = [
            {
                id: 1,
                cpf: "155.047.028-07",
                nome: "Pedro Miguel da Silva"
            },
            {
                id: 2,
                cpf: "437.068.079-60",
                nome: "José Miguel da Silva"
            },
            {
                id: 3,
                cpf: "064.980.612-07",
                nome: "Sara Miguel da Silva"
            }
        ];

        public Contratos_ListaProdutosTitular: Array<any> = [
            {
                id: 1,
                produto: "Produto 1",
                dtInicio: "15/06/2020",
                dtFim: "",
                motivo: "",
                observacao: ""
            },
            {
                id: 2,
                produto: "Produto 2",
                dtInicio: "01/01/2019",
                dtFim: "15/06/2020",
                motivo: "Motivo 1",
                observacao: "Solicitado pelo cliente em xx/xx/xxxx"

            },
            {
                id: 3,
                produto: "Produto 3",
                dtInicio: "01/01/2015",
                dtFim: "10/01/2019",
                motivo: "Motivo 3",
                observacao: "Renegociação contrato da empresa em xx/xx/xxxx"
            }
        ]

        public Contratos_ListaDependentesTitulares: Array<any> =[
            {
                id: 1,
                nome: "Josué Ejota",
                cpf: "145.569.748-08",
                dtNascimento: "13/04/1990"
            }
        ]

        public Tratamentos_ListaProcedimentos: Array<any> =[
            {
                id: 1,
                data: "27/05/2020",
                procedimento: "Restauração Livre de Metal",
                dentesFaces: "26 (Geral)",
                status: "Realizado",
                dentista: "Dentista 1"
            },
            {
                id: 2,
                data: "15/06/2020",
                procedimento: "Aplicação de Cariostático",
                dentesFaces: "26 (Geral)",
                status: "Em andamento",
                dentista: "Dentista 1"
            },
            {
                id: 3,
                data: "25/06/2020",
                procedimento: "Aparelho de Klammt",
                dentesFaces: "12, 11, 21, 22 (Geral)",
                status: "A Realizar",
                dentista: "Dentista 1"
            }
        ]

        public Faces = {
            "1A": ['I', 'P', 'D', 'V', 'M'],
            "1P": ['O', 'P', 'D', 'V', 'M'],
            "2A": ['I', 'P', 'M', 'V', 'D'],
            "2P": ['O', 'P', 'M', 'V', 'D'],
            "3A": ['I', 'V', 'D', 'L', 'M'],
            "3P": ['O', 'V', 'D', 'L', 'M'],
            "4A": ['I', 'V', 'M', 'L', 'D'],
            "4P": ['O', 'V', 'M', 'L', 'D'],
        }

        public Odontograma: Array<any> = [
            {
                "id": "18",
                "Quadrante": 1,
                "Posicao": "P",
                "Leite": false,
            },
            {
                "id": "17",
                "Quadrante": 1,
                "Posicao": "P",
                "Leite": false
            },
            {
                "id": "16",
                "Quadrante": 1,
                "Posicao": "P",
                "Leite": false
            },
            {
                "id": "15",
                "Quadrante": 1,
                "Posicao": "P",
                "Leite": false
            },
            {
                "id": "14",
                "Quadrante": 1,
                "Posicao": "P",
                "Leite": false
            },
            {
                "id": "13",
                "Quadrante": 1,
                "Posicao": "A",
                "Leite": false
            },
            {
                "id": "12",
                "Quadrante": 1,
                "Posicao": "A",
                "Leite": false
            },
            {
                "id": "11",
                "Quadrante": 1,
                "Posicao": "A",
                "Leite": false
            },
            {
                "id": "21",
                "Quadrante": 2,
                "Posicao": "A",
                "Leite": false
            },
            {
                "id": "22",
                "Quadrante": 2,
                "Posicao": "A",
                "Leite": false
            },
            {
                "id": "23",
                "Quadrante": 2,
                "Posicao": "A",
                "Leite": false
            },
            {
                "id": "24",
                "Quadrante": 2,
                "Posicao": "P",
                "Leite": false
            },
            {
                "id": "25",
                "Quadrante": 2,
                "Posicao": "P",
                "Leite": false
            },
            {
                "id": "26",
                "Quadrante": 2,
                "Posicao": "P",
                "Leite": false
            },
            {
                "id": "27",
                "Quadrante": 2,
                "Posicao": "P",
                "Leite": false
            },
            {
                "id": "28",
                "Quadrante": 2,
                "Posicao": "P",
                "Leite": false
            },
            {
                "id": "55",
                "Quadrante": 1,
                "Posicao": "P",
                "Leite": true
            },
            {
                "id": "54",
                "Quadrante": 1,
                "Posicao": "P",
                "Leite": true
            },
            {
                "id": "53",
                "Quadrante": 1,
                "Posicao": "A",
                "Leite": true
            },
            {
                "id": "52",
                "Quadrante": 1,
                "Posicao": "A",
                "Leite": true
            },
            {
                "id": "51",
                "Quadrante": 1,
                "Posicao": "A",
                "Leite": true
            },
            {
                "id": "61",
                "Quadrante": 2,
                "Posicao": "A",
                "Leite": true
            },
            {
                "id": "62",
                "Quadrante": 2,
                "Posicao": "A",
                "Leite": true
            },
            {
                "id": "63",
                "Quadrante": 2,
                "Posicao": "A",
                "Leite": true
            },
            {
                "id": "64",
                "Quadrante": 2,
                "Posicao": "P",
                "Leite": true
            },
            {
                "id": "65",
                "Quadrante": 2,
                "Posicao": "P",
                "Leite": true
            },
            {
                "id": "48",
                "Quadrante": 3,
                "Posicao": "P",
                "Leite": false
            },
            {
                "id": "47",
                "Quadrante": 3,
                "Posicao": "P",
                "Leite": false
            },
            {
                "id": "46",
                "Quadrante": 3,
                "Posicao": "P",
                "Leite": false
            },
            {
                "id": "45",
                "Quadrante": 3,
                "Posicao": "P",
                "Leite": false
            },
            {
                "id": "44",
                "Quadrante": 3,
                "Posicao": "P",
                "Leite": false
            },
            {
                "id": "43",
                "Quadrante": 3,
                "Posicao": "A",
                "Leite": false
            },
            {
                "id": "42",
                "Quadrante": 3,
                "Posicao": "A",
                "Leite": false
            },
            {
                "id": "41",
                "Quadrante": 3,
                "Posicao": "A",
                "Leite": false
            },
            {
                "id": "31",
                "Quadrante": 4,
                "Posicao": "A",
                "Leite": false
            },
            {
                "id": "32",
                "Quadrante": 4,
                "Posicao": "A",
                "Leite": false
            },
            {
                "id": "33",
                "Quadrante": 4,
                "Posicao": "A",
                "Leite": false
            },
            {
                "id": "34",
                "Quadrante": 4,
                "Posicao": "P",
                "Leite": false
            },
            {
                "id": "35",
                "Quadrante": 4,
                "Posicao": "P",
                "Leite": false
            },
            {
                "id": "36",
                "Quadrante": 4,
                "Posicao": "P",
                "Leite": false
            },
            {
                "id": "37",
                "Quadrante": 4,
                "Posicao": "P",
                "Leite": false
            },
            {
                "id": "38",
                "Quadrante": 4,
                "Posicao": "P",
                "Leite": false
            },
            {
                "id": "85",
                "Quadrante": 3,
                "Posicao": "P",
                "Leite": true
            },
            {
                "id": "84",
                "Quadrante": 3,
                "Posicao": "P",
                "Leite": true
            },
            {
                "id": "83",
                "Quadrante": 3,
                "Posicao": "A",
                "Leite": true
            },
            {
                "id": "82",
                "Quadrante": 3,
                "Posicao": "A",
                "Leite": true
            },
            {
                "id": "81",
                "Quadrante": 3,
                "Posicao": "A",
                "Leite": true
            },
            {
                "id": "71",
                "Quadrante": 4,
                "Posicao": "A",
                "Leite": true
            },
            {
                "id": "72",
                "Quadrante": 3,
                "Posicao": "A",
                "Leite": true
            },
            {
                "id": "73",
                "Quadrante": 3,
                "Posicao": "A",
                "Leite": true
            },
            {
                "id": "74",
                "Quadrante": 3,
                "Posicao": "P",
                "Leite": true
            },
            {
                "id": "75",
                "Quadrante": 3,
                "Posicao": "P",
                "Leite": true
            }
        ];

        public Odontograma_Superiores: Array<any> = [
            {
                "id": "18",
                "img": "/assets/img/odonto/P18.png"
            },
            {
                "id": "17",
                "img": "/assets/img/odonto/P17.png"
            },
            {
                "id": "16",
                "img": "/assets/img/odonto/P16.png"
            },
            {
                "id": "15",
                "img": "/assets/img/odonto/P15.png"
            },
            {
                "id": "14",
                "img": "/assets/img/odonto/P14.png"
            },
            {
                "id": "13",
                "img": "/assets/img/odonto/P13.png"
            },
            {
                "id": "12",
                "img": "/assets/img/odonto/P12.png"
            },
            {
                "id": "11",
                "img": "/assets/img/odonto/P11.png"
            },
            {
                "id": "21",
                "img": "/assets/img/odonto/P21.png"
            },
            {
                "id": "22",
                "img": "/assets/img/odonto/P22.png"
            },
            {
                "id": "23",
                "img": "/assets/img/odonto/P23.png"
            },
            {
                "id": "24",
                "img": "/assets/img/odonto/P24.png"
            },
            {
                "id": "25",
                "img": "/assets/img/odonto/P25.png"
            },
            {
                "id": "26",
                "img": "/assets/img/odonto/P26.png"
            },
            {
                "id": "27",
                "img": "/assets/img/odonto/P27.png"
            },
            {
                "id": "28",
                "img": "/assets/img/odonto/P28.png"
            }
        ];

        public Odontograma_Superiores_L: Array<any> = [ 
            {
                "id": "55",
                "img": "/assets/img/odonto/P28.png"
            },
            {
                "id": "54",
                "img": "/assets/img/odonto/P28.png"
            },
            {
                "id": "53",
                "img": "/assets/img/odonto/P28.png"
            },
            {
                "id": "52",
                "img": "/assets/img/odonto/P28.png"
            },
            {
                "id": "51",
                "img": "/assets/img/odonto/P28.png"
            },
            {
                "id": "61",
                "img": "/assets/img/odonto/P28.png"
            },
            {
                "id": "62",
                "img": "/assets/img/odonto/P28.png"
            },
            {
                "id": "63",
                "img": "/assets/img/odonto/P28.png"
            },
            {
                "id": "64",
                "img": "/assets/img/odonto/P28.png"
            },
            {
                "id": "65",
                "img": "/assets/img/odonto/P28.png"
            }
        ];

        public Odontograma_Inferiores: Array<any> = [
            {
                "id": "48",
                "img": "/assets/img/odonto/P48.png"
            },
            {
                "id": "47",
                "img": "/assets/img/odonto/P47.png"
            },
            {
                "id": "46",
                "img": "/assets/img/odonto/P46.png"
            },
            {
                "id": "45",
                "img": "/assets/img/odonto/P45.png"
            },
            {
                "id": "44",
                "img": "/assets/img/odonto/P44.png"
            },
            {
                "id": "43",
                "img": "/assets/img/odonto/P43.png"
            },
            {
                "id": "42",
                "img": "/assets/img/odonto/P42.png"
            },
            {
                "id": "41",
                "img": "/assets/img/odonto/P41.png"
            },
            {
                "id": "31",
                "img": "/assets/img/odonto/P31.png"
            },
            {
                "id": "32",
                "img": "/assets/img/odonto/P32.png"
            },
            {
                "id": "33",
                "img": "/assets/img/odonto/P33.png"
            },
            {
                "id": "34",
                "img": "/assets/img/odonto/P34.png"
            },
            {
                "id": "35",
                "img": "/assets/img/odonto/P35.png"
            },
            {
                "id": "36",
                "img": "/assets/img/odonto/P36.png"
            },
            {
                "id": "37",
                "img": "/assets/img/odonto/P37.png"
            },
            {
                "id": "38",
                "img": "/assets/img/odonto/P38.png"
            }
        ];

        public Odontograma_Inferiores_L: Array<any> = [
            {
                "id": "85",
                "img": "/assets/img/odonto/P28.png"
            },
            {
                "id": "84",
                "img": "/assets/img/odonto/P28.png"
            },
            {
                "id": "83",
                "img": "/assets/img/odonto/P28.png"
            },
            {
                "id": "82",
                "img": "/assets/img/odonto/P28.png"
            },
            {
                "id": "81",
                "img": "/assets/img/odonto/P28.png"
            },
            {
                "id": "71",
                "img": "/assets/img/odonto/P28.png"
            },
            {
                "id": "72",
                "img": "/assets/img/odonto/P28.png"
            },
            {
                "id": "73",
                "img": "/assets/img/odonto/P28.png"
            },
            {
                "id": "74",
                "img": "/assets/img/odonto/P28.png"
            },
            {
                "id": "75",
                "img": "/assets/img/odonto/P28.png"
            }
        ];

        public Lista_Dentes: Array<any> = [
            { item_id: 18, item_text: '18' },
            { item_id: 17, item_text: '17' },
            { item_id: 16, item_text: '16' },
            { item_id: 15, item_text: '15' },
            { item_id: 14, item_text: '14' },
            { item_id: 13, item_text: '13' },
            { item_id: 12, item_text: '12' },
            { item_id: 11, item_text: '11' },
            { item_id: 21, item_text: '21' },
            { item_id: 22, item_text: '22' },
            { item_id: 23, item_text: '23' },
            { item_id: 24, item_text: '24' },
            { item_id: 25, item_text: '25' },
            { item_id: 26, item_text: '26' },
            { item_id: 27, item_text: '27' },
            { item_id: 28, item_text: '28' },
            { item_id: 48, item_text: '48' },
            { item_id: 47, item_text: '47' },
            { item_id: 46, item_text: '46' },
            { item_id: 45, item_text: '45' },
            { item_id: 44, item_text: '44' },
            { item_id: 43, item_text: '43' },
            { item_id: 42, item_text: '42' },
            { item_id: 41, item_text: '41' },
            { item_id: 31, item_text: '31' },
            { item_id: 32, item_text: '32' },
            { item_id: 33, item_text: '33' },
            { item_id: 34, item_text: '34' },
            { item_id: 35, item_text: '35' },
            { item_id: 36, item_text: '36' },
            { item_id: 37, item_text: '37' },
            { item_id: 38, item_text: '38' },
        ];

        public Lista_Faces: Array<any> = [
            { item_id: 1, item_text: 'Geral' },
            { item_id: 2, item_text: 'C' },
            { item_id: 3, item_text: 'D' },
            { item_id: 4, item_text: 'L/P' },
            { item_id: 5, item_text: 'M' },
            { item_id: 6, item_text: 'O/I' },
            { item_id: 7, item_text: 'V' }
        ]

        public Search_Pessoas: Array<any> = [
            {
                id: 1,
                nome: "Pedro José Miguel Da Silva"
            },
            {
                id: 2,
                nome: "Maria Antonia Cruz Qipe"
            },
            {
                id: 3,
                nome: "Lucas da Galileia"
            },
            {
                id: 4,
                nome: "João Marcos Oliveira"
            },
            {
                id: 5,
                nome: "Noé Hebreu Cananeu"
            },
            {
                id: 6,
                nome: "Pedro José Miguel Da Silva"
            }
        ];

        public ListaDeProcedimentos : Array<any> = [
            {
                id: 1,
                categoria: 'Consulta',
                codTISS: '81000030',
                especialidade: 'Consulta odontológica',
                uso: 57,
                trm: 6,
                dom: 'ASAI'
            },
            {
                id: 2,
                categoria: 'Consulta',
                codTISS: '81000049',
                especialidade: 'Consulta odontológica de Urgência',
                uso: 74,
                trm: 0,
                dom: 'ASAI'
            },
            {
                id: 3,
                categoria: 'Consulta',
                codTISS: '81000057',
                especialidade: 'Consulta odontológica de Urgência 24 hs',
                uso: 179,
                trm: 0,
                dom: 'ASAI'
            },
            {
                id: 4,
                categoria: 'Consulta',
                codTISS: '81000065',
                especialidade: 'Consulta odontológica inicial',
                uso: 57,
                trm: 6,
                dom: 'ASAI'
            },
            {
                id: 5,
                categoria: 'Consulta',
                codTISS: '81000073',
                especialidade: 'Consulta odontológica para avaliação técnica de auditoria',
                uso: 57,
                trm: 0,
                dom: 'ASAI'
            },
            {
                id: 6,
                categoria: 'Consulta',
                codTISS: '87000148',
                especialidade: 'Estabilização por meio de contenção física e/ou mecânica em pacientes com necessidades especiais em odontologia',
                uso: 57,
                trm: 0,
                dom: 'ASAI'
            },
            {
                id: 7,
                categoria: 'Consulta',
                codTISS: '87000149',
                especialidade: 'TESTE 7',
                uso: 57,
                trm: 0,
                dom: 'ASAI'
            },
        ]

        public ListaGuiasAtendimento : Array<any> = [
            {
                id: 903174,
                PRESTADOR_OPERADORA: 'FREDERICO WINDLIN GARCIA',
                DATA_EMISSAO: '06/07/2020',
                DATA_SOLICITACAO: '06/07/2020',
                DATA_APROVACAO: '',
                DATA_CONCLUSAO: '',
                STATUS_GUIA: 'APROVAÇÃO SOLICITADA',
                TITULAR: '034-0146-0000032 - PAULO ALVES CORREA',
                DEPENDENTE: 'STELLA CAROLINA DE ASSIS CORREA'
            },
            {
                id: 903175,
                PRESTADOR_OPERADORA: 'VLADIMIR ALVES TEIXEIRA',
                DATA_EMISSAO: '06/07/2020',
                DATA_SOLICITACAO: '06/07/2020',
                DATA_APROVACAO: '',
                DATA_CONCLUSAO: '',
                STATUS_GUIA: 'APROVAÇÃO SOLICITADA',
                TITULAR: '008-0101-0000097 - ANDERSON DA SILVA',
                DEPENDENTE: ''
            },
            {
                id: 903176,
                PRESTADOR_OPERADORA: 'REGINA APARECIDA OLIVEIRA',
                DATA_EMISSAO: '06/07/2020',
                DATA_SOLICITACAO: '06/07/2020',
                DATA_APROVACAO: '',
                DATA_CONCLUSAO: '',
                STATUS_GUIA: 'APROVAÇÃO SOLICITADA',
                TITULAR: '008-2046-0006146 - DANILO PIRES OLIVEIRA',
                DEPENDENTE: 'LUIZ SEBASTIÃO DE OLIVEIRA'
            },
            {
                id: 776547,
                PRESTADOR_OPERADORA: 'GUSTAVO GALATI REGO',
                DATA_EMISSAO: '06/07/2020',
                DATA_SOLICITACAO: '06/07/2020',
                DATA_APROVACAO: '',
                DATA_CONCLUSAO: '',
                STATUS_GUIA: 'APROVAÇÃO SOLICITADA',
                TITULAR: '008-3094-0004784 - SUELI GONÇALVEZ',
                DEPENDENTE: 'ANDRE JESUS SILVA'
            },
            {
                id: 897654,
                PRESTADOR_OPERADORA: 'GUSTAVO GALATI REGO',
                DATA_EMISSAO: '06/07/2020',
                DATA_SOLICITACAO: '06/07/2020',
                DATA_APROVACAO: '',
                DATA_CONCLUSAO: '',
                STATUS_GUIA: 'APROVAÇÃO SOLICITADA',
                TITULAR: '008-7464-0009901 - JOSÉ PEDRO ALVAREZ',
                DEPENDENTE: ''
            },
            {
                id: 903174,
                PRESTADOR_OPERADORA: 'FREDERICO WINDLIN GARCIA',
                DATA_EMISSAO: '06/07/2020',
                DATA_SOLICITACAO: '06/07/2020',
                DATA_APROVACAO: '',
                DATA_CONCLUSAO: '',
                STATUS_GUIA: 'APROVAÇÃO SOLICITADA',
                TITULAR: '034-0146-0000032 - PAULO ALVES CORREA',
                DEPENDENTE: 'STELLA CAROLINA DE ASSIS CORREA'
            },
            {
                id: 903175,
                PRESTADOR_OPERADORA: 'VLADIMIR ALVES TEIXEIRA',
                DATA_EMISSAO: '06/07/2020',
                DATA_SOLICITACAO: '06/07/2020',
                DATA_APROVACAO: '',
                DATA_CONCLUSAO: '',
                STATUS_GUIA: 'APROVAÇÃO SOLICITADA',
                TITULAR: '008-0101-0000097 - ANDERSON DA SILVA',
                DEPENDENTE: ''
            },
            {
                id: 903176,
                PRESTADOR_OPERADORA: 'REGINA APARECIDA OLIVEIRA',
                DATA_EMISSAO: '06/07/2020',
                DATA_SOLICITACAO: '06/07/2020',
                DATA_APROVACAO: '',
                DATA_CONCLUSAO: '',
                STATUS_GUIA: 'APROVAÇÃO SOLICITADA',
                TITULAR: '008-2046-0006146 - DANILO PIRES OLIVEIRA',
                DEPENDENTE: 'LUIZ SEBASTIÃO DE OLIVEIRA'
            },
            {
                id: 776547,
                PRESTADOR_OPERADORA: 'GUSTAVO GALATI REGO',
                DATA_EMISSAO: '06/07/2020',
                DATA_SOLICITACAO: '06/07/2020',
                DATA_APROVACAO: '',
                DATA_CONCLUSAO: '',
                STATUS_GUIA: 'APROVAÇÃO SOLICITADA',
                TITULAR: '008-3094-0004784 - SUELI GONÇALVEZ',
                DEPENDENTE: 'ANDRE JESUS SILVA'
            },
            {
                id: 897654,
                PRESTADOR_OPERADORA: 'GUSTAVO GALATI REGO',
                DATA_EMISSAO: '06/07/2020',
                DATA_SOLICITACAO: '06/07/2020',
                DATA_APROVACAO: '',
                DATA_CONCLUSAO: '',
                STATUS_GUIA: 'APROVAÇÃO SOLICITADA',
                TITULAR: '008-7464-0009901 - JOSÉ PEDRO ALVAREZ',
                DEPENDENTE: ''
            },
            {
                id: 903174,
                PRESTADOR_OPERADORA: 'FREDERICO WINDLIN GARCIA',
                DATA_EMISSAO: '06/07/2020',
                DATA_SOLICITACAO: '06/07/2020',
                DATA_APROVACAO: '',
                DATA_CONCLUSAO: '',
                STATUS_GUIA: 'APROVAÇÃO SOLICITADA',
                TITULAR: '034-0146-0000032 - PAULO ALVES CORREA',
                DEPENDENTE: 'STELLA CAROLINA DE ASSIS CORREA'
            },
            {
                id: 903175,
                PRESTADOR_OPERADORA: 'VLADIMIR ALVES TEIXEIRA',
                DATA_EMISSAO: '06/07/2020',
                DATA_SOLICITACAO: '06/07/2020',
                DATA_APROVACAO: '',
                DATA_CONCLUSAO: '',
                STATUS_GUIA: 'APROVAÇÃO SOLICITADA',
                TITULAR: '008-0101-0000097 - ANDERSON DA SILVA',
                DEPENDENTE: ''
            },
            {
                id: 903176,
                PRESTADOR_OPERADORA: 'REGINA APARECIDA OLIVEIRA',
                DATA_EMISSAO: '06/07/2020',
                DATA_SOLICITACAO: '06/07/2020',
                DATA_APROVACAO: '',
                DATA_CONCLUSAO: '',
                STATUS_GUIA: 'APROVAÇÃO SOLICITADA',
                TITULAR: '008-2046-0006146 - DANILO PIRES OLIVEIRA',
                DEPENDENTE: 'LUIZ SEBASTIÃO DE OLIVEIRA'
            },
            {
                id: 776547,
                PRESTADOR_OPERADORA: 'GUSTAVO GALATI REGO',
                DATA_EMISSAO: '06/07/2020',
                DATA_SOLICITACAO: '06/07/2020',
                DATA_APROVACAO: '',
                DATA_CONCLUSAO: '',
                STATUS_GUIA: 'APROVAÇÃO SOLICITADA',
                TITULAR: '008-3094-0004784 - SUELI GONÇALVEZ',
                DEPENDENTE: 'ANDRE JESUS SILVA'
            },
            {
                id: 897654,
                PRESTADOR_OPERADORA: 'GUSTAVO GALATI REGO',
                DATA_EMISSAO: '06/07/2020',
                DATA_SOLICITACAO: '06/07/2020',
                DATA_APROVACAO: '',
                DATA_CONCLUSAO: '',
                STATUS_GUIA: 'APROVAÇÃO SOLICITADA',
                TITULAR: '008-7464-0009901 - JOSÉ PEDRO ALVAREZ',
                DEPENDENTE: ''
            },
            {
                id: 903174,
                PRESTADOR_OPERADORA: 'FREDERICO WINDLIN GARCIA',
                DATA_EMISSAO: '06/07/2020',
                DATA_SOLICITACAO: '06/07/2020',
                DATA_APROVACAO: '',
                DATA_CONCLUSAO: '',
                STATUS_GUIA: 'APROVAÇÃO SOLICITADA',
                TITULAR: '034-0146-0000032 - PAULO ALVES CORREA',
                DEPENDENTE: 'STELLA CAROLINA DE ASSIS CORREA'
            },
            {
                id: 903175,
                PRESTADOR_OPERADORA: 'VLADIMIR ALVES TEIXEIRA',
                DATA_EMISSAO: '06/07/2020',
                DATA_SOLICITACAO: '06/07/2020',
                DATA_APROVACAO: '',
                DATA_CONCLUSAO: '',
                STATUS_GUIA: 'APROVAÇÃO SOLICITADA',
                TITULAR: '008-0101-0000097 - ANDERSON DA SILVA',
                DEPENDENTE: ''
            },
            {
                id: 903176,
                PRESTADOR_OPERADORA: 'REGINA APARECIDA OLIVEIRA',
                DATA_EMISSAO: '06/07/2020',
                DATA_SOLICITACAO: '06/07/2020',
                DATA_APROVACAO: '',
                DATA_CONCLUSAO: '',
                STATUS_GUIA: 'APROVAÇÃO SOLICITADA',
                TITULAR: '008-2046-0006146 - DANILO PIRES OLIVEIRA',
                DEPENDENTE: 'LUIZ SEBASTIÃO DE OLIVEIRA'
            },
            {
                id: 776547,
                PRESTADOR_OPERADORA: 'GUSTAVO GALATI REGO',
                DATA_EMISSAO: '06/07/2020',
                DATA_SOLICITACAO: '06/07/2020',
                DATA_APROVACAO: '',
                DATA_CONCLUSAO: '',
                STATUS_GUIA: 'APROVAÇÃO SOLICITADA',
                TITULAR: '008-3094-0004784 - SUELI GONÇALVEZ',
                DEPENDENTE: 'ANDRE JESUS SILVA'
            },
            {
                id: 897654,
                PRESTADOR_OPERADORA: 'GUSTAVO GALATI REGO',
                DATA_EMISSAO: '06/07/2020',
                DATA_SOLICITACAO: '06/07/2020',
                DATA_APROVACAO: '',
                DATA_CONCLUSAO: '',
                STATUS_GUIA: 'APROVAÇÃO SOLICITADA',
                TITULAR: '008-7464-0009901 - JOSÉ PEDRO ALVAREZ',
                DEPENDENTE: ''
            },
            {
                id: 903174,
                PRESTADOR_OPERADORA: 'FREDERICO WINDLIN GARCIA',
                DATA_EMISSAO: '06/07/2020',
                DATA_SOLICITACAO: '06/07/2020',
                DATA_APROVACAO: '',
                DATA_CONCLUSAO: '',
                STATUS_GUIA: 'APROVAÇÃO SOLICITADA',
                TITULAR: '034-0146-0000032 - PAULO ALVES CORREA',
                DEPENDENTE: 'STELLA CAROLINA DE ASSIS CORREA'
            },
            {
                id: 903175,
                PRESTADOR_OPERADORA: 'VLADIMIR ALVES TEIXEIRA',
                DATA_EMISSAO: '06/07/2020',
                DATA_SOLICITACAO: '06/07/2020',
                DATA_APROVACAO: '',
                DATA_CONCLUSAO: '',
                STATUS_GUIA: 'APROVAÇÃO SOLICITADA',
                TITULAR: '008-0101-0000097 - ANDERSON DA SILVA',
                DEPENDENTE: ''
            },
            {
                id: 903176,
                PRESTADOR_OPERADORA: 'REGINA APARECIDA OLIVEIRA',
                DATA_EMISSAO: '06/07/2020',
                DATA_SOLICITACAO: '06/07/2020',
                DATA_APROVACAO: '',
                DATA_CONCLUSAO: '',
                STATUS_GUIA: 'APROVAÇÃO SOLICITADA',
                TITULAR: '008-2046-0006146 - DANILO PIRES OLIVEIRA',
                DEPENDENTE: 'LUIZ SEBASTIÃO DE OLIVEIRA'
            },
            {
                id: 776547,
                PRESTADOR_OPERADORA: 'GUSTAVO GALATI REGO',
                DATA_EMISSAO: '06/07/2020',
                DATA_SOLICITACAO: '06/07/2020',
                DATA_APROVACAO: '',
                DATA_CONCLUSAO: '',
                STATUS_GUIA: 'APROVAÇÃO SOLICITADA',
                TITULAR: '008-3094-0004784 - SUELI GONÇALVEZ',
                DEPENDENTE: 'ANDRE JESUS SILVA'
            },
            {
                id: 897654,
                PRESTADOR_OPERADORA: 'GUSTAVO GALATI REGO',
                DATA_EMISSAO: '06/07/2020',
                DATA_SOLICITACAO: '06/07/2020',
                DATA_APROVACAO: '',
                DATA_CONCLUSAO: '',
                STATUS_GUIA: 'APROVAÇÃO SOLICITADA',
                TITULAR: '008-7464-0009901 - JOSÉ PEDRO ALVAREZ',
                DEPENDENTE: ''
            },
            {
                id: 903174,
                PRESTADOR_OPERADORA: 'FREDERICO WINDLIN GARCIA',
                DATA_EMISSAO: '06/07/2020',
                DATA_SOLICITACAO: '06/07/2020',
                DATA_APROVACAO: '',
                DATA_CONCLUSAO: '',
                STATUS_GUIA: 'APROVAÇÃO SOLICITADA',
                TITULAR: '034-0146-0000032 - PAULO ALVES CORREA',
                DEPENDENTE: 'STELLA CAROLINA DE ASSIS CORREA'
            },
            {
                id: 903175,
                PRESTADOR_OPERADORA: 'VLADIMIR ALVES TEIXEIRA',
                DATA_EMISSAO: '06/07/2020',
                DATA_SOLICITACAO: '06/07/2020',
                DATA_APROVACAO: '',
                DATA_CONCLUSAO: '',
                STATUS_GUIA: 'APROVAÇÃO SOLICITADA',
                TITULAR: '008-0101-0000097 - ANDERSON DA SILVA',
                DEPENDENTE: ''
            },
            {
                id: 903176,
                PRESTADOR_OPERADORA: 'REGINA APARECIDA OLIVEIRA',
                DATA_EMISSAO: '06/07/2020',
                DATA_SOLICITACAO: '06/07/2020',
                DATA_APROVACAO: '',
                DATA_CONCLUSAO: '',
                STATUS_GUIA: 'APROVAÇÃO SOLICITADA',
                TITULAR: '008-2046-0006146 - DANILO PIRES OLIVEIRA',
                DEPENDENTE: 'LUIZ SEBASTIÃO DE OLIVEIRA'
            },
            {
                id: 776547,
                PRESTADOR_OPERADORA: 'GUSTAVO GALATI REGO',
                DATA_EMISSAO: '06/07/2020',
                DATA_SOLICITACAO: '06/07/2020',
                DATA_APROVACAO: '',
                DATA_CONCLUSAO: '',
                STATUS_GUIA: 'APROVAÇÃO SOLICITADA',
                TITULAR: '008-3094-0004784 - SUELI GONÇALVEZ',
                DEPENDENTE: 'ANDRE JESUS SILVA'
            },
            {
                id: 897654,
                PRESTADOR_OPERADORA: 'GUSTAVO GALATI REGO',
                DATA_EMISSAO: '06/07/2020',
                DATA_SOLICITACAO: '06/07/2020',
                DATA_APROVACAO: '',
                DATA_CONCLUSAO: '',
                STATUS_GUIA: 'APROVAÇÃO SOLICITADA',
                TITULAR: '008-7464-0009901 - JOSÉ PEDRO ALVAREZ',
                DEPENDENTE: ''
            },
            {
                id: 903174,
                PRESTADOR_OPERADORA: 'FREDERICO WINDLIN GARCIA',
                DATA_EMISSAO: '06/07/2020',
                DATA_SOLICITACAO: '06/07/2020',
                DATA_APROVACAO: '',
                DATA_CONCLUSAO: '',
                STATUS_GUIA: 'APROVAÇÃO SOLICITADA',
                TITULAR: '034-0146-0000032 - PAULO ALVES CORREA',
                DEPENDENTE: 'STELLA CAROLINA DE ASSIS CORREA'
            },
            {
                id: 903175,
                PRESTADOR_OPERADORA: 'VLADIMIR ALVES TEIXEIRA',
                DATA_EMISSAO: '06/07/2020',
                DATA_SOLICITACAO: '06/07/2020',
                DATA_APROVACAO: '',
                DATA_CONCLUSAO: '',
                STATUS_GUIA: 'APROVAÇÃO SOLICITADA',
                TITULAR: '008-0101-0000097 - ANDERSON DA SILVA',
                DEPENDENTE: ''
            },
            {
                id: 903176,
                PRESTADOR_OPERADORA: 'REGINA APARECIDA OLIVEIRA',
                DATA_EMISSAO: '06/07/2020',
                DATA_SOLICITACAO: '06/07/2020',
                DATA_APROVACAO: '',
                DATA_CONCLUSAO: '',
                STATUS_GUIA: 'APROVAÇÃO SOLICITADA',
                TITULAR: '008-2046-0006146 - DANILO PIRES OLIVEIRA',
                DEPENDENTE: 'LUIZ SEBASTIÃO DE OLIVEIRA'
            },
            {
                id: 776547,
                PRESTADOR_OPERADORA: 'GUSTAVO GALATI REGO',
                DATA_EMISSAO: '06/07/2020',
                DATA_SOLICITACAO: '06/07/2020',
                DATA_APROVACAO: '',
                DATA_CONCLUSAO: '',
                STATUS_GUIA: 'APROVAÇÃO SOLICITADA',
                TITULAR: '008-3094-0004784 - SUELI GONÇALVEZ',
                DEPENDENTE: 'ANDRE JESUS SILVA'
            },
            {
                id: 897654,
                PRESTADOR_OPERADORA: 'GUSTAVO GALATI REGO',
                DATA_EMISSAO: '06/07/2020',
                DATA_SOLICITACAO: '06/07/2020',
                DATA_APROVACAO: '',
                DATA_CONCLUSAO: '',
                STATUS_GUIA: 'APROVAÇÃO SOLICITADA',
                TITULAR: '008-7464-0009901 - JOSÉ PEDRO ALVAREZ',
                DEPENDENTE: ''
            },
            {
                id: 903174,
                PRESTADOR_OPERADORA: 'FREDERICO WINDLIN GARCIA',
                DATA_EMISSAO: '06/07/2020',
                DATA_SOLICITACAO: '06/07/2020',
                DATA_APROVACAO: '',
                DATA_CONCLUSAO: '',
                STATUS_GUIA: 'APROVAÇÃO SOLICITADA',
                TITULAR: '034-0146-0000032 - PAULO ALVES CORREA',
                DEPENDENTE: 'STELLA CAROLINA DE ASSIS CORREA'
            },
            {
                id: 903175,
                PRESTADOR_OPERADORA: 'VLADIMIR ALVES TEIXEIRA',
                DATA_EMISSAO: '06/07/2020',
                DATA_SOLICITACAO: '06/07/2020',
                DATA_APROVACAO: '',
                DATA_CONCLUSAO: '',
                STATUS_GUIA: 'APROVAÇÃO SOLICITADA',
                TITULAR: '008-0101-0000097 - ANDERSON DA SILVA',
                DEPENDENTE: ''
            },
            {
                id: 903176,
                PRESTADOR_OPERADORA: 'REGINA APARECIDA OLIVEIRA',
                DATA_EMISSAO: '06/07/2020',
                DATA_SOLICITACAO: '06/07/2020',
                DATA_APROVACAO: '',
                DATA_CONCLUSAO: '',
                STATUS_GUIA: 'APROVAÇÃO SOLICITADA',
                TITULAR: '008-2046-0006146 - DANILO PIRES OLIVEIRA',
                DEPENDENTE: 'LUIZ SEBASTIÃO DE OLIVEIRA'
            },
            {
                id: 776547,
                PRESTADOR_OPERADORA: 'GUSTAVO GALATI REGO',
                DATA_EMISSAO: '06/07/2020',
                DATA_SOLICITACAO: '06/07/2020',
                DATA_APROVACAO: '',
                DATA_CONCLUSAO: '',
                STATUS_GUIA: 'APROVAÇÃO SOLICITADA',
                TITULAR: '008-3094-0004784 - SUELI GONÇALVEZ',
                DEPENDENTE: 'ANDRE JESUS SILVA'
            },
            {
                id: 897654,
                PRESTADOR_OPERADORA: 'GUSTAVO GALATI REGO',
                DATA_EMISSAO: '06/07/2020',
                DATA_SOLICITACAO: '06/07/2020',
                DATA_APROVACAO: '',
                DATA_CONCLUSAO: '',
                STATUS_GUIA: 'APROVAÇÃO SOLICITADA',
                TITULAR: '008-7464-0009901 - JOSÉ PEDRO ALVAREZ',
                DEPENDENTE: ''
            },
            {
                id: 903174,
                PRESTADOR_OPERADORA: 'FREDERICO WINDLIN GARCIA',
                DATA_EMISSAO: '06/07/2020',
                DATA_SOLICITACAO: '06/07/2020',
                DATA_APROVACAO: '',
                DATA_CONCLUSAO: '',
                STATUS_GUIA: 'APROVAÇÃO SOLICITADA',
                TITULAR: '034-0146-0000032 - PAULO ALVES CORREA',
                DEPENDENTE: 'STELLA CAROLINA DE ASSIS CORREA'
            },
            {
                id: 903175,
                PRESTADOR_OPERADORA: 'VLADIMIR ALVES TEIXEIRA',
                DATA_EMISSAO: '06/07/2020',
                DATA_SOLICITACAO: '06/07/2020',
                DATA_APROVACAO: '',
                DATA_CONCLUSAO: '',
                STATUS_GUIA: 'APROVAÇÃO SOLICITADA',
                TITULAR: '008-0101-0000097 - ANDERSON DA SILVA',
                DEPENDENTE: ''
            },
            {
                id: 903176,
                PRESTADOR_OPERADORA: 'REGINA APARECIDA OLIVEIRA',
                DATA_EMISSAO: '06/07/2020',
                DATA_SOLICITACAO: '06/07/2020',
                DATA_APROVACAO: '',
                DATA_CONCLUSAO: '',
                STATUS_GUIA: 'APROVAÇÃO SOLICITADA',
                TITULAR: '008-2046-0006146 - DANILO PIRES OLIVEIRA',
                DEPENDENTE: 'LUIZ SEBASTIÃO DE OLIVEIRA'
            },
            {
                id: 776547,
                PRESTADOR_OPERADORA: 'GUSTAVO GALATI REGO',
                DATA_EMISSAO: '06/07/2020',
                DATA_SOLICITACAO: '06/07/2020',
                DATA_APROVACAO: '',
                DATA_CONCLUSAO: '',
                STATUS_GUIA: 'APROVAÇÃO SOLICITADA',
                TITULAR: '008-3094-0004784 - SUELI GONÇALVEZ',
                DEPENDENTE: 'ANDRE JESUS SILVA'
            },
            {
                id: 897654,
                PRESTADOR_OPERADORA: 'GUSTAVO GALATI REGO',
                DATA_EMISSAO: '06/07/2020',
                DATA_SOLICITACAO: '06/07/2020',
                DATA_APROVACAO: '',
                DATA_CONCLUSAO: '',
                STATUS_GUIA: 'APROVAÇÃO SOLICITADA',
                TITULAR: '008-7464-0009901 - JOSÉ PEDRO ALVAREZ',
                DEPENDENTE: ''
            },
            {
                id: 903174,
                PRESTADOR_OPERADORA: 'FREDERICO WINDLIN GARCIA',
                DATA_EMISSAO: '06/07/2020',
                DATA_SOLICITACAO: '06/07/2020',
                DATA_APROVACAO: '',
                DATA_CONCLUSAO: '',
                STATUS_GUIA: 'APROVAÇÃO SOLICITADA',
                TITULAR: '034-0146-0000032 - PAULO ALVES CORREA',
                DEPENDENTE: 'STELLA CAROLINA DE ASSIS CORREA'
            },
            {
                id: 903175,
                PRESTADOR_OPERADORA: 'VLADIMIR ALVES TEIXEIRA',
                DATA_EMISSAO: '06/07/2020',
                DATA_SOLICITACAO: '06/07/2020',
                DATA_APROVACAO: '',
                DATA_CONCLUSAO: '',
                STATUS_GUIA: 'APROVAÇÃO SOLICITADA',
                TITULAR: '008-0101-0000097 - ANDERSON DA SILVA',
                DEPENDENTE: ''
            },
            {
                id: 903176,
                PRESTADOR_OPERADORA: 'REGINA APARECIDA OLIVEIRA',
                DATA_EMISSAO: '06/07/2020',
                DATA_SOLICITACAO: '06/07/2020',
                DATA_APROVACAO: '',
                DATA_CONCLUSAO: '',
                STATUS_GUIA: 'APROVAÇÃO SOLICITADA',
                TITULAR: '008-2046-0006146 - DANILO PIRES OLIVEIRA',
                DEPENDENTE: 'LUIZ SEBASTIÃO DE OLIVEIRA'
            },
            {
                id: 776547,
                PRESTADOR_OPERADORA: 'GUSTAVO GALATI REGO',
                DATA_EMISSAO: '06/07/2020',
                DATA_SOLICITACAO: '06/07/2020',
                DATA_APROVACAO: '',
                DATA_CONCLUSAO: '',
                STATUS_GUIA: 'APROVAÇÃO SOLICITADA',
                TITULAR: '008-3094-0004784 - SUELI GONÇALVEZ',
                DEPENDENTE: 'ANDRE JESUS SILVA'
            },
            {
                id: 897654,
                PRESTADOR_OPERADORA: 'GUSTAVO GALATI REGO',
                DATA_EMISSAO: '06/07/2020',
                DATA_SOLICITACAO: '06/07/2020',
                DATA_APROVACAO: '',
                DATA_CONCLUSAO: '',
                STATUS_GUIA: 'APROVAÇÃO SOLICITADA',
                TITULAR: '008-7464-0009901 - JOSÉ PEDRO ALVAREZ',
                DEPENDENTE: ''
            },
            {
                id: 903174,
                PRESTADOR_OPERADORA: 'FREDERICO WINDLIN GARCIA',
                DATA_EMISSAO: '06/07/2020',
                DATA_SOLICITACAO: '06/07/2020',
                DATA_APROVACAO: '',
                DATA_CONCLUSAO: '',
                STATUS_GUIA: 'APROVAÇÃO SOLICITADA',
                TITULAR: '034-0146-0000032 - PAULO ALVES CORREA',
                DEPENDENTE: 'STELLA CAROLINA DE ASSIS CORREA'
            },
            {
                id: 903175,
                PRESTADOR_OPERADORA: 'VLADIMIR ALVES TEIXEIRA',
                DATA_EMISSAO: '06/07/2020',
                DATA_SOLICITACAO: '06/07/2020',
                DATA_APROVACAO: '',
                DATA_CONCLUSAO: '',
                STATUS_GUIA: 'APROVAÇÃO SOLICITADA',
                TITULAR: '008-0101-0000097 - ANDERSON DA SILVA',
                DEPENDENTE: ''
            },
            {
                id: 903176,
                PRESTADOR_OPERADORA: 'REGINA APARECIDA OLIVEIRA',
                DATA_EMISSAO: '06/07/2020',
                DATA_SOLICITACAO: '06/07/2020',
                DATA_APROVACAO: '',
                DATA_CONCLUSAO: '',
                STATUS_GUIA: 'APROVAÇÃO SOLICITADA',
                TITULAR: '008-2046-0006146 - DANILO PIRES OLIVEIRA',
                DEPENDENTE: 'LUIZ SEBASTIÃO DE OLIVEIRA'
            },
            {
                id: 776547,
                PRESTADOR_OPERADORA: 'GUSTAVO GALATI REGO',
                DATA_EMISSAO: '06/07/2020',
                DATA_SOLICITACAO: '06/07/2020',
                DATA_APROVACAO: '',
                DATA_CONCLUSAO: '',
                STATUS_GUIA: 'APROVAÇÃO SOLICITADA',
                TITULAR: '008-3094-0004784 - SUELI GONÇALVEZ',
                DEPENDENTE: 'ANDRE JESUS SILVA'
            },
            {
                id: 897654,
                PRESTADOR_OPERADORA: 'GUSTAVO GALATI REGO',
                DATA_EMISSAO: '06/07/2020',
                DATA_SOLICITACAO: '06/07/2020',
                DATA_APROVACAO: '',
                DATA_CONCLUSAO: '',
                STATUS_GUIA: 'APROVAÇÃO SOLICITADA',
                TITULAR: '008-7464-0009901 - JOSÉ PEDRO ALVAREZ',
                DEPENDENTE: ''
            },
            {
                id: 903174,
                PRESTADOR_OPERADORA: 'FREDERICO WINDLIN GARCIA',
                DATA_EMISSAO: '06/07/2020',
                DATA_SOLICITACAO: '06/07/2020',
                DATA_APROVACAO: '',
                DATA_CONCLUSAO: '',
                STATUS_GUIA: 'APROVAÇÃO SOLICITADA',
                TITULAR: '034-0146-0000032 - PAULO ALVES CORREA',
                DEPENDENTE: 'STELLA CAROLINA DE ASSIS CORREA'
            },
            {
                id: 903175,
                PRESTADOR_OPERADORA: 'VLADIMIR ALVES TEIXEIRA',
                DATA_EMISSAO: '06/07/2020',
                DATA_SOLICITACAO: '06/07/2020',
                DATA_APROVACAO: '',
                DATA_CONCLUSAO: '',
                STATUS_GUIA: 'APROVAÇÃO SOLICITADA',
                TITULAR: '008-0101-0000097 - ANDERSON DA SILVA',
                DEPENDENTE: ''
            },
            {
                id: 903176,
                PRESTADOR_OPERADORA: 'REGINA APARECIDA OLIVEIRA',
                DATA_EMISSAO: '06/07/2020',
                DATA_SOLICITACAO: '06/07/2020',
                DATA_APROVACAO: '',
                DATA_CONCLUSAO: '',
                STATUS_GUIA: 'APROVAÇÃO SOLICITADA',
                TITULAR: '008-2046-0006146 - DANILO PIRES OLIVEIRA',
                DEPENDENTE: 'LUIZ SEBASTIÃO DE OLIVEIRA'
            },
            {
                id: 776547,
                PRESTADOR_OPERADORA: 'GUSTAVO GALATI REGO',
                DATA_EMISSAO: '06/07/2020',
                DATA_SOLICITACAO: '06/07/2020',
                DATA_APROVACAO: '',
                DATA_CONCLUSAO: '',
                STATUS_GUIA: 'APROVAÇÃO SOLICITADA',
                TITULAR: '008-3094-0004784 - SUELI GONÇALVEZ',
                DEPENDENTE: 'ANDRE JESUS SILVA'
            },
            {
                id: 897654,
                PRESTADOR_OPERADORA: 'GUSTAVO GALATI REGO',
                DATA_EMISSAO: '06/07/2020',
                DATA_SOLICITACAO: '06/07/2020',
                DATA_APROVACAO: '',
                DATA_CONCLUSAO: '',
                STATUS_GUIA: 'APROVAÇÃO SOLICITADA',
                TITULAR: '008-7464-0009901 - JOSÉ PEDRO ALVAREZ',
                DEPENDENTE: ''
            },
            {
                id: 903174,
                PRESTADOR_OPERADORA: 'FREDERICO WINDLIN GARCIA',
                DATA_EMISSAO: '06/07/2020',
                DATA_SOLICITACAO: '06/07/2020',
                DATA_APROVACAO: '',
                DATA_CONCLUSAO: '',
                STATUS_GUIA: 'APROVAÇÃO SOLICITADA',
                TITULAR: '034-0146-0000032 - PAULO ALVES CORREA',
                DEPENDENTE: 'STELLA CAROLINA DE ASSIS CORREA'
            },
            {
                id: 903175,
                PRESTADOR_OPERADORA: 'VLADIMIR ALVES TEIXEIRA',
                DATA_EMISSAO: '06/07/2020',
                DATA_SOLICITACAO: '06/07/2020',
                DATA_APROVACAO: '',
                DATA_CONCLUSAO: '',
                STATUS_GUIA: 'APROVAÇÃO SOLICITADA',
                TITULAR: '008-0101-0000097 - ANDERSON DA SILVA',
                DEPENDENTE: ''
            },
            {
                id: 903176,
                PRESTADOR_OPERADORA: 'REGINA APARECIDA OLIVEIRA',
                DATA_EMISSAO: '06/07/2020',
                DATA_SOLICITACAO: '06/07/2020',
                DATA_APROVACAO: '',
                DATA_CONCLUSAO: '',
                STATUS_GUIA: 'APROVAÇÃO SOLICITADA',
                TITULAR: '008-2046-0006146 - DANILO PIRES OLIVEIRA',
                DEPENDENTE: 'LUIZ SEBASTIÃO DE OLIVEIRA'
            },
            {
                id: 776547,
                PRESTADOR_OPERADORA: 'GUSTAVO GALATI REGO',
                DATA_EMISSAO: '06/07/2020',
                DATA_SOLICITACAO: '06/07/2020',
                DATA_APROVACAO: '',
                DATA_CONCLUSAO: '',
                STATUS_GUIA: 'APROVAÇÃO SOLICITADA',
                TITULAR: '008-3094-0004784 - SUELI GONÇALVEZ',
                DEPENDENTE: 'ANDRE JESUS SILVA'
            },
            {
                id: 897654,
                PRESTADOR_OPERADORA: 'GUSTAVO GALATI REGO',
                DATA_EMISSAO: '06/07/2020',
                DATA_SOLICITACAO: '06/07/2020',
                DATA_APROVACAO: '',
                DATA_CONCLUSAO: '',
                STATUS_GUIA: 'APROVAÇÃO SOLICITADA',
                TITULAR: '008-7464-0009901 - JOSÉ PEDRO ALVAREZ',
                DEPENDENTE: ''
            },
            {
                id: 903174,
                PRESTADOR_OPERADORA: 'FREDERICO WINDLIN GARCIA',
                DATA_EMISSAO: '06/07/2020',
                DATA_SOLICITACAO: '06/07/2020',
                DATA_APROVACAO: '',
                DATA_CONCLUSAO: '',
                STATUS_GUIA: 'APROVAÇÃO SOLICITADA',
                TITULAR: '034-0146-0000032 - PAULO ALVES CORREA',
                DEPENDENTE: 'STELLA CAROLINA DE ASSIS CORREA'
            },
            {
                id: 903175,
                PRESTADOR_OPERADORA: 'VLADIMIR ALVES TEIXEIRA',
                DATA_EMISSAO: '06/07/2020',
                DATA_SOLICITACAO: '06/07/2020',
                DATA_APROVACAO: '',
                DATA_CONCLUSAO: '',
                STATUS_GUIA: 'APROVAÇÃO SOLICITADA',
                TITULAR: '008-0101-0000097 - ANDERSON DA SILVA',
                DEPENDENTE: ''
            },
            {
                id: 903176,
                PRESTADOR_OPERADORA: 'REGINA APARECIDA OLIVEIRA',
                DATA_EMISSAO: '06/07/2020',
                DATA_SOLICITACAO: '06/07/2020',
                DATA_APROVACAO: '',
                DATA_CONCLUSAO: '',
                STATUS_GUIA: 'APROVAÇÃO SOLICITADA',
                TITULAR: '008-2046-0006146 - DANILO PIRES OLIVEIRA',
                DEPENDENTE: 'LUIZ SEBASTIÃO DE OLIVEIRA'
            },
            {
                id: 776547,
                PRESTADOR_OPERADORA: 'GUSTAVO GALATI REGO',
                DATA_EMISSAO: '06/07/2020',
                DATA_SOLICITACAO: '06/07/2020',
                DATA_APROVACAO: '',
                DATA_CONCLUSAO: '',
                STATUS_GUIA: 'APROVAÇÃO SOLICITADA',
                TITULAR: '008-3094-0004784 - SUELI GONÇALVEZ',
                DEPENDENTE: 'ANDRE JESUS SILVA'
            },
            {
                id: 897654,
                PRESTADOR_OPERADORA: 'GUSTAVO GALATI REGO',
                DATA_EMISSAO: '06/07/2020',
                DATA_SOLICITACAO: '06/07/2020',
                DATA_APROVACAO: '',
                DATA_CONCLUSAO: '',
                STATUS_GUIA: 'APROVAÇÃO SOLICITADA',
                TITULAR: '008-7464-0009901 - JOSÉ PEDRO ALVAREZ',
                DEPENDENTE: ''
            },
            {
                id: 903174,
                PRESTADOR_OPERADORA: 'FREDERICO WINDLIN GARCIA',
                DATA_EMISSAO: '06/07/2020',
                DATA_SOLICITACAO: '06/07/2020',
                DATA_APROVACAO: '',
                DATA_CONCLUSAO: '',
                STATUS_GUIA: 'APROVAÇÃO SOLICITADA',
                TITULAR: '034-0146-0000032 - PAULO ALVES CORREA',
                DEPENDENTE: 'STELLA CAROLINA DE ASSIS CORREA'
            },
            {
                id: 903175,
                PRESTADOR_OPERADORA: 'VLADIMIR ALVES TEIXEIRA',
                DATA_EMISSAO: '06/07/2020',
                DATA_SOLICITACAO: '06/07/2020',
                DATA_APROVACAO: '',
                DATA_CONCLUSAO: '',
                STATUS_GUIA: 'APROVAÇÃO SOLICITADA',
                TITULAR: '008-0101-0000097 - ANDERSON DA SILVA',
                DEPENDENTE: ''
            },
            {
                id: 903176,
                PRESTADOR_OPERADORA: 'REGINA APARECIDA OLIVEIRA',
                DATA_EMISSAO: '06/07/2020',
                DATA_SOLICITACAO: '06/07/2020',
                DATA_APROVACAO: '',
                DATA_CONCLUSAO: '',
                STATUS_GUIA: 'APROVAÇÃO SOLICITADA',
                TITULAR: '008-2046-0006146 - DANILO PIRES OLIVEIRA',
                DEPENDENTE: 'LUIZ SEBASTIÃO DE OLIVEIRA'
            },
            {
                id: 776547,
                PRESTADOR_OPERADORA: 'GUSTAVO GALATI REGO',
                DATA_EMISSAO: '06/07/2020',
                DATA_SOLICITACAO: '06/07/2020',
                DATA_APROVACAO: '',
                DATA_CONCLUSAO: '',
                STATUS_GUIA: 'APROVAÇÃO SOLICITADA',
                TITULAR: '008-3094-0004784 - SUELI GONÇALVEZ',
                DEPENDENTE: 'ANDRE JESUS SILVA'
            },
            {
                id: 897654,
                PRESTADOR_OPERADORA: 'GUSTAVO GALATI REGO',
                DATA_EMISSAO: '06/07/2020',
                DATA_SOLICITACAO: '06/07/2020',
                DATA_APROVACAO: '',
                DATA_CONCLUSAO: '',
                STATUS_GUIA: 'APROVAÇÃO SOLICITADA',
                TITULAR: '008-7464-0009901 - JOSÉ PEDRO ALVAREZ',
                DEPENDENTE: ''
            },
            {
                id: 903174,
                PRESTADOR_OPERADORA: 'FREDERICO WINDLIN GARCIA',
                DATA_EMISSAO: '06/07/2020',
                DATA_SOLICITACAO: '06/07/2020',
                DATA_APROVACAO: '',
                DATA_CONCLUSAO: '',
                STATUS_GUIA: 'APROVAÇÃO SOLICITADA',
                TITULAR: '034-0146-0000032 - PAULO ALVES CORREA',
                DEPENDENTE: 'STELLA CAROLINA DE ASSIS CORREA'
            },
            {
                id: 903175,
                PRESTADOR_OPERADORA: 'VLADIMIR ALVES TEIXEIRA',
                DATA_EMISSAO: '06/07/2020',
                DATA_SOLICITACAO: '06/07/2020',
                DATA_APROVACAO: '',
                DATA_CONCLUSAO: '',
                STATUS_GUIA: 'APROVAÇÃO SOLICITADA',
                TITULAR: '008-0101-0000097 - ANDERSON DA SILVA',
                DEPENDENTE: ''
            },
            {
                id: 903176,
                PRESTADOR_OPERADORA: 'REGINA APARECIDA OLIVEIRA',
                DATA_EMISSAO: '06/07/2020',
                DATA_SOLICITACAO: '06/07/2020',
                DATA_APROVACAO: '',
                DATA_CONCLUSAO: '',
                STATUS_GUIA: 'APROVAÇÃO SOLICITADA',
                TITULAR: '008-2046-0006146 - DANILO PIRES OLIVEIRA',
                DEPENDENTE: 'LUIZ SEBASTIÃO DE OLIVEIRA'
            },
            {
                id: 776547,
                PRESTADOR_OPERADORA: 'GUSTAVO GALATI REGO',
                DATA_EMISSAO: '06/07/2020',
                DATA_SOLICITACAO: '06/07/2020',
                DATA_APROVACAO: '',
                DATA_CONCLUSAO: '',
                STATUS_GUIA: 'APROVAÇÃO SOLICITADA',
                TITULAR: '008-3094-0004784 - SUELI GONÇALVEZ',
                DEPENDENTE: 'ANDRE JESUS SILVA'
            },
            {
                id: 897654,
                PRESTADOR_OPERADORA: 'GUSTAVO GALATI REGO',
                DATA_EMISSAO: '06/07/2020',
                DATA_SOLICITACAO: '06/07/2020',
                DATA_APROVACAO: '',
                DATA_CONCLUSAO: '',
                STATUS_GUIA: 'APROVAÇÃO SOLICITADA',
                TITULAR: '008-7464-0009901 - JOSÉ PEDRO ALVAREZ',
                DEPENDENTE: ''
            }
        ]

        public ListaDIVsDENTES : Array<any> = [
            '018o',
            '018l',
            '018d',
            '018m',
            '018v',
            '117o',
            '117l',
            '117d',
            '117m',
            '117v',
            '216o',
            '216l',
            '216d',
            '216m',
            '216v',
            '315o',
            '315l',
            '315d',
            '315m',
            '315v',
            '414o',
            '414l',
            '414d',
            '414m',
            '414v',
            '513o',
            '513l',
            '513d',
            '513m',
            '513v',
            '612o',
            '612l',
            '612d',
            '612m',
            '612v',
            '711o',
            '711l',
            '711d',
            '711m',
            '711v',
            '821o',
            '821l',
            '821d',
            '821m',
            '821v',
            '922o',
            '922l',
            '922d',
            '922m',
            '922v',
            '1023o',
            '1023l',
            '1023d',
            '1023m',
            '1023v',
            '1124o',
            '1124l',
            '1124d',
            '1124m',
            '1124v',
            '1225o',
            '1225l',
            '1225d',
            '1225m',
            '1225v',
            '1326o',
            '1326l',
            '1326d',
            '1326m',
            '1326v',
            '1427o',
            '1427l',
            '1427d',
            '1427m',
            '1427v',
            '1528o',
            '1528l',
            '1528d',
            '1528m',
            '1528v',
            '055o',
            '055l',
            '055d',
            '055m',
            '055v',
            '154o',
            '154l',
            '154d',
            '154m',
            '154v',
            '253o',
            '253l',
            '253d',
            '253m',
            '253v',
            '352o',
            '352l',
            '352d',
            '352m',
            '352v',
            '451o',
            '451l',
            '451d',
            '451m',
            '451v',
            '561o',
            '561l',
            '561d',
            '561m',
            '561v',
            '662o',
            '662l',
            '662d',
            '662m',
            '662v',
            '763o',
            '763l',
            '763d',
            '763m',
            '763v',
            '864o',
            '864l',
            '864d',
            '864m',
            '864v',
            '965o',
            '965l',
            '965d',
            '965m',
            '965v',
            '085o',
            '085l',
            '085d',
            '085m',
            '085v',
            '184o',
            '184l',
            '184d',
            '184m',
            '184v',
            '283o',
            '283l',
            '283d',
            '283m',
            '283v',
            '382o',
            '382l',
            '382d',
            '382m',
            '382v',
            '481o',
            '481l',
            '481d',
            '481m',
            '481v',
            '571o',
            '571l',
            '571d',
            '571m',
            '571v',
            '672o',
            '672l',
            '672d',
            '672m',
            '672v',
            '773o',
            '773l',
            '773d',
            '773m',
            '773v',
            '874o',
            '874l',
            '874d',
            '874m',
            '874v',
            '975o',
            '975l',
            '975d',
            '975m',
            '975v',
            '048o',
            '048l',
            '048d',
            '048m',
            '048v',
            '147o',
            '147l',
            '147d',
            '147m',
            '147v',
            '246o',
            '246l',
            '246d',
            '246m',
            '246v',
            '345o',
            '345l',
            '345d',
            '345m',
            '345v',
            '444o',
            '444l',
            '444d',
            '444m',
            '444v',
            '543o',
            '543l',
            '543d',
            '543m',
            '543v',
            '642o',
            '642l',
            '642d',
            '642m',
            '642v',
            '741o',
            '741l',
            '741d',
            '741m',
            '741v',
            '831o',
            '831l',
            '831d',
            '831m',
            '831v',
            '932o',
            '932l',
            '932d',
            '932m',
            '932v',
            '1033o',
            '1033l',
            '1033d',
            '1033m',
            '1033v',
            '1134o',
            '1134l',
            '1134d',
            '1134m',
            '1134v',
            '1235o',
            '1235l',
            '1235d',
            '1235m',
            '1235v',
            '1336o',
            '1336l',
            '1336d',
            '1336m',
            '1336v',
            '1437o',
            '1437l',
            '1437d',
            '1437m',
            '1437v',
            '1538o',
            '1538l',
            '1538d',
            '1538m',
            '1538v',
            '018full',
            '117full',
            '216full',
            '315full',
            '414full',
            '513full',
            '612full',
            '711full',
            '821full',
            '922full',
            '1023full',
            '1124full',
            '1225full',
            '1326full',
            '1427full',
            '1528full',
            '055full',
            '154full',
            '253full',
            '352full',
            '451full',
            '561full',
            '662full',
            '763full',
            '864full',
            '965full',
            '085full',
            '184full',
            '283full',
            '382full',
            '481full',
            '571full',
            '672full',
            '773full',
            '874full',
            '975full',
            '048full',
            '147full',
            '246full',
            '345full',
            '444full',
            '543full',
            '642full',
            '741full',
            '831full',
            '932full',
            '1033full',
            '1134full',
            '1235full',
            '1336full',
            '1437full',
            '1538full'
        ]
}