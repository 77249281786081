//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { TipoEntidade } from './tipoentidade.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const TipoEntidadeEntityConfig = {
  name: 'TipoEntidade',
  keys: [{name: 'Id'}],
  fields: {
    Id: {type: 'Edm.Int32', nullable: false},
    Nome: {type: 'Edm.String'},
    EnumTipoEntidade: {type: 'Uniodonto.Domain.Model.Enum.EnumTipoEntidade', nullable: false},
    OperadoraId: {type: 'Edm.Int32'},
    ClinicaId: {type: 'Edm.Int32'},
    Operadora: {type: 'Uniodonto.Domain.Entities.Operadora', navigation: true, referentials: [{property: 'OperadoraId', referencedProperty: 'Id'}]},
    Clinica: {type: 'Uniodonto.Domain.Entities.Clinica', navigation: true, referentials: [{property: 'ClinicaId', referencedProperty: 'Id'}]}
  }
} as StructuredTypeConfig<TipoEntidade>;
//#endregion