//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { ContratoProduto } from './contratoproduto.entity';
import { Contrato } from './contrato.entity';
import { PreEspelho } from './preespelho.entity';
import { Produto } from './produto.entity';
//#endregion

export interface FaturamentoMovimentacoes {
  //#region ODataApiGen Properties
  PreEspelhoId?: number;
  ContratoId?: number;
  ContratoProdutoId?: number;
  ProdutoId?: number;
  TitularesAdicionados: number;
  AgregadosAdicionados: number;
  DependentesAdicionados: number;
  TitularesExcluidos: number;
  AgregadosExcluidos: number;
  DependentesExcluidos: number;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  PreEspelho?: PreEspelho;
  ContratoProduto?: ContratoProduto;
  Contrato?: Contrato;
  Produto?: Produto;
  //#endregion
}