//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
//#endregion

export interface ProcedimentoLegado {
  //#region ODataApiGen Properties
  NumeroGuia?: string;
  IdProcedimentoLegado?: string;
  CodigoProcedimento?: string;
  NomeProcedimento?: string;
  DominioNome?: string;
  NumeroDente?: number;
  Face?: string;
  UsQuantidade?: number;
  UsValor?: number;
  Observacao?: string;
  PossuiCooparticipacao?: boolean;
  QuantidadeCooparticipacao?: number;
  ValorCooparticipacao?: number;
  UsQuantidadeIntercambio?: number;
  UsValorIntercambio?: number;
  DataExecucao?: Date;
  OperadoraId?: number;
  DataAprovacao?: Date;
  CarteiraId?: string;
  StatusGuia?: string;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  //#endregion
}