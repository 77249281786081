//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { ClinicaDentistas } from './clinicadentistas.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const ClinicaDentistasEntityConfig = {
  name: 'ClinicaDentistas',
  keys: [{name: 'Id'}],
  fields: {
    DentistaId: {type: 'Edm.Int32'},
    ClinicaId: {type: 'Edm.Int32'},
    OperadoraId: {type: 'Edm.Int32'},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'},
    Dentista: {type: 'Uniodonto.Domain.Entities.Dentista', navigation: true, referentials: [{property: 'DentistaId', referencedProperty: 'Id'}]},
    Clinica: {type: 'Uniodonto.Domain.Entities.Clinica', navigation: true, referentials: [{property: 'ClinicaId', referencedProperty: 'Id'}]},
    Operadora: {type: 'Uniodonto.Domain.Entities.Operadora', navigation: true, referentials: [{property: 'OperadoraId', referencedProperty: 'Id'}]}
  }
} as StructuredTypeConfig<ClinicaDentistas>;
//#endregion