//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { OperadoraV2Service } from './operadorav2.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const OperadoraV2ServiceEntitySetConfig = {
  name: 'OperadoraV2',
  entityType: 'Uniodonto.Domain.Entities.Operadora',
  service: OperadoraV2Service
} as EntitySetConfig;
//#endregion