//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { OperadoraParametro } from './operadoraparametro.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const OperadoraParametroEntityConfig = {
  name: 'OperadoraParametro',
  keys: [{name: 'Id'}],
  fields: {
    QtdAnosExpiracaoCarteira: {type: 'Edm.Int32'},
    PerfilCarenciaId: {type: 'Edm.Int32'},
    AceitaInadimplentes: {type: 'Edm.Boolean'},
    PermiteAtoNaoCoberto: {type: 'Edm.Boolean'},
    PermiteAtosConflitantes: {type: 'Edm.Boolean'},
    AprovacaoAutomaticaGuias: {type: 'Edm.Boolean', nullable: false},
    ValidadeGuia: {type: 'Edm.DateTimeOffset', nullable: false},
    Meses: {type: 'Edm.Int32', nullable: false},
    PermiteAuditoriaSimultanea: {type: 'Edm.Boolean', nullable: false},
    AuditoriaAdmObrigatoria: {type: 'Edm.Boolean', nullable: false},
    AuditoriaTecnicaObrigatoria: {type: 'Edm.Boolean', nullable: false},
    PermiteSolicitarAutorizacao: {type: 'Edm.Boolean', nullable: false},
    BloquearCriacaoGuiasSemAnexo: {type: 'Edm.Boolean', nullable: false},
    BloquearExecucaoProcedimentoSemAnexo: {type: 'Edm.Boolean', nullable: false},
    OperadoraId: {type: 'Edm.Int32'},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'},
    PerfilCarencia: {type: 'Uniodonto.Domain.Entities.PerfilCarencia', navigation: true, referentials: [{property: 'PerfilCarenciaId', referencedProperty: 'Id'}]},
    Operadora: {type: 'Uniodonto.Domain.Entities.Operadora', navigation: true, referentials: [{property: 'OperadoraId', referencedProperty: 'Id'}]}
  }
} as StructuredTypeConfig<OperadoraParametro>;
//#endregion