//#region ODataApiGen Imports
//#endregion

export enum EnumStatusProcedimento {
  //#region ODataApiGen Members
  PENDENTE = 1,
  APROVADO = 2,
  REJEITADO = 3,
  AGUARDANDO_EXECUCAO = 5,
  EXECUTADO = 6,
  PARCIALMENTE_EXECUTADO = 7,
  CONCLUIDO = 8,
  GLOSADO = 9,
  PARCIALMENTE_CONCLUIDO = 10,
  PARCIALMENTE_GLOSADO = 11,
  EM_LOTE_CRIADO = 12,
  EM_LOTE_CONCLUIDO = 13,
  LOTE_ANALISE = 14,
  LOTE_CONCLUIDO = 15,
  LOTE_GLOSADO = 16,
  LOTE_PARCIALMENTE_GLOSADO = 17,
  LOTE_CRIADO = 18,
  EM_LOTE_EM_ANALISE = 19,
  LOTE_EM_FILA_ERP = 20,
  LOTE_REJEITADO_ERP = 21,
  LOTE_EXECUTADO_ERP = 22,
  APROVACAO_TECNICA = 23,
  APROVACAO_ADMINISTRATIVA = 24,
  NAO_AUTORIZADO = 25,
  EmAberto = 70,
  ComRestricao = 71,
  GuiaCriada = 72,
  GuiaCriadaComRestrição = 73,
  Cancelado = -1,
  //#endregion
}
