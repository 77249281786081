//#region ODataApiGen ODataImports
import {
  EntityContainerConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { ArquivoV2ServiceEntitySetConfig } from './arquivov2.service.config';
import { ArquivoServiceEntitySetConfig } from './arquivo.service.config';
import { ClienteV2ServiceEntitySetConfig } from './clientev2.service.config';
import { ClienteServiceEntitySetConfig } from './cliente.service.config';
import { ContratoProdutoServiceEntitySetConfig } from './contratoproduto.service.config';
import { ContratoProdutoV2ServiceEntitySetConfig } from './contratoprodutov2.service.config';
import { ContratoV2ServiceEntitySetConfig } from './contratov2.service.config';
import { DependenteGrupoFamiliarV2ServiceEntitySetConfig } from './dependentegrupofamiliarv2.service.config';
import { PreEspelhoDetalheServiceEntitySetConfig } from './preespelhodetalhe.service.config';
import { PreEspelhoServiceEntitySetConfig } from './preespelho.service.config';
import { HistoricoAtivacaoInativacaoOperadoraV2ServiceEntitySetConfig } from './historicoativacaoinativacaooperadorav2.service.config';
import { HistoricoConsultaLotePrestadoresRPSV2ServiceEntitySetConfig } from './historicoconsultaloteprestadoresrpsv2.service.config';
import { JobV2ServiceEntitySetConfig } from './jobv2.service.config';
import { LogJobV2ServiceEntitySetConfig } from './logjobv2.service.config';
import { LogTrocaSenhaV2ServiceEntitySetConfig } from './logtrocasenhav2.service.config';
import { OperadoraV2ServiceEntitySetConfig } from './operadorav2.service.config';
import { PeriodoV2ServiceEntitySetConfig } from './periodov2.service.config';
import { ReajusteContratualV2ServiceEntitySetConfig } from './reajustecontratualv2.service.config';
import { StatusJobV2ServiceEntitySetConfig } from './statusjobv2.service.config';
import { SubclienteV2ServiceEntitySetConfig } from './subclientev2.service.config';
import { BeneficiarioV2ServiceEntitySetConfig } from './beneficiariov2.service.config';
import { BeneficiarioServiceEntitySetConfig } from './beneficiario.service.config';
import { CarteiraV2ServiceEntitySetConfig } from './carteirav2.service.config';
import { CarteiraServiceEntitySetConfig } from './carteira.service.config';
import { TitularV2ServiceEntitySetConfig } from './titularv2.service.config';
import { GrupoFamiliarContratoV2ServiceEntitySetConfig } from './grupofamiliarcontratov2.service.config';
import { EnderecoV2ServiceEntitySetConfig } from './enderecov2.service.config';
import { CEPV2ServiceEntitySetConfig } from './cepv2.service.config';
import { CEPServiceEntitySetConfig } from './cep.service.config';
import { BairroV2ServiceEntitySetConfig } from './bairrov2.service.config';
import { BairroServiceEntitySetConfig } from './bairro.service.config';
import { CidadeV2ServiceEntitySetConfig } from './cidadev2.service.config';
import { CidadeServiceEntitySetConfig } from './cidade.service.config';
import { TRMAtoOperadoraV2ServiceEntitySetConfig } from './trmatooperadorav2.service.config';
import { UsuarioOperadoraV2ServiceEntitySetConfig } from './usuariooperadorav2.service.config';
import { GrupoV2ServiceEntitySetConfig } from './grupov2.service.config';
import { GrupoAtoOdontologicoServiceEntitySetConfig } from './grupoatoodontologico.service.config';
import { AtoOdontologicoServiceEntitySetConfig } from './atoodontologico.service.config';
import { TitularServiceEntitySetConfig } from './titular.service.config';
import { ContratoServiceEntitySetConfig } from './contrato.service.config';
import { ComissaoV2ServiceEntitySetConfig } from './comissaov2.service.config';
import { ComissaoServiceEntitySetConfig } from './comissao.service.config';
import { ContratoProdutoAtoPrecoV2ServiceEntitySetConfig } from './contratoprodutoatoprecov2.service.config';
import { DependenteServiceEntitySetConfig } from './dependente.service.config';
import { OrcamentoV2ServiceEntitySetConfig } from './orcamentov2.service.config';
import { AtoOdontologicoV2ServiceEntitySetConfig } from './atoodontologicov2.service.config';
import { DominioV2ServiceEntitySetConfig } from './dominiov2.service.config';
import { ClinicaV2ServiceEntitySetConfig } from './clinicav2.service.config';
import { GuiaV2ServiceEntitySetConfig } from './guiav2.service.config';
import { SubContratoServiceEntitySetConfig } from './subcontrato.service.config';
import { SubContratoProdutoServiceEntitySetConfig } from './subcontratoproduto.service.config';
import { ValorProcedimentoTipoProdutoServiceEntitySetConfig } from './valorprocedimentotipoproduto.service.config';
import { ProcedimentosV2ServiceEntitySetConfig } from './procedimentosv2.service.config';
import { ProcedimentoGlosaV2ServiceEntitySetConfig } from './procedimentoglosav2.service.config';
import { LoteV2ServiceEntitySetConfig } from './lotev2.service.config';
import { ContratoOperadoraRepasseServiceEntitySetConfig } from './contratooperadorarepasse.service.config';
import { ProcedimentoRecursosHistoricoServiceEntitySetConfig } from './procedimentorecursoshistorico.service.config';
import { ProcessamentosRetornoSIBServiceEntitySetConfig } from './processamentosretornosib.service.config';
import { HistoricoNaoIncluidosSIBServiceEntitySetConfig } from './historiconaoincluidossib.service.config';
import { HistoricoRejeitadosSIBServiceEntitySetConfig } from './historicorejeitadossib.service.config';
import { ConsolidadoProcessamentoSIBServiceEntitySetConfig } from './consolidadoprocessamentosib.service.config';
import { DentistaV2ServiceEntitySetConfig } from './dentistav2.service.config';
import { GuiaObservacaoServiceEntitySetConfig } from './guiaobservacao.service.config';
import { IntegracaoEntidadePortalERPLogServiceEntitySetConfig } from './integracaoentidadeportalerplog.service.config';
import { NumberSequenceServiceEntitySetConfig } from './numbersequence.service.config';
import { GuiaLegadoServiceEntitySetConfig } from './guialegado.service.config';
import { ProcedimentoLegadoServiceEntitySetConfig } from './procedimentolegado.service.config';
import { GuiaProcedimentoImagemLegadoServiceEntitySetConfig } from './guiaprocedimentoimagemlegado.service.config';
import { ProfissionalSolicitanteServiceEntitySetConfig } from './profissionalsolicitante.service.config';
import { FaturamentoMovimentacoesServiceEntitySetConfig } from './faturamentomovimentacoes.service.config';
import { PerfilCobrancaServiceEntitySetConfig } from './perfilcobranca.service.config';
import { ProdutosServiceEntitySetConfig } from './produtos.service.config';
import { GrupoFornecedorERPServiceEntitySetConfig } from './grupofornecedorerp.service.config';
import { AvisoV2ServiceEntitySetConfig } from './avisov2.service.config';
import { LinkV2ServiceEntitySetConfig } from './linkv2.service.config';
import { RacaServiceEntitySetConfig } from './raca.service.config';
import { GrauInstrucaoServiceEntitySetConfig } from './grauinstrucao.service.config';
//#endregion

//#region ODataApiGen EntityContainerConfig
export const ContainerContainer = {
  name: 'Container',
  entitySets: [
    ArquivoV2ServiceEntitySetConfig,
    ArquivoServiceEntitySetConfig,
    ClienteV2ServiceEntitySetConfig,
    ClienteServiceEntitySetConfig,
    ContratoProdutoServiceEntitySetConfig,
    ContratoProdutoV2ServiceEntitySetConfig,
    ContratoV2ServiceEntitySetConfig,
    DependenteGrupoFamiliarV2ServiceEntitySetConfig,
    PreEspelhoDetalheServiceEntitySetConfig,
    PreEspelhoServiceEntitySetConfig,
    HistoricoAtivacaoInativacaoOperadoraV2ServiceEntitySetConfig,
    HistoricoConsultaLotePrestadoresRPSV2ServiceEntitySetConfig,
    JobV2ServiceEntitySetConfig,
    LogJobV2ServiceEntitySetConfig,
    LogTrocaSenhaV2ServiceEntitySetConfig,
    OperadoraV2ServiceEntitySetConfig,
    PeriodoV2ServiceEntitySetConfig,
    ReajusteContratualV2ServiceEntitySetConfig,
    StatusJobV2ServiceEntitySetConfig,
    SubclienteV2ServiceEntitySetConfig,
    BeneficiarioV2ServiceEntitySetConfig,
    BeneficiarioServiceEntitySetConfig,
    CarteiraV2ServiceEntitySetConfig,
    CarteiraServiceEntitySetConfig,
    TitularV2ServiceEntitySetConfig,
    GrupoFamiliarContratoV2ServiceEntitySetConfig,
    EnderecoV2ServiceEntitySetConfig,
    CEPV2ServiceEntitySetConfig,
    CEPServiceEntitySetConfig,
    BairroV2ServiceEntitySetConfig,
    BairroServiceEntitySetConfig,
    CidadeV2ServiceEntitySetConfig,
    CidadeServiceEntitySetConfig,
    TRMAtoOperadoraV2ServiceEntitySetConfig,
    UsuarioOperadoraV2ServiceEntitySetConfig,
    GrupoV2ServiceEntitySetConfig,
    GrupoAtoOdontologicoServiceEntitySetConfig,
    AtoOdontologicoServiceEntitySetConfig,
    TitularServiceEntitySetConfig,
    ContratoServiceEntitySetConfig,
    ComissaoV2ServiceEntitySetConfig,
    ComissaoServiceEntitySetConfig,
    ContratoProdutoAtoPrecoV2ServiceEntitySetConfig,
    DependenteServiceEntitySetConfig,
    OrcamentoV2ServiceEntitySetConfig,
    AtoOdontologicoV2ServiceEntitySetConfig,
    DominioV2ServiceEntitySetConfig,
    ClinicaV2ServiceEntitySetConfig,
    GuiaV2ServiceEntitySetConfig,
    SubContratoServiceEntitySetConfig,
    SubContratoProdutoServiceEntitySetConfig,
    ValorProcedimentoTipoProdutoServiceEntitySetConfig,
    ProcedimentosV2ServiceEntitySetConfig,
    ProcedimentoGlosaV2ServiceEntitySetConfig,
    LoteV2ServiceEntitySetConfig,
    ContratoOperadoraRepasseServiceEntitySetConfig,
    ProcedimentoRecursosHistoricoServiceEntitySetConfig,
    ProcessamentosRetornoSIBServiceEntitySetConfig,
    HistoricoNaoIncluidosSIBServiceEntitySetConfig,
    HistoricoRejeitadosSIBServiceEntitySetConfig,
    ConsolidadoProcessamentoSIBServiceEntitySetConfig,
    DentistaV2ServiceEntitySetConfig,
    GuiaObservacaoServiceEntitySetConfig,
    IntegracaoEntidadePortalERPLogServiceEntitySetConfig,
    NumberSequenceServiceEntitySetConfig,
    GuiaLegadoServiceEntitySetConfig,
    ProcedimentoLegadoServiceEntitySetConfig,
    GuiaProcedimentoImagemLegadoServiceEntitySetConfig,
    ProfissionalSolicitanteServiceEntitySetConfig,
    FaturamentoMovimentacoesServiceEntitySetConfig,
    PerfilCobrancaServiceEntitySetConfig,
    ProdutosServiceEntitySetConfig,
    GrupoFornecedorERPServiceEntitySetConfig,
    AvisoV2ServiceEntitySetConfig,
    LinkV2ServiceEntitySetConfig,
    RacaServiceEntitySetConfig,
    GrauInstrucaoServiceEntitySetConfig
  ]
} as EntityContainerConfig;
//#endregion