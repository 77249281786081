//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumTipoPerfilCarencia } from '../Model/Enum/enumtipoperfilcarencia.enum';
import { Operadora } from './operadora.entity';
import { AtoOdontologico } from './atoodontologico.entity';
import { Especialidade } from './especialidade.entity';
import { PerfilCarencia } from './perfilcarencia.entity';
//#endregion

export interface PerfilCarenciaLinha {
  //#region ODataApiGen Properties
  CarenciaDias: number;
  PerfilId?: number;
  PerfilTipo: EnumTipoPerfilCarencia;
  OperadoraId?: number;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  Especialidade?: Especialidade;
  AtoOdontologico?: AtoOdontologico;
  Perfil?: PerfilCarencia;
  Operadora?: Operadora;
  //#endregion
}