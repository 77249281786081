//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { GuiaAnexo } from './guiaanexo.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const GuiaAnexoEntityConfig = {
  name: 'GuiaAnexo',
  keys: [{name: 'Id'}],
  fields: {
    DocumentoUrl: {type: 'Edm.String'},
    DocumentoNome: {type: 'Edm.String'},
    TipoImagem: {type: 'Uniodonto.Domain.Model.Enum.EnumTipoDocumento', nullable: false},
    GuiaId: {type: 'Edm.Int32'},
    UFastEnviado: {type: 'Edm.Boolean', nullable: false},
    OperadoraId: {type: 'Edm.Int32'},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'},
    Guia: {type: 'Uniodonto.Domain.Entities.Guia', navigation: true, referentials: [{property: 'GuiaId', referencedProperty: 'Id'}]},
    Operadora: {type: 'Uniodonto.Domain.Entities.Operadora', navigation: true, referentials: [{property: 'OperadoraId', referencedProperty: 'Id'}]}
  }
} as StructuredTypeConfig<GuiaAnexo>;
//#endregion