//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { ProcedimentoLegadoService } from './procedimentolegado.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const ProcedimentoLegadoServiceEntitySetConfig = {
  name: 'ProcedimentoLegado',
  entityType: 'Uniodonto.Domain.Entities.ProcedimentoLegado',
  service: ProcedimentoLegadoService
} as EntitySetConfig;
//#endregion