//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumModuloPortal } from '../Model/Enum/enummoduloportal.enum';
import { Operadora } from './operadora.entity';
//#endregion

export interface Aviso {
  //#region ODataApiGen Properties
  TituloAviso?: string;
  Texto?: string;
  Ativo: boolean;
  TipoPortal?: string;
  Modulo: EnumModuloPortal;
  OperadoraId?: number;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  Operadora?: Operadora;
  //#endregion
}