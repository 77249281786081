//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Operadora } from './operadora.entity';
import { Titular } from './titular.entity';
import { Dependente } from './dependente.entity';
import { Participante } from './participante.entity';
//#endregion

export interface Beneficiario {
  //#region ODataApiGen Properties
  BeneficiarioCpf?: string;
  BeneficiarioCns?: string;
  BeneficiarioNome?: string;
  BeneficiarioNomeMae?: string;
  BeneficiarioNomePai?: string;
  BeneficiarioDataNascimento: Date;
  BeneficiarioIdade: number;
  BeneficiarioSexo?: string;
  ParticipanteId?: number;
  CCO?: string;
  Intercambio: boolean;
  OperadoraId?: number;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  Participante?: Participante;
  Titulares?: Titular[];
  Dependentes?: Dependente[];
  Operadora?: Operadora;
  //#endregion
}