import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { environment } from "../../../environments/environment";

import { catchError, map, tap } from "rxjs/operators";

import { Injectable } from "@angular/core";

import { APIResponse } from "../../models";
import {
  TitularModel,
  Beneficiario_ListaEnderecoContato,
  TitularBeneficiarioModel,
  DependenteDoTitular,
} from "../../models/entities";
//import { Beneficiario_ListaEnderecoContato } from 'src/app/models/entities/Beneficiario_ListaEnderecoContato';

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
  }),
};

@Injectable({
  providedIn: "root",
})
export class TitularService {
  public log: String;

  constructor(private http: HttpClient) { }

  getTitular(idBeneficiario: any): Observable<any[]> {
    return this.http
      .get<any[]>(
        `${environment.apiUrl}/titular?idBeneficiario=${idBeneficiario}`
      )
      .pipe(
        tap((data) => console.log("titular obtidos")),
        catchError(this.handleError)
      );
  }

  getTitulaIdBeneficiario(idBeneficiario: any): Observable<any[]> {
    return this.http
      .get<any[]>(
        `${environment.apiUrl}/Titular/buscar-por-beneficiarioId?beneficiarioId=${idBeneficiario}`
      )
      .pipe(
        tap((data) => console.log("Dependente obtidos")),
        catchError(this.handleError)
      );
  }


  getDependenteIdBeneficiario(idBeneficiario: any): Observable<any[]> {
    return this.http
      .get<any[]>(
        `${environment.apiUrl}/Titular/buscar-Dependente-por-IdBeneficiario?beneficiarioId=${idBeneficiario}`
      )
      .pipe(
        tap((data) => console.log("titular obtidos")),
        catchError(this.handleError)
      );
  }

  createTitular(titular: any): Observable<APIResponse> {
    return this.http
      .post<APIResponse>(
        `${environment.apiUrl}/titular/criar-titular`,
        titular,
        httpOptions
      )
      .pipe(
        tap((resp) => console.log(JSON.stringify(resp))),
        catchError(this.handleError)
      );
  }

  createTitularBeneficiario(titular: TitularModel): Observable<APIResponse> {
    return this.http
      .post<APIResponse>(
        `${environment.apiUrl}/titular/criar-titular`,
        titular,
        httpOptions
      )
      .pipe(
        tap((resp) => console.log(JSON.stringify(resp))),
        catchError(this.handleError)
      );
  }

  updateTitular(titular: any): Observable<APIResponse> {
    return this.http
      .put<APIResponse>(
        `${environment.apiUrl}/titular/editar-titular`,
        titular,
        httpOptions
      )
  }

  updateDependente(dependente: any): Observable<APIResponse> {
    return this.http
      .put<APIResponse>(
        `${environment.apiUrl}/Titular/editar-dependente`,
        dependente,
        httpOptions
      )
      .pipe(
        tap((resp) => console.log(JSON.stringify(resp))),
        catchError(this.handleError)
      );
  }

  createDependente(dependente: any): Observable<APIResponse> {
    return this.http
      .post<APIResponse>(
        `${environment.apiUrl}/titular/criar-dependente`,
        dependente,
        httpOptions
      )
      .pipe(
        tap((resp) => console.log(JSON.stringify(resp))),
        catchError(this.handleError)
      );
  }

  getById(Id: any): Observable<any[]> {
    return this.http
      .get<any[]>(`${environment.apiUrl}/Titular/buscar-por-id?titularId=${Id}`)
      .pipe(
        tap((data) => console.log("titular obtidos")),
        catchError(this.handleError)
      );
  }

  getDependente(Id: any): Observable<any[]> {
    return this.http
      .get<any[]>(
        `${environment.apiUrl}/Titular/buscar-dependente-por-titular-id?titularId=${Id}`
      )
      .pipe(
        tap((data) => console.log("titular obtidos")),
        catchError(this.handleError)
      );
  }

  getByDocumento(Cpf: any): Observable<any[]> {
    return this.http
      .get<any[]>(
        `${environment.apiUrl}/Titular/buscar-por-documento?documento=${Cpf}`
      )
      .pipe(
        tap((data) => console.log("titular obtidos")),
        catchError(this.handleError)
      );
  }

  /** desativar titular de um contrato */
  desativarTitular(titularId: number): Observable<APIResponse> {
    return this.http
      .delete<APIResponse>(
        `${environment.apiUrl}/Titular/deletar-titular?id=${titularId}`
      )
      .pipe(
        tap((data) => console.log("titular desativado")),
        catchError(this.handleError)
      );
  }

  private handleError(err: HttpErrorResponse) {   
    return throwError(err);
  }
}
