//#region ODataApiGen Imports
//#endregion

export enum EnumStatusAuditoria {
  //#region ODataApiGen Members
  AGUARDANDO_LOTE_SER_ENVIADO = 1,
  EM_FILA_AUDITORIA_ADMINISTRATIVA = 2,
  EM_FILA_AUDITORIA_TECNICA = 3,
  AGUARDANDO_AUDITORIAS_ADMINISTRATIVAS = 4,
  AGUARDANDO_AUDITORIAS_TECNICAS = 5,
  APROVADO_ADMINISTRATIVAMENTE = 6,
  APROVADO_TECNICAMENTE = 7,
  NAO_NECESSITA_AUDITORIA_ADMINISTRATIVA = 8,
  NAO_NECESSITA_AUDITORIA_TECNICA = 9,
  EM_RECURSO_DENTISTA = 10,
  EM_RECURSO_AUDITOR = 11,
  GLOSADO = 12,
  //#endregion
}
