//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { DependenteService } from './dependente.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const DependenteServiceEntitySetConfig = {
  name: 'Dependente',
  entityType: 'Uniodonto.Domain.Entities.Dependente',
  service: DependenteService
} as EntitySetConfig;
//#endregion