//#region ODataApiGen ODataImports
import {
  EnumTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumStatusProcedimentoPlano } from './enumstatusprocedimentoplano.enum';
//#endregion

//#region ODataApiGen EnumTypeConfig
export const EnumStatusProcedimentoPlanoConfig = {
  name: 'EnumStatusProcedimentoPlano',
  members: EnumStatusProcedimentoPlano,
  fields: {
    EmAberto: {value: 0, annotations: [{"term":"Default.Description","string":"Em Aberto"}]},
    Concluido: {value: 10, annotations: [{"term":"Default.Description","string":"Conclu\u00EDdo"}]},
    Cancelado: {value: 20, annotations: [{"term":"Default.Description","string":"Cancelado"}]},
    Substituido: {value: 30, annotations: [{"term":"Default.Description","string":"Substituido"}]}
  }
} as EnumTypeConfig<EnumStatusProcedimentoPlano>;
//#endregion