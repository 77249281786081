//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumTipoProduto } from '../Model/Enum/enumtipoproduto.enum';
import { EnumTipoContrato } from '../Model/Enum/enumtipocontrato.enum';
import { Operadora } from './operadora.entity';
import { Clinica } from './clinica.entity';
import { Produto } from './produto.entity';
//#endregion

export interface TipoProduto {
  //#region ODataApiGen Properties
  Nome?: string;
  EnumTipoProduto: EnumTipoProduto;
  EnumTipoContrato: EnumTipoContrato;
  OperadoraId?: number;
  ClinicaId?: number;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  Produtos?: Produto[];
  Operadora?: Operadora;
  Clinica?: Clinica;
  //#endregion
}