//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { ContratoV2Service } from './contratov2.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const ContratoV2ServiceEntitySetConfig = {
  name: 'ContratoV2',
  entityType: 'Uniodonto.Domain.Entities.Contrato',
  service: ContratoV2Service
} as EntitySetConfig;
//#endregion