//#region ODataApiGen Imports
//#endregion

export enum EnumTipoPrecoContratoProdutoAto {
  //#region ODataApiGen Members
  INDEFINIDO = 0,
  QUANTIDADE_US = 1,
  VALOR_PROCEDIMENTO = 2,
  VALOR_US = 3,
  QUANTIDADEVALOR_US = 4,
  //#endregion
}
