//#region ODataApiGen ODataImports
import {
  SchemaConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { SituacaoAutorizacaoConfig } from './situacaoautorizacao.enum.config';
//#endregion

//#region ODataApiGen SchemaConfig
export const EnumSchema = {
  namespace: 'Uniodonto.Domain.Model.Results.UFast.Enum',
  enums: [SituacaoAutorizacaoConfig],
  entities: [],
  callables: [],
  containers: []
} as SchemaConfig;
//#endregion