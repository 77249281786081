//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Operadora } from './operadora.entity';
import { Clinica } from './clinica.entity';
import { Dentista } from './dentista.entity';
import { Usuario } from './usuario.entity';
import { ControleAcesso } from './controleacesso.entity';
//#endregion

export interface UsuarioClinica {
  //#region ODataApiGen Properties
  Id: number;
  UsuarioId?: number;
  ClinicaId?: number;
  ControleAcessoId?: number;
  DentistaId?: number;
  OperadoraId?: number;
  Usuario?: Usuario;
  Clinica?: Clinica;
  ControleAcesso?: ControleAcesso;
  Dentista?: Dentista;
  Operadora?: Operadora;
  //#endregion
}