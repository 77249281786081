//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Operadora } from './operadora.entity';
import { Nucleo } from './nucleo.entity';
//#endregion

export interface NucleoEndereco {
  //#region ODataApiGen Properties
  NucleoId?: number;
  TipoEndereco?: string;
  CEP?: string;
  Rua?: string;
  EnderecoNumero?: string;
  EnderecoComplemento?: string;
  EnderecoCaixaPostal?: string;
  Bairro?: string;
  Cidade?: string;
  UF?: string;
  CodIBGE?: string;
  UFNome?: string;
  UFIBGE?: string;
  OperadoraId?: number;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  Nucleo?: Nucleo;
  Operadora?: Operadora;
  //#endregion
}