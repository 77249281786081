import { NgxSpinnerService } from "ngx-spinner";
import { SideMenuModel } from "./../../../models/entities/sidemenu.model";
import { Injectable, OnDestroy, OnInit } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { ControleAcessoService } from "../controle-de-acesso.service";
import { MenuModel } from "../../../models/entities/menu.model";
import { AuthService } from "../../../core/auth/auth.service";
import { GetUserEmail, GetUserId } from "../../../helpers/localstorage.getter";

@Injectable({
  providedIn: "root",
})
export class MenusControleAcessoHandleService implements OnInit, OnDestroy {
  _menusSource = new BehaviorSubject<any>([]);
  _updatemenusSource = new BehaviorSubject<any>([]);

  private _unsubscribeAll: Subject<any>;

  private currentIds = [];
  private currentData: Array<SideMenuModel> = [];
  user: string = "User";

  constructor(
    private controleAcessoservice: ControleAcessoService,
    private _authService: AuthService,
    private ngxService: NgxSpinnerService
  ) {
    this._unsubscribeAll = new Subject();

    try {
      if (true || GetUserId()) {
        this._authService
          .check()
          .toPromise()
          .then((val) => (val ? this.initialize() : null))
          .catch((err) => console.log(err));
      }
    } catch (err) {
      throw new Error("Valid token not returned");
    }
  }

  ngOnDestroy(): void {
    if (this._unsubscribeAll) {
      this._unsubscribeAll.unsubscribe();
    }
  }

  ngOnInit(): void {}

  async initialize() {
    this.ngxService.show();
    // this.user = GetUserEmail();
    // const user = this._authService.userData;

    // let userRoleSelected = this._authService.currentRole;

    // let controleAcessoId;

    // switch(userRoleSelected) {
    //   case "CLINICA":
    //     let clinica = user.UsuarioClinicas.find(x => x.ClinidaId == this._authService.currentRoleKey);
    //     console.log(clinica);
    //     controleAcessoId = clinica.ControleAcessoId;
    //     break;
    //   case "OPERADORA":
    //     let operadora = user.OperadoraUsuarios.find(x => x.OperadoraId == this._authService.currentRoleKey);
    //     console.log(operadora);
    //     controleAcessoId = operadora.ControleAcessoId;
    //     break;
    // }

    // let resultControleAcesso = this.controleAcessoservice
    //   .getMenu(controleAcessoId)
    //   .toPromise();

    // let resultControleAcessoList;

    // if (resultControleAcesso) {
    //   resultControleAcessoList = await resultControleAcesso;
    // }

    // if (!resultControleAcessoList) {
    //   this.ngxService.hide();
    // }

    // const dataHandle = resultControleAcessoList["data"];


    const dataHandle = this._authService.menu;

    let treeData = {};
    dataHandle?.forEach((element) => {
      this.treeConstructor(element, treeData);
    });

    let formattedData = this.buildTree(treeData, 0);
    this.currentData = formattedData;

    this._menusSource.next([this.currentData, this.currentIds]);

    this.ngxService.hide();
  }

  //selectedItemsConstructor(obj: SideMenuModel) {
  //  if (this.currentIds.includes(obj.id)) {
  //    console.log("MARCAR MENU", obj);
  //  }
  //  obj.Subs.forEach((sub) => {
  //    this.selectedItemsConstructor(sub);
  //  });
  //}

  buildTree(data, level: number) {
    return Object.keys(data)
      .reduce((accumulator, key) => {
        const value = data[key];
        const parsedValue = JSON.parse(key);

        const sideMenuModel: SideMenuModel = new SideMenuModel();

        sideMenuModel.id = parsedValue.Id;
        sideMenuModel.isActive = false;
        sideMenuModel.title = parsedValue.Nome;
        sideMenuModel.icon = parsedValue.Icone;
        sideMenuModel.Rota = parsedValue.Rota;
        sideMenuModel.Ordem = parsedValue.Ordem;

        if (!sideMenuModel.Subs) {
          sideMenuModel.Subs = parsedValue.Subs;
        }

        if (sideMenuModel.Subs.length === 0) {
          if (this.currentIds.includes(sideMenuModel.id)) {
            sideMenuModel.show = true;
            sideMenuModel.type = "child";
          }
        } else {
          sideMenuModel.type = "father";
        }

        if (value != null) {
          if (typeof value === "object") {
            sideMenuModel.Subs = this.buildTree(value, level + 1);
          } else {
            sideMenuModel.title = value;
          }
        }

        return accumulator.concat(sideMenuModel);
      }, [])
      .sort((a, b) => {
        return a.Ordem > b.Ordem ? 1 : -1;
      });
  }

  treeConstructor(obj: SideMenuModel, populate) {
    populate[JSON.stringify(obj)] = {};

    obj.Subs.forEach((sub) => {
      this.treeConstructor(sub, populate[JSON.stringify(obj)]);
    });
  }

  findAncestors(array, id) {
    for (const item of array) {
      const result = item.id === id ? item : this.findAncestors(item.Subs, id);
      if (result) return result;
    }
  }
}
