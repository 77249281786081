import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumStatusIntegracao } from '../Uniodonto/Domain/Model/Enum/enumstatusintegracao.enum';
import { EnumClinicaTipo } from '../Uniodonto/Domain/Model/Enum/enumclinicatipo.enum';
import { EnumTipoDeClinica } from '../Uniodonto/Domain/Model/Enum/enumtipodeclinica.enum';
import { EnumTipoProfissional } from '../Uniodonto/Domain/Model/Enum/enumtipoprofissional.enum';
import { EnumEstadoCivil } from '../Uniodonto/Domain/Model/Enum/enumestadocivil.enum';
import { Operadora } from '../Uniodonto/Domain/Entities/operadora.entity';
import { Clinica } from '../Uniodonto/Domain/Entities/clinica.entity';
import { Guia } from '../Uniodonto/Domain/Entities/guia.entity';
import { GrupoFornecedorERP } from '../Uniodonto/Domain/Entities/grupofornecedorerp.entity';
import { Raca } from '../Uniodonto/Domain/Entities/raca.entity';
import { GrauInstrucao } from '../Uniodonto/Domain/Entities/grauinstrucao.entity';
//#endregion

@Injectable()
export class ClinicaV2Service extends ODataEntitySetService<Clinica> {
  constructor(client: ODataClient) {
    super(client, 'ClinicaV2', 'Uniodonto.Domain.Entities.Clinica');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public grauInstrucao(key: EntityKey<Clinica>): ODataNavigationPropertyResource<GrauInstrucao> { 
    return this.entity(key).navigationProperty<GrauInstrucao>('GrauInstrucao'); 
  }
  public fetchGrauInstrucao(key: EntityKey<Clinica>, options?: ODataQueryArgumentsOptions<GrauInstrucao>) {
    return this.fetchNavigationProperty<GrauInstrucao>(
      this.grauInstrucao(key), 
      'entity', options) as Observable<ODataEntity<GrauInstrucao>>;
  }
  public setGrauInstrucaoAsGrauInstrucao(key: EntityKey<Clinica>, target: ODataEntityResource<ODataEntity<GrauInstrucao>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.grauInstrucao(key).reference()
      .set(target, {etag});
  }
  public unsetGrauInstrucaoAsGrauInstrucao(key: EntityKey<Clinica>, {target, etag}: {target?: ODataEntityResource<ODataEntity<GrauInstrucao>>, etag?: string} = {}): Observable<any> {
    return this.grauInstrucao(key).reference()
      .unset({etag});
  }
  public grupoFornecedorERP(key: EntityKey<Clinica>): ODataNavigationPropertyResource<GrupoFornecedorERP> { 
    return this.entity(key).navigationProperty<GrupoFornecedorERP>('GrupoFornecedorERP'); 
  }
  public fetchGrupoFornecedorERP(key: EntityKey<Clinica>, options?: ODataQueryArgumentsOptions<GrupoFornecedorERP>) {
    return this.fetchNavigationProperty<GrupoFornecedorERP>(
      this.grupoFornecedorERP(key), 
      'entity', options) as Observable<ODataEntity<GrupoFornecedorERP>>;
  }
  public setGrupoFornecedorERPAsGrupoFornecedorERP(key: EntityKey<Clinica>, target: ODataEntityResource<ODataEntity<GrupoFornecedorERP>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.grupoFornecedorERP(key).reference()
      .set(target, {etag});
  }
  public unsetGrupoFornecedorERPAsGrupoFornecedorERP(key: EntityKey<Clinica>, {target, etag}: {target?: ODataEntityResource<ODataEntity<GrupoFornecedorERP>>, etag?: string} = {}): Observable<any> {
    return this.grupoFornecedorERP(key).reference()
      .unset({etag});
  }
  public guias(key: EntityKey<Clinica>): ODataNavigationPropertyResource<Guia> { 
    return this.entity(key).navigationProperty<Guia>('Guias'); 
  }
  public fetchGuias(key: EntityKey<Clinica>, options?: ODataQueryArgumentsOptions<Guia>) {
    return this.fetchNavigationProperty<Guia>(
      this.guias(key), 
      'entities', options) as Observable<ODataEntities<Guia>>;
  }
  public addGuiaToGuias(key: EntityKey<Clinica>, target: ODataEntityResource<ODataEntities<Guia>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.guias(key).reference()
      .add(target);
  }
  public removeGuiaFromGuias(key: EntityKey<Clinica>, {target, etag}: {target?: ODataEntityResource<ODataEntities<Guia>>, etag?: string} = {}): Observable<any> {
    return this.guias(key).reference()
      .remove(target);
  }
  public operadora(key: EntityKey<Clinica>): ODataNavigationPropertyResource<Operadora> { 
    return this.entity(key).navigationProperty<Operadora>('Operadora'); 
  }
  public fetchOperadora(key: EntityKey<Clinica>, options?: ODataQueryArgumentsOptions<Operadora>) {
    return this.fetchNavigationProperty<Operadora>(
      this.operadora(key), 
      'entity', options) as Observable<ODataEntity<Operadora>>;
  }
  public setOperadoraAsOperadora(key: EntityKey<Clinica>, target: ODataEntityResource<ODataEntity<Operadora>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.operadora(key).reference()
      .set(target, {etag});
  }
  public unsetOperadoraAsOperadora(key: EntityKey<Clinica>, {target, etag}: {target?: ODataEntityResource<ODataEntity<Operadora>>, etag?: string} = {}): Observable<any> {
    return this.operadora(key).reference()
      .unset({etag});
  }
  public raca(key: EntityKey<Clinica>): ODataNavigationPropertyResource<Raca> { 
    return this.entity(key).navigationProperty<Raca>('Raca'); 
  }
  public fetchRaca(key: EntityKey<Clinica>, options?: ODataQueryArgumentsOptions<Raca>) {
    return this.fetchNavigationProperty<Raca>(
      this.raca(key), 
      'entity', options) as Observable<ODataEntity<Raca>>;
  }
  public setRacaAsRaca(key: EntityKey<Clinica>, target: ODataEntityResource<ODataEntity<Raca>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.raca(key).reference()
      .set(target, {etag});
  }
  public unsetRacaAsRaca(key: EntityKey<Clinica>, {target, etag}: {target?: ODataEntityResource<ODataEntity<Raca>>, etag?: string} = {}): Observable<any> {
    return this.raca(key).reference()
      .unset({etag});
  }
  //#endregion
}
