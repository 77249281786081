//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumTipoEntidade } from '../Model/Enum/enumtipoentidade.enum';
import { Operadora } from './operadora.entity';
import { Clinica } from './clinica.entity';
//#endregion

export interface TipoEntidade {
  //#region ODataApiGen Properties
  Id: number;
  Nome?: string;
  EnumTipoEntidade: EnumTipoEntidade;
  OperadoraId?: number;
  ClinicaId?: number;
  Operadora?: Operadora;
  Clinica?: Clinica;
  //#endregion
}