//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Dependente } from './dependente.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const DependenteEntityConfig = {
  name: 'Dependente',
  keys: [{name: 'Id'}],
  fields: {
    Codigo: {type: 'Edm.String'},
    BeneficiarioId: {type: 'Edm.Int32'},
    TitularId: {type: 'Edm.Int32'},
    Ativo: {type: 'Edm.Boolean', nullable: false},
    DependenteCodigoExterno: {type: 'Edm.String'},
    DependenteSetor: {type: 'Edm.String'},
    DependenteObservacoes: {type: 'Edm.String'},
    DependenteEmpresa: {type: 'Edm.String'},
    DependenteUnidade: {type: 'Edm.String'},
    DependenteOutrasInformacoes: {type: 'Edm.String'},
    DependenteEnviarDmed: {type: 'Edm.Boolean', nullable: false},
    DependenteBloquearLancamentos: {type: 'Edm.Boolean', nullable: false},
    DependenteDataInclusao: {type: 'Edm.DateTimeOffset', nullable: false},
    DependenteDataDesligamento: {type: 'Edm.DateTimeOffset'},
    TaxaInscricaoPaga: {type: 'Edm.Boolean', nullable: false},
    DataFinalAtendimento: {type: 'Edm.DateTimeOffset'},
    MotivoDesligamentoId: {type: 'Edm.Int32'},
    CCO: {type: 'Edm.String'},
    RepasseOperadoraId: {type: 'Edm.Int32'},
    Intercambio: {type: 'Edm.Boolean', nullable: false},
    IntercambioEmergencia: {type: 'Edm.Boolean', nullable: false},
    GrupoFamiliarId: {type: 'Edm.Int32'},
    OperadoraId: {type: 'Edm.Int32'},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'},
    Beneficiario: {type: 'Uniodonto.Domain.Entities.Beneficiario', navigation: true, referentials: [{property: 'BeneficiarioId', referencedProperty: 'Id'}]},
    Titular: {type: 'Uniodonto.Domain.Entities.Titular', navigation: true, referentials: [{property: 'TitularId', referencedProperty: 'Id'}]},
    MotivoDesligamento: {type: 'Uniodonto.Domain.Entities.MotivoDesligamento', navigation: true, referentials: [{property: 'MotivoDesligamentoId', referencedProperty: 'Id'}]},
    Carteiras: {type: 'Uniodonto.Domain.Entities.Carteira', collection: true, navigation: true},
    RepasseOperadora: {type: 'Uniodonto.Domain.Entities.Operadora', navigation: true, referentials: [{property: 'OperadoraId', referencedProperty: 'Id'}]},
    GrupoFamiliar: {type: 'Uniodonto.Domain.Entities.GrupoFamiliar', navigation: true, referentials: [{property: 'GrupoFamiliarId', referencedProperty: 'Id'}]},
    Operadora: {type: 'Uniodonto.Domain.Entities.Operadora', navigation: true, referentials: [{property: 'OperadoraId', referencedProperty: 'Id'}]}
  }
} as StructuredTypeConfig<Dependente>;
//#endregion