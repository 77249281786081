//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumTipoPeriodo } from '../Model/Enum/enumtipoperiodo.enum';
import { Operadora } from './operadora.entity';
//#endregion

export interface Periodo {
  //#region ODataApiGen Properties
  NomePeriodo?: string;
  DataInicial: Date;
  DataFinal: Date;
  Observacao?: string;
  TipoPeriodo: EnumTipoPeriodo;
  OperadoraId?: number;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  Operadora?: Operadora;
  //#endregion
}