//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Carteira } from './carteira.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const CarteiraEntityConfig = {
  name: 'Carteira',
  keys: [{name: 'Id'}],
  fields: {
    CodigoCarteira: {type: 'Edm.String', maxLength: 50},
    BeneficiarioId: {type: 'Edm.Int32'},
    ContratoProdutoId: {type: 'Edm.Int32'},
    MotivoId: {type: 'Edm.Int32'},
    ETitular: {type: 'Edm.Boolean', nullable: false},
    TitularId: {type: 'Edm.Int32'},
    Status: {type: 'Uniodonto.Domain.Model.Enum.EnumStatusCarteira', nullable: false},
    Versao: {type: 'Edm.Int32', nullable: false},
    Ativo: {type: 'Edm.Boolean', nullable: false},
    DataExpiracao: {type: 'Edm.DateTimeOffset'},
    CobrarSegundaVia: {type: 'Edm.Boolean', nullable: false},
    PagamentoEfetuado: {type: 'Edm.Boolean', nullable: false},
    DependenteId: {type: 'Edm.Int32'},
    SubContratoId: {type: 'Edm.Int32'},
    Intercambio: {type: 'Edm.Boolean', nullable: false},
    OperadoraId: {type: 'Edm.Int32'},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'},
    Beneficiario: {type: 'Uniodonto.Domain.Entities.Beneficiario', navigation: true, referentials: [{property: 'BeneficiarioId', referencedProperty: 'Id'}]},
    ContratoProduto: {type: 'Uniodonto.Domain.Entities.ContratoProduto', navigation: true, referentials: [{property: 'ContratoProdutoId', referencedProperty: 'Id'}]},
    Motivo: {type: 'Uniodonto.Domain.Entities.MotivoSegundaVia', navigation: true},
    Titular: {type: 'Uniodonto.Domain.Entities.Titular', navigation: true, referentials: [{property: 'TitularId', referencedProperty: 'Id'}]},
    Dependente: {type: 'Uniodonto.Domain.Entities.Dependente', navigation: true, referentials: [{property: 'DependenteId', referencedProperty: 'Id'}]},
    SubContrato: {type: 'Uniodonto.Domain.Entities.SubContrato', navigation: true, referentials: [{property: 'SubContratoId', referencedProperty: 'Id'}]},
    Operadora: {type: 'Uniodonto.Domain.Entities.Operadora', navigation: true, referentials: [{property: 'OperadoraId', referencedProperty: 'Id'}]}
  }
} as StructuredTypeConfig<Carteira>;
//#endregion