//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { ConfiguracaoComissionamento } from './configuracaocomissionamento.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const ConfiguracaoComissionamentoEntityConfig = {
  name: 'ConfiguracaoComissionamento',
  keys: [{name: 'Id'}],
  fields: {
    ComissionamentoId: {type: 'Edm.Int32'},
    PeriodoInicial: {type: 'Edm.Int32', nullable: false},
    PeriodoFinal: {type: 'Edm.Int32'},
    NumeroMinimoUsuarios: {type: 'Edm.Int32'},
    NumeroMaximoUsuarios: {type: 'Edm.Int32'},
    Porcentagem: {type: 'Edm.Decimal', nullable: false},
    ValorFixo: {type: 'Edm.Decimal'},
    ValorMensalidade: {type: 'Edm.Boolean', nullable: false},
    ValorInscricao: {type: 'Edm.Boolean', nullable: false},
    ValorCarteirinha: {type: 'Edm.Boolean', nullable: false},
    ValorRetido: {type: 'Edm.Boolean'},
    OperadoraId: {type: 'Edm.Int32'},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'},
    Comissionamento: {type: 'Uniodonto.Domain.Entities.Comissionamento', navigation: true, referentials: [{property: 'ComissionamentoId', referencedProperty: 'Id'}]},
    Operadora: {type: 'Uniodonto.Domain.Entities.Operadora', navigation: true, referentials: [{property: 'OperadoraId', referencedProperty: 'Id'}]}
  }
} as StructuredTypeConfig<ConfiguracaoComissionamento>;
//#endregion