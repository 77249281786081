//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumEstadoCivil } from '../Model/Enum/enumestadocivil.enum';
import { Operadora } from './operadora.entity';
import { Clinica } from './clinica.entity';
import { Participante } from './participante.entity';
import { Habilidades } from './habilidades.entity';
import { DentistaEspecialidades } from './dentistaespecialidades.entity';
import { DentistaEspecialidadeEAtendimento } from './dentistaespecialidadeeatendimento.entity';
import { DentistaFormacaoEHabilidade } from './dentistaformacaoehabilidade.entity';
import { DentistaDocumento } from './dentistadocumento.entity';
import { UsuarioClinica } from './usuarioclinica.entity';
//#endregion

export interface Dentista {
  //#region ODataApiGen Properties
  Nome?: string;
  Idade: number;
  NomeMae?: string;
  RG?: string;
  OrgaoEmissor?: string;
  CPF?: string;
  CBO?: string;
  CRO: number;
  UFCRO?: string;
  Sexo?: string;
  DataNascimento: Date;
  EstadoCivil: EnumEstadoCivil;
  InscricaoINSS?: string;
  InscricaoISS?: string;
  PISPASEP?: string;
  ParticipanteId?: number;
  ClinicaId?: number;
  HabilidadesId?: number;
  OperadoraId?: number;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  Participante?: Participante;
  Clinica?: Clinica;
  Habilidades?: Habilidades;
  DentistaEspecialidades?: DentistaEspecialidades;
  DentistaEspecialidadeEAtendimentos?: DentistaEspecialidadeEAtendimento[];
  DentistaFormacaoEHabilidades?: DentistaFormacaoEHabilidade[];
  DentistaDocumentos?: DentistaDocumento[];
  UsuarioClinicas?: UsuarioClinica[];
  Operadora?: Operadora;
  //#endregion
}