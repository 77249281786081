//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Estado } from './estado.entity';
//#endregion

export interface Cidade {
  //#region ODataApiGen Properties
  CidadeIbgeCod?: string;
  CidadeNome?: string;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  Estado?: Estado;
  //#endregion
}