//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { HistoricoNaoIncluidosSIBService } from './historiconaoincluidossib.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const HistoricoNaoIncluidosSIBServiceEntitySetConfig = {
  name: 'HistoricoNaoIncluidosSIB',
  entityType: 'Uniodonto.Domain.Entities.HistoricoNaoIncluidosProcessamentoSIB',
  service: HistoricoNaoIncluidosSIBService
} as EntitySetConfig;
//#endregion