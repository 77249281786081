//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { DependenteGrupoFamiliar } from './dependentegrupofamiliar.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const DependenteGrupoFamiliarEntityConfig = {
  name: 'DependenteGrupoFamiliar',
  keys: [{name: 'Id'}],
  fields: {
    DependenteId: {type: 'Edm.Int32'},
    GrupoFamiliarId: {type: 'Edm.Int32'},
    OperadoraId: {type: 'Edm.Int32'},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'},
    Dependente: {type: 'Uniodonto.Domain.Entities.Dependente', navigation: true, referentials: [{property: 'DependenteId', referencedProperty: 'Id'}]},
    GrupoFamiliar: {type: 'Uniodonto.Domain.Entities.GrupoFamiliar', navigation: true, referentials: [{property: 'GrupoFamiliarId', referencedProperty: 'Id'}]},
    Operadora: {type: 'Uniodonto.Domain.Entities.Operadora', navigation: true, referentials: [{property: 'OperadoraId', referencedProperty: 'Id'}]}
  }
} as StructuredTypeConfig<DependenteGrupoFamiliar>;
//#endregion