//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Cliente } from './cliente.entity';
import { Beneficiario } from './beneficiario.entity';
import { UsuarioOperadora } from './usuariooperadora.entity';
import { Participante } from './participante.entity';
import { UsuarioClinica } from './usuarioclinica.entity';
import { OperadoraUsuarios } from './operadorausuarios.entity';
import { UserIdentity } from './Account/useridentity.entity';
//#endregion

export interface Usuario {
  //#region ODataApiGen Properties
  Nome?: string;
  Email?: string;
  Senha?: string;
  Documento?: string;
  GrupoUsuario: number;
  ParticipanteId?: number;
  RoleDefault?: string;
  EmailAzure?: string;
  BeneficiarioId?: number;
  ClienteId?: number;
  Ativo: boolean;
  PermitirEdicaoCarteirinha: boolean;
  TokenResetSenha?: string;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  Participante?: Participante;
  UserIdentity?: UserIdentity;
  UsuarioOperadoras?: UsuarioOperadora[];
  OperadoraUsuarios?: OperadoraUsuarios[];
  UsuarioClinicas?: UsuarioClinica[];
  Beneficiario?: Beneficiario;
  Cliente?: Cliente;
  //#endregion
}