//#region ODataApiGen ODataImports
import {
  EnumTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumEntidadesIntegradasERP } from './enumentidadesintegradaserp.enum';
//#endregion

//#region ODataApiGen EnumTypeConfig
export const EnumEntidadesIntegradasERPConfig = {
  name: 'EnumEntidadesIntegradasERP',
  members: EnumEntidadesIntegradasERP,
  fields: {
    CLIENTE: {value: 1, annotations: [{"term":"Default.Description","string":"Cliente"}]},
    CLINICA: {value: 2, annotations: [{"term":"Default.Description","string":"Cl\u00EDnica"}]},
    CLINICA_CONTATO: {value: 3, annotations: [{"term":"Default.Description","string":"Contato cl\u00EDcina"}]},
    CLINICA_ENDERECOS: {value: 4, annotations: [{"term":"Default.Description","string":"Endere\u00E7o cl\u00EDnica"}]},
    CONTATO: {value: 5, annotations: [{"term":"Default.Description","string":"Contato"}]}
  }
} as EnumTypeConfig<EnumEntidadesIntegradasERP>;
//#endregion