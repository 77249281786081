//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { GrupoAtoOdontologicoService } from './grupoatoodontologico.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const GrupoAtoOdontologicoServiceEntitySetConfig = {
  name: 'GrupoAtoOdontologico',
  entityType: 'Uniodonto.Domain.Entities.GrupoAtoOdontologico',
  service: GrupoAtoOdontologicoService
} as EntitySetConfig;
//#endregion