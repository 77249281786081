//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Periodo } from './periodo.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const PeriodoEntityConfig = {
  name: 'Periodo',
  keys: [{name: 'Id'}],
  fields: {
    NomePeriodo: {type: 'Edm.String'},
    DataInicial: {type: 'Edm.DateTimeOffset', nullable: false},
    DataFinal: {type: 'Edm.DateTimeOffset', nullable: false},
    Observacao: {type: 'Edm.String'},
    TipoPeriodo: {type: 'Uniodonto.Domain.Model.Enum.EnumTipoPeriodo', nullable: false},
    OperadoraId: {type: 'Edm.Int32'},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'},
    Operadora: {type: 'Uniodonto.Domain.Entities.Operadora', navigation: true, referentials: [{property: 'OperadoraId', referencedProperty: 'Id'}]}
  }
} as StructuredTypeConfig<Periodo>;
//#endregion