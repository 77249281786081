//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumStatusFaturamento } from '../Model/Enum/enumstatusfaturamento.enum';
import { Cliente } from './cliente.entity';
import { ContratoProduto } from './contratoproduto.entity';
import { Contrato } from './contrato.entity';
import { PreEspelho } from './preespelho.entity';
import { Operadora } from './operadora.entity';
import { Titular } from './titular.entity';
import { Dependente } from './dependente.entity';
import { SubContrato } from './subcontrato.entity';
import { ValorProcedimentoTipoProduto } from './valorprocedimentotipoproduto.entity';
import { Produto } from './produto.entity';
import { GrupoFamiliar } from './grupofamiliar.entity';
//#endregion

export interface PreEspelhoDetalhe {
  //#region ODataApiGen Properties
  ClienteId?: number;
  ContratoId?: number;
  ContratoProdutoId?: number;
  SubContratoId?: number;
  ProdutoId?: number;
  TitularId?: number;
  DependenteId?: number;
  ValorMensalidade: number;
  ValorMensalidaderRepasse: number;
  ValorTaxaInscricao: number;
  ValorTaxaInscricaoRepasse: number;
  ValorCarteirinha: number;
  ValorCarteirinhaRepasse: number;
  ValorTotalProcedimentosPOS: number;
  ValorTotalProcedimentosMisto: number;
  PreEspelhoId?: number;
  Faturado: boolean;
  Agregado: boolean;
  GrupoFamiliarId?: number;
  StatusFaturamento: EnumStatusFaturamento;
  OperadoraRepasseId?: number;
  OperadoraId?: number;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  ContratoProduto?: ContratoProduto;
  OperadoraRepasse?: Operadora;
  Cliente?: Cliente;
  Contrato?: Contrato;
  Produto?: Produto;
  Titular?: Titular;
  Dependente?: Dependente;
  PreEspelho?: PreEspelho;
  GrupoFamiliar?: GrupoFamiliar;
  SubContrato?: SubContrato;
  ValorProcedimentoTipoProdutos?: ValorProcedimentoTipoProduto[];
  Operadora?: Operadora;
  //#endregion
}