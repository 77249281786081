//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Arquivo } from './arquivo.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const ArquivoEntityConfig = {
  name: 'Arquivo',
  keys: [{name: 'Id'}],
  fields: {
    NomeDoArquivo: {type: 'Edm.String'},
    FormatoDoArquivo: {type: 'Edm.String'},
    TipoDoArquivo: {type: 'Edm.String'},
    AcaoArquivo: {type: 'Edm.String'},
    UrlArquivo: {type: 'Edm.String'},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'}
  }
} as StructuredTypeConfig<Arquivo>;
//#endregion