//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { ContratoOperadoraRepasseService } from './contratooperadorarepasse.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const ContratoOperadoraRepasseServiceEntitySetConfig = {
  name: 'ContratoOperadoraRepasse',
  entityType: 'Uniodonto.Domain.Entities.ContratoOperadoraRepasse',
  service: ContratoOperadoraRepasseService
} as EntitySetConfig;
//#endregion