//#region ODataApiGen ODataImports
import {
  EnumTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumStatusRecursoProcedimento } from './enumstatusrecursoprocedimento.enum';
//#endregion

//#region ODataApiGen EnumTypeConfig
export const EnumStatusRecursoProcedimentoConfig = {
  name: 'EnumStatusRecursoProcedimento',
  members: EnumStatusRecursoProcedimento,
  fields: {
    AGUARDANDO_RECURSO_DENTISTA: {value: 1},
    AGUARDANDO_ANALISE_AUDITOR: {value: 2},
    ACEITO_AUDITOR: {value: 3},
    RECUSADO_AUDITOR: {value: 4}
  }
} as EnumTypeConfig<EnumStatusRecursoProcedimento>;
//#endregion