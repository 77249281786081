//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { HistoricoConsultaLotePrestadoresRPSV2Service } from './historicoconsultaloteprestadoresrpsv2.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const HistoricoConsultaLotePrestadoresRPSV2ServiceEntitySetConfig = {
  name: 'HistoricoConsultaLotePrestadoresRPSV2',
  entityType: 'Uniodonto.Domain.Entities.HistoricoConsultaLotePrestadoresRPS',
  service: HistoricoConsultaLotePrestadoresRPSV2Service
} as EntitySetConfig;
//#endregion