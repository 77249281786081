//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Operadora } from './operadora.entity';
import { Beneficiario } from './beneficiario.entity';
import { Endereco } from './endereco.entity';
//#endregion

export interface EnderecoBeneficiario {
  //#region ODataApiGen Properties
  EnderecoId?: number;
  BeneficiarioId?: number;
  OperadoraId?: number;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  Endereco?: Endereco;
  Beneficiario?: Beneficiario;
  Operadora?: Operadora;
  //#endregion
}