//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { HistoricoNaoIncluidosProcessamentoSIB } from './historiconaoincluidosprocessamentosib.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const HistoricoNaoIncluidosProcessamentoSIBEntityConfig = {
  name: 'HistoricoNaoIncluidosProcessamentoSIB',
  keys: [{name: 'Id'}],
  fields: {
    Hash: {type: 'Edm.String'},
    NumeroProtocolo: {type: 'Edm.String'},
    NomeArquivo: {type: 'Edm.String'},
    CCO: {type: 'Edm.String'},
    CodigoBeneficiario: {type: 'Edm.String'},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'}
  }
} as StructuredTypeConfig<HistoricoNaoIncluidosProcessamentoSIB>;
//#endregion