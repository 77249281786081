//#region ODataApiGen ODataImports
import {
  EnumTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumTipoDeClinica } from './enumtipodeclinica.enum';
//#endregion

//#region ODataApiGen EnumTypeConfig
export const EnumTipoDeClinicaConfig = {
  name: 'EnumTipoDeClinica',
  members: EnumTipoDeClinica,
  fields: {
    CREDENCIADA: {value: 1},
    RADIOLOGIA: {value: 2},
    OPERADORA: {value: 3},
    PLANTAO: {value: 4}
  }
} as EnumTypeConfig<EnumTipoDeClinica>;
//#endregion