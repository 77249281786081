//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Job } from './job.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const JobEntityConfig = {
  name: 'Job',
  keys: [{name: 'Id'}],
  fields: {
    NomeJob: {type: 'Edm.String'},
    DescricaoJob: {type: 'Edm.String'},
    RecorrenciaJob: {type: 'Edm.String'},
    StatusJobId: {type: 'Edm.Int32'},
    StatusLoteJob: {type: 'Uniodonto.Domain.Model.Enum.EnumStatusLoteJob', nullable: false},
    TipoLoteJob: {type: 'Uniodonto.Domain.Model.Enum.EnumTipoLoteJob', nullable: false},
    JobCommand: {type: 'Edm.String'},
    JobResult: {type: 'Edm.String'},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'},
    StatusJob: {type: 'Uniodonto.Domain.Entities.StatusJob', navigation: true, referentials: [{property: 'StatusJobId', referencedProperty: 'Id'}]}
  }
} as StructuredTypeConfig<Job>;
//#endregion