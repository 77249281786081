//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Titular } from './titular.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const TitularEntityConfig = {
  name: 'Titular',
  keys: [{name: 'Id'}],
  fields: {
    Ativo: {type: 'Edm.Boolean', nullable: false},
    TitularCodigoExterno: {type: 'Edm.String'},
    Codigo: {type: 'Edm.String'},
    TitularSetor: {type: 'Edm.String'},
    TitularObservacoes: {type: 'Edm.String'},
    TitularEmpresa: {type: 'Edm.String'},
    TitularUnidade: {type: 'Edm.String'},
    TitularOutrasInformacoes: {type: 'Edm.String'},
    TitularEnviarDmed: {type: 'Edm.Boolean', nullable: false},
    TitularBloquearLancamentos: {type: 'Edm.Boolean', nullable: false},
    TitularCountEmissoesDeCartao: {type: 'Edm.Int32', nullable: false},
    TitularDataInclusao: {type: 'Edm.DateTimeOffset', nullable: false},
    TitularDataDesligamento: {type: 'Edm.DateTimeOffset'},
    DataFinalAtendimento: {type: 'Edm.DateTimeOffset'},
    BeneficiarioId: {type: 'Edm.Int32'},
    TaxaInscricaoPaga: {type: 'Edm.Boolean', nullable: false},
    DataPagamentoTaxaInscricao: {type: 'Edm.DateTimeOffset'},
    MotivoDesligamentoId: {type: 'Edm.Int32'},
    TitularPossuiCarencia: {type: 'Edm.Boolean', nullable: false},
    ContratoProdutoId: {type: 'Edm.Int32'},
    PerfilCarenciaId: {type: 'Edm.Int32'},
    Inadimplente: {type: 'Edm.Boolean', nullable: false},
    DataDe: {type: 'Edm.DateTimeOffset', nullable: false},
    DataAte: {type: 'Edm.DateTimeOffset'},
    CCO: {type: 'Edm.String'},
    SubContratoId: {type: 'Edm.Int32'},
    RepasseOperadoraId: {type: 'Edm.Int32'},
    Intercambio: {type: 'Edm.Boolean', nullable: false},
    IntercambioEmergencia: {type: 'Edm.Boolean', nullable: false},
    OperadoraId: {type: 'Edm.Int32'},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'},
    Beneficiario: {type: 'Uniodonto.Domain.Entities.Beneficiario', navigation: true, referentials: [{property: 'BeneficiarioId', referencedProperty: 'Id'}]},
    Dependentes: {type: 'Uniodonto.Domain.Entities.Dependente', collection: true, navigation: true},
    Carteiras: {type: 'Uniodonto.Domain.Entities.Carteira', collection: true, navigation: true},
    MotivoDesligamento: {type: 'Uniodonto.Domain.Entities.MotivoDesligamento', navigation: true, referentials: [{property: 'MotivoDesligamentoId', referencedProperty: 'Id'}]},
    ContratoProduto: {type: 'Uniodonto.Domain.Entities.ContratoProduto', navigation: true, referentials: [{property: 'ContratoProdutoId', referencedProperty: 'Id'}]},
    PerfilCarencia: {type: 'Uniodonto.Domain.Entities.PerfilCarencia', navigation: true, referentials: [{property: 'PerfilCarenciaId', referencedProperty: 'Id'}]},
    SubContrato: {type: 'Uniodonto.Domain.Entities.SubContrato', navigation: true, referentials: [{property: 'SubContratoId', referencedProperty: 'Id'}]},
    RepasseOperadora: {type: 'Uniodonto.Domain.Entities.Operadora', navigation: true, referentials: [{property: 'OperadoraId', referencedProperty: 'Id'}]},
    Operadora: {type: 'Uniodonto.Domain.Entities.Operadora', navigation: true, referentials: [{property: 'OperadoraId', referencedProperty: 'Id'}]}
  }
} as StructuredTypeConfig<Titular>;
//#endregion