//#region ODataApiGen ODataImports
import {
  EnumTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumStatusImportacaoBeneficiario } from './enumstatusimportacaobeneficiario.enum';
//#endregion

//#region ODataApiGen EnumTypeConfig
export const EnumStatusImportacaoBeneficiarioConfig = {
  name: 'EnumStatusImportacaoBeneficiario',
  members: EnumStatusImportacaoBeneficiario,
  fields: {
    REGISTRO_COMPLETO: {value: 1, annotations: [{"term":"Default.Description","string":"Registro completo"}]},
    REGISTRO_INCOMPLETO: {value: 2, annotations: [{"term":"Default.Description","string":"Registro incompleto"}]},
    REGISTRO_ERRO: {value: 3, annotations: [{"term":"Default.Description","string":"Registro com erro"}]},
    REGISTRO_IMPORTANDO: {value: 4, annotations: [{"term":"Default.Description","string":"Importando registro"}]}
  }
} as EnumTypeConfig<EnumStatusImportacaoBeneficiario>;
//#endregion