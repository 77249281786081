//#region ODataApiGen ODataImports
import {
  EnumTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumTipoOrcamento } from './enumtipoorcamento.enum';
//#endregion

//#region ODataApiGen EnumTypeConfig
export const EnumTipoOrcamentoConfig = {
  name: 'EnumTipoOrcamento',
  members: EnumTipoOrcamento,
  fields: {
    NORMAL: {value: 0},
    REEMBOLSO: {value: 1},
    INTERCAMBIO: {value: 2}
  }
} as EnumTypeConfig<EnumTipoOrcamento>;
//#endregion