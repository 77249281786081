//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumStatusGuia } from '../Model/Enum/enumstatusguia.enum';
import { EnumTipoGuia } from '../Model/Enum/enumtipoguia.enum';
import { EnumTipoAtendimentoGuia } from '../Model/Enum/enumtipoatendimentoguia.enum';
import { SituacaoAutorizacao } from '../Model/Results/UFast/Enum/situacaoautorizacao.enum';
import { Operadora } from './operadora.entity';
import { Carteira } from './carteira.entity';
import { Orcamento } from './orcamento.entity';
import { Clinica } from './clinica.entity';
import { ValorProcedimentoTipoProduto } from './valorprocedimentotipoproduto.entity';
import { Procedimentos } from './procedimentos.entity';
import { Lote } from './lote.entity';
import { ProfissionalSolicitante } from './profissionalsolicitante.entity';
import { Usuario } from './usuario.entity';
import { Reembolso } from './reembolso.entity';
import { GuiaAnexo } from './guiaanexo.entity';
import { GuiaHistorico } from './guiahistorico.entity';
//#endregion

export interface Guia {
  //#region ODataApiGen Properties
  NumeroGuia?: string;
  Senha?: string;
  ValidadeSenha: Date;
  OrcamentoId?: number;
  CarteiraId?: number;
  ClinicaId?: number;
  DataAnalise: Date;
  DataAprovacao: Date;
  DataExecucao: Date;
  DataConclusao: Date;
  UsuarioId?: number;
  Ativa: boolean;
  LoteId?: number;
  IsExecutado: boolean;
  IsAnalisado: boolean;
  StatusGuia: EnumStatusGuia;
  TipoGuia: EnumTipoGuia;
  GuiaPaga?: boolean;
  ReembolsoId?: number;
  Observacao?: string;
  TipoAtendimentoGuia: EnumTipoAtendimentoGuia;
  UFastGuiaId?: string;
  UFastAutorizadora?: string;
  ProfissionalSolicitanteId?: number;
  UFastStatus?: SituacaoAutorizacao;
  OperadoraId?: number;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  Orcamento?: Orcamento;
  Carteira?: Carteira;
  Usuario?: Usuario;
  Procedimentos?: Procedimentos[];
  Lote?: Lote;
  Clinica?: Clinica;
  Reembolso?: Reembolso;
  ValorProcedimentoTipoProdutos?: ValorProcedimentoTipoProduto[];
  GuiaAnexos?: GuiaAnexo[];
  ProfissionalSolicitante?: ProfissionalSolicitante;
  GuiaHistorico?: GuiaHistorico[];
  Operadora?: Operadora;
  //#endregion
}