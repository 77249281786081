//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumTipoGlosa } from '../Model/Enum/enumtipoglosa.enum';
import { EnumGrupoGlosa } from '../Model/Enum/enumgrupoglosa.enum';
import { Operadora } from './operadora.entity';
//#endregion

export interface Glosa {
  //#region ODataApiGen Properties
  CodigoANS?: string;
  Descricao?: string;
  Tipo: EnumTipoGlosa;
  Grupo: EnumGrupoGlosa;
  OperadoraId?: number;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  Operadora?: Operadora;
  //#endregion
}