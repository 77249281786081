//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Documentos } from './documentos.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const DocumentosEntityConfig = {
  name: 'Documentos',
  keys: [{name: 'Id'}],
  fields: {
    Arquivo: {type: 'Edm.String'},
    Tipo: {type: 'Edm.String'},
    Observacao: {type: 'Edm.String'},
    NomeArquivo: {type: 'Edm.String'},
    DataArquivo: {type: 'Edm.DateTimeOffset', nullable: false},
    OperadoraId: {type: 'Edm.Int32'},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'},
    Operadora: {type: 'Uniodonto.Domain.Entities.Operadora', navigation: true, referentials: [{property: 'OperadoraId', referencedProperty: 'Id'}]}
  }
} as StructuredTypeConfig<Documentos>;
//#endregion