//#region ODataApiGen Imports
//#endregion

export enum EnumMetodoPagamento {
  //#region ODataApiGen Members
  Nenhum = 0,
  Boleto = 1,
  CartaoCredito = 2,
  PIX = 3,
  //#endregion
}
