//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { ContratoProdutoV2Service } from './contratoprodutov2.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const ContratoProdutoV2ServiceEntitySetConfig = {
  name: 'ContratoProdutoV2',
  entityType: 'Uniodonto.Domain.Entities.ContratoProduto',
  service: ContratoProdutoV2Service
} as EntitySetConfig;
//#endregion