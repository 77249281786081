//#region ODataApiGen Imports
//#endregion

export enum EnumStatusGuia {
  //#region ODataApiGen Members
  Nenhum = 0,
  AguardandoAutorizacao = 10,
  NaoAutorizado = 20,
  ParcialmenteAutorizado = 30,
  Autorizado = 40,
  ParcialmenteExecutado = 50,
  Executado = 60,
  Cancelado = 70,
  ParcialmenteGlosado = 80,
  Glosado = 90,
  Concluido = 999,
  //#endregion
}
