//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumTipoOrcamento } from '../Model/Enum/enumtipoorcamento.enum';
import { EnumOrigem } from '../Model/Enum/enumorigem.enum';
import { Operadora } from './operadora.entity';
import { Carteira } from './carteira.entity';
import { Clinica } from './clinica.entity';
import { ValorProcedimentoTipoProduto } from './valorprocedimentotipoproduto.entity';
import { Procedimentos } from './procedimentos.entity';
import { Dentista } from './dentista.entity';
import { ProfissionalSolicitante } from './profissionalsolicitante.entity';
import { DentistaReembolso } from './dentistareembolso.entity';
//#endregion

export interface Orcamento {
  //#region ODataApiGen Properties
  CarteiraId?: number;
  DentistaId?: number;
  StatusId: number;
  ClinicaId?: number;
  TipoOrcamento: EnumTipoOrcamento;
  Origem: EnumOrigem;
  UFastGuiaSolicitante?: string;
  ProfissionalSolicitanteId?: number;
  OperadoraId?: number;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  Carteira?: Carteira;
  Clinica?: Clinica;
  Procedimentos?: Procedimentos[];
  Dentista?: Dentista;
  ValorProcedimentoTipoProdutos?: ValorProcedimentoTipoProduto[];
  UFastDentistaReembolso?: DentistaReembolso;
  ProfissionalSolicitante?: ProfissionalSolicitante;
  Operadora?: Operadora;
  //#endregion
}