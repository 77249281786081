//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Operadora } from './operadora.entity';
import { Clinica } from './clinica.entity';
import { Especialidade } from './especialidade.entity';
//#endregion

export interface ClinicaEspecialidadeEAtendimento {
  //#region ODataApiGen Properties
  ClinicaId?: number;
  EspecialidadeId?: number;
  IsEspecialista: boolean;
  OperadoraId?: number;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  Clinica?: Clinica;
  Especialidade?: Especialidade;
  Operadora?: Operadora;
  //#endregion
}