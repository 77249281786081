//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { TRMAtoOperadora } from './trmatooperadora.entity';
import { UsuarioOperadora } from './usuariooperadora.entity';
import { Grupo } from './grupo.entity';
import { AtoOdontologico } from './atoodontologico.entity';
import { ContratoProdutoAtoPreco } from './contratoprodutoatopreco.entity';
import { SubContrato } from './subcontrato.entity';
import { ContratoOperadoraRepasse } from './contratooperadorarepasse.entity';
import { Produto } from './produto.entity';
import { Participante } from './participante.entity';
import { Nucleo } from './nucleo.entity';
import { Contato } from './contato.entity';
import { Repasse } from './repasse.entity';
import { Responsavel } from './responsavel.entity';
import { Documentos } from './documentos.entity';
import { AreaGeografica } from './areageografica.entity';
import { AtoRestricaoIdade } from './atorestricaoidade.entity';
import { OperadoraUsuarios } from './operadorausuarios.entity';
import { ControleAcesso } from './controleacesso.entity';
import { Glosa } from './glosa.entity';
import { OperadoraParametro } from './operadoraparametro.entity';
import { Pagamento } from './pagamento.entity';
import { Ufast } from './ufast.entity';
import { USPagamentoRepasse } from './uspagamentorepasse.entity';
import { AreaAtuacao } from './areaatuacao.entity';
import { OperadoraDocumento } from './operadoradocumento.entity';
import { NegociacaoOperadora } from './negociacaooperadora.entity';
import { MotivoGlosa } from './motivoglosa.entity';
//#endregion

export interface Operadora {
  //#region ODataApiGen Properties
  Ativa: boolean;
  Codigo?: string;
  TipoCadastro?: string;
  CNES?: string;
  CNPJ?: string;
  CPFResponsavelCNPJ?: string;
  RazaoSocial?: string;
  NomeFantasia?: string;
  Homepage?: string;
  InscricaoEstadual?: string;
  InscricaoMunicipal?: string;
  CRO?: string;
  UFCRO?: string;
  AutorizaEmail: boolean;
  AceitaAtosAdicionais: boolean;
  ValorUS: number;
  DataFundacao?: Date;
  DataConvite?: Date;
  DataAssinaturaContrato?: Date;
  DataRecebimentoContrato?: Date;
  DataRecebimentoDocumentacao?: Date;
  ValorCotaParteOperadoraPrestadoras?: string;
  CodOperadoraANS?: string;
  CodigoFederacao?: string;
  EmailCentralAtendimento?: string;
  CelularCentralAtendimento?: string;
  TelefoneCentralAtendimento?: string;
  ObsCentralAtendimento?: string;
  Atende24hrsCentraldeAtendimento: boolean;
  RepasseId?: number;
  AssinaturaEletronica: boolean;
  PermiteRegraConflitoDuplicado: boolean;
  DocumentoId?: number;
  ParticipanteId?: number;
  OperadoraAreaAtuacaoId?: number;
  PagamentoId?: number;
  DataAreaId?: string;
  UFastChaveDeAcesso?: string;
  BeneficiarioControleAcessoId?: number;
  ClienteControleAcessoId?: number;
  UFastAtivo: boolean;
  UsValorIntercambioUFAST: number;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  Repasse?: Repasse;
  Responsaveis?: Responsavel[];
  Documento?: Documentos;
  Participante?: Participante;
  OperadoraAreaAtuacao?: AreaGeografica;
  Pagamento?: Pagamento;
  Ufast?: Ufast;
  USPagamentoRepasse?: USPagamentoRepasse;
  Nucleos?: Nucleo[];
  AreaAtuacaos?: AreaAtuacao[];
  Contatos?: Contato[];
  UsuarioOperadoras?: UsuarioOperadora[];
  OperadoraUsuarios?: OperadoraUsuarios[];
  OperadoraDocumentos?: OperadoraDocumento[];
  NegociacaoOperadoras?: NegociacaoOperadora[];
  Grupos?: Grupo[];
  AtosOdontologicos?: AtoOdontologico[];
  Glosas?: Glosa[];
  MotivoGlosas?: MotivoGlosa[];
  TRMAtoOperadoras?: TRMAtoOperadora[];
  AtoRestricaoIdades?: AtoRestricaoIdade[];
  Produtos?: Produto[];
  ContratoOperadoraRepasses?: ContratoOperadoraRepasse[];
  ContratoProdutoAtoPrecos?: ContratoProdutoAtoPreco[];
  OperadoraParametro?: OperadoraParametro;
  SubContratos?: SubContrato[];
  BeneficiarioControleAcesso?: ControleAcesso;
  ClienteControleAcesso?: ControleAcesso;
  //#endregion
}