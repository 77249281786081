//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Usuario } from './usuario.entity';
//#endregion

export interface LogTrocaSenha {
  //#region ODataApiGen Properties
  UsuarioId?: number;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  Usuario?: Usuario;
  //#endregion
}