import { FormGroup } from "@angular/forms";
import * as moment from "moment";
import { isNullOrUndefined } from "util";

const formatterMoneyPTbrDecimilesimal = new Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
  minimumFractionDigits: 4,
});

export const fnMoneyPTbrDecimilesimalFormatValue = (value) => {
  return formatterMoneyPTbrDecimilesimal.format(value);
};

const formatterMoneyPTbr = new Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
  minimumFractionDigits: 2,
});

export const fnMoneyPTbrFormatValue = (value) => {
  return formatterMoneyPTbr.format(value);
};

export const RetornaDenteId = (denteFace, face) => {
  var dente;

  switch (face) {
    case "o":
      dente = denteFace.split("o")[0];
      break;
    case "l":
      dente = denteFace.split("l")[0];
      break;
    case "m":
      dente = denteFace.split("m")[0];
      break;
    case "v":
      dente = denteFace.split("v")[0];
      break;
    case "d":
      dente = denteFace.split("d")[0];
      break;
    default:
      break;
  }

  return dente;
};

export const RetornaIdade = (
  ano_aniversario: number,
  mes_aniversario: number,
  dia_aniversario: number
) => {
  var d = new Date(),
    ano_atual = d.getFullYear(),
    mes_atual = d.getMonth() + 1,
    dia_atual = d.getDate(),
    ano_aniversario = +ano_aniversario,
    mes_aniversario = +mes_aniversario,
    dia_aniversario = +dia_aniversario,
    quantos_anos = ano_atual - ano_aniversario;

  if (
    mes_atual < mes_aniversario ||
    (mes_atual == mes_aniversario && dia_atual < dia_aniversario)
  ) {
    quantos_anos--;
  }

  return quantos_anos < 0 ? 0 : quantos_anos;
};

export const RetornaIdadeComData = (dataSelecionada: string) => {
  if (dataSelecionada) {
    const data$ = dataSelecionada.split("-");

    var d = new Date(),
      ano_atual = d.getFullYear(),
      mes_atual = d.getMonth() + 1,
      dia_atual = d.getDate(),
      ano_aniversario = +data$[0],
      mes_aniversario = +data$[1],
      dia_aniversario = +data$[2],
      quantos_anos = ano_atual - ano_aniversario;

    if (
      mes_atual < mes_aniversario ||
      (mes_atual == mes_aniversario && dia_atual < dia_aniversario)
    ) {
      quantos_anos--;
    }

    return quantos_anos < 0 ? 0 : quantos_anos;
  }
};

export const RetornaQuantidadeFaces = (dominiosDoProcedimento: any[]) => {
  var dominioFace = dominiosDoProcedimento.filter(
    (x) => x.DOMINIO_CODIGO === "DEN/FAC"
  )[0];
  return dominioFace.QUANTIDADE_FACES;
};

export const idadePorDascimento = (dateOfBirth: any): any => {
  var obj ={
    idade:0,
    label:'',
  }

  if( moment().diff(dateOfBirth,'years') > 0){
    obj.idade = moment().diff(dateOfBirth,'years')
    obj.label = 'Idade'
  }else{
    obj.idade = moment().diff(dateOfBirth,'months')
    obj.label = 'Mês'
  }
  return obj
}

export const idadeStr = (dateOfBirth: any): any => {
  let idadeObj = idadePorDascimento(dateOfBirth);
  if (idadeObj.idade == 1) {
    return `${idadeObj.idade} ${idadeObj.label == 'Mês' ? 'mês' : 'ano'}`;
  } else {
    return `${idadeObj.idade} ${idadeObj.label == 'Mês' ? 'meses' : 'anos'}`;
  }
}


export const ValidaQntdeFacesSelecionadas = (
  denteId: number,
  qntdeFaces: number,
  selecoes: any[]
) => {
  var v = selecoes.filter((x) => x.id === denteId);

  if (v.length >= qntdeFaces) {
    return false;
  } else {
    return true;
  }
};

export const RetornaStringToBoolean = (value: string) => {
  if (value != null) return value === "true";
};

export const RetornaTrueFalse = (value: string) => {
  if (value != null)
    if (value === "Sim") return true;
    else return false;
};

export const RetornaSimNao = (value: boolean) => {
  if (value != null)
    if (value === true) return "Sim";
    else return "Não";
};

export const RetornaNumeros = (value: string) => {
  if (value) {
    const newValue = value.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, "");
    return newValue;
  }
};

export const RetornaNumerosCPF = (value: string) => {
  if (value != null)
    var cpf = value.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, "");
  return cpf;
};

export const RetornaNumerosCEP = (value: string) => {
  if (value != null)
    var cep = value.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, "");
  return cep;
};

export const RetornaCPFFormatado = (value: string) => {
  if (value != null) var cpf = value.replace(/[^\d]/g, "");
  if (cpf) return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
};

export const RetornaCNPJormatado = (value: string) => {
  //55.633.153/0001-68
  if (value != null) var cnpj = value.replace(/[^\d]/g, "");
  if (cnpj)
    return cnpj.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      "$1.$2.$3/$4-$5"
    );
};

export const RetornaDocumentoFormatado = (value: string) => {
  //55.633.153/0001-68
  if (isNullOrUndefined(value)) { 
    return '';
  }
  if (value.length > 11) {
    var cnpj = value.replace(/[^\d]/g, "");
    if (cnpj)
      return cnpj.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
        "$1.$2.$3/$4-$5"
      );
  } else {
    var cpf = value.replace(/[^\d]/g, "");
    if (cpf) return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  }
};

export const ValidarData = (value: string):boolean => {

  var dataAtual =   moment(new Date()).format('YYYY-MM-DD');
  var dataFim   =   moment(value).format('YYYY-MM-DD')

  return dataFim > dataAtual? true: false;
}

export const RetornaBoolean = (value: string) => {
  if (value != null)
    if (value === "true") return true;
    else return false;
};

export const toForm = (entity: any, form: FormGroup) => {
  if (isNullOrUndefined(entity) || isNullOrUndefined(form)) {
    return;
  }
  Object.keys(entity).forEach(key => {
    if (form.controls[key]) {
      if (isDate(entity[key])) console.log(key); 
      form.controls[key].setValue(
        isDate(entity[key]) ? 
        moment(entity[key]).format("yyyy-MM-DD") :
        entity[key], { emitEvent: false }
      );
    }
  });
}

export const enableForm = (entity: any, form: FormGroup) => {
  if (isNullOrUndefined(entity) || isNullOrUndefined(form)) {
    return;
  }
  Object.keys(entity).forEach(key => {
    if (form.controls[key]) {
      form.controls[key].enable();
    }
  });
}

export const isDate = (value: any) => {
  return Object.prototype.toString.call(value) == '[object Date]';
}