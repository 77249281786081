//#region ODataApiGen Imports
//#endregion

export enum EnumTipoLoteJob {
  //#region ODataApiGen Members
  ReajusteContrato = 1,
  PreEspelhoFaturamento = 2,
  PreEspelhoCobranca = 3,
  //#endregion
}
