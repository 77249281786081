//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { NucleoEndereco } from './nucleoendereco.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const NucleoEnderecoEntityConfig = {
  name: 'NucleoEndereco',
  keys: [{name: 'Id'}],
  fields: {
    NucleoId: {type: 'Edm.Int32'},
    TipoEndereco: {type: 'Edm.String'},
    CEP: {type: 'Edm.String'},
    Rua: {type: 'Edm.String'},
    EnderecoNumero: {type: 'Edm.String'},
    EnderecoComplemento: {type: 'Edm.String'},
    EnderecoCaixaPostal: {type: 'Edm.String'},
    Bairro: {type: 'Edm.String'},
    Cidade: {type: 'Edm.String'},
    UF: {type: 'Edm.String'},
    CodIBGE: {type: 'Edm.String'},
    UFNome: {type: 'Edm.String'},
    UFIBGE: {type: 'Edm.String'},
    OperadoraId: {type: 'Edm.Int32'},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'},
    Nucleo: {type: 'Uniodonto.Domain.Entities.Nucleo', navigation: true, referentials: [{property: 'NucleoId', referencedProperty: 'Id'}]},
    Operadora: {type: 'Uniodonto.Domain.Entities.Operadora', navigation: true, referentials: [{property: 'OperadoraId', referencedProperty: 'Id'}]}
  }
} as StructuredTypeConfig<NucleoEndereco>;
//#endregion