import { Component, Input } from "@angular/core";

@Component({
  selector: "app-validacao-senha",
  templateUrl: "./validacao-senha.component.html",
})
export class ValidacaoSenhaComponent {

  @Input() control: any;

}
