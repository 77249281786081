//#region ODataApiGen ODataImports
import {
  EnumTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumStatusContrato } from './enumstatuscontrato.enum';
//#endregion

//#region ODataApiGen EnumTypeConfig
export const EnumStatusContratoConfig = {
  name: 'EnumStatusContrato',
  members: EnumStatusContrato,
  fields: {
    ATIVO: {value: 1},
    NATIVO: {value: 2}
  }
} as EnumTypeConfig<EnumStatusContrato>;
//#endregion