import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Operadora } from '../Uniodonto/Domain/Entities/operadora.entity';
import { Guia } from '../Uniodonto/Domain/Entities/guia.entity';
import { Procedimentos } from '../Uniodonto/Domain/Entities/procedimentos.entity';
import { GuiaObservacao } from '../Uniodonto/Domain/Entities/guiaobservacao.entity';
//#endregion

@Injectable()
export class GuiaObservacaoService extends ODataEntitySetService<GuiaObservacao> {
  constructor(client: ODataClient) {
    super(client, 'GuiaObservacao', 'Uniodonto.Domain.Entities.GuiaObservacao');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public guia(key: EntityKey<GuiaObservacao>): ODataNavigationPropertyResource<Guia> { 
    return this.entity(key).navigationProperty<Guia>('Guia'); 
  }
  public fetchGuia(key: EntityKey<GuiaObservacao>, options?: ODataQueryArgumentsOptions<Guia>) {
    return this.fetchNavigationProperty<Guia>(
      this.guia(key), 
      'entity', options) as Observable<ODataEntity<Guia>>;
  }
  public setGuiaAsGuia(key: EntityKey<GuiaObservacao>, target: ODataEntityResource<ODataEntity<Guia>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.guia(key).reference()
      .set(target, {etag});
  }
  public unsetGuiaAsGuia(key: EntityKey<GuiaObservacao>, {target, etag}: {target?: ODataEntityResource<ODataEntity<Guia>>, etag?: string} = {}): Observable<any> {
    return this.guia(key).reference()
      .unset({etag});
  }
  public operadora(key: EntityKey<GuiaObservacao>): ODataNavigationPropertyResource<Operadora> { 
    return this.entity(key).navigationProperty<Operadora>('Operadora'); 
  }
  public fetchOperadora(key: EntityKey<GuiaObservacao>, options?: ODataQueryArgumentsOptions<Operadora>) {
    return this.fetchNavigationProperty<Operadora>(
      this.operadora(key), 
      'entity', options) as Observable<ODataEntity<Operadora>>;
  }
  public setOperadoraAsOperadora(key: EntityKey<GuiaObservacao>, target: ODataEntityResource<ODataEntity<Operadora>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.operadora(key).reference()
      .set(target, {etag});
  }
  public unsetOperadoraAsOperadora(key: EntityKey<GuiaObservacao>, {target, etag}: {target?: ODataEntityResource<ODataEntity<Operadora>>, etag?: string} = {}): Observable<any> {
    return this.operadora(key).reference()
      .unset({etag});
  }
  public procedimentos(key: EntityKey<GuiaObservacao>): ODataNavigationPropertyResource<Procedimentos> { 
    return this.entity(key).navigationProperty<Procedimentos>('Procedimentos'); 
  }
  public fetchProcedimentos(key: EntityKey<GuiaObservacao>, options?: ODataQueryArgumentsOptions<Procedimentos>) {
    return this.fetchNavigationProperty<Procedimentos>(
      this.procedimentos(key), 
      'entity', options) as Observable<ODataEntity<Procedimentos>>;
  }
  public setProcedimentosAsProcedimentos(key: EntityKey<GuiaObservacao>, target: ODataEntityResource<ODataEntity<Procedimentos>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.procedimentos(key).reference()
      .set(target, {etag});
  }
  public unsetProcedimentosAsProcedimentos(key: EntityKey<GuiaObservacao>, {target, etag}: {target?: ODataEntityResource<ODataEntity<Procedimentos>>, etag?: string} = {}): Observable<any> {
    return this.procedimentos(key).reference()
      .unset({etag});
  }
  //#endregion
}
