//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Operadora } from './operadora.entity';
import { Dependente } from './dependente.entity';
import { GrupoFamiliar } from './grupofamiliar.entity';
//#endregion

export interface DependenteGrupoFamiliar {
  //#region ODataApiGen Properties
  DependenteId?: number;
  GrupoFamiliarId?: number;
  OperadoraId?: number;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  Dependente?: Dependente;
  GrupoFamiliar?: GrupoFamiliar;
  Operadora?: Operadora;
  //#endregion
}