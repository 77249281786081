//#region ODataApiGen ODataImports
import {
  EnumTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumTipoPessoa } from './enumtipopessoa.enum';
//#endregion

//#region ODataApiGen EnumTypeConfig
export const EnumTipoPessoaConfig = {
  name: 'EnumTipoPessoa',
  members: EnumTipoPessoa,
  fields: {
    PessoaFisica: {value: 1, annotations: [{"term":"Default.Description","string":"Pessoa F\u00EDsica"}]},
    PessoaJuridica: {value: 2, annotations: [{"term":"Default.Description","string":"Pessoa Jur\u00EDdica"}]}
  }
} as EnumTypeConfig<EnumTipoPessoa>;
//#endregion