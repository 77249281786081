//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumTipoPagamento } from '../Model/Enum/enumtipopagamento.enum';
import { Operadora } from './operadora.entity';
import { Clinica } from './clinica.entity';
//#endregion

export interface Pagamento {
  //#region ODataApiGen Properties
  TipoPagamento: EnumTipoPagamento;
  Banco?: string;
  Agencia?: string;
  ContaCorrente?: string;
  ClinicaId?: number;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  Operadora?: Operadora;
  Clinica?: Clinica;
  //#endregion
}