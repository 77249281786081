var appSettings = {
	appTheme: '',
	appDarkMode: false,
	appSidebarGrid: false,
	appSidebarFixed: true,
  appSidebarMinified: true,
  appSidebarNone: false,
  appSidebarEnd: false,
  appSidebarRightCollapsed: false,
  appSidebarTwo: false,
  appSidebarWide: false,
  appSidebarTransparent: false,
  appSidebarLight: false,
  appSidebarSearch: false,
  appSidebarMobileToggled: false,
  appSidebarEndToggled: false,
  appSidebarEndMobileToggled: false,
  appContentFullHeight: false,
  appContentClass: '',
  appTopMenu: false,
  appTopMenuMobileToggled: false,
  appHeaderFixed: true,
  appHeaderNone: false,
  appHeaderInverse: false,
  appHeaderMegaMenu: false,
  appHeaderLanguageBar: false,
  appHeaderMegaMenuMobileToggled: false,
  appEmpty: false,
  appBodyWhite: false,
  appGradientEnabled: false,
  appThemePanelNone: false,
  pageFilterOpened: false
};


export default appSettings;
