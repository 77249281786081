import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumStatusGuia } from '../Uniodonto/Domain/Model/Enum/enumstatusguia.enum';
import { EnumTipoGuia } from '../Uniodonto/Domain/Model/Enum/enumtipoguia.enum';
import { EnumTipoAtendimentoGuia } from '../Uniodonto/Domain/Model/Enum/enumtipoatendimentoguia.enum';
import { SituacaoAutorizacao } from '../Uniodonto/Domain/Model/Results/UFast/Enum/situacaoautorizacao.enum';
import { Operadora } from '../Uniodonto/Domain/Entities/operadora.entity';
import { Orcamento } from '../Uniodonto/Domain/Entities/orcamento.entity';
import { Clinica } from '../Uniodonto/Domain/Entities/clinica.entity';
import { Guia } from '../Uniodonto/Domain/Entities/guia.entity';
import { ValorProcedimentoTipoProduto } from '../Uniodonto/Domain/Entities/valorprocedimentotipoproduto.entity';
import { Procedimentos } from '../Uniodonto/Domain/Entities/procedimentos.entity';
import { Lote } from '../Uniodonto/Domain/Entities/lote.entity';
import { ProfissionalSolicitante } from '../Uniodonto/Domain/Entities/profissionalsolicitante.entity';
//#endregion

@Injectable()
export class GuiaV2Service extends ODataEntitySetService<Guia> {
  constructor(client: ODataClient) {
    super(client, 'GuiaV2', 'Uniodonto.Domain.Entities.Guia');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public clinica(key: EntityKey<Guia>): ODataNavigationPropertyResource<Clinica> { 
    return this.entity(key).navigationProperty<Clinica>('Clinica'); 
  }
  public fetchClinica(key: EntityKey<Guia>, options?: ODataQueryArgumentsOptions<Clinica>) {
    return this.fetchNavigationProperty<Clinica>(
      this.clinica(key), 
      'entity', options) as Observable<ODataEntity<Clinica>>;
  }
  public setClinicaAsClinica(key: EntityKey<Guia>, target: ODataEntityResource<ODataEntity<Clinica>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.clinica(key).reference()
      .set(target, {etag});
  }
  public unsetClinicaAsClinica(key: EntityKey<Guia>, {target, etag}: {target?: ODataEntityResource<ODataEntity<Clinica>>, etag?: string} = {}): Observable<any> {
    return this.clinica(key).reference()
      .unset({etag});
  }
  public lote(key: EntityKey<Guia>): ODataNavigationPropertyResource<Lote> { 
    return this.entity(key).navigationProperty<Lote>('Lote'); 
  }
  public fetchLote(key: EntityKey<Guia>, options?: ODataQueryArgumentsOptions<Lote>) {
    return this.fetchNavigationProperty<Lote>(
      this.lote(key), 
      'entity', options) as Observable<ODataEntity<Lote>>;
  }
  public setLoteAsLote(key: EntityKey<Guia>, target: ODataEntityResource<ODataEntity<Lote>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.lote(key).reference()
      .set(target, {etag});
  }
  public unsetLoteAsLote(key: EntityKey<Guia>, {target, etag}: {target?: ODataEntityResource<ODataEntity<Lote>>, etag?: string} = {}): Observable<any> {
    return this.lote(key).reference()
      .unset({etag});
  }
  public operadora(key: EntityKey<Guia>): ODataNavigationPropertyResource<Operadora> { 
    return this.entity(key).navigationProperty<Operadora>('Operadora'); 
  }
  public fetchOperadora(key: EntityKey<Guia>, options?: ODataQueryArgumentsOptions<Operadora>) {
    return this.fetchNavigationProperty<Operadora>(
      this.operadora(key), 
      'entity', options) as Observable<ODataEntity<Operadora>>;
  }
  public setOperadoraAsOperadora(key: EntityKey<Guia>, target: ODataEntityResource<ODataEntity<Operadora>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.operadora(key).reference()
      .set(target, {etag});
  }
  public unsetOperadoraAsOperadora(key: EntityKey<Guia>, {target, etag}: {target?: ODataEntityResource<ODataEntity<Operadora>>, etag?: string} = {}): Observable<any> {
    return this.operadora(key).reference()
      .unset({etag});
  }
  public orcamento(key: EntityKey<Guia>): ODataNavigationPropertyResource<Orcamento> { 
    return this.entity(key).navigationProperty<Orcamento>('Orcamento'); 
  }
  public fetchOrcamento(key: EntityKey<Guia>, options?: ODataQueryArgumentsOptions<Orcamento>) {
    return this.fetchNavigationProperty<Orcamento>(
      this.orcamento(key), 
      'entity', options) as Observable<ODataEntity<Orcamento>>;
  }
  public setOrcamentoAsOrcamento(key: EntityKey<Guia>, target: ODataEntityResource<ODataEntity<Orcamento>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.orcamento(key).reference()
      .set(target, {etag});
  }
  public unsetOrcamentoAsOrcamento(key: EntityKey<Guia>, {target, etag}: {target?: ODataEntityResource<ODataEntity<Orcamento>>, etag?: string} = {}): Observable<any> {
    return this.orcamento(key).reference()
      .unset({etag});
  }
  public procedimentos(key: EntityKey<Guia>): ODataNavigationPropertyResource<Procedimentos> { 
    return this.entity(key).navigationProperty<Procedimentos>('Procedimentos'); 
  }
  public fetchProcedimentos(key: EntityKey<Guia>, options?: ODataQueryArgumentsOptions<Procedimentos>) {
    return this.fetchNavigationProperty<Procedimentos>(
      this.procedimentos(key), 
      'entities', options) as Observable<ODataEntities<Procedimentos>>;
  }
  public addProcedimentosToProcedimentos(key: EntityKey<Guia>, target: ODataEntityResource<ODataEntities<Procedimentos>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.procedimentos(key).reference()
      .add(target);
  }
  public removeProcedimentosFromProcedimentos(key: EntityKey<Guia>, {target, etag}: {target?: ODataEntityResource<ODataEntities<Procedimentos>>, etag?: string} = {}): Observable<any> {
    return this.procedimentos(key).reference()
      .remove(target);
  }
  public profissionalSolicitante(key: EntityKey<Guia>): ODataNavigationPropertyResource<ProfissionalSolicitante> { 
    return this.entity(key).navigationProperty<ProfissionalSolicitante>('ProfissionalSolicitante'); 
  }
  public fetchProfissionalSolicitante(key: EntityKey<Guia>, options?: ODataQueryArgumentsOptions<ProfissionalSolicitante>) {
    return this.fetchNavigationProperty<ProfissionalSolicitante>(
      this.profissionalSolicitante(key), 
      'entity', options) as Observable<ODataEntity<ProfissionalSolicitante>>;
  }
  public setProfissionalSolicitanteAsProfissionalSolicitante(key: EntityKey<Guia>, target: ODataEntityResource<ODataEntity<ProfissionalSolicitante>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.profissionalSolicitante(key).reference()
      .set(target, {etag});
  }
  public unsetProfissionalSolicitanteAsProfissionalSolicitante(key: EntityKey<Guia>, {target, etag}: {target?: ODataEntityResource<ODataEntity<ProfissionalSolicitante>>, etag?: string} = {}): Observable<any> {
    return this.profissionalSolicitante(key).reference()
      .unset({etag});
  }
  public valorProcedimentoTipoProdutos(key: EntityKey<Guia>): ODataNavigationPropertyResource<ValorProcedimentoTipoProduto> { 
    return this.entity(key).navigationProperty<ValorProcedimentoTipoProduto>('ValorProcedimentoTipoProdutos'); 
  }
  public fetchValorProcedimentoTipoProdutos(key: EntityKey<Guia>, options?: ODataQueryArgumentsOptions<ValorProcedimentoTipoProduto>) {
    return this.fetchNavigationProperty<ValorProcedimentoTipoProduto>(
      this.valorProcedimentoTipoProdutos(key), 
      'entities', options) as Observable<ODataEntities<ValorProcedimentoTipoProduto>>;
  }
  public addValorProcedimentoTipoProdutoToValorProcedimentoTipoProdutos(key: EntityKey<Guia>, target: ODataEntityResource<ODataEntities<ValorProcedimentoTipoProduto>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.valorProcedimentoTipoProdutos(key).reference()
      .add(target);
  }
  public removeValorProcedimentoTipoProdutoFromValorProcedimentoTipoProdutos(key: EntityKey<Guia>, {target, etag}: {target?: ODataEntityResource<ODataEntities<ValorProcedimentoTipoProduto>>, etag?: string} = {}): Observable<any> {
    return this.valorProcedimentoTipoProdutos(key).reference()
      .remove(target);
  }
  //#endregion
}
