//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Comissao } from './comissao.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const ComissaoEntityConfig = {
  name: 'Comissao',
  keys: [{name: 'Id'}],
  fields: {
    FaturaId: {type: 'Edm.String'},
    DataVencimentoFatura: {type: 'Edm.DateTimeOffset', nullable: false},
    DataRecebimentoFatura: {type: 'Edm.DateTimeOffset', nullable: false},
    Periodo: {type: 'Edm.String'},
    PercentualValor: {type: 'Edm.Decimal', nullable: false},
    MetaPorQuantidade: {type: 'Edm.Decimal', nullable: false},
    NumeroUsuario: {type: 'Edm.Int32', nullable: false},
    MensalidadeFaturado: {type: 'Edm.Decimal', nullable: false},
    TaxaInscricaoFaturado: {type: 'Edm.Decimal', nullable: false},
    TaxaCarteirinhaFaturado: {type: 'Edm.Decimal', nullable: false},
    MensalidadeComissao: {type: 'Edm.Decimal', nullable: false},
    TaxaInscricaoComissao: {type: 'Edm.Decimal', nullable: false},
    TaxaCarteirinhaComissao: {type: 'Edm.Decimal', nullable: false},
    Adicional: {type: 'Edm.Decimal', nullable: false},
    ContratoId: {type: 'Edm.Int32'},
    PreEspelhoId: {type: 'Edm.Int32'},
    TitularId: {type: 'Edm.Int32'},
    DependenteId: {type: 'Edm.Int32'},
    OperadoraId: {type: 'Edm.Int32'},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'},
    Contrato: {type: 'Uniodonto.Domain.Entities.Contrato', navigation: true, referentials: [{property: 'ContratoId', referencedProperty: 'Id'}]},
    PreEspelho: {type: 'Uniodonto.Domain.Entities.PreEspelho', navigation: true, referentials: [{property: 'PreEspelhoId', referencedProperty: 'Id'}]},
    Titular: {type: 'Uniodonto.Domain.Entities.Titular', navigation: true, referentials: [{property: 'TitularId', referencedProperty: 'Id'}]},
    Dependente: {type: 'Uniodonto.Domain.Entities.Dependente', navigation: true, referentials: [{property: 'DependenteId', referencedProperty: 'Id'}]},
    Operadora: {type: 'Uniodonto.Domain.Entities.Operadora', navigation: true, referentials: [{property: 'OperadoraId', referencedProperty: 'Id'}]}
  }
} as StructuredTypeConfig<Comissao>;
//#endregion