//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Operadora } from './operadora.entity';
import { Clinica } from './clinica.entity';
//#endregion

export interface DependenteClinica {
  //#region ODataApiGen Properties
  ClinicaId?: number;
  NomeDependente?: string;
  DependenteDataNascimento: Date;
  DependenteCPF?: string;
  DependenteCBO?: string;
  DependenteMatricula?: string;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoPor?: string;
  OperadoraId?: number;
  Id: number;
  ModificadoEm?: Date;
  Clinica?: Clinica;
  Operadora?: Operadora;
  //#endregion
}