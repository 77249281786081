import { Injectable } from "@angular/core";
import { ServerSideTransactionResultStatus } from "ag-grid-community";
import { NotifierService } from "angular-notifier";


@Injectable({
  providedIn: "root",
})
export class NotificationService {

  constructor(private notifier: NotifierService) {}

  show(result: any): void {
    if (typeof(result) === "string") {
      this.notifier.notify("error", result);
      return;
    }
    
    let type = (result.success||result.Success)  ? 'success' : 'error';
    this.notifier.notify(type, result.message || result.Message);

    (result.details?.Messages || []).forEach((message) => {
      this.notifier.notify('success', message);
    });
    (result.details?.Warnings || []).forEach((message) => {
      this.notifier.notify('warning', message);
    });
    (result.details?.Errors || []).forEach((message) => {
      this.notifier.notify('error', message);
    });
  }

  warning(message: string) {
    this.notifier.notify('warning', message);
  }
}
