//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Operadora } from './operadora.entity';
import { CEP } from './cep.entity';
import { Participante } from './participante.entity';
import { EnderecoBeneficiario } from './enderecobeneficiario.entity';
//#endregion

export interface Endereco {
  //#region ODataApiGen Properties
  EnderecoNumero?: string;
  EnderecoRua?: string;
  EnderecoComplemento?: string;
  EnderecoCaixaPostal?: string;
  TipoEndereco?: string;
  CEPId?: number;
  CEPCodigo?: string;
  ParticipanteId?: number;
  OperadoraId?: number;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  CEP?: CEP;
  EnderecosBeneficiario?: EnderecoBeneficiario[];
  Participante?: Participante;
  Operadora?: Operadora;
  //#endregion
}