//#region ODataApiGen ODataImports
import {
  EnumTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumModuloPortal } from './enummoduloportal.enum';
//#endregion

//#region ODataApiGen EnumTypeConfig
export const EnumModuloPortalConfig = {
  name: 'EnumModuloPortal',
  members: EnumModuloPortal,
  fields: {
    OPERADORA: {value: 0, annotations: [{"term":"Default.Description","string":"Operadora"}]},
    CLINICA: {value: 1, annotations: [{"term":"Default.Description","string":"Cl\u00EDnica"}]},
    BENEFICIARIO: {value: 2, annotations: [{"term":"Default.Description","string":"Benefici\u00E1rio"}]},
    CLIENTE: {value: 3, annotations: [{"term":"Default.Description","string":"Cliente"}]}
  }
} as EnumTypeConfig<EnumModuloPortal>;
//#endregion