//#region ODataApiGen Imports
//#endregion

export enum EnumStatusLoteJob {
  //#region ODataApiGen Members
  Criado = 0,
  Criando = 1,
  JobsAgendados = 2,
  Erro = 3,
  //#endregion
}
