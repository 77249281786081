//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Clinica } from './clinica.entity';
import { LotePrestadores } from './loteprestadores.entity';
//#endregion

export interface HistoricoConsultaLotePrestadoresRPS {
  //#region ODataApiGen Properties
  CodigoInconsistencia?: string;
  ClinicaId?: number;
  LotePrestadoresId?: number;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  Clinica?: Clinica;
  LotePrestadores?: LotePrestadores;
  //#endregion
}