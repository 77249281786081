import { AtoOdontologicoModel, AtoOdontologico_ListaDominio, GrupoDeAtosModel } from '../../models/entities';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';

import { APIResponse } from '../../models';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class GrupoDeAtosService {
  public log: String;

  constructor(
    private http: HttpClient
  ) { }

  getAll(): Observable<APIResponse> {
    return this.http.get<APIResponse>(`${environment.apiUrl}/Grupo/buscar-todos`).pipe(
      tap(data => 'Ok'),
      catchError(this.handleError)
    );
  };

  getById(id: number): Observable<APIResponse> {
    return this.http.get<APIResponse>(`${environment.apiUrl}/Grupo/${id}`).pipe(
      tap(data => 'Ok'),
      catchError(this.handleError)
    );
  };

  create(body: GrupoDeAtosModel): Observable<APIResponse> {
    return this.http.post<APIResponse>(`${environment.apiUrl}/Grupo/criar`, body, httpOptions).pipe(
      tap(data => 'Ok'),
      catchError(this.handleError)
    );
  };

  update(body: GrupoDeAtosModel): Observable<APIResponse> {
    return this.http.put<APIResponse>(`${environment.apiUrl}/Grupo/atualizar`, body, httpOptions).pipe(
      tap(data => 'Ok'),
      catchError(this.handleError)
    );
  };

  delete(id: number): Observable<APIResponse> {
    return this.http.delete<APIResponse>(`${environment.apiUrl}/Grupo/${id}`).pipe(
      tap(data => 'Ok'),
      catchError(this.handleError)
    );
  };

  deleteAto(id: number): Observable<APIResponse> {
    return this.http.delete<APIResponse>(`${environment.odataUrl}/GrupoAtoOdontologico(${id})`).pipe(
      tap(data => 'Ok'),
      catchError(this.handleError)
    );
  };

  private handleError(err: HttpErrorResponse) {
    return throwError(err);
  }

}
