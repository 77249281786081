//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumTipoSite } from '../Model/Enum/enumtiposite.enum';
import { Operadora } from './operadora.entity';
import { Clinica } from './clinica.entity';
//#endregion

export interface ClinicaMidiaSociais {
  //#region ODataApiGen Properties
  ClinicaId?: number;
  Site?: string;
  TipoSite: EnumTipoSite;
  EnderecoURLSite?: string;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm: Date;
  ModificadoPor?: string;
  OperadoraId?: number;
  Id: number;
  Clinica?: Clinica;
  Operadora?: Operadora;
  //#endregion
}