//#region ODataApiGen ODataImports
import {
  EnumTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumTipoSite } from './enumtiposite.enum';
//#endregion

//#region ODataApiGen EnumTypeConfig
export const EnumTipoSiteConfig = {
  name: 'EnumTipoSite',
  members: EnumTipoSite,
  fields: {
    SemMidia: {value: 0, annotations: [{"term":"Default.Description","string":"Sem m\u00EDdia"}]},
    Facebook: {value: 1, annotations: [{"term":"Default.Description","string":"Facebook"}]},
    Instagram: {value: 2, annotations: [{"term":"Default.Description","string":"Instagram"}]},
    LinkedIn: {value: 3, annotations: [{"term":"Default.Description","string":"LinkedIn"}]},
    Twitter: {value: 4, annotations: [{"term":"Default.Description","string":"Twitter"}]},
    TikTok: {value: 5, annotations: [{"term":"Default.Description","string":"Tik Tok"}]},
    Doctoralia: {value: 6, annotations: [{"term":"Default.Description","string":"Doctoralia"}]}
  }
} as EnumTypeConfig<EnumTipoSite>;
//#endregion