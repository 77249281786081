//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { GrupoFornecedorERP } from './grupofornecedorerp.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const GrupoFornecedorERPEntityConfig = {
  name: 'GrupoFornecedorERP',
  keys: [{name: 'Id'}],
  fields: {
    GrupoDeFornecedorERP: {type: 'Edm.String', maxLength: 60},
    Descricao: {type: 'Edm.String', maxLength: 255},
    OperadoraId: {type: 'Edm.Int32'},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'},
    Operadora: {type: 'Uniodonto.Domain.Entities.Operadora', navigation: true, referentials: [{property: 'OperadoraId', referencedProperty: 'Id'}]}
  }
} as StructuredTypeConfig<GrupoFornecedorERP>;
//#endregion