import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Operadora } from '../Uniodonto/Domain/Entities/operadora.entity';
import { Subcliente } from '../Uniodonto/Domain/Entities/subcliente.entity';
import { SubContrato } from '../Uniodonto/Domain/Entities/subcontrato.entity';
//#endregion

@Injectable()
export class SubclienteV2Service extends ODataEntitySetService<Subcliente> {
  constructor(client: ODataClient) {
    super(client, 'SubclienteV2', 'Uniodonto.Domain.Entities.Subcliente');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public operadora(key: EntityKey<Subcliente>): ODataNavigationPropertyResource<Operadora> { 
    return this.entity(key).navigationProperty<Operadora>('Operadora'); 
  }
  public fetchOperadora(key: EntityKey<Subcliente>, options?: ODataQueryArgumentsOptions<Operadora>) {
    return this.fetchNavigationProperty<Operadora>(
      this.operadora(key), 
      'entity', options) as Observable<ODataEntity<Operadora>>;
  }
  public setOperadoraAsOperadora(key: EntityKey<Subcliente>, target: ODataEntityResource<ODataEntity<Operadora>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.operadora(key).reference()
      .set(target, {etag});
  }
  public unsetOperadoraAsOperadora(key: EntityKey<Subcliente>, {target, etag}: {target?: ODataEntityResource<ODataEntity<Operadora>>, etag?: string} = {}): Observable<any> {
    return this.operadora(key).reference()
      .unset({etag});
  }
  public subContrato(key: EntityKey<Subcliente>): ODataNavigationPropertyResource<SubContrato> { 
    return this.entity(key).navigationProperty<SubContrato>('SubContrato'); 
  }
  public fetchSubContrato(key: EntityKey<Subcliente>, options?: ODataQueryArgumentsOptions<SubContrato>) {
    return this.fetchNavigationProperty<SubContrato>(
      this.subContrato(key), 
      'entity', options) as Observable<ODataEntity<SubContrato>>;
  }
  public setSubContratoAsSubContrato(key: EntityKey<Subcliente>, target: ODataEntityResource<ODataEntity<SubContrato>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.subContrato(key).reference()
      .set(target, {etag});
  }
  public unsetSubContratoAsSubContrato(key: EntityKey<Subcliente>, {target, etag}: {target?: ODataEntityResource<ODataEntity<SubContrato>>, etag?: string} = {}): Observable<any> {
    return this.subContrato(key).reference()
      .unset({etag});
  }
  //#endregion
}
