//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Operadora } from './operadora.entity';
import { Dentista } from './dentista.entity';
//#endregion

export interface DentistaDocumento {
  //#region ODataApiGen Properties
  DocumentoTipo?: string;
  DocumentoNomeOriginal?: string;
  DocumentoNomeUnico?: string;
  DocumentoAutor?: string;
  DocumentoUrl?: string;
  DentistaId?: number;
  OperadoraId?: number;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  Dentista?: Dentista;
  Operadora?: Operadora;
  //#endregion
}