//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { GuiaProcedimentoImagemLegadoService } from './guiaprocedimentoimagemlegado.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const GuiaProcedimentoImagemLegadoServiceEntitySetConfig = {
  name: 'GuiaProcedimentoImagemLegado',
  entityType: 'Uniodonto.Domain.Entities.GuiaProcedimentoImagemLegado',
  service: GuiaProcedimentoImagemLegadoService
} as EntitySetConfig;
//#endregion