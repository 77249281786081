//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Orcamento } from './orcamento.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const OrcamentoEntityConfig = {
  name: 'Orcamento',
  keys: [{name: 'Id'}],
  fields: {
    CarteiraId: {type: 'Edm.Int32'},
    DentistaId: {type: 'Edm.Int32'},
    StatusId: {type: 'Edm.Int32', nullable: false},
    ClinicaId: {type: 'Edm.Int32'},
    TipoOrcamento: {type: 'Uniodonto.Domain.Model.Enum.EnumTipoOrcamento', nullable: false},
    Origem: {type: 'Uniodonto.Domain.Model.Enum.EnumOrigem', nullable: false},
    UFastGuiaSolicitante: {type: 'Edm.String'},
    ProfissionalSolicitanteId: {type: 'Edm.Int32'},
    OperadoraId: {type: 'Edm.Int32'},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'},
    Carteira: {type: 'Uniodonto.Domain.Entities.Carteira', navigation: true, referentials: [{property: 'CarteiraId', referencedProperty: 'Id'}]},
    Clinica: {type: 'Uniodonto.Domain.Entities.Clinica', navigation: true, referentials: [{property: 'ClinicaId', referencedProperty: 'Id'}]},
    Procedimentos: {type: 'Uniodonto.Domain.Entities.Procedimentos', collection: true, navigation: true},
    Dentista: {type: 'Uniodonto.Domain.Entities.Dentista', navigation: true, referentials: [{property: 'DentistaId', referencedProperty: 'Id'}]},
    ValorProcedimentoTipoProdutos: {type: 'Uniodonto.Domain.Entities.ValorProcedimentoTipoProduto', collection: true, navigation: true},
    UFastDentistaReembolso: {type: 'Uniodonto.Domain.Entities.DentistaReembolso', navigation: true},
    ProfissionalSolicitante: {type: 'Uniodonto.Domain.Entities.ProfissionalSolicitante', navigation: true, referentials: [{property: 'ProfissionalSolicitanteId', referencedProperty: 'Id'}]},
    Operadora: {type: 'Uniodonto.Domain.Entities.Operadora', navigation: true, referentials: [{property: 'OperadoraId', referencedProperty: 'Id'}]}
  }
} as StructuredTypeConfig<Orcamento>;
//#endregion