//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { SubContratoProduto } from './subcontratoproduto.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const SubContratoProdutoEntityConfig = {
  name: 'SubContratoProduto',
  keys: [{name: 'Id'}],
  fields: {
    SubContratoId: {type: 'Edm.Int32'},
    ProdutoId: {type: 'Edm.Int32'},
    ContratoProdutoId: {type: 'Edm.Int32'},
    PerfilCarenciaId: {type: 'Edm.Int32'},
    ValorMensalidade: {type: 'Edm.Decimal', nullable: false},
    ValorMensalidadeDependente: {type: 'Edm.Decimal', nullable: false},
    ValorMensalidadeAgregado: {type: 'Edm.Decimal', nullable: false},
    ValorTaxaInscricao: {type: 'Edm.Decimal', nullable: false},
    ValorTaxaInscricaoDependente: {type: 'Edm.Decimal', nullable: false},
    ValorTaxaInscricaoAgregado: {type: 'Edm.Decimal', nullable: false},
    ValorSegundaViaCarteirinha: {type: 'Edm.Decimal', nullable: false},
    ValorSegundaViaCarteirinhaDependente: {type: 'Edm.Decimal', nullable: false},
    ValorSegundaViaCarteirinhaAgregado: {type: 'Edm.Decimal', nullable: false},
    UltimaAtualizacaoReajuste: {type: 'Edm.DateTimeOffset'},
    OperadoraId: {type: 'Edm.Int32'},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'},
    ContratoProduto: {type: 'Uniodonto.Domain.Entities.ContratoProduto', navigation: true, referentials: [{property: 'ContratoProdutoId', referencedProperty: 'Id'}]},
    PerfilCarencia: {type: 'Uniodonto.Domain.Entities.PerfilCarencia', navigation: true, referentials: [{property: 'PerfilCarenciaId', referencedProperty: 'Id'}]},
    SubContrato: {type: 'Uniodonto.Domain.Entities.SubContrato', navigation: true, referentials: [{property: 'SubContratoId', referencedProperty: 'Id'}]},
    Produto: {type: 'Uniodonto.Domain.Entities.Produto', navigation: true, referentials: [{property: 'ProdutoId', referencedProperty: 'Id'}]},
    ReajustesContratuais: {type: 'Uniodonto.Domain.Entities.ReajusteContratual', collection: true, navigation: true},
    Operadora: {type: 'Uniodonto.Domain.Entities.Operadora', navigation: true, referentials: [{property: 'OperadoraId', referencedProperty: 'Id'}]}
  }
} as StructuredTypeConfig<SubContratoProduto>;
//#endregion