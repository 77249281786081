//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { PreEspelho } from './preespelho.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const PreEspelhoEntityConfig = {
  name: 'PreEspelho',
  keys: [{name: 'Id'}],
  fields: {
    Numero: {type: 'Edm.String'},
    Tipo: {type: 'Uniodonto.Domain.Model.Enum.EnumTipoPreEspelho', nullable: false},
    Nome: {type: 'Edm.String'},
    Descricao: {type: 'Edm.String'},
    QuantidadeClientes: {type: 'Edm.Int32', nullable: false},
    QuantidadeSubClientes: {type: 'Edm.Int32', nullable: false},
    QuantidadeContratos: {type: 'Edm.Int32', nullable: false},
    QuantidadeProdutos: {type: 'Edm.Int32', nullable: false},
    QuantidadeTitulares: {type: 'Edm.Int32', nullable: false},
    QuantidadeDependentes: {type: 'Edm.Int32', nullable: false},
    QuantidadeTitularesRepasses: {type: 'Edm.Int32', nullable: false},
    QuantidadeDependentesRepasses: {type: 'Edm.Int32', nullable: false},
    QuantidadeTitularesCarteirinha: {type: 'Edm.Int32', nullable: false},
    QuantidadeTitularesCarteirinhaRepasse: {type: 'Edm.Int32', nullable: false},
    QuantidadeTitularesTaxaInscricao: {type: 'Edm.Int32', nullable: false},
    QuantidadeTitularesTaxaInscricaoRepasse: {type: 'Edm.Int32', nullable: false},
    QuantidadeTitularesMensalidade: {type: 'Edm.Int32', nullable: false},
    QuantidadeTitularesMensalidadeRepasse: {type: 'Edm.Int32', nullable: false},
    QuantidadeDependentesCarteirinha: {type: 'Edm.Int32', nullable: false},
    QuantidadeDependentesCarteirinhaRepasse: {type: 'Edm.Int32', nullable: false},
    QuantidadeDependentesTaxaInscricao: {type: 'Edm.Int32', nullable: false},
    QuantidadeDependentesTaxaInscricaoRepasse: {type: 'Edm.Int32', nullable: false},
    QuantidadeDependentesMensalidade: {type: 'Edm.Int32', nullable: false},
    QuantidadeDependentesMensalidadeRepasse: {type: 'Edm.Int32', nullable: false},
    DataVigenciaInicial: {type: 'Edm.DateTimeOffset', nullable: false},
    DataVigenciaFinal: {type: 'Edm.DateTimeOffset', nullable: false},
    ValorTotalTitulares: {type: 'Edm.Decimal', nullable: false},
    ValorTotalTitularesRepasse: {type: 'Edm.Decimal', nullable: false},
    ValorTotalDependentes: {type: 'Edm.Decimal', nullable: false},
    ValorTotalDependentesRepasse: {type: 'Edm.Decimal', nullable: false},
    ValorTotalTitularesCarteirinha: {type: 'Edm.Decimal', nullable: false},
    ValorTotalTitularesCarteirinhaRepasse: {type: 'Edm.Decimal', nullable: false},
    ValorTotalTitularesTaxaInscricao: {type: 'Edm.Decimal', nullable: false},
    ValorTotalTitularesTaxaInscricaoRepasse: {type: 'Edm.Decimal', nullable: false},
    ValorTotalTitularesMensalidade: {type: 'Edm.Decimal', nullable: false},
    ValorTotalTitularesMensalidadeRepasse: {type: 'Edm.Decimal', nullable: false},
    ValorTotalDependentesCarteirinha: {type: 'Edm.Decimal', nullable: false},
    ValorTotalDependentesCarteirinhaRepasse: {type: 'Edm.Decimal', nullable: false},
    ValorTotalDependentesTaxaInscricao: {type: 'Edm.Decimal', nullable: false},
    ValorTotalDependentesTaxaInscricaoRepasse: {type: 'Edm.Decimal', nullable: false},
    ValorTotalDependentesMensalidade: {type: 'Edm.Decimal', nullable: false},
    ValorTotalDependentesMensalidadeRepasse: {type: 'Edm.Decimal', nullable: false},
    ValorTotalTitularesPOSPagamento: {type: 'Edm.Decimal', nullable: false},
    ValorTotalDependentesPOSPagamento: {type: 'Edm.Decimal', nullable: false},
    ValorTotalTitularesMisto: {type: 'Edm.Decimal', nullable: false},
    ValorTotalDependentesMisto: {type: 'Edm.Decimal', nullable: false},
    Faturado: {type: 'Edm.Boolean', nullable: false},
    FaturamentoAgrupadoCliente: {type: 'Edm.Boolean'},
    ContratoId: {type: 'Edm.Int32'},
    SubContratoId: {type: 'Edm.Int32'},
    ClienteId: {type: 'Edm.Int32'},
    ProdutoId: {type: 'Edm.Int32'},
    TipoFaturamento: {type: 'Uniodonto.Domain.Model.Enum.EnumTipoFaturamento', nullable: false},
    StatusFaturamento: {type: 'Uniodonto.Domain.Model.Enum.EnumStatusFaturamento', nullable: false},
    NumeroDoRps: {type: 'Edm.String'},
    NumeroFatura: {type: 'Edm.String'},
    DataFatura: {type: 'Edm.DateTimeOffset'},
    CalcJobId: {type: 'Edm.String'},
    Log: {type: 'Edm.String'},
    MetodoPagamento: {type: 'Uniodonto.Domain.Model.Enum.EnumMetodoPagamento', nullable: false},
    OperadoraId: {type: 'Edm.Int32'},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'},
    SubContrato: {type: 'Uniodonto.Domain.Entities.SubContrato', navigation: true, referentials: [{property: 'SubContratoId', referencedProperty: 'Id'}]},
    PreEspelhoDetalhes: {type: 'Uniodonto.Domain.Entities.PreEspelhoDetalhe', collection: true, navigation: true},
    FaturamentoMovimentacoes: {type: 'Uniodonto.Domain.Entities.FaturamentoMovimentacoes', collection: true, navigation: true},
    ValorProcedimentoTipoProdutos: {type: 'Uniodonto.Domain.Entities.ValorProcedimentoTipoProduto', collection: true, navigation: true},
    Contrato: {type: 'Uniodonto.Domain.Entities.Contrato', navigation: true, referentials: [{property: 'ContratoId', referencedProperty: 'Id'}]},
    Cliente: {type: 'Uniodonto.Domain.Entities.Cliente', navigation: true, referentials: [{property: 'ClienteId', referencedProperty: 'Id'}]},
    Produto: {type: 'Uniodonto.Domain.Entities.Produto', navigation: true, referentials: [{property: 'ProdutoId', referencedProperty: 'Id'}]},
    Operadora: {type: 'Uniodonto.Domain.Entities.Operadora', navigation: true, referentials: [{property: 'OperadoraId', referencedProperty: 'Id'}]}
  }
} as StructuredTypeConfig<PreEspelho>;
//#endregion