//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { GuiaObservacao } from './guiaobservacao.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const GuiaObservacaoEntityConfig = {
  name: 'GuiaObservacao',
  keys: [{name: 'Id'}],
  fields: {
    GuiaId: {type: 'Edm.Int32'},
    ProcedimentosId: {type: 'Edm.Int32'},
    Texto: {type: 'Edm.String'},
    OperadoraId: {type: 'Edm.Int32'},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'},
    Guia: {type: 'Uniodonto.Domain.Entities.Guia', navigation: true, referentials: [{property: 'GuiaId', referencedProperty: 'Id'}]},
    Procedimentos: {type: 'Uniodonto.Domain.Entities.Procedimentos', navigation: true, referentials: [{property: 'ProcedimentosId', referencedProperty: 'Id'}]},
    Operadora: {type: 'Uniodonto.Domain.Entities.Operadora', navigation: true, referentials: [{property: 'OperadoraId', referencedProperty: 'Id'}]}
  }
} as StructuredTypeConfig<GuiaObservacao>;
//#endregion