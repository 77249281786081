//#region ODataApiGen ODataImports
import {
  EnumTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumTipoAcao } from './enumtipoacao.enum';
//#endregion

//#region ODataApiGen EnumTypeConfig
export const EnumTipoAcaoConfig = {
  name: 'EnumTipoAcao',
  members: EnumTipoAcao,
  fields: {
    AUTOMATICO: {value: 1, annotations: [{"term":"Default.Description","string":"Automatico"}]},
    MANUAL: {value: 2, annotations: [{"term":"Default.Description","string":"Manual"}]}
  }
} as EnumTypeConfig<EnumTipoAcao>;
//#endregion