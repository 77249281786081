//#region ODataApiGen ODataImports
import {
  EnumTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumDentistaEspecialidadesTipo } from './enumdentistaespecialidadestipo.enum';
//#endregion

//#region ODataApiGen EnumTypeConfig
export const EnumDentistaEspecialidadesTipoConfig = {
  name: 'EnumDentistaEspecialidadesTipo',
  members: EnumDentistaEspecialidadesTipo,
  fields: {
    Autorizado: {value: 1},
    NaoAutorizado: {value: 2},
    Especialista: {value: 3}
  }
} as EnumTypeConfig<EnumDentistaEspecialidadesTipo>;
//#endregion