//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumStatusFaturamento } from '../Model/Enum/enumstatusfaturamento.enum';
import { EnumTipoPreEspelho } from '../Model/Enum/enumtipopreespelho.enum';
import { EnumMetodoPagamento } from '../Model/Enum/enummetodopagamento.enum';
import { EnumTipoFaturamento } from '../Model/Enum/enumtipofaturamento.enum';
import { Cliente } from './cliente.entity';
import { Contrato } from './contrato.entity';
import { PreEspelhoDetalhe } from './preespelhodetalhe.entity';
import { Operadora } from './operadora.entity';
import { SubContrato } from './subcontrato.entity';
import { ValorProcedimentoTipoProduto } from './valorprocedimentotipoproduto.entity';
import { FaturamentoMovimentacoes } from './faturamentomovimentacoes.entity';
import { Produto } from './produto.entity';
//#endregion

export interface PreEspelho {
  //#region ODataApiGen Properties
  Numero?: string;
  Tipo: EnumTipoPreEspelho;
  Nome?: string;
  Descricao?: string;
  QuantidadeClientes: number;
  QuantidadeSubClientes: number;
  QuantidadeContratos: number;
  QuantidadeProdutos: number;
  QuantidadeTitulares: number;
  QuantidadeDependentes: number;
  QuantidadeTitularesRepasses: number;
  QuantidadeDependentesRepasses: number;
  QuantidadeTitularesCarteirinha: number;
  QuantidadeTitularesCarteirinhaRepasse: number;
  QuantidadeTitularesTaxaInscricao: number;
  QuantidadeTitularesTaxaInscricaoRepasse: number;
  QuantidadeTitularesMensalidade: number;
  QuantidadeTitularesMensalidadeRepasse: number;
  QuantidadeDependentesCarteirinha: number;
  QuantidadeDependentesCarteirinhaRepasse: number;
  QuantidadeDependentesTaxaInscricao: number;
  QuantidadeDependentesTaxaInscricaoRepasse: number;
  QuantidadeDependentesMensalidade: number;
  QuantidadeDependentesMensalidadeRepasse: number;
  DataVigenciaInicial: Date;
  DataVigenciaFinal: Date;
  ValorTotalTitulares: number;
  ValorTotalTitularesRepasse: number;
  ValorTotalDependentes: number;
  ValorTotalDependentesRepasse: number;
  ValorTotalTitularesCarteirinha: number;
  ValorTotalTitularesCarteirinhaRepasse: number;
  ValorTotalTitularesTaxaInscricao: number;
  ValorTotalTitularesTaxaInscricaoRepasse: number;
  ValorTotalTitularesMensalidade: number;
  ValorTotalTitularesMensalidadeRepasse: number;
  ValorTotalDependentesCarteirinha: number;
  ValorTotalDependentesCarteirinhaRepasse: number;
  ValorTotalDependentesTaxaInscricao: number;
  ValorTotalDependentesTaxaInscricaoRepasse: number;
  ValorTotalDependentesMensalidade: number;
  ValorTotalDependentesMensalidadeRepasse: number;
  ValorTotalTitularesPOSPagamento: number;
  ValorTotalDependentesPOSPagamento: number;
  ValorTotalTitularesMisto: number;
  ValorTotalDependentesMisto: number;
  Faturado: boolean;
  FaturamentoAgrupadoCliente?: boolean;
  ContratoId?: number;
  SubContratoId?: number;
  ClienteId?: number;
  ProdutoId?: number;
  TipoFaturamento: EnumTipoFaturamento;
  StatusFaturamento: EnumStatusFaturamento;
  NumeroDoRps?: string;
  NumeroFatura?: string;
  DataFatura?: Date;
  CalcJobId?: string;
  Log?: string;
  MetodoPagamento: EnumMetodoPagamento;
  OperadoraId?: number;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  SubContrato?: SubContrato;
  PreEspelhoDetalhes?: PreEspelhoDetalhe[];
  FaturamentoMovimentacoes?: FaturamentoMovimentacoes[];
  ValorProcedimentoTipoProdutos?: ValorProcedimentoTipoProduto[];
  Contrato?: Contrato;
  Cliente?: Cliente;
  Produto?: Produto;
  Operadora?: Operadora;
  //#endregion
}