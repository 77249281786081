//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { BeneficiarioService } from './beneficiario.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const BeneficiarioServiceEntitySetConfig = {
  name: 'Beneficiario',
  entityType: 'Uniodonto.Domain.Entities.Beneficiario',
  service: BeneficiarioService
} as EntitySetConfig;
//#endregion