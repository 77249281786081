//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumTipoContratacao } from '../Model/Enum/enumtipocontratacao.enum';
import { Operadora } from './operadora.entity';
import { Clinica } from './clinica.entity';
import { Produto } from './produto.entity';
import { TipoProduto } from './tipoproduto.entity';
//#endregion

export interface TipoContratacaoProduto {
  //#region ODataApiGen Properties
  Nome?: string;
  EnumTipoContratacao: EnumTipoContratacao;
  OperadoraId?: number;
  ClinicaId?: number;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  TipoProdutos?: TipoProduto[];
  Produtos?: Produto[];
  Operadora?: Operadora;
  Clinica?: Clinica;
  //#endregion
}