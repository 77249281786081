//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Lote } from './lote.entity';
//#endregion

export interface BatchIntegrationLog {
  //#region ODataApiGen Properties
  Id: number;
  HttpStatusCode: number;
  RequestActor?: string;
  ResponseActor?: string;
  RequestData?: string;
  ResponseMessage?: string;
  CreatedAt: Date;
  BatchId: number;
  Batch?: Lote;
  //#endregion
}