//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Operadora } from './operadora.entity';
import { Clinica } from './clinica.entity';
//#endregion

export interface Ufast {
  //#region ODataApiGen Properties
  OperadoraId?: number;
  SolicitaInformacoesUsiarios: boolean;
  AutorizaSolicitarAprovacaoOrcamento: boolean;
  AprovacaoAutomaticaApos48: boolean;
  Tabela?: string;
  Plano?: string;
  ClinicaId?: number;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  Operadora?: Operadora;
  Clinica?: Clinica;
  //#endregion
}