//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { GrupoFamiliarContrato } from './grupofamiliarcontrato.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const GrupoFamiliarContratoEntityConfig = {
  name: 'GrupoFamiliarContrato',
  keys: [{name: 'Id'}],
  fields: {
    NomeDependente: {type: 'Edm.String'},
    ContratoId: {type: 'Edm.Int32'},
    GrupoFamiliarId: {type: 'Edm.Int32'},
    EAgregado: {type: 'Edm.Boolean', nullable: false},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'},
    Contrato: {type: 'Uniodonto.Domain.Entities.Contrato', navigation: true, referentials: [{property: 'ContratoId', referencedProperty: 'Id'}]},
    GrupoFamiliar: {type: 'Uniodonto.Domain.Entities.GrupoFamiliar', navigation: true, referentials: [{property: 'GrupoFamiliarId', referencedProperty: 'Id'}]}
  }
} as StructuredTypeConfig<GrupoFamiliarContrato>;
//#endregion