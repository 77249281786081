//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Nucleo } from './nucleo.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const NucleoEntityConfig = {
  name: 'Nucleo',
  keys: [{name: 'Id'}],
  fields: {
    Nome: {type: 'Edm.String'},
    EnderecoId: {type: 'Edm.Int32'},
    ResponsavelId: {type: 'Edm.Int32'},
    OperadoraId: {type: 'Edm.Int32'},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'},
    Endereco: {type: 'Uniodonto.Domain.Entities.Endereco', navigation: true, referentials: [{property: 'EnderecoId', referencedProperty: 'Id'}]},
    Contatos: {type: 'Uniodonto.Domain.Entities.Contato', collection: true, navigation: true},
    Responsavel: {type: 'Uniodonto.Domain.Entities.Responsavel', navigation: true, referentials: [{property: 'ResponsavelId', referencedProperty: 'Id'}]},
    Clinicas: {type: 'Uniodonto.Domain.Entities.Clinica', collection: true, navigation: true},
    NucleoEnderecos: {type: 'Uniodonto.Domain.Entities.NucleoEndereco', collection: true, navigation: true},
    Contratos: {type: 'Uniodonto.Domain.Entities.Contrato', collection: true, navigation: true},
    Operadora: {type: 'Uniodonto.Domain.Entities.Operadora', navigation: true, referentials: [{property: 'OperadoraId', referencedProperty: 'Id'}]}
  }
} as StructuredTypeConfig<Nucleo>;
//#endregion