//#region ODataApiGen ODataImports
import {
  EnumTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumStatusIntegracao } from './enumstatusintegracao.enum';
//#endregion

//#region ODataApiGen EnumTypeConfig
export const EnumStatusIntegracaoConfig = {
  name: 'EnumStatusIntegracao',
  members: EnumStatusIntegracao,
  fields: {
    NAO_INTEGRADO: {value: 1, annotations: [{"term":"Default.Description","string":"N\u00E3o integrado"}]},
    INTEGRADO: {value: 2, annotations: [{"term":"Default.Description","string":"Integrado"}]},
    FALHA_NA_INTEGRACAO: {value: 3, annotations: [{"term":"Default.Description","string":"Falha na integra\u00E7\u00E3o"}]}
  }
} as EnumTypeConfig<EnumStatusIntegracao>;
//#endregion