//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Cliente } from './cliente.entity';
import { Operadora } from './operadora.entity';
import { Beneficiario } from './beneficiario.entity';
import { Endereco } from './endereco.entity';
import { Clinica } from './clinica.entity';
import { Dentista } from './dentista.entity';
import { Contato } from './contato.entity';
import { Usuario } from './usuario.entity';
//#endregion

export interface Participante {
  //#region ODataApiGen Properties
  NomeParticipante?: string;
  CNPJ?: string;
  CPF?: string;
  OperadoraId?: number;
  ClinicaId?: number;
  Intercambio: boolean;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  Observacao?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  Enderecos?: Endereco[];
  Contatos?: Contato[];
  Cliente?: Cliente;
  Usuario?: Usuario;
  Beneficiario?: Beneficiario;
  Dentista?: Dentista;
  Operadora?: Operadora;
  Clinica?: Clinica;
  //#endregion
}