//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Operadora } from './operadora.entity';
import { Produto } from './produto.entity';
//#endregion

export interface PerfilCobranca {
  //#region ODataApiGen Properties
  ProdutoId?: number;
  Codigo?: string;
  NomeDivulgacao?: string;
  OperadoraId?: number;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  Produto?: Produto;
  Operadora?: Operadora;
  //#endregion
}