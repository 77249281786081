//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Produto } from './produto.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const ProdutoEntityConfig = {
  name: 'Produto',
  keys: [{name: 'Id'}],
  fields: {
    ProdutoNome: {type: 'Edm.String'},
    ProdutoDescricao: {type: 'Edm.String'},
    ProdutoPossuiRegANS: {type: 'Edm.Boolean', nullable: false},
    ProdutoRegANS: {type: 'Edm.String'},
    DataRegistro: {type: 'Edm.DateTimeOffset', nullable: false},
    ProdutoAntLei: {type: 'Edm.Boolean', nullable: false},
    ProdutoFatorModerador: {type: 'Edm.Boolean', nullable: false},
    ProdutoServAdd: {type: 'Edm.Boolean', nullable: false},
    ProdutoRelacaoOperadora: {type: 'Edm.String'},
    ProdutoTipoContratualizacao: {type: 'Edm.String'},
    ProdutoAtivo: {type: 'Edm.Boolean', nullable: false},
    RegulamentacaoProduto: {type: 'Uniodonto.Domain.Model.Enum.EnumRegulamentacaoProduto', nullable: false},
    ProdutoTipoContratoId: {type: 'Edm.Int32'},
    TipoPessoa: {type: 'Uniodonto.Domain.Model.Enum.EnumTipoPessoa', nullable: false},
    ProdutoTipoId: {type: 'Edm.Int32'},
    ProdutoTipoContratacaoId: {type: 'Edm.Int32'},
    ProdutoTipoAdesaoId: {type: 'Edm.Int32'},
    ProdutoCondVinculoID: {type: 'Edm.Int32'},
    ProdutoAreaGeoId: {type: 'Edm.Int32'},
    ProdutoTipoPagId: {type: 'Edm.Int32'},
    ProdutoFormaPreco: {type: 'Uniodonto.Domain.Model.Enum.EnumFormacaoPrecoProduto', nullable: false},
    ProdutoTipoFatorId: {type: 'Edm.Int32'},
    PaymentModality: {type: 'Edm.String'},
    BeneficiarioIntercambio: {type: 'Edm.Boolean', nullable: false},
    Intercambio: {type: 'Edm.Boolean', nullable: false},
    IntercambioEmergencia: {type: 'Edm.Boolean', nullable: false},
    OperadoraId: {type: 'Edm.Int32'},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'},
    ProdutoTipo: {type: 'Uniodonto.Domain.Entities.TipoProduto', navigation: true, referentials: [{property: 'ProdutoTipoContratoId', referencedProperty: 'Id'}]},
    ProdutoTipoContratacao: {type: 'Uniodonto.Domain.Entities.TipoContratacaoProduto', navigation: true, referentials: [{property: 'ProdutoTipoContratacaoId', referencedProperty: 'Id'}]},
    ProdutoTipoAdesao: {type: 'Uniodonto.Domain.Entities.TipoAdesaoProduto', navigation: true, referentials: [{property: 'ProdutoTipoAdesaoId', referencedProperty: 'Id'}]},
    ProdutoCondVinculo: {type: 'Uniodonto.Domain.Entities.CondicaoVinculoProduto', navigation: true, referentials: [{property: 'ProdutoCondVinculoID', referencedProperty: 'Id'}]},
    ProdutoAreaGeo: {type: 'Uniodonto.Domain.Entities.AreaGeografica', navigation: true, referentials: [{property: 'ProdutoAreaGeoId', referencedProperty: 'Id'}]},
    ProdutoTipoPag: {type: 'Uniodonto.Domain.Entities.TipoPagamentoProduto', navigation: true, referentials: [{property: 'ProdutoTipoPagId', referencedProperty: 'Id'}]},
    ProdutoTipoFator: {type: 'Uniodonto.Domain.Entities.FatorModeradorProduto', navigation: true, referentials: [{property: 'ProdutoTipoFatorId', referencedProperty: 'Id'}]},
    ProdutoServicosAdicionais: {type: 'Uniodonto.Domain.Entities.ProdutoServicosAdicionais', collection: true, navigation: true},
    ProdutoServicosECoberturas: {type: 'Uniodonto.Domain.Entities.ProdutoServicosECobertura', collection: true, navigation: true},
    Produtos_Do_Contrato: {type: 'Uniodonto.Domain.Entities.ContratoProduto', collection: true, navigation: true},
    Produtos: {type: 'Uniodonto.Domain.Entities.Produto', collection: true, navigation: true},
    ContratoProdutoAtosPrecos: {type: 'Uniodonto.Domain.Entities.ContratoProdutoAtoPreco', collection: true, navigation: true},
    Operadora: {type: 'Uniodonto.Domain.Entities.Operadora', navigation: true, referentials: [{property: 'OperadoraId', referencedProperty: 'Id'}]}
  }
} as StructuredTypeConfig<Produto>;
//#endregion