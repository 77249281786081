//#region ODataApiGen Imports
//#endregion

export enum EnumStatusProcedimentoPlano {
  //#region ODataApiGen Members
  EmAberto = 0,
  Concluido = 10,
  Cancelado = 20,
  Substituido = 30,
  //#endregion
}
