//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumFormacaoPrecoProduto } from '../Model/Enum/enumformacaoprecoproduto.enum';
import { PreEspelhoDetalhe } from './preespelhodetalhe.entity';
import { PreEspelho } from './preespelho.entity';
import { Operadora } from './operadora.entity';
import { Orcamento } from './orcamento.entity';
import { Guia } from './guia.entity';
import { Procedimentos } from './procedimentos.entity';
//#endregion

export interface ValorProcedimentoTipoProduto {
  //#region ODataApiGen Properties
  OrcamentoId?: number;
  ProcedimentoId: number;
  GuiaId?: number;
  TipoProdutoId: EnumFormacaoPrecoProduto;
  ValorUS: number;
  QuantidadeUS?: number;
  Sucesso: boolean;
  PreEspelhoDetalheId?: number;
  PreEspelhoId?: number;
  OperadoraId?: number;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  PreEspelhoDetalhe?: PreEspelhoDetalhe;
  PreEspelho?: PreEspelho;
  Orcamento?: Orcamento;
  Procedimento?: Procedimentos;
  Guia?: Guia;
  Operadora?: Operadora;
  //#endregion
}