//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Negociacao } from './negociacao.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const NegociacaoEntityConfig = {
  name: 'Negociacao',
  keys: [{name: 'Id'}],
  fields: {
    ClinicaId: {type: 'Edm.Int32'},
    EnumTipoNegociacao: {type: 'Uniodonto.Domain.Model.Enum.EnumTipoNegociacao', nullable: false},
    UnidadeServico: {type: 'Edm.String'},
    EspecialidadeId: {type: 'Edm.Int32'},
    AtoOdontologicoId: {type: 'Edm.Int32'},
    ValorNegociado: {type: 'Edm.Decimal', nullable: false},
    ValorUS: {type: 'Edm.Decimal', nullable: false},
    QuantidadeUS: {type: 'Edm.Decimal', nullable: false},
    ValorIntercambio: {type: 'Edm.Decimal', nullable: false},
    ClienteId: {type: 'Edm.Int32'},
    ContratoId: {type: 'Edm.Int32'},
    Indice: {type: 'Edm.String'},
    VigenciaDataInicio: {type: 'Edm.DateTimeOffset'},
    VigenciaDataTermino: {type: 'Edm.DateTimeOffset'},
    EnumStatusNegociacao: {type: 'Uniodonto.Domain.Model.Enum.EnumStatusNegociacao', nullable: false},
    OperadoraId: {type: 'Edm.Int32'},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'},
    Clinica: {type: 'Uniodonto.Domain.Entities.Clinica', navigation: true, referentials: [{property: 'ClinicaId', referencedProperty: 'Id'}]},
    Especialidade: {type: 'Uniodonto.Domain.Entities.Especialidade', navigation: true, referentials: [{property: 'EspecialidadeId', referencedProperty: 'Id'}]},
    AtoOdontologico: {type: 'Uniodonto.Domain.Entities.AtoOdontologico', navigation: true, referentials: [{property: 'AtoOdontologicoId', referencedProperty: 'Id'}]},
    Cliente: {type: 'Uniodonto.Domain.Entities.Cliente', navigation: true, referentials: [{property: 'ClienteId', referencedProperty: 'Id'}]},
    Contrato: {type: 'Uniodonto.Domain.Entities.Contrato', navigation: true, referentials: [{property: 'ContratoId', referencedProperty: 'Id'}]},
    Operadora: {type: 'Uniodonto.Domain.Entities.Operadora', navigation: true, referentials: [{property: 'OperadoraId', referencedProperty: 'Id'}]}
  }
} as StructuredTypeConfig<Negociacao>;
//#endregion