//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Operadora } from './operadora.entity';
//#endregion

export interface Documentos {
  //#region ODataApiGen Properties
  Arquivo?: string;
  Tipo?: string;
  Observacao?: string;
  NomeArquivo?: string;
  DataArquivo: Date;
  OperadoraId?: number;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  Operadora?: Operadora;
  //#endregion
}