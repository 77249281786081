//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { ProcedimentosV2Service } from './procedimentosv2.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const ProcedimentosV2ServiceEntitySetConfig = {
  name: 'ProcedimentosV2',
  entityType: 'Uniodonto.Domain.Entities.Procedimentos',
  service: ProcedimentosV2Service
} as EntitySetConfig;
//#endregion