import {
	animate,
	state,
	style,
	transition,
	trigger,
} from "@angular/animations";
import { SelectionModel } from "@angular/cdk/collections";
import { FlatTreeControl } from "@angular/cdk/tree";
import {
	AfterViewChecked,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	OnInit,
	Output,
	ViewChild,
	ViewRef,
} from "@angular/core";
import {
	MatTreeFlatDataSource,
	MatTreeFlattener,
} from "@angular/material/tree";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import appMenus from "../../config/app-menus";
import appSettings from "../../config/app-settings";
import { AuthService } from "../../core/auth/auth.service";
import {
	SideMenuModel,
	SideMenuModelChildreen,
} from "../../models/entities/sidemenu.model";
import { ControleAcessoService } from "../../services";
import { MenusControleAcessoHandleService } from "../../services/entities/menus-controle-acesso-handle/menus-controle-acesso-handle.service";

@Component({
	selector: "sidebar",
	templateUrl: "./sidebar.component.html",
	animations: [
		trigger("expandCollapse", [
			state(
				"expand",
				style({ height: "*", overflow: "hidden", display: "block" })
			),
			state(
				"collapse",
				style({ height: "0px", overflow: "hidden", display: "none" })
			),
			state(
				"active",
				style({ height: "*", overflow: "hidden", display: "block" })
			),
			transition("expand <=> collapse", animate(100)),
			transition("active => collapse", animate(100)),
		]),
	],
})
export class SidebarComponent implements AfterViewChecked, OnInit {
	navProfileState = "collapse";
	@ViewChild("sidebarScrollbar", { static: false })
	private sidebarScrollbar: ElementRef;
	@Output() appSidebarMinifiedToggled = new EventEmitter<boolean>();
	@Output() hideMobileSidebar = new EventEmitter<boolean>();
	@Output() setPageFloatSubMenu = new EventEmitter();

	@Output() appSidebarMobileToggled = new EventEmitter<boolean>();
	@Input() appSidebarTransparent;
	@Input() appSidebarGrid;
	@Input() appSidebarFixed;
	@Input() appSidebarMinified;
	@Input() menuData: any;

	rotaAtual: string = "/beneficiario/dashboard";
	currentIds = [];

	/** Map from flat node to nested node. This helps us finding the nested node to be modified */
	flatNodeMap = new Map<SideMenuModelChildreen, SideMenuModel>();

	/** Map from nested node to flattened node. This helps us to keep the same object for selection */
	nestedNodeMap = new Map<SideMenuModel, SideMenuModelChildreen>();

	treeControl: FlatTreeControl<SideMenuModelChildreen>;
	treeFlattener: MatTreeFlattener<SideMenuModel, SideMenuModelChildreen>;
	dataSource: MatTreeFlatDataSource<SideMenuModel, SideMenuModelChildreen>;

	/** The selection for checklist */
	checklistSelection = new SelectionModel<SideMenuModelChildreen>(
		true /* multiple */
	);

	userRoleSelected = "";
	roleIsExpanded: boolean = false;
	userRolesList = [];

	menus = appMenus;
	appSettings = appSettings;
	appSidebarFloatSubMenu;
	appSidebarFloatSubMenuHide;
	appSidebarFloatSubMenuHideTime = 250;
	appSidebarFloatSubMenuTop;
	appSidebarFloatSubMenuLeft = "60px";
	appSidebarFloatSubMenuRight;
	appSidebarFloatSubMenuBottom;
	appSidebarFloatSubMenuArrowTop;
	appSidebarFloatSubMenuArrowBottom;
	appSidebarFloatSubMenuLineTop;
	appSidebarFloatSubMenuLineBottom;
	appSidebarFloatSubMenuOffset;

	mobileMode;
	desktopMode;
	scrollTop;

	toggleNavProfile() {
		if (this.navProfileState == "collapse") {
			this.navProfileState = "expand";
		} else {
			this.navProfileState = "collapse";
		}
	}

	toggleAppSidebarMinified() {
		this.appSidebarMinifiedToggled.emit(true);
		this.navProfileState = "collapse";
		this.scrollTop = 40;
	}

	toggleAppSidebarMobile() {
		this.appSidebarMobileToggled.emit(true);
	}

	calculateAppSidebarFloatSubMenuPosition() {
		var targetTop = this.appSidebarFloatSubMenuOffset.top;
		var direction = document.body.style.direction;
		var windowHeight = window.innerHeight;

		setTimeout(() => {
			let targetElm = <HTMLElement>(
				document.querySelector(".app-sidebar-float-submenu-container")
			);
			let targetSidebar = <HTMLElement>document.getElementById("sidebar");
			var targetHeight = targetElm.offsetHeight;
			this.appSidebarFloatSubMenuRight = "auto";
			this.appSidebarFloatSubMenuLeft =
				this.appSidebarFloatSubMenuOffset.width +
				targetSidebar.offsetLeft +
				"px";

			if (windowHeight - targetTop > targetHeight) {
				this.appSidebarFloatSubMenuTop =
					this.appSidebarFloatSubMenuOffset.top + "px";
				this.appSidebarFloatSubMenuBottom = "auto";
				this.appSidebarFloatSubMenuArrowTop = "20px";
				this.appSidebarFloatSubMenuArrowBottom = "auto";
				this.appSidebarFloatSubMenuLineTop = "20px";
				this.appSidebarFloatSubMenuLineBottom = "auto";
			} else {
				this.appSidebarFloatSubMenuTop = "auto";
				this.appSidebarFloatSubMenuBottom = "0";

				var arrowBottom = windowHeight - targetTop - 21;
				this.appSidebarFloatSubMenuArrowTop = "auto";
				this.appSidebarFloatSubMenuArrowBottom = arrowBottom + "px";
				this.appSidebarFloatSubMenuLineTop = "20px";
				this.appSidebarFloatSubMenuLineBottom = arrowBottom + "px";
			}
		}, 0);
	}

	showAppSidebarFloatSubMenu(menu, e) {
		if (this.appSettings.appSidebarMinified) {
			clearTimeout(this.appSidebarFloatSubMenuHide);

			this.appSidebarFloatSubMenu = menu;
			this.appSidebarFloatSubMenuOffset = e.target.getBoundingClientRect();
			this.calculateAppSidebarFloatSubMenuPosition();
		}
	}

	hideAppSidebarFloatSubMenu() {
		this.appSidebarFloatSubMenuHide = setTimeout(() => {
			this.appSidebarFloatSubMenu = "";
		}, this.appSidebarFloatSubMenuHideTime);
	}

	remainAppSidebarFloatSubMenu() {
		clearTimeout(this.appSidebarFloatSubMenuHide);
	}
	toggleNavMenu(id) {
		this.menuData.forEach((element, index) => {
			if (element.id === id) {
				this.menuData[index].isActive = !this.menuData[index].isActive;
			}
		});
	}

	toggleSubMenu(id) {
		this.menuData.forEach((element: SideMenuModel, index) => {
			element.Subs.forEach((suelement: SideMenuModel, suindex) => {
				if (suelement.id === id) {
					suelement.isActive = !suelement.isActive;
				}
			});
		});
	}

	expandCollapseSubmenu(currentMenu, allMenu, active) {
		for (let menu of allMenu) {
			if (menu != currentMenu) {
				menu.state = "collapse";
			}
		}
		if (active.isActive) {
			currentMenu.state =
				currentMenu.state && currentMenu.state == "collapse"
					? "expand"
					: "collapse";
		} else {
			currentMenu.state =
				currentMenu.state && currentMenu.state == "expand"
					? "collapse"
					: "expand";
		}
	}

	appSidebarSearch(e: any) {
		var value = e.target.value;
		value = value.toLowerCase();

		if (value) {
			for (let menu of this.menus) {
				var title = menu.title;
				title = title.toLowerCase();

				if (title.search(value) > -1) {
					menu["hide"] = false;
					if (menu.submenu) {
						menu["state"] = "expand";
					}
				} else {
					var hasSearch = false;
					if (menu.submenu) {
						for (var x = 0; x < menu.submenu.length; x++) {
							var subtitle = menu.submenu[x].title;
							subtitle = subtitle.toLowerCase();

							if (subtitle.search(value) > -1) {
								hasSearch = true;
							}
						}
					}
					if (hasSearch) {
						menu["hide"] = false;
						menu["state"] = "expand";
					} else {
						menu["hide"] = true;
					}
				}
			}
		} else {
			for (let menu of this.menus) {
				menu["hide"] = "";
				menu["state"] = "";
			}
		}
	}

	@HostListener("scroll", ["$event"])
	onScroll(event) {
		this.scrollTop = this.appSettings.appSidebarMinified
			? event.srcElement.scrollTop + 40
			: 0;
		if (typeof Storage !== "undefined") {
			localStorage.setItem("sidebarScroll", event.srcElement.scrollTop);
		}
	}

	@HostListener("window:resize", ["$event"])
	onResize(event) {
		if (window.innerWidth <= 767) {
			this.mobileMode = true;
			this.desktopMode = false;
		} else {
			this.mobileMode = false;
			this.desktopMode = true;
		}
	}

	ngAfterViewChecked() {
		if (typeof Storage !== "undefined" && localStorage.sidebarScroll) {
			if (this.sidebarScrollbar && this.sidebarScrollbar.nativeElement) {
				this.sidebarScrollbar.nativeElement.scrollTop =
					localStorage.sidebarScroll;
			}
		}
	}
	transformer = (node: SideMenuModel, level: number) => {
		const existingNode = this.nestedNodeMap.get(node);
		const flatNode =
			existingNode && existingNode.title === node.title
				? existingNode
				: new SideMenuModelChildreen();
		flatNode.title = node.title;
		flatNode.level = level;
		flatNode.expandable = !!node.Subs?.length;
		flatNode.id = node.id;
		flatNode.Rota = node.Rota;
		flatNode.show = node.show;
		flatNode.icon = node.icon;
		flatNode.Subs = node.Subs;
		this.flatNodeMap.set(flatNode, node);
		this.nestedNodeMap.set(node, flatNode);
		return flatNode;
	};

	getLevel = (node: SideMenuModelChildreen) => node.level;

	isExpandable = (node: SideMenuModelChildreen) => node.expandable;

	getChildren = (node: SideMenuModel): SideMenuModel[] => node.Subs;

	constructor(
		private eRef: ElementRef,
		private ref: ChangeDetectorRef,
		private router: Router,
		public authService: AuthService,
		private controleAcessoService: ControleAcessoService,
		private menusControleAcessoHandleService: MenusControleAcessoHandleService,
		private ngxSpinner: NgxSpinnerService
	) {
		if (window.innerWidth <= 767) {
			this.mobileMode = true;
			this.desktopMode = false;
			this.treeFlattener = new MatTreeFlattener(
				this.transformer,
				this.getLevel,
				this.isExpandable,
				this.getChildren
			);
			this.treeControl = new FlatTreeControl<SideMenuModelChildreen>(
				this.getLevel,
				this.isExpandable
			);
			this.dataSource = new MatTreeFlatDataSource(
				this.treeControl,
				this.treeFlattener
			);
		} else {
			this.mobileMode = false;
			this.desktopMode = true;

			this.treeFlattener = new MatTreeFlattener(
				this.transformer,
				this.getLevel,
				this.isExpandable,
				this.getChildren
			);
			this.treeControl = new FlatTreeControl<SideMenuModelChildreen>(
				this.getLevel,
				this.isExpandable
			);
			this.dataSource = new MatTreeFlatDataSource(
				this.treeControl,
				this.treeFlattener
			);
		}
	}

	ngOnInit(): void {
		this.rotaAtual = this.router.url;
	}

	hasChild = (_: number, _nodeData: SideMenuModelChildreen) =>
		_nodeData.expandable;

	hasNoContent = (_: number, _nodeData: SideMenuModelChildreen) =>
		_nodeData.title === "";

	descendantsAllSelected(node: SideMenuModelChildreen): boolean {
		const descendants = this.treeControl.getDescendants(node);
		const descAllSelected =
			descendants.length > 0 &&
			descendants.every((child) => {
				return this.checklistSelection.isSelected(child);
			});
		return descAllSelected;
	}

	descendantsPartiallySelected(node: SideMenuModelChildreen): boolean {
		const descendants = this.treeControl.getDescendants(node);

		const result = descendants.some((child) =>
			this.checklistSelection.isSelected(child)
		);

		return result && !this.descendantsAllSelected(node);
	}

	todoItemSelectionToggle(node: SideMenuModelChildreen): void {
		this.checklistSelection.toggle(node);
		const descendants = this.treeControl.getDescendants(node);
		this.checklistSelection.isSelected(node)
			? this.checklistSelection.select(...descendants)
			: this.checklistSelection.deselect(...descendants);

		// Force update for the parent
		descendants.forEach((child) => this.checklistSelection.isSelected(child));
		this.checkAllParentsSelection(node);
	}

	todoLeafItemSelectionToggle(node: SideMenuModelChildreen): void {
		this.checklistSelection.toggle(node);
		this.checkAllParentsSelection(node);
	}

	checkAllParentsSelection(node: SideMenuModelChildreen): void {
		let parent: SideMenuModelChildreen | null = this.getParentNode(node);
		while (parent) {
			this.checkRootNodeSelection(parent);
			parent = this.getParentNode(parent);
		}
	}

	getParentNode(node: SideMenuModelChildreen): SideMenuModelChildreen | null {
		const currentLevel = this.getLevel(node);

		if (currentLevel < 1) {
			return null;
		}

		const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

		for (let i = startIndex; i >= 0; i--) {
			const currentNode = this.treeControl.dataNodes[i];

			if (this.getLevel(currentNode) < currentLevel) {
				return currentNode;
			}
		}
		return null;
	}

	checkRootNodeSelection(node: SideMenuModelChildreen): void {
		const nodeSelected = this.checklistSelection.isSelected(node);
		const descendants = this.treeControl.getDescendants(node);
		const descAllSelected =
			descendants.length > 0 &&
			descendants.every((child) => {
				return this.checklistSelection.isSelected(child);
			});
		if (nodeSelected && !descAllSelected) {
			this.checklistSelection.deselect(node);
		} else if (!nodeSelected && descAllSelected) {
			this.checklistSelection.select(node);
		}
	}

	ngAfterViewInit(): void {
		this.menusControleAcessoHandleService._updatemenusSource
			// .pipe(takeUntil(this._unsubscribeAll))
			.subscribe((data) => {
				this.menuData = data;
				const arrayData = this.menuData[0] as Array<SideMenuModel>;
				this.currentIds = this.menuData[1];

				if (arrayData) {
					this.dataSource.data = arrayData;
				}

				this.dataSource.data.forEach((element) => {
					this.selectedItemsConstructor(element);
				});

				this.updateComponent();
				const user = this.authService.userData;
				this.userRolesList = user ? user["Roles"] : [];
				this.userRoleSelected = this.authService.currentRole;
			});
	}

	selectedItemsConstructor(obj: SideMenuModel) {
		if (
			this.currentIds.includes(obj.id) &&
			!this.checklistSelection.selected.includes(this.nestedNodeMap.get(obj))
		) {
			this.todoItemSelectionToggle(this.nestedNodeMap.get(obj));
		}
		obj.Subs.forEach((sub) => {
			this.selectedItemsConstructor(sub);
		});
	}

	updateComponent() {
		if (this.ref && !(this.ref as ViewRef).destroyed) {
			this.ref.detectChanges();
		}
	}

	_navLink(url) {
		// this.menuCollapsed = true;
		appSettings.appSidebarMinified = true;
		this.rotaAtual = url;
	}
	@HostListener("document:click", ["$event"])
	clickout(event) {
		if (window.innerWidth <= 767) {
			appSettings.appSidebarMinified = event
				.composedPath()
				.find((e) => e.id === "sidebar");
		} else {
			appSettings.appSidebarMinified =
				event.composedPath().find((e) => e.id === "sidebar") == null;
		}
	}
}
