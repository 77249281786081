//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumTipoDocumento } from '../Model/Enum/enumtipodocumento.enum';
//#endregion

export interface GuiaProcedimentoImagemLegado {
  //#region ODataApiGen Properties
  NumeroGuia?: string;
  NumeroProcedimento?: string;
  TipoImagem?: string;
  TipoImagemEnum?: EnumTipoDocumento;
  ImagemURL?: string;
  OperadoraId?: number;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  //#endregion
}