import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumStatusProcedimentoGuia } from '../Uniodonto/Domain/Model/Enum/enumstatusprocedimentoguia.enum';
import { EnumStatusProcedimentoPlano } from '../Uniodonto/Domain/Model/Enum/enumstatusprocedimentoplano.enum';
import { EnumStatusAuditoria } from '../Uniodonto/Domain/Model/Enum/enumstatusauditoria.enum';
import { EnumTipoAcao } from '../Uniodonto/Domain/Model/Enum/enumtipoacao.enum';
import { Operadora } from '../Uniodonto/Domain/Entities/operadora.entity';
import { Orcamento } from '../Uniodonto/Domain/Entities/orcamento.entity';
import { Dominio } from '../Uniodonto/Domain/Entities/dominio.entity';
import { Guia } from '../Uniodonto/Domain/Entities/guia.entity';
import { ValorProcedimentoTipoProduto } from '../Uniodonto/Domain/Entities/valorprocedimentotipoproduto.entity';
import { Procedimentos } from '../Uniodonto/Domain/Entities/procedimentos.entity';
import { ProcedimentoGlosa } from '../Uniodonto/Domain/Entities/procedimentoglosa.entity';
import { ProcedimentoRecursosHistorico } from '../Uniodonto/Domain/Entities/procedimentorecursoshistorico.entity';
import { GuiaObservacao } from '../Uniodonto/Domain/Entities/guiaobservacao.entity';
//#endregion

@Injectable()
export class ProcedimentosV2Service extends ODataEntitySetService<Procedimentos> {
  constructor(client: ODataClient) {
    super(client, 'ProcedimentosV2', 'Uniodonto.Domain.Entities.Procedimentos');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public dominio(key: EntityKey<Procedimentos>): ODataNavigationPropertyResource<Dominio> { 
    return this.entity(key).navigationProperty<Dominio>('Dominio'); 
  }
  public fetchDominio(key: EntityKey<Procedimentos>, options?: ODataQueryArgumentsOptions<Dominio>) {
    return this.fetchNavigationProperty<Dominio>(
      this.dominio(key), 
      'entity', options) as Observable<ODataEntity<Dominio>>;
  }
  public setDominioAsDominio(key: EntityKey<Procedimentos>, target: ODataEntityResource<ODataEntity<Dominio>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.dominio(key).reference()
      .set(target, {etag});
  }
  public unsetDominioAsDominio(key: EntityKey<Procedimentos>, {target, etag}: {target?: ODataEntityResource<ODataEntity<Dominio>>, etag?: string} = {}): Observable<any> {
    return this.dominio(key).reference()
      .unset({etag});
  }
  public glosas(key: EntityKey<Procedimentos>): ODataNavigationPropertyResource<ProcedimentoGlosa> { 
    return this.entity(key).navigationProperty<ProcedimentoGlosa>('Glosas'); 
  }
  public fetchGlosas(key: EntityKey<Procedimentos>, options?: ODataQueryArgumentsOptions<ProcedimentoGlosa>) {
    return this.fetchNavigationProperty<ProcedimentoGlosa>(
      this.glosas(key), 
      'entities', options) as Observable<ODataEntities<ProcedimentoGlosa>>;
  }
  public addProcedimentoGlosaToGlosas(key: EntityKey<Procedimentos>, target: ODataEntityResource<ODataEntities<ProcedimentoGlosa>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.glosas(key).reference()
      .add(target);
  }
  public removeProcedimentoGlosaFromGlosas(key: EntityKey<Procedimentos>, {target, etag}: {target?: ODataEntityResource<ODataEntities<ProcedimentoGlosa>>, etag?: string} = {}): Observable<any> {
    return this.glosas(key).reference()
      .remove(target);
  }
  public guia(key: EntityKey<Procedimentos>): ODataNavigationPropertyResource<Guia> { 
    return this.entity(key).navigationProperty<Guia>('Guia'); 
  }
  public fetchGuia(key: EntityKey<Procedimentos>, options?: ODataQueryArgumentsOptions<Guia>) {
    return this.fetchNavigationProperty<Guia>(
      this.guia(key), 
      'entity', options) as Observable<ODataEntity<Guia>>;
  }
  public setGuiaAsGuia(key: EntityKey<Procedimentos>, target: ODataEntityResource<ODataEntity<Guia>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.guia(key).reference()
      .set(target, {etag});
  }
  public unsetGuiaAsGuia(key: EntityKey<Procedimentos>, {target, etag}: {target?: ODataEntityResource<ODataEntity<Guia>>, etag?: string} = {}): Observable<any> {
    return this.guia(key).reference()
      .unset({etag});
  }
  public observacoes(key: EntityKey<Procedimentos>): ODataNavigationPropertyResource<GuiaObservacao> { 
    return this.entity(key).navigationProperty<GuiaObservacao>('Observacoes'); 
  }
  public fetchObservacoes(key: EntityKey<Procedimentos>, options?: ODataQueryArgumentsOptions<GuiaObservacao>) {
    return this.fetchNavigationProperty<GuiaObservacao>(
      this.observacoes(key), 
      'entities', options) as Observable<ODataEntities<GuiaObservacao>>;
  }
  public addGuiaObservacaoToObservacoes(key: EntityKey<Procedimentos>, target: ODataEntityResource<ODataEntities<GuiaObservacao>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.observacoes(key).reference()
      .add(target);
  }
  public removeGuiaObservacaoFromObservacoes(key: EntityKey<Procedimentos>, {target, etag}: {target?: ODataEntityResource<ODataEntities<GuiaObservacao>>, etag?: string} = {}): Observable<any> {
    return this.observacoes(key).reference()
      .remove(target);
  }
  public operadora(key: EntityKey<Procedimentos>): ODataNavigationPropertyResource<Operadora> { 
    return this.entity(key).navigationProperty<Operadora>('Operadora'); 
  }
  public fetchOperadora(key: EntityKey<Procedimentos>, options?: ODataQueryArgumentsOptions<Operadora>) {
    return this.fetchNavigationProperty<Operadora>(
      this.operadora(key), 
      'entity', options) as Observable<ODataEntity<Operadora>>;
  }
  public setOperadoraAsOperadora(key: EntityKey<Procedimentos>, target: ODataEntityResource<ODataEntity<Operadora>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.operadora(key).reference()
      .set(target, {etag});
  }
  public unsetOperadoraAsOperadora(key: EntityKey<Procedimentos>, {target, etag}: {target?: ODataEntityResource<ODataEntity<Operadora>>, etag?: string} = {}): Observable<any> {
    return this.operadora(key).reference()
      .unset({etag});
  }
  public orcamento(key: EntityKey<Procedimentos>): ODataNavigationPropertyResource<Orcamento> { 
    return this.entity(key).navigationProperty<Orcamento>('Orcamento'); 
  }
  public fetchOrcamento(key: EntityKey<Procedimentos>, options?: ODataQueryArgumentsOptions<Orcamento>) {
    return this.fetchNavigationProperty<Orcamento>(
      this.orcamento(key), 
      'entity', options) as Observable<ODataEntity<Orcamento>>;
  }
  public setOrcamentoAsOrcamento(key: EntityKey<Procedimentos>, target: ODataEntityResource<ODataEntity<Orcamento>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.orcamento(key).reference()
      .set(target, {etag});
  }
  public unsetOrcamentoAsOrcamento(key: EntityKey<Procedimentos>, {target, etag}: {target?: ODataEntityResource<ODataEntity<Orcamento>>, etag?: string} = {}): Observable<any> {
    return this.orcamento(key).reference()
      .unset({etag});
  }
  public procedimentoRecursosHistoricos(key: EntityKey<Procedimentos>): ODataNavigationPropertyResource<ProcedimentoRecursosHistorico> { 
    return this.entity(key).navigationProperty<ProcedimentoRecursosHistorico>('ProcedimentoRecursosHistoricos'); 
  }
  public fetchProcedimentoRecursosHistoricos(key: EntityKey<Procedimentos>, options?: ODataQueryArgumentsOptions<ProcedimentoRecursosHistorico>) {
    return this.fetchNavigationProperty<ProcedimentoRecursosHistorico>(
      this.procedimentoRecursosHistoricos(key), 
      'entities', options) as Observable<ODataEntities<ProcedimentoRecursosHistorico>>;
  }
  public addProcedimentoRecursosHistoricoToProcedimentoRecursosHistoricos(key: EntityKey<Procedimentos>, target: ODataEntityResource<ODataEntities<ProcedimentoRecursosHistorico>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.procedimentoRecursosHistoricos(key).reference()
      .add(target);
  }
  public removeProcedimentoRecursosHistoricoFromProcedimentoRecursosHistoricos(key: EntityKey<Procedimentos>, {target, etag}: {target?: ODataEntityResource<ODataEntities<ProcedimentoRecursosHistorico>>, etag?: string} = {}): Observable<any> {
    return this.procedimentoRecursosHistoricos(key).reference()
      .remove(target);
  }
  public valorProcedimentoTipoProdutos(key: EntityKey<Procedimentos>): ODataNavigationPropertyResource<ValorProcedimentoTipoProduto> { 
    return this.entity(key).navigationProperty<ValorProcedimentoTipoProduto>('ValorProcedimentoTipoProdutos'); 
  }
  public fetchValorProcedimentoTipoProdutos(key: EntityKey<Procedimentos>, options?: ODataQueryArgumentsOptions<ValorProcedimentoTipoProduto>) {
    return this.fetchNavigationProperty<ValorProcedimentoTipoProduto>(
      this.valorProcedimentoTipoProdutos(key), 
      'entities', options) as Observable<ODataEntities<ValorProcedimentoTipoProduto>>;
  }
  public addValorProcedimentoTipoProdutoToValorProcedimentoTipoProdutos(key: EntityKey<Procedimentos>, target: ODataEntityResource<ODataEntities<ValorProcedimentoTipoProduto>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.valorProcedimentoTipoProdutos(key).reference()
      .add(target);
  }
  public removeValorProcedimentoTipoProdutoFromValorProcedimentoTipoProdutos(key: EntityKey<Procedimentos>, {target, etag}: {target?: ODataEntityResource<ODataEntities<ValorProcedimentoTipoProduto>>, etag?: string} = {}): Observable<any> {
    return this.valorProcedimentoTipoProdutos(key).reference()
      .remove(target);
  }
  //#endregion
}
