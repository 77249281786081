//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumAreaGeografica } from '../Model/Enum/enumareageografica.enum';
import { Operadora } from './operadora.entity';
import { Produto } from './produto.entity';
//#endregion

export interface AreaGeografica {
  //#region ODataApiGen Properties
  Nome?: string;
  EnumAreaGeografica: EnumAreaGeografica;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  Produtos?: Produto[];
  Operadoras?: Operadora[];
  //#endregion
}