import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';

import { catchError, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { APIResponse } from '../../models';
import { CreateContatoCommand } from '../../models/command/CreateContatoCommand.model';
// import { ContatoModel } from '../../models/entities';
// import { CreateContatoCorretoraCommand } from '../../components/corretoras/Commands/createContatoCommand.model';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: "root",
})
export class ContatoService {
  public log: String;

  constructor(private http: HttpClient) { }

  getByBeneficiario(beneficiario_id: any): Observable<any[]> {
    return this.http.get<any[]>(`${environment.apiUrl}/contato/buscar-por-id?id=${beneficiario_id}`).pipe(
      tap(data => console.log('contatos obtidos')),
      catchError(this.handleError)
    );
  };

  create(
    contatos: Array<any>,
    documento: any
  ): Observable<APIResponse> {
    let enderecosRequest = { "Contatos": contatos };
    return this.http
      .post<APIResponse>(
        `${environment.apiUrl}/contato/criar-contato-por-documento?documento=${documento}`,
        enderecosRequest,
        httpOptions
      )
      .pipe(
        tap((resp) => console.log(JSON.stringify(resp))),
        catchError(this.handleError)
      );
  }

  createContatoParticipante(participanteId: CreateContatoCommand): Observable<APIResponse> {
    return this.http.post<APIResponse>(`${environment.apiUrl}/Contato/criar-contato-participante-id`, participanteId, httpOptions )
      .pipe(
        tap((resp) => console.log('success', 'Contato cadastrado com sucesso!')
        ),
        catchError(this.handleError)
      );
  }

  Update(contato: any): Observable<APIResponse> {
    return this.http.post<APIResponse>(`${environment.apiUrl}/Contato/editar-contato`, contato, httpOptions )
      .pipe(
        tap((resp) => console.log('success', 'Contato atualizado com sucesso!')
        ),
        catchError(this.handleError)
      );
  }

  deleteContato(contatoId: number): Observable<APIResponse> {
    return this.http.delete<APIResponse>(`${environment.apiUrl}/Contato/remover-contato?id=${contatoId}`, httpOptions).pipe(
      tap(resp => console.log('Contato deletado')),
      catchError(this.handleError)
    );
  }

  private handleError(err: HttpErrorResponse) {
    let errorMessage = "";
    if (err.error instanceof ErrorEvent) {
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.error(errorMessage);
    return throwError(errorMessage);
  }
}