//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { ContratoService } from './contrato.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const ContratoServiceEntitySetConfig = {
  name: 'Contrato',
  entityType: 'Uniodonto.Domain.Entities.Contrato',
  service: ContratoService
} as EntitySetConfig;
//#endregion