//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { ConsolidadoProcessamentoSIBService } from './consolidadoprocessamentosib.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const ConsolidadoProcessamentoSIBServiceEntitySetConfig = {
  name: 'ConsolidadoProcessamentoSIB',
  entityType: 'Uniodonto.Domain.Entities.ConsolidadoProcessamentoSIB',
  service: ConsolidadoProcessamentoSIBService
} as EntitySetConfig;
//#endregion