//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Bairro } from './bairro.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const BairroEntityConfig = {
  name: 'Bairro',
  keys: [{name: 'Id'}],
  fields: {
    BairroNome: {type: 'Edm.String'},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'},
    Cidade: {type: 'Uniodonto.Domain.Entities.Cidade', navigation: true}
  }
} as StructuredTypeConfig<Bairro>;
//#endregion