//#region ODataApiGen ODataImports
import {
  EnumTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumTipoContrato } from './enumtipocontrato.enum';
//#endregion

//#region ODataApiGen EnumTypeConfig
export const EnumTipoContratoConfig = {
  name: 'EnumTipoContrato',
  members: EnumTipoContrato,
  fields: {
    PESSOA_JURIDICA: {value: 0},
    PESSOA_FISICA: {value: 1}
  }
} as EnumTypeConfig<EnumTipoContrato>;
//#endregion