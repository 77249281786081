//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { GrupoFamiliar } from './grupofamiliar.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const GrupoFamiliarEntityConfig = {
  name: 'GrupoFamiliar',
  keys: [{name: 'Id'}],
  fields: {
    Nome: {type: 'Edm.String'},
    RelacaoDependencia: {type: 'Edm.Int32', nullable: false},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'},
    GruposFamiliaresContrato: {type: 'Uniodonto.Domain.Entities.GrupoFamiliarContrato', collection: true, navigation: true}
  }
} as StructuredTypeConfig<GrupoFamiliar>;
//#endregion