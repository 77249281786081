
import { RouterModule, Routes } from "@angular/router";

import { AuthGuard } from "./core/auth/guards/auth.guard";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { NoAuthGuard } from "./core/auth/guards/noAuth.guard";
const routes: Routes = [
  { 
    path: "", 
    redirectTo: "beneficiario/login", 
    pathMatch: "full",
  },
  {
    path: 'beneficiario',
    data: {source: "beneficiario", title: "Portal Beneficiário"},
    loadChildren: () => import('./beneficiario/beneficiario.module').then(m => m.BeneficiarioModule)
  },
  {
    path: 'cliente',
    data: { source: "cliente", title: "Portal Cliente" },
    loadChildren: () => import('./cliente/cliente.module').then(m => m.ClienteModule)
  },
];

@NgModule({
  imports: [CommonModule, RouterModule.forRoot(routes)],
  exports: [RouterModule],
  declarations: [],
})
export class AppRoutingModule {}
