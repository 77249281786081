//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { BatchIntegrationLog } from './batchintegrationlog.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const BatchIntegrationLogEntityConfig = {
  name: 'BatchIntegrationLog',
  keys: [{name: 'Id'}],
  fields: {
    Id: {type: 'Edm.Int32', nullable: false},
    HttpStatusCode: {type: 'Edm.Int32', nullable: false},
    RequestActor: {type: 'Edm.String'},
    ResponseActor: {type: 'Edm.String'},
    RequestData: {type: 'Edm.String'},
    ResponseMessage: {type: 'Edm.String'},
    CreatedAt: {type: 'Edm.DateTimeOffset', nullable: false},
    BatchId: {type: 'Edm.Int32', nullable: false},
    Batch: {type: 'Uniodonto.Domain.Entities.Lote', navigation: true}
  }
} as StructuredTypeConfig<BatchIntegrationLog>;
//#endregion