//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { ReajusteContratualV2Service } from './reajustecontratualv2.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const ReajusteContratualV2ServiceEntitySetConfig = {
  name: 'ReajusteContratualV2',
  entityType: 'Uniodonto.Domain.Entities.ReajusteContratual',
  service: ReajusteContratualV2Service
} as EntitySetConfig;
//#endregion