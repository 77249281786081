//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Operadora } from './operadora.entity';
import { Dentista } from './dentista.entity';
//#endregion

export interface ProfissionalSolicitante {
  //#region ODataApiGen Properties
  Nome?: string;
  CBO?: string;
  CRO: number;
  UFCRO?: string;
  DentistaId?: number;
  OperadoraId?: number;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  Dentista?: Dentista;
  Operadora?: Operadora;
  //#endregion
}