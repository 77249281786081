//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { ProcedimentoLegado } from './procedimentolegado.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const ProcedimentoLegadoEntityConfig = {
  name: 'ProcedimentoLegado',
  keys: [{name: 'Id'}],
  fields: {
    NumeroGuia: {type: 'Edm.String'},
    IdProcedimentoLegado: {type: 'Edm.String'},
    CodigoProcedimento: {type: 'Edm.String'},
    NomeProcedimento: {type: 'Edm.String'},
    DominioNome: {type: 'Edm.String'},
    NumeroDente: {type: 'Edm.Int32'},
    Face: {type: 'Edm.String'},
    UsQuantidade: {type: 'Edm.Decimal'},
    UsValor: {type: 'Edm.Decimal'},
    Observacao: {type: 'Edm.String'},
    PossuiCooparticipacao: {type: 'Edm.Boolean'},
    QuantidadeCooparticipacao: {type: 'Edm.Decimal'},
    ValorCooparticipacao: {type: 'Edm.Decimal'},
    UsQuantidadeIntercambio: {type: 'Edm.Decimal'},
    UsValorIntercambio: {type: 'Edm.Decimal'},
    DataExecucao: {type: 'Edm.DateTimeOffset'},
    OperadoraId: {type: 'Edm.Int32'},
    DataAprovacao: {type: 'Edm.DateTimeOffset'},
    CarteiraId: {type: 'Edm.String'},
    StatusGuia: {type: 'Edm.String'},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'}
  }
} as StructuredTypeConfig<ProcedimentoLegado>;
//#endregion