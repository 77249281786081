//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AtoOdontologico } from './atoodontologico.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const AtoOdontologicoEntityConfig = {
  name: 'AtoOdontologico',
  keys: [{name: 'Id'}],
  fields: {
    CodigoProcedimento: {type: 'Edm.String', maxLength: 8},
    NomeProcedimento: {type: 'Edm.String'},
    QuantidadePadrao: {type: 'Edm.Int32', nullable: false},
    RecorrenciaMinima: {type: 'Edm.Int32', nullable: false},
    CodigoTabela: {type: 'Edm.String'},
    EspecificacaoPadrao: {type: 'Edm.String'},
    FaixaIdadeDe: {type: 'Edm.String'},
    FaixaIdadeAte: {type: 'Edm.String'},
    RN59: {type: 'Edm.Boolean', nullable: false},
    ROL: {type: 'Edm.Boolean', nullable: false},
    DescricaoProduto: {type: 'Edm.String'},
    QuantidadeUs: {type: 'Edm.Decimal', nullable: false},
    EspecialidadeId: {type: 'Edm.Int32'},
    TipoId: {type: 'Edm.Int32', nullable: false},
    ObrigatoriedadeImagemInicial: {type: 'Edm.Boolean', nullable: false},
    ObrigatoriedadeImagemFinal: {type: 'Edm.Boolean', nullable: false},
    Intercambio: {type: 'Edm.Boolean', nullable: false},
    IntercambioEmergencia: {type: 'Edm.Boolean', nullable: false},
    OperadoraId: {type: 'Edm.Int32'},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'},
    Especialidade: {type: 'Uniodonto.Domain.Entities.Especialidade', navigation: true, referentials: [{property: 'EspecialidadeId', referencedProperty: 'Id'}]},
    AtoDominios: {type: 'Uniodonto.Domain.Entities.AtoDominio', collection: true, navigation: true},
    Negociacoes: {type: 'Uniodonto.Domain.Entities.Negociacao', collection: true, navigation: true},
    AtoOdontologicoFilho: {type: 'Uniodonto.Domain.Entities.AtoOdontologicoFilhos', collection: true, navigation: true},
    AtoOdontologicoPai: {type: 'Uniodonto.Domain.Entities.AtoOdontologicoFilhos', collection: true, navigation: true},
    GrupoAtoOdontologicos: {type: 'Uniodonto.Domain.Entities.GrupoAtoOdontologico', collection: true, navigation: true},
    TRMAtoOperadoras: {type: 'Uniodonto.Domain.Entities.TRMAtoOperadora', collection: true, navigation: true},
    AtoRestricaoIdades: {type: 'Uniodonto.Domain.Entities.AtoRestricaoIdade', collection: true, navigation: true},
    ContratoProdutoAtosPrecos: {type: 'Uniodonto.Domain.Entities.ContratoProdutoAtoPreco', collection: true, navigation: true},
    Operadora: {type: 'Uniodonto.Domain.Entities.Operadora', navigation: true, referentials: [{property: 'OperadoraId', referencedProperty: 'Id'}]}
  }
} as StructuredTypeConfig<AtoOdontologico>;
//#endregion