"use strict";
import { SlimScrollOptions } from "ngx-slimscroll";

export const verticalCEASlimScrollOptions = new SlimScrollOptions({
  position: "right",
  barBackground: "#000",
  // barOpacity: 0.4,
  // barWidth: 6,
  // barBorderRadius: 20,
  // barMargin: '2px',
  gridBackground: "transparent",
  // gridOpacity: 0,
  // gridBorderRadius: 20,
  // gridWidth: 0,
  // gridMargin: 20,
  // alwaysVisible: true,
  visibleTimeout: 1000,
  // scrollSensitivity: 1
});

export const blackSlimScrollOptions = new SlimScrollOptions({
  position: "right",
  barBackground: "#000000",
  // barOpacity: 0.4,
  // barWidth: 6,
  // barBorderRadius: 20,
  barMargin: "2px",
  gridBackground: "transparent",
  // gridOpacity: 0,
  // gridBorderRadius: 20,
  // gridWidth: 0,
  // gridMargin: 20,
  alwaysVisible: true,
  visibleTimeout: 1000,
  // scrollSensitivity: 1
});

export const whiteSlimScrollOptions = new SlimScrollOptions({
  position: "right",
  barBackground: "rgba(255,255,255,0.5)",
  // barOpacity: 0.4,
  // barWidth: 6,
  // barBorderRadius: 20,
  barMargin: "2px",
  gridBackground: "transparent",
  // gridOpacity: 0,
  // gridBorderRadius: 20,
  // gridWidth: 0,
  // gridMargin: 20,
  alwaysVisible: false,
  visibleTimeout: 1000,
  // scrollSensitivity: 1
});

export const FONT_COLOR = "#2d353c";
export const FONT_FAMILY =
  "Open Sans, Helvetica Neue,Helvetica,Arial,sans-serif";
export const FONT_WEIGHT = "600";
export const FONT_SIZE = "12px";

export const COLOR_BLUE = "#348fe2";
export const COLOR_BLUE_LIGHTER = "#5da5e8";
export const COLOR_BLUE_DARKER = "#2a72b5";
export const COLOR_BLUE_TRANSPARENT_1 = "rgba(52, 143, 226, 0.1)";
export const COLOR_BLUE_TRANSPARENT_2 = "rgba(52, 143, 226, 0.2)";
export const COLOR_BLUE_TRANSPARENT_3 = "rgba(52, 143, 226, 0.3)";
export const COLOR_BLUE_TRANSPARENT_4 = "rgba(52, 143, 226, 0.4)";
export const COLOR_BLUE_TRANSPARENT_5 = "rgba(52, 143, 226, 0.5)";
export const COLOR_BLUE_TRANSPARENT_6 = "rgba(52, 143, 226, 0.6)";
export const COLOR_BLUE_TRANSPARENT_7 = "rgba(52, 143, 226, 0.7)";
export const COLOR_BLUE_TRANSPARENT_8 = "rgba(52, 143, 226, 0.8)";
export const COLOR_BLUE_TRANSPARENT_9 = "rgba(52, 143, 226, 0.9)";

export const COLOR_AQUA = "#5AC8FA";
export const COLOR_AQUA_LIGHTER = "#6dc5de";
export const COLOR_AQUA_DARKER = "#3a92ab";
export const COLOR_AQUA_TRANSPARENT_1 = "rgba(73, 182, 214, 0.1)";
export const COLOR_AQUA_TRANSPARENT_2 = "rgba(73, 182, 214, 0.2)";
export const COLOR_AQUA_TRANSPARENT_3 = "rgba(73, 182, 214, 0.3)";
export const COLOR_AQUA_TRANSPARENT_4 = "rgba(73, 182, 214, 0.4)";
export const COLOR_AQUA_TRANSPARENT_5 = "rgba(73, 182, 214, 0.5)";
export const COLOR_AQUA_TRANSPARENT_6 = "rgba(73, 182, 214, 0.6)";
export const COLOR_AQUA_TRANSPARENT_7 = "rgba(73, 182, 214, 0.7)";
export const COLOR_AQUA_TRANSPARENT_8 = "rgba(73, 182, 214, 0.8)";
export const COLOR_AQUA_TRANSPARENT_9 = "rgba(73, 182, 214, 0.9)";

export const COLOR_GREEN = "#00ACAC";
export const COLOR_GREEN_LIGHTER = "#33bdbd";
export const COLOR_GREEN_DARKER = "#008a8a";
export const COLOR_GREEN_TRANSPARENT_1 = "rgba(0, 172, 172, 0.1)";
export const COLOR_GREEN_TRANSPARENT_2 = "rgba(0, 172, 172, 0.2)";
export const COLOR_GREEN_TRANSPARENT_3 = "rgba(0, 172, 172, 0.3)";
export const COLOR_GREEN_TRANSPARENT_4 = "rgba(0, 172, 172, 0.4)";
export const COLOR_GREEN_TRANSPARENT_5 = "rgba(0, 172, 172, 0.5)";
export const COLOR_GREEN_TRANSPARENT_6 = "rgba(0, 172, 172, 0.6)";
export const COLOR_GREEN_TRANSPARENT_7 = "rgba(0, 172, 172, 0.7)";
export const COLOR_GREEN_TRANSPARENT_8 = "rgba(0, 172, 172, 0.8)";
export const COLOR_GREEN_TRANSPARENT_9 = "rgba(0, 172, 172, 0.9)";

export const COLOR_YELLOW = "#ffd900";
export const COLOR_YELLOW_LIGHTER = "#fde248";
export const COLOR_YELLOW_DARKER = "#bfa300";
export const COLOR_YELLOW_TRANSPARENT_1 = "rgba(255, 217, 0, 0.1)";
export const COLOR_YELLOW_TRANSPARENT_2 = "rgba(255, 217, 0, 0.2)";
export const COLOR_YELLOW_TRANSPARENT_3 = "rgba(255, 217, 0, 0.3)";
export const COLOR_YELLOW_TRANSPARENT_4 = "rgba(255, 217, 0, 0.4)";
export const COLOR_YELLOW_TRANSPARENT_5 = "rgba(255, 217, 0, 0.5)";
export const COLOR_YELLOW_TRANSPARENT_6 = "rgba(255, 217, 0, 0.6)";
export const COLOR_YELLOW_TRANSPARENT_7 = "rgba(255, 217, 0, 0.7)";
export const COLOR_YELLOW_TRANSPARENT_8 = "rgba(255, 217, 0, 0.8)";
export const COLOR_YELLOW_TRANSPARENT_9 = "rgba(255, 217, 0, 0.9)";

export const COLOR_ORANGE = "#f59c1a";
export const COLOR_ORANGE_LIGHTER = "#f7b048";
export const COLOR_ORANGE_DARKER = "#c47d15";
export const COLOR_ORANGE_TRANSPARENT_1 = "rgba(245, 156, 26, 0.1)";
export const COLOR_ORANGE_TRANSPARENT_2 = "rgba(245, 156, 26, 0.2)";
export const COLOR_ORANGE_TRANSPARENT_3 = "rgba(245, 156, 26, 0.3)";
export const COLOR_ORANGE_TRANSPARENT_4 = "rgba(245, 156, 26, 0.4)";
export const COLOR_ORANGE_TRANSPARENT_5 = "rgba(245, 156, 26, 0.5)";
export const COLOR_ORANGE_TRANSPARENT_6 = "rgba(245, 156, 26, 0.6)";
export const COLOR_ORANGE_TRANSPARENT_7 = "rgba(245, 156, 26, 0.7)";
export const COLOR_ORANGE_TRANSPARENT_8 = "rgba(245, 156, 26, 0.8)";
export const COLOR_ORANGE_TRANSPARENT_9 = "rgba(245, 156, 26, 0.9)";

export const COLOR_PURPLE = "#727cb6";
export const COLOR_PURPLE_LIGHTER = "#8e96c5";
export const COLOR_PURPLE_DARKER = "#5b6392";
export const COLOR_PURPLE_TRANSPARENT_1 = "rgba(114, 124, 182, 0.1)";
export const COLOR_PURPLE_TRANSPARENT_2 = "rgba(114, 124, 182, 0.2)";
export const COLOR_PURPLE_TRANSPARENT_3 = "rgba(114, 124, 182, 0.3)";
export const COLOR_PURPLE_TRANSPARENT_4 = "rgba(114, 124, 182, 0.4)";
export const COLOR_PURPLE_TRANSPARENT_5 = "rgba(114, 124, 182, 0.5)";
export const COLOR_PURPLE_TRANSPARENT_6 = "rgba(114, 124, 182, 0.6)";
export const COLOR_PURPLE_TRANSPARENT_7 = "rgba(114, 124, 182, 0.7)";
export const COLOR_PURPLE_TRANSPARENT_8 = "rgba(114, 124, 182, 0.8)";
export const COLOR_PURPLE_TRANSPARENT_9 = "rgba(114, 124, 182, 0.9)";

export const COLOR_RED = "#ff5b57";
export const COLOR_RED_LIGHTER = "#ff7c79";
export const COLOR_RED_DARKER = "#cc4946";
export const COLOR_RED_TRANSPARENT_1 = "rgba(255, 91, 87, 0.1)";
export const COLOR_RED_TRANSPARENT_2 = "rgba(255, 91, 87, 0.2)";
export const COLOR_RED_TRANSPARENT_3 = "rgba(255, 91, 87, 0.3)";
export const COLOR_RED_TRANSPARENT_4 = "rgba(255, 91, 87, 0.4)";
export const COLOR_RED_TRANSPARENT_5 = "rgba(255, 91, 87, 0.5)";
export const COLOR_RED_TRANSPARENT_6 = "rgba(255, 91, 87, 0.6)";
export const COLOR_RED_TRANSPARENT_7 = "rgba(255, 91, 87, 0.7)";
export const COLOR_RED_TRANSPARENT_8 = "rgba(255, 91, 87, 0.8)";
export const COLOR_RED_TRANSPARENT_9 = "rgba(255, 91, 87, 0.9)";

export const COLOR_GREY = "#b6c2c9";
export const COLOR_GREY_LIGHTER = "#c5ced4";
export const COLOR_GREY_DARKER = "#929ba1";
export const COLOR_GREY_TRANSPARENT_1 = "rgba(182, 194, 201, 0.1)";
export const COLOR_GREY_TRANSPARENT_2 = "rgba(182, 194, 201, 0.2)";
export const COLOR_GREY_TRANSPARENT_3 = "rgba(182, 194, 201, 0.3)";
export const COLOR_GREY_TRANSPARENT_4 = "rgba(182, 194, 201, 0.4)";
export const COLOR_GREY_TRANSPARENT_5 = "rgba(182, 194, 201, 0.5)";
export const COLOR_GREY_TRANSPARENT_6 = "rgba(182, 194, 201, 0.6)";
export const COLOR_GREY_TRANSPARENT_7 = "rgba(182, 194, 201, 0.7)";
export const COLOR_GREY_TRANSPARENT_8 = "rgba(182, 194, 201, 0.8)";
export const COLOR_GREY_TRANSPARENT_9 = "rgba(182, 194, 201, 0.9)";

export const COLOR_SILVER = "#f0f3f4";
export const COLOR_SILVER_LIGHTER = "#f4f6f7";
export const COLOR_SILVER_DARKER = "#b4b6b7";
export const COLOR_SILVER_TRANSPARENT_1 = "rgba(240, 243, 244, 0.1)";
export const COLOR_SILVER_TRANSPARENT_2 = "rgba(240, 243, 244, 0.2)";
export const COLOR_SILVER_TRANSPARENT_3 = "rgba(240, 243, 244, 0.3)";
export const COLOR_SILVER_TRANSPARENT_4 = "rgba(240, 243, 244, 0.4)";
export const COLOR_SILVER_TRANSPARENT_5 = "rgba(240, 243, 244, 0.5)";
export const COLOR_SILVER_TRANSPARENT_6 = "rgba(240, 243, 244, 0.6)";
export const COLOR_SILVER_TRANSPARENT_7 = "rgba(240, 243, 244, 0.7)";
export const COLOR_SILVER_TRANSPARENT_8 = "rgba(240, 243, 244, 0.8)";
export const COLOR_SILVER_TRANSPARENT_9 = "rgba(240, 243, 244, 0.9)";

export const COLOR_BLACK = "#2d353c";
export const COLOR_BLACK_LIGHTER = "#575d63";
export const COLOR_BLACK_DARKER = "#242a30";
export const COLOR_BLACK_TRANSPARENT_1 = "rgba(45, 53, 60, 0.1)";
export const COLOR_BLACK_TRANSPARENT_2 = "rgba(45, 53, 60, 0.2)";
export const COLOR_BLACK_TRANSPARENT_3 = "rgba(45, 53, 60, 0.3)";
export const COLOR_BLACK_TRANSPARENT_4 = "rgba(45, 53, 60, 0.4)";
export const COLOR_BLACK_TRANSPARENT_5 = "rgba(45, 53, 60, 0.5)";
export const COLOR_BLACK_TRANSPARENT_6 = "rgba(45, 53, 60, 0.6)";
export const COLOR_BLACK_TRANSPARENT_7 = "rgba(45, 53, 60, 0.7)";
export const COLOR_BLACK_TRANSPARENT_8 = "rgba(45, 53, 60, 0.8)";
export const COLOR_BLACK_TRANSPARENT_9 = "rgba(45, 53, 60, 0.9)";

export const COLOR_WHITE = "#FFFFFF";
export const COLOR_WHITE_TRANSPARENT_1 = "rgba(255, 255, 255, 0.1)";
export const COLOR_WHITE_TRANSPARENT_2 = "rgba(255, 255, 255, 0.2)";
export const COLOR_WHITE_TRANSPARENT_3 = "rgba(255, 255, 255, 0.3)";
export const COLOR_WHITE_TRANSPARENT_4 = "rgba(255, 255, 255, 0.4)";
export const COLOR_WHITE_TRANSPARENT_5 = "rgba(255, 255, 255, 0.5)";
export const COLOR_WHITE_TRANSPARENT_6 = "rgba(255, 255, 255, 0.6)";
export const COLOR_WHITE_TRANSPARENT_7 = "rgba(255, 255, 255, 0.7)";
export const COLOR_WHITE_TRANSPARENT_8 = "rgba(255, 255, 255, 0.8)";
export const COLOR_WHITE_TRANSPARENT_9 = "rgba(255, 255, 255, 0.9)";

export const fileSendSizeLimitInMegas = 20;
export const fileSendSizeLimitDescription = "20 megas";
