import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumTipoPessoa } from '../Uniodonto/Domain/Model/Enum/enumtipopessoa.enum';
import { EnumStatusIntegracao } from '../Uniodonto/Domain/Model/Enum/enumstatusintegracao.enum';
import { Cliente } from '../Uniodonto/Domain/Entities/cliente.entity';
import { PreEspelho } from '../Uniodonto/Domain/Entities/preespelho.entity';
import { Operadora } from '../Uniodonto/Domain/Entities/operadora.entity';
import { Subcliente } from '../Uniodonto/Domain/Entities/subcliente.entity';
import { SubContrato } from '../Uniodonto/Domain/Entities/subcontrato.entity';
//#endregion

@Injectable()
export class ClienteService extends ODataEntitySetService<Cliente> {
  constructor(client: ODataClient) {
    super(client, 'Cliente', 'Uniodonto.Domain.Entities.Cliente');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public operadora(key: EntityKey<Cliente>): ODataNavigationPropertyResource<Operadora> { 
    return this.entity(key).navigationProperty<Operadora>('Operadora'); 
  }
  public fetchOperadora(key: EntityKey<Cliente>, options?: ODataQueryArgumentsOptions<Operadora>) {
    return this.fetchNavigationProperty<Operadora>(
      this.operadora(key), 
      'entity', options) as Observable<ODataEntity<Operadora>>;
  }
  public setOperadoraAsOperadora(key: EntityKey<Cliente>, target: ODataEntityResource<ODataEntity<Operadora>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.operadora(key).reference()
      .set(target, {etag});
  }
  public unsetOperadoraAsOperadora(key: EntityKey<Cliente>, {target, etag}: {target?: ODataEntityResource<ODataEntity<Operadora>>, etag?: string} = {}): Observable<any> {
    return this.operadora(key).reference()
      .unset({etag});
  }
  public preEspelhos(key: EntityKey<Cliente>): ODataNavigationPropertyResource<PreEspelho> { 
    return this.entity(key).navigationProperty<PreEspelho>('PreEspelhos'); 
  }
  public fetchPreEspelhos(key: EntityKey<Cliente>, options?: ODataQueryArgumentsOptions<PreEspelho>) {
    return this.fetchNavigationProperty<PreEspelho>(
      this.preEspelhos(key), 
      'entities', options) as Observable<ODataEntities<PreEspelho>>;
  }
  public addPreEspelhoToPreEspelhos(key: EntityKey<Cliente>, target: ODataEntityResource<ODataEntities<PreEspelho>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.preEspelhos(key).reference()
      .add(target);
  }
  public removePreEspelhoFromPreEspelhos(key: EntityKey<Cliente>, {target, etag}: {target?: ODataEntityResource<ODataEntities<PreEspelho>>, etag?: string} = {}): Observable<any> {
    return this.preEspelhos(key).reference()
      .remove(target);
  }
  public subClientes(key: EntityKey<Cliente>): ODataNavigationPropertyResource<Subcliente> { 
    return this.entity(key).navigationProperty<Subcliente>('SubClientes'); 
  }
  public fetchSubClientes(key: EntityKey<Cliente>, options?: ODataQueryArgumentsOptions<Subcliente>) {
    return this.fetchNavigationProperty<Subcliente>(
      this.subClientes(key), 
      'entities', options) as Observable<ODataEntities<Subcliente>>;
  }
  public addSubclienteToSubClientes(key: EntityKey<Cliente>, target: ODataEntityResource<ODataEntities<Subcliente>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.subClientes(key).reference()
      .add(target);
  }
  public removeSubclienteFromSubClientes(key: EntityKey<Cliente>, {target, etag}: {target?: ODataEntityResource<ODataEntities<Subcliente>>, etag?: string} = {}): Observable<any> {
    return this.subClientes(key).reference()
      .remove(target);
  }
  public subContratos(key: EntityKey<Cliente>): ODataNavigationPropertyResource<SubContrato> { 
    return this.entity(key).navigationProperty<SubContrato>('SubContratos'); 
  }
  public fetchSubContratos(key: EntityKey<Cliente>, options?: ODataQueryArgumentsOptions<SubContrato>) {
    return this.fetchNavigationProperty<SubContrato>(
      this.subContratos(key), 
      'entities', options) as Observable<ODataEntities<SubContrato>>;
  }
  public addSubContratoToSubContratos(key: EntityKey<Cliente>, target: ODataEntityResource<ODataEntities<SubContrato>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.subContratos(key).reference()
      .add(target);
  }
  public removeSubContratoFromSubContratos(key: EntityKey<Cliente>, {target, etag}: {target?: ODataEntityResource<ODataEntities<SubContrato>>, etag?: string} = {}): Observable<any> {
    return this.subContratos(key).reference()
      .remove(target);
  }
  //#endregion
}
