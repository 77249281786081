//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumDentistaEspecialidadesTipo } from '../Model/Enum/enumdentistaespecialidadestipo.enum';
import { Operadora } from './operadora.entity';
import { Dentista } from './dentista.entity';
import { Especialidade } from './especialidade.entity';
//#endregion

export interface DentistaEspecialidades {
  //#region ODataApiGen Properties
  EspecialidadeId?: number;
  Tipo: EnumDentistaEspecialidadesTipo;
  DataInicio: Date;
  DataFim: Date;
  DentistaId?: number;
  OperadoraId?: number;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  Especialidade?: Especialidade;
  Dentista?: Dentista;
  Operadora?: Operadora;
  //#endregion
}