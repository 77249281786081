//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { OrcamentoV2Service } from './orcamentov2.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const OrcamentoV2ServiceEntitySetConfig = {
  name: 'OrcamentoV2',
  entityType: 'Uniodonto.Domain.Entities.Orcamento',
  service: OrcamentoV2Service
} as EntitySetConfig;
//#endregion