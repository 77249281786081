//#region ODataApiGen Imports
//#endregion

export enum EnumProcessamentoRetornoSIB {
  //#region ODataApiGen Members
  NAO_PROCESSADO = 1,
  EM_PROCESSAMENTO = 2,
  PROCESSADO_COM_SUCESSO = 3,
  PROCESSADO_COM_ERRO = 4,
  //#endregion
}
