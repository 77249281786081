//#region ODataApiGen Imports
//#endregion

export enum EnumTipoSite {
  //#region ODataApiGen Members
  SemMidia = 0,
  Facebook = 1,
  Instagram = 2,
  LinkedIn = 3,
  Twitter = 4,
  TikTok = 5,
  Doctoralia = 6,
  //#endregion
}
