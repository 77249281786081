//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { ClienteService } from './cliente.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const ClienteServiceEntitySetConfig = {
  name: 'Cliente',
  entityType: 'Uniodonto.Domain.Entities.Cliente',
  service: ClienteService
} as EntitySetConfig;
//#endregion