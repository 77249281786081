//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AtoOdontologico } from './atoodontologico.entity';
import { Negociacao } from './negociacao.entity';
import { ClinicaEspecialidadeEAtendimento } from './clinicaespecialidadeeatendimento.entity';
//#endregion

export interface Especialidade {
  //#region ODataApiGen Properties
  Nome?: string;
  Codigo?: string;
  CodigoRPSWeb?: string;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  AtoOdontologicos?: AtoOdontologico[];
  Negociacoes?: Negociacao[];
  ClinicaEspecialidadeEAtendimentos?: ClinicaEspecialidadeEAtendimento[];
  //#endregion
}