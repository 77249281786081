//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { GrupoFamiliarContrato } from './grupofamiliarcontrato.entity';
//#endregion

export interface GrupoFamiliar {
  //#region ODataApiGen Properties
  Nome?: string;
  RelacaoDependencia: number;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  GruposFamiliaresContrato?: GrupoFamiliarContrato[];
  //#endregion
}