//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { HistoricoRejeitadosSIBService } from './historicorejeitadossib.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const HistoricoRejeitadosSIBServiceEntitySetConfig = {
  name: 'HistoricoRejeitadosSIB',
  entityType: 'Uniodonto.Domain.Entities.HistoricoRejeitadosProcessamentoSIB',
  service: HistoricoRejeitadosSIBService
} as EntitySetConfig;
//#endregion