//#region ODataApiGen ODataImports
import {
  EnumTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumStatusGuia } from './enumstatusguia.enum';
//#endregion

//#region ODataApiGen EnumTypeConfig
export const EnumStatusGuiaConfig = {
  name: 'EnumStatusGuia',
  members: EnumStatusGuia,
  fields: {
    Nenhum: {value: 0, annotations: [{"term":"Default.Description","string":"Nenhum"}]},
    AguardandoAutorizacao: {value: 10, annotations: [{"term":"Default.Description","string":"Aguardando autoriza\u00E7\u00E3o"}]},
    NaoAutorizado: {value: 20, annotations: [{"term":"Default.Description","string":"N\u00E3o autorizado"}]},
    ParcialmenteAutorizado: {value: 30, annotations: [{"term":"Default.Description","string":"Parcialmente autorizado"}]},
    Autorizado: {value: 40, annotations: [{"term":"Default.Description","string":"Autorizado"}]},
    ParcialmenteExecutado: {value: 50, annotations: [{"term":"Default.Description","string":"Parcialmente executado"}]},
    Executado: {value: 60, annotations: [{"term":"Default.Description","string":"Executado"}]},
    Cancelado: {value: 70, annotations: [{"term":"Default.Description","string":"Cancelado"}]},
    ParcialmenteGlosado: {value: 80, annotations: [{"term":"Default.Description","string":"Parcialmente glosado"}]},
    Glosado: {value: 90, annotations: [{"term":"Default.Description","string":"Glosado"}]},
    Concluido: {value: 999, annotations: [{"term":"Default.Description","string":"Conclu\u00EDdo"}]}
  }
} as EnumTypeConfig<EnumStatusGuia>;
//#endregion