//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { CEPV2Service } from './cepv2.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const CEPV2ServiceEntitySetConfig = {
  name: 'CEPV2',
  entityType: 'Uniodonto.Domain.Entities.CEP',
  service: CEPV2Service
} as EntitySetConfig;
//#endregion