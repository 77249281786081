//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { ComissaoV2Service } from './comissaov2.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const ComissaoV2ServiceEntitySetConfig = {
  name: 'ComissaoV2',
  entityType: 'Uniodonto.Domain.Entities.Comissao',
  service: ComissaoV2Service
} as EntitySetConfig;
//#endregion