//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { ConsolidadoProcessamentoSIB } from './consolidadoprocessamentosib.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const ConsolidadoProcessamentoSIBEntityConfig = {
  name: 'ConsolidadoProcessamentoSIB',
  keys: [{name: 'Id'}],
  fields: {
    Hash: {type: 'Edm.String'},
    NumeroProtocolo: {type: 'Edm.String'},
    NomeArquivo: {type: 'Edm.String'},
    QuantidadeRegistrosInclusao: {type: 'Edm.Int32', nullable: false},
    QuantidadeProcessadosInclusao: {type: 'Edm.Int32', nullable: false},
    QuantidadeRejeitadosInclusao: {type: 'Edm.Int32', nullable: false},
    PercentualAcertoInclusao: {type: 'Edm.Decimal', nullable: false},
    QuantidadeRegistrosRetificacao: {type: 'Edm.Int32', nullable: false},
    QuantidadeProcessadosRetificacao: {type: 'Edm.Int32', nullable: false},
    QuantidadeRejeitadosRetificacao: {type: 'Edm.Int32', nullable: false},
    PercentualAcertoRetificacao: {type: 'Edm.Decimal', nullable: false},
    QuantidadeRegistrosMudancaContratual: {type: 'Edm.Int32', nullable: false},
    QuantidadeProcessadosMudancaContratual: {type: 'Edm.Int32', nullable: false},
    QuantidadeRejeitadosMudancaContratual: {type: 'Edm.Int32', nullable: false},
    PercentualAcertoMudancaContratual: {type: 'Edm.Decimal', nullable: false},
    QuantidadeRegistrosReativacao: {type: 'Edm.Int32', nullable: false},
    QuantidadeProcessadosReativacao: {type: 'Edm.Int32', nullable: false},
    QuantidadeRejeitadosReativacao: {type: 'Edm.Int32', nullable: false},
    PercentualAcertoReativacao: {type: 'Edm.Decimal', nullable: false},
    QuantidadeRegistrosCancelamento: {type: 'Edm.Int32', nullable: false},
    QuantidadeProcessadosCancelamento: {type: 'Edm.Int32', nullable: false},
    QuantidadeRejeitadosCancelamento: {type: 'Edm.Int32', nullable: false},
    PercentualAcertoCancelamento: {type: 'Edm.Decimal', nullable: false},
    QuantidadeRegistrosSemMovimentacao: {type: 'Edm.Int32', nullable: false},
    QuantidadeProcessadosSemMovimentacao: {type: 'Edm.Int32', nullable: false},
    QuantidadeRejeitadosSemMovimentacao: {type: 'Edm.Int32', nullable: false},
    PercentualAcertoSemMovimentacao: {type: 'Edm.Decimal', nullable: false},
    QuantidadeRegistrosTotal: {type: 'Edm.Int32', nullable: false},
    QuantidadeProcessadosTotal: {type: 'Edm.Int32', nullable: false},
    QuantidadeRejeitadosTotal: {type: 'Edm.Int32', nullable: false},
    PercentualAcertoTotal: {type: 'Edm.Decimal', nullable: false},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'}
  }
} as StructuredTypeConfig<ConsolidadoProcessamentoSIB>;
//#endregion