//#region ODataApiGen ODataImports
import {
  EnumTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumStatusLoteJob } from './enumstatuslotejob.enum';
//#endregion

//#region ODataApiGen EnumTypeConfig
export const EnumStatusLoteJobConfig = {
  name: 'EnumStatusLoteJob',
  members: EnumStatusLoteJob,
  fields: {
    Criado: {value: 0, annotations: [{"term":"Default.Description","string":"Criado"}]},
    Criando: {value: 1, annotations: [{"term":"Default.Description","string":"Criando Jobs"}]},
    JobsAgendados: {value: 2, annotations: [{"term":"Default.Description","string":"Jobs Agendados"}]},
    Erro: {value: 3, annotations: [{"term":"Default.Description","string":"Erro no agendamento"}]}
  }
} as EnumTypeConfig<EnumStatusLoteJob>;
//#endregion