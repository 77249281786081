//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { HistoricoConsultaLotePrestadoresRPS } from './historicoconsultaloteprestadoresrps.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const HistoricoConsultaLotePrestadoresRPSEntityConfig = {
  name: 'HistoricoConsultaLotePrestadoresRPS',
  keys: [{name: 'Id'}],
  fields: {
    CodigoInconsistencia: {type: 'Edm.String'},
    ClinicaId: {type: 'Edm.Int32'},
    LotePrestadoresId: {type: 'Edm.Int32'},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'},
    Clinica: {type: 'Uniodonto.Domain.Entities.Clinica', navigation: true, referentials: [{property: 'ClinicaId', referencedProperty: 'Id'}]},
    LotePrestadores: {type: 'Uniodonto.Domain.Entities.LotePrestadores', navigation: true, referentials: [{property: 'LotePrestadoresId', referencedProperty: 'Id'}]}
  }
} as StructuredTypeConfig<HistoricoConsultaLotePrestadoresRPS>;
//#endregion