//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { PreEspelhoDetalhe } from './preespelhodetalhe.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const PreEspelhoDetalheEntityConfig = {
  name: 'PreEspelhoDetalhe',
  keys: [{name: 'Id'}],
  fields: {
    ClienteId: {type: 'Edm.Int32'},
    ContratoId: {type: 'Edm.Int32'},
    ContratoProdutoId: {type: 'Edm.Int32'},
    SubContratoId: {type: 'Edm.Int32'},
    ProdutoId: {type: 'Edm.Int32'},
    TitularId: {type: 'Edm.Int32'},
    DependenteId: {type: 'Edm.Int32'},
    ValorMensalidade: {type: 'Edm.Decimal', nullable: false},
    ValorMensalidaderRepasse: {type: 'Edm.Decimal', nullable: false},
    ValorTaxaInscricao: {type: 'Edm.Decimal', nullable: false},
    ValorTaxaInscricaoRepasse: {type: 'Edm.Decimal', nullable: false},
    ValorCarteirinha: {type: 'Edm.Decimal', nullable: false},
    ValorCarteirinhaRepasse: {type: 'Edm.Decimal', nullable: false},
    ValorTotalProcedimentosPOS: {type: 'Edm.Decimal', nullable: false},
    ValorTotalProcedimentosMisto: {type: 'Edm.Decimal', nullable: false},
    PreEspelhoId: {type: 'Edm.Int32'},
    Faturado: {type: 'Edm.Boolean', nullable: false},
    Agregado: {type: 'Edm.Boolean', nullable: false},
    GrupoFamiliarId: {type: 'Edm.Int32'},
    StatusFaturamento: {type: 'Uniodonto.Domain.Model.Enum.EnumStatusFaturamento', nullable: false},
    OperadoraRepasseId: {type: 'Edm.Int32'},
    OperadoraId: {type: 'Edm.Int32'},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'},
    ContratoProduto: {type: 'Uniodonto.Domain.Entities.ContratoProduto', navigation: true, referentials: [{property: 'ContratoProdutoId', referencedProperty: 'Id'}]},
    OperadoraRepasse: {type: 'Uniodonto.Domain.Entities.Operadora', navigation: true, referentials: [{property: 'OperadoraId', referencedProperty: 'Id'}]},
    Cliente: {type: 'Uniodonto.Domain.Entities.Cliente', navigation: true, referentials: [{property: 'ClienteId', referencedProperty: 'Id'}]},
    Contrato: {type: 'Uniodonto.Domain.Entities.Contrato', navigation: true, referentials: [{property: 'ContratoId', referencedProperty: 'Id'}]},
    Produto: {type: 'Uniodonto.Domain.Entities.Produto', navigation: true, referentials: [{property: 'ProdutoId', referencedProperty: 'Id'}]},
    Titular: {type: 'Uniodonto.Domain.Entities.Titular', navigation: true, referentials: [{property: 'TitularId', referencedProperty: 'Id'}]},
    Dependente: {type: 'Uniodonto.Domain.Entities.Dependente', navigation: true, referentials: [{property: 'DependenteId', referencedProperty: 'Id'}]},
    PreEspelho: {type: 'Uniodonto.Domain.Entities.PreEspelho', navigation: true, referentials: [{property: 'PreEspelhoId', referencedProperty: 'Id'}]},
    GrupoFamiliar: {type: 'Uniodonto.Domain.Entities.GrupoFamiliar', navigation: true, referentials: [{property: 'GrupoFamiliarId', referencedProperty: 'Id'}]},
    SubContrato: {type: 'Uniodonto.Domain.Entities.SubContrato', navigation: true, referentials: [{property: 'SubContratoId', referencedProperty: 'Id'}]},
    ValorProcedimentoTipoProdutos: {type: 'Uniodonto.Domain.Entities.ValorProcedimentoTipoProduto', collection: true, navigation: true},
    Operadora: {type: 'Uniodonto.Domain.Entities.Operadora', navigation: true, referentials: [{property: 'OperadoraId', referencedProperty: 'Id'}]}
  }
} as StructuredTypeConfig<PreEspelhoDetalhe>;
//#endregion