//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { BairroService } from './bairro.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const BairroServiceEntitySetConfig = {
  name: 'Bairro',
  entityType: 'Uniodonto.Domain.Entities.Bairro',
  service: BairroService
} as EntitySetConfig;
//#endregion