//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumTipoDocumento } from '../Model/Enum/enumtipodocumento.enum';
import { Operadora } from './operadora.entity';
import { Procedimentos } from './procedimentos.entity';
//#endregion

export interface ProcedimentoImagens {
  //#region ODataApiGen Properties
  ImagemUrl?: string;
  TipoImagem: EnumTipoDocumento;
  ImagemNome?: string;
  ProcedimentoId: number;
  UFastEnviado: boolean;
  OperadoraId?: number;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  Procedimento?: Procedimentos;
  Operadora?: Operadora;
  //#endregion
}