//#region ODataApiGen Imports
//#endregion

export enum EnumStatusCarteira {
  //#region ODataApiGen Members
  undefined = 0,
  EMITIDO = 1,
  CANCELADO = 2,
  CARTEIRA_EXPIRADO = 3,
  AGUARDANDO_EMISSAO = 4,
  CARTEIRA_EXPIRADA_BENEFICIARIOPRODUTO = 5,
  //#endregion
}
