//#region ODataApiGen ODataImports
import {
  EnumTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumStatusProcedimento } from './enumstatusprocedimento.enum';
//#endregion

//#region ODataApiGen EnumTypeConfig
export const EnumStatusProcedimentoConfig = {
  name: 'EnumStatusProcedimento',
  members: EnumStatusProcedimento,
  fields: {
    PENDENTE: {value: 1, annotations: [{"term":"Default.Description","string":"Pendente"}]},
    APROVADO: {value: 2, annotations: [{"term":"Default.Description","string":"Autorizado"}]},
    REJEITADO: {value: 3, annotations: [{"term":"Default.Description","string":"Rejeitado"}]},
    AGUARDANDO_EXECUCAO: {value: 5, annotations: [{"term":"Default.Description","string":"Aguardando execu\u00E7\u00E3o"}]},
    EXECUTADO: {value: 6, annotations: [{"term":"Default.Description","string":"Executado"}]},
    PARCIALMENTE_EXECUTADO: {value: 7, annotations: [{"term":"Default.Description","string":"Parcialmente executado"}]},
    CONCLUIDO: {value: 8, annotations: [{"term":"Default.Description","string":"Conclu\u00EDdo"}]},
    GLOSADO: {value: 9, annotations: [{"term":"Default.Description","string":"Glosado"}]},
    PARCIALMENTE_CONCLUIDO: {value: 10, annotations: [{"term":"Default.Description","string":"Parcialmente concluido"}]},
    PARCIALMENTE_GLOSADO: {value: 11, annotations: [{"term":"Default.Description","string":"Parcialmente glosado"}]},
    EM_LOTE_CRIADO: {value: 12, annotations: [{"term":"Default.Description","string":"Em lote - Criado"}]},
    EM_LOTE_CONCLUIDO: {value: 13, annotations: [{"term":"Default.Description","string":"Em lote - Conclu\u00EDdo"}]},
    LOTE_ANALISE: {value: 14, annotations: [{"term":"Default.Description","string":"Lote em an\u00E1lise"}]},
    LOTE_CONCLUIDO: {value: 15, annotations: [{"term":"Default.Description","string":"Lote conclu\u00EDdo"}]},
    LOTE_GLOSADO: {value: 16, annotations: [{"term":"Default.Description","string":"Lote glosado"}]},
    LOTE_PARCIALMENTE_GLOSADO: {value: 17, annotations: [{"term":"Default.Description","string":"Lote parcialmente glosado"}]},
    LOTE_CRIADO: {value: 18, annotations: [{"term":"Default.Description","string":"Lote Criado"}]},
    EM_LOTE_EM_ANALISE: {value: 19, annotations: [{"term":"Default.Description","string":"Em lote - em an\u00E1lise"}]},
    LOTE_EM_FILA_ERP: {value: 20, annotations: [{"term":"Default.Description","string":"Lote - Enviado para fila ERP"}]},
    LOTE_REJEITADO_ERP: {value: 21, annotations: [{"term":"Default.Description","string":"Lote - Erro ao enviar para ERP"}]},
    LOTE_EXECUTADO_ERP: {value: 22, annotations: [{"term":"Default.Description","string":"Lote - Enviado e executado no ERP"}]},
    APROVACAO_TECNICA: {value: 23, annotations: [{"term":"Default.Description","string":"Aguardando aprova\u00E7\u00E3o tecnica"}]},
    APROVACAO_ADMINISTRATIVA: {value: 24, annotations: [{"term":"Default.Description","string":"Aguardando aprova\u00E7\u00E3o admistrativa"}]},
    NAO_AUTORIZADO: {value: 25, annotations: [{"term":"Default.Description","string":"N\u00E3o autorizado"}]},
    EmAberto: {value: 70, annotations: [{"term":"Default.Description","string":"Em Aberto"}]},
    ComRestricao: {value: 71, annotations: [{"term":"Default.Description","string":"Com Restri\u00E7\u00E3o"}]},
    GuiaCriada: {value: 72, annotations: [{"term":"Default.Description","string":"Guia Criada"}]},
    GuiaCriadaComRestrição: {value: 73, annotations: [{"term":"Default.Description","string":"Guia Criada com Restri\u00E7\u00E3o"}]},
    Cancelado: {value: -1, annotations: [{"term":"Default.Description","string":"Cancelado"}]}
  }
} as EnumTypeConfig<EnumStatusProcedimento>;
//#endregion