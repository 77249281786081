//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { DominioV2Service } from './dominiov2.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const DominioV2ServiceEntitySetConfig = {
  name: 'DominioV2',
  entityType: 'Uniodonto.Domain.Entities.Dominio',
  service: DominioV2Service
} as EntitySetConfig;
//#endregion