import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';

import { EnderecoModel } from '../../models/entities/endereco.model';
import { APIResponse } from '../../models';

import { catchError, map, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class EnderecoService {
  public log: String

  constructor(
    private http: HttpClient
  ) { }

  getByBeneficiario(beneficiario_id: any): Observable<any[]> {
    return this.http.get<any[]>(`${environment.apiUrl}/endereco?beneficiario_id=${beneficiario_id}`).pipe(
      tap(data => console.log('enderecos obtidos')),
      catchError(this.handleError)
    );
  };
  
  createEnderecoParticipante(dadosEndereco: any): Observable<APIResponse> {
    return this.http.post<APIResponse>(`${environment.apiUrl}/Endereco/criar-endereco-participante-id`, dadosEndereco, httpOptions );
  }


  create(
    documento: any,
    enderecos: Array<any>,

  ): Observable<APIResponse> {
    let enderecosRequest = { "Enderecos": enderecos };
    return this.http
      .post<APIResponse>(
        `${environment.apiUrl}/Endereco/criar-endereco-por-documento?documento=${documento}`,
        enderecosRequest,
        httpOptions
      )
      .pipe(
        tap((resp) => console.log(JSON.stringify(resp))),
        catchError(this.handleError)
      );
  }

  cUpdateEndereco(dadosEndereco: any): Observable<APIResponse> {
    return this.http.post<APIResponse>(`${environment.apiUrl}/Endereco/editar-Endereco`, dadosEndereco, httpOptions )
      .pipe(
        tap((resp) => console.log('success', 'Endereco atualizado com sucesso!')
        ),
        catchError(this.handleError)
      );
  }

  getAll(): Observable<EnderecoModel[]> {
    return this.http.get<EnderecoModel[]>(`${environment.apiUrl}/Endereco`).pipe(
      tap(data => console.log('All: ' + JSON.stringify(data))),
      catchError(this.handleError)
    );
  };

  getByCEP(cepCode: string): Observable<EnderecoModel> {
    return this.http.post<EnderecoModel>(`${environment.apiUrl}/Endereco/buscar-cep`, cepCode)
      .pipe(
        tap(data => console.log('All: ' + JSON.stringify(data))),
        catchError(this.handleError)
      );
  };

  deleteEndereco(enderecoId: number): Observable<APIResponse> {
    return this.http.delete<APIResponse>(`${environment.apiUrl}/Endereco/remover-endereco?id=${enderecoId}`, httpOptions).pipe(
      tap(resp => console.log('Endereco deletado')),
      catchError(this.handleError)
    );
  }

  buscar_por_id(id: number): Observable<any> {
    return this.http
        .get<any>(`${environment.apiUrl}/Endereco/buscar-por-id?id=${id}`)
  }

  buscar_por_operadora(id: number): Observable<any> {
    return this.http
        .get<any>(`${environment.apiUrl}/Endereco/buscar-endereco-por-participanteID?operadorId=${id}`)
        .pipe(
            tap((data) => console.log('Operadora obtida')),
            catchError(this.handleError)
        );
}

  private handleError(err: HttpErrorResponse) {

    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {

      errorMessage = `An error occurred: ${err.error.message}`;
    } else {

      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.error(errorMessage);
    return throwError(errorMessage);
  }

}