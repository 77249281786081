//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { ReajusteContratual } from './reajustecontratual.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const ReajusteContratualEntityConfig = {
  name: 'ReajusteContratual',
  keys: [{name: 'Id'}],
  fields: {
    SubContratoProdutoId: {type: 'Edm.Int32'},
    ContratoProdutoListaId: {type: 'Edm.Int32'},
    ValorMensalidade: {type: 'Edm.Decimal', nullable: false},
    ValorMensalidadeAgregado: {type: 'Edm.Decimal', nullable: false},
    ValorTaxaInscricao: {type: 'Edm.Decimal', nullable: false},
    ValorTaxaInscricaoDependente: {type: 'Edm.Decimal', nullable: false},
    ValorTaxaInscricaoAgregado: {type: 'Edm.Decimal', nullable: false},
    ValorSegundaViaCarteirinha: {type: 'Edm.Decimal', nullable: false},
    ValorSegundaViaCarteirinhaDependente: {type: 'Edm.Decimal', nullable: false},
    ValorSegundaViaCarteirinhaAgregado: {type: 'Edm.Decimal', nullable: false},
    ValorMensalidadeDependente: {type: 'Edm.Decimal', nullable: false},
    ValorMensalidadeOld: {type: 'Edm.Decimal', nullable: false},
    ValorMensalidadeAgregadoOld: {type: 'Edm.Decimal', nullable: false},
    ValorTaxaInscricaoOld: {type: 'Edm.Decimal', nullable: false},
    ValorTaxaInscricaoDependenteOld: {type: 'Edm.Decimal', nullable: false},
    ValorTaxaInscricaoAgregadoOld: {type: 'Edm.Decimal', nullable: false},
    ValorSegundaViaCarteirinhaOld: {type: 'Edm.Decimal', nullable: false},
    ValorSegundaViaCarteirinhaDependenteOld: {type: 'Edm.Decimal', nullable: false},
    ValorSegundaViaCarteirinhaAgregadoOld: {type: 'Edm.Decimal', nullable: false},
    ValorMensalidadeDependenteOld: {type: 'Edm.Decimal', nullable: false},
    DataInicioReajuste: {type: 'Edm.DateTimeOffset', nullable: false},
    PercentualReajuste: {type: 'Edm.Decimal', nullable: false},
    Executado: {type: 'Edm.Boolean', nullable: false},
    Observacao: {type: 'Edm.String'},
    OperadoraId: {type: 'Edm.Int32'},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'},
    ContratoProdutoLista: {type: 'Uniodonto.Domain.Entities.ContratoProduto', navigation: true},
    SubContratoProduto: {type: 'Uniodonto.Domain.Entities.SubContratoProduto', navigation: true, referentials: [{property: 'SubContratoProdutoId', referencedProperty: 'Id'}]},
    Operadora: {type: 'Uniodonto.Domain.Entities.Operadora', navigation: true, referentials: [{property: 'OperadoraId', referencedProperty: 'Id'}]}
  }
} as StructuredTypeConfig<ReajusteContratual>;
//#endregion