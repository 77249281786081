//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { ClinicaEnderecos } from './clinicaenderecos.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const ClinicaEnderecosEntityConfig = {
  name: 'ClinicaEnderecos',
  keys: [{name: 'Id'}],
  fields: {
    ClinicaId: {type: 'Edm.Int32'},
    TipoEndereco: {type: 'Edm.String'},
    CEPCode: {type: 'Edm.String'},
    CEPId: {type: 'Edm.Int32'},
    Rua: {type: 'Edm.String'},
    Numero: {type: 'Edm.String'},
    Complemento: {type: 'Edm.String'},
    CaixaPostal: {type: 'Edm.String'},
    Bairro: {type: 'Edm.String'},
    Cidade: {type: 'Edm.String'},
    UF: {type: 'Edm.String'},
    IntegradoERP: {type: 'Edm.Boolean', nullable: false},
    StatusIntegracaoERP: {type: 'Uniodonto.Domain.Model.Enum.EnumStatusIntegracao', nullable: false},
    OperadoraId: {type: 'Edm.Int32'},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'},
    Clinica: {type: 'Uniodonto.Domain.Entities.Clinica', navigation: true, referentials: [{property: 'ClinicaId', referencedProperty: 'Id'}]},
    CEP: {type: 'Uniodonto.Domain.Entities.CEP', navigation: true, referentials: [{property: 'CEPId', referencedProperty: 'Id'}]},
    Operadora: {type: 'Uniodonto.Domain.Entities.Operadora', navigation: true, referentials: [{property: 'OperadoraId', referencedProperty: 'Id'}]}
  }
} as StructuredTypeConfig<ClinicaEnderecos>;
//#endregion