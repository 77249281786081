import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class HeaderService {
	dadosAtualizados: Subject<any> = new Subject<any>();
	changeRole: Subject<any> = new Subject<any>();

	constructor() {}

	atualizarCarteirinha(beneficiario) {
		this.dadosAtualizados.next(beneficiario);
	}

	changeRoleButton(role) {
		this.changeRole.next(role);
	}
}
