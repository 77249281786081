//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Cliente } from './cliente.entity';
import { Operadora } from './operadora.entity';
//#endregion

export interface ClienteDocumento {
  //#region ODataApiGen Properties
  DocumentoNomeOriginal?: string;
  DocumentoNomeUnico?: string;
  DocumentoAutor?: string;
  DocumentoUrl?: string;
  ClienteId?: number;
  OperadoraId?: number;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  Cliente?: Cliente;
  Operadora?: Operadora;
  //#endregion
}