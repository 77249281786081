//#region ODataApiGen Imports
//#endregion

export enum EnumGrupoGlosa {
  //#region ODataApiGen Members
  Elegibilidade = 1,
  Protocolo = 2,
  Guia = 3,
  Autorizacao_Solicitacao = 4,
  Diagnostico = 5,
  Regre_Valorizacao = 6,
  Procedimento = 7,
  Procedimentos_emSerie = 8,
  Exames = 9,
  Revisao_deGlosa = 10,
  Mensagens_deSistema = 11,
  //#endregion
}
