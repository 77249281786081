//#region ODataApiGen ODataImports
import {
  EnumTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumTipoNegociacao } from './enumtiponegociacao.enum';
//#endregion

//#region ODataApiGen EnumTypeConfig
export const EnumTipoNegociacaoConfig = {
  name: 'EnumTipoNegociacao',
  members: EnumTipoNegociacao,
  fields: {
    undefined: {value: 0, annotations: [{"term":"Default.Description","string":"N\u00E3o definido"}]},
    ValorUS: {value: 1, annotations: [{"term":"Default.Description","string":"Valor de US"}]},
    QuantidadeUS: {value: 2, annotations: [{"term":"Default.Description","string":"Quantidade de US"}]},
    Padrao: {value: 3, annotations: [{"term":"Default.Description","string":"Padr\u00E3o"}]}
  }
} as EnumTypeConfig<EnumTipoNegociacao>;
//#endregion