//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Operadora } from './operadora.entity';
import { Participante } from './participante.entity';
import { TipoEntidade } from './tipoentidade.entity';
//#endregion

export interface Corretor {
  //#region ODataApiGen Properties
  Documento?: string;
  Nome?: string;
  NomeFantasia?: string;
  RamoAtividade?: string;
  InscricaoEstadual?: string;
  InscricaoMunicipal?: string;
  OptanteSimplesNacional: boolean;
  TipoCorretor?: string;
  TipoEntidadeId?: number;
  ParticipanteId?: number;
  OperadoraId?: number;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  TipoEntidade?: TipoEntidade;
  Participante?: Participante;
  Operadora?: Operadora;
  //#endregion
}