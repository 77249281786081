//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { TitularService } from './titular.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const TitularServiceEntitySetConfig = {
  name: 'Titular',
  entityType: 'Uniodonto.Domain.Entities.Titular',
  service: TitularService
} as EntitySetConfig;
//#endregion