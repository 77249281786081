//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { GuiaObservacaoService } from './guiaobservacao.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const GuiaObservacaoServiceEntitySetConfig = {
  name: 'GuiaObservacao',
  entityType: 'Uniodonto.Domain.Entities.GuiaObservacao',
  service: GuiaObservacaoService
} as EntitySetConfig;
//#endregion