//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { TipoContratoColetivo } from './tipocontratocoletivo.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const TipoContratoColetivoEntityConfig = {
  name: 'TipoContratoColetivo',
  keys: [{name: 'Id'}],
  fields: {
    Nome: {type: 'Edm.String'},
    Descricao: {type: 'Edm.String'},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'},
    Contratos: {type: 'Uniodonto.Domain.Entities.Contrato', collection: true, navigation: true}
  }
} as StructuredTypeConfig<TipoContratoColetivo>;
//#endregion