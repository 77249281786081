//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AvisoV2Service } from './avisov2.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const AvisoV2ServiceEntitySetConfig = {
  name: 'AvisoV2',
  entityType: 'Uniodonto.Domain.Entities.Aviso',
  service: AvisoV2Service
} as EntitySetConfig;
//#endregion