//#region ODataApiGen ODataImports
import {
  EnumTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumMetodoPagamento } from './enummetodopagamento.enum';
//#endregion

//#region ODataApiGen EnumTypeConfig
export const EnumMetodoPagamentoConfig = {
  name: 'EnumMetodoPagamento',
  members: EnumMetodoPagamento,
  fields: {
    Nenhum: {value: 0, annotations: [{"term":"Default.Description","string":"Nenhum"}]},
    Boleto: {value: 1, annotations: [{"term":"Default.Description","string":"Boleto"}]},
    CartaoCredito: {value: 2, annotations: [{"term":"Default.Description","string":"Cart\u00E3o de Cr\u00E9dito"}]},
    PIX: {value: 3, annotations: [{"term":"Default.Description","string":"PIX"}]}
  }
} as EnumTypeConfig<EnumMetodoPagamento>;
//#endregion