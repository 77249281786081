import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumStatusFaturamento } from '../Uniodonto/Domain/Model/Enum/enumstatusfaturamento.enum';
import { EnumTipoPreEspelho } from '../Uniodonto/Domain/Model/Enum/enumtipopreespelho.enum';
import { EnumMetodoPagamento } from '../Uniodonto/Domain/Model/Enum/enummetodopagamento.enum';
import { EnumTipoFaturamento } from '../Uniodonto/Domain/Model/Enum/enumtipofaturamento.enum';
import { PreEspelhoDetalhe } from '../Uniodonto/Domain/Entities/preespelhodetalhe.entity';
import { PreEspelho } from '../Uniodonto/Domain/Entities/preespelho.entity';
import { Operadora } from '../Uniodonto/Domain/Entities/operadora.entity';
import { SubContrato } from '../Uniodonto/Domain/Entities/subcontrato.entity';
import { ValorProcedimentoTipoProduto } from '../Uniodonto/Domain/Entities/valorprocedimentotipoproduto.entity';
import { FaturamentoMovimentacoes } from '../Uniodonto/Domain/Entities/faturamentomovimentacoes.entity';
import { Produto } from '../Uniodonto/Domain/Entities/produto.entity';
//#endregion

@Injectable()
export class PreEspelhoService extends ODataEntitySetService<PreEspelho> {
  constructor(client: ODataClient) {
    super(client, 'PreEspelho', 'Uniodonto.Domain.Entities.PreEspelho');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public faturamentoMovimentacoes(key: EntityKey<PreEspelho>): ODataNavigationPropertyResource<FaturamentoMovimentacoes> { 
    return this.entity(key).navigationProperty<FaturamentoMovimentacoes>('FaturamentoMovimentacoes'); 
  }
  public fetchFaturamentoMovimentacoes(key: EntityKey<PreEspelho>, options?: ODataQueryArgumentsOptions<FaturamentoMovimentacoes>) {
    return this.fetchNavigationProperty<FaturamentoMovimentacoes>(
      this.faturamentoMovimentacoes(key), 
      'entities', options) as Observable<ODataEntities<FaturamentoMovimentacoes>>;
  }
  public addFaturamentoMovimentacoesToFaturamentoMovimentacoes(key: EntityKey<PreEspelho>, target: ODataEntityResource<ODataEntities<FaturamentoMovimentacoes>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.faturamentoMovimentacoes(key).reference()
      .add(target);
  }
  public removeFaturamentoMovimentacoesFromFaturamentoMovimentacoes(key: EntityKey<PreEspelho>, {target, etag}: {target?: ODataEntityResource<ODataEntities<FaturamentoMovimentacoes>>, etag?: string} = {}): Observable<any> {
    return this.faturamentoMovimentacoes(key).reference()
      .remove(target);
  }
  public operadora(key: EntityKey<PreEspelho>): ODataNavigationPropertyResource<Operadora> { 
    return this.entity(key).navigationProperty<Operadora>('Operadora'); 
  }
  public fetchOperadora(key: EntityKey<PreEspelho>, options?: ODataQueryArgumentsOptions<Operadora>) {
    return this.fetchNavigationProperty<Operadora>(
      this.operadora(key), 
      'entity', options) as Observable<ODataEntity<Operadora>>;
  }
  public setOperadoraAsOperadora(key: EntityKey<PreEspelho>, target: ODataEntityResource<ODataEntity<Operadora>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.operadora(key).reference()
      .set(target, {etag});
  }
  public unsetOperadoraAsOperadora(key: EntityKey<PreEspelho>, {target, etag}: {target?: ODataEntityResource<ODataEntity<Operadora>>, etag?: string} = {}): Observable<any> {
    return this.operadora(key).reference()
      .unset({etag});
  }
  public preEspelhoDetalhes(key: EntityKey<PreEspelho>): ODataNavigationPropertyResource<PreEspelhoDetalhe> { 
    return this.entity(key).navigationProperty<PreEspelhoDetalhe>('PreEspelhoDetalhes'); 
  }
  public fetchPreEspelhoDetalhes(key: EntityKey<PreEspelho>, options?: ODataQueryArgumentsOptions<PreEspelhoDetalhe>) {
    return this.fetchNavigationProperty<PreEspelhoDetalhe>(
      this.preEspelhoDetalhes(key), 
      'entities', options) as Observable<ODataEntities<PreEspelhoDetalhe>>;
  }
  public addPreEspelhoDetalheToPreEspelhoDetalhes(key: EntityKey<PreEspelho>, target: ODataEntityResource<ODataEntities<PreEspelhoDetalhe>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.preEspelhoDetalhes(key).reference()
      .add(target);
  }
  public removePreEspelhoDetalheFromPreEspelhoDetalhes(key: EntityKey<PreEspelho>, {target, etag}: {target?: ODataEntityResource<ODataEntities<PreEspelhoDetalhe>>, etag?: string} = {}): Observable<any> {
    return this.preEspelhoDetalhes(key).reference()
      .remove(target);
  }
  public produto(key: EntityKey<PreEspelho>): ODataNavigationPropertyResource<Produto> { 
    return this.entity(key).navigationProperty<Produto>('Produto'); 
  }
  public fetchProduto(key: EntityKey<PreEspelho>, options?: ODataQueryArgumentsOptions<Produto>) {
    return this.fetchNavigationProperty<Produto>(
      this.produto(key), 
      'entity', options) as Observable<ODataEntity<Produto>>;
  }
  public setProdutoAsProduto(key: EntityKey<PreEspelho>, target: ODataEntityResource<ODataEntity<Produto>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.produto(key).reference()
      .set(target, {etag});
  }
  public unsetProdutoAsProduto(key: EntityKey<PreEspelho>, {target, etag}: {target?: ODataEntityResource<ODataEntity<Produto>>, etag?: string} = {}): Observable<any> {
    return this.produto(key).reference()
      .unset({etag});
  }
  public subContrato(key: EntityKey<PreEspelho>): ODataNavigationPropertyResource<SubContrato> { 
    return this.entity(key).navigationProperty<SubContrato>('SubContrato'); 
  }
  public fetchSubContrato(key: EntityKey<PreEspelho>, options?: ODataQueryArgumentsOptions<SubContrato>) {
    return this.fetchNavigationProperty<SubContrato>(
      this.subContrato(key), 
      'entity', options) as Observable<ODataEntity<SubContrato>>;
  }
  public setSubContratoAsSubContrato(key: EntityKey<PreEspelho>, target: ODataEntityResource<ODataEntity<SubContrato>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.subContrato(key).reference()
      .set(target, {etag});
  }
  public unsetSubContratoAsSubContrato(key: EntityKey<PreEspelho>, {target, etag}: {target?: ODataEntityResource<ODataEntity<SubContrato>>, etag?: string} = {}): Observable<any> {
    return this.subContrato(key).reference()
      .unset({etag});
  }
  public valorProcedimentoTipoProdutos(key: EntityKey<PreEspelho>): ODataNavigationPropertyResource<ValorProcedimentoTipoProduto> { 
    return this.entity(key).navigationProperty<ValorProcedimentoTipoProduto>('ValorProcedimentoTipoProdutos'); 
  }
  public fetchValorProcedimentoTipoProdutos(key: EntityKey<PreEspelho>, options?: ODataQueryArgumentsOptions<ValorProcedimentoTipoProduto>) {
    return this.fetchNavigationProperty<ValorProcedimentoTipoProduto>(
      this.valorProcedimentoTipoProdutos(key), 
      'entities', options) as Observable<ODataEntities<ValorProcedimentoTipoProduto>>;
  }
  public addValorProcedimentoTipoProdutoToValorProcedimentoTipoProdutos(key: EntityKey<PreEspelho>, target: ODataEntityResource<ODataEntities<ValorProcedimentoTipoProduto>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.valorProcedimentoTipoProdutos(key).reference()
      .add(target);
  }
  public removeValorProcedimentoTipoProdutoFromValorProcedimentoTipoProdutos(key: EntityKey<PreEspelho>, {target, etag}: {target?: ODataEntityResource<ODataEntities<ValorProcedimentoTipoProduto>>, etag?: string} = {}): Observable<any> {
    return this.valorProcedimentoTipoProdutos(key).reference()
      .remove(target);
  }
  //#endregion
}
