export class SideMenuModel {
  id: number;
  isActive: boolean;
  title: string;
  icon: string;
  Subs: Array<any>;
  show: boolean;
  type: string;
  Rota: string;
  Ordem: number;
}

export class SideMenuModelChildreen {
  id: number;
  isActive: boolean;
  title: string;
  icon: string;
  Subs: SideMenuModel[];
  level: number;
  show: boolean;
  expandable: boolean;
  Rota: string;
  Ordem: number;
}
