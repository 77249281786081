//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnderecoBeneficiario } from './enderecobeneficiario.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const EnderecoBeneficiarioEntityConfig = {
  name: 'EnderecoBeneficiario',
  keys: [{name: 'Id'}],
  fields: {
    EnderecoId: {type: 'Edm.Int32'},
    BeneficiarioId: {type: 'Edm.Int32'},
    OperadoraId: {type: 'Edm.Int32'},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'},
    Endereco: {type: 'Uniodonto.Domain.Entities.Endereco', navigation: true, referentials: [{property: 'EnderecoId', referencedProperty: 'Id'}]},
    Beneficiario: {type: 'Uniodonto.Domain.Entities.Beneficiario', navigation: true, referentials: [{property: 'BeneficiarioId', referencedProperty: 'Id'}]},
    Operadora: {type: 'Uniodonto.Domain.Entities.Operadora', navigation: true, referentials: [{property: 'OperadoraId', referencedProperty: 'Id'}]}
  }
} as StructuredTypeConfig<EnderecoBeneficiario>;
//#endregion