//#region ODataApiGen Imports
//#endregion

export enum EnumEntidadesIntegradasERP {
  //#region ODataApiGen Members
  CLIENTE = 1,
  CLINICA = 2,
  CLINICA_CONTATO = 3,
  CLINICA_ENDERECOS = 4,
  CONTATO = 5,
  //#endregion
}
