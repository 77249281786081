//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Operadora } from './operadora.entity';
import { Titular } from './titular.entity';
import { PerfilCarenciaLinha } from './perfilcarencialinha.entity';
import { OperadoraParametro } from './operadoraparametro.entity';
import { ImportacaoBeneficiario } from './importacaobeneficiario.entity';
//#endregion

export interface PerfilCarencia {
  //#region ODataApiGen Properties
  PerfilNome?: string;
  OperadoraId?: number;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  Linhas?: PerfilCarenciaLinha[];
  Titulares?: Titular[];
  OperadoraParametros?: OperadoraParametro[];
  ImportacaoBeneficiarios?: ImportacaoBeneficiario[];
  Operadora?: Operadora;
  //#endregion
}