//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Operadora } from './operadora.entity';
import { Guia } from './guia.entity';
import { Procedimentos } from './procedimentos.entity';
//#endregion

export interface GuiaObservacao {
  //#region ODataApiGen Properties
  GuiaId?: number;
  ProcedimentosId?: number;
  Texto?: string;
  OperadoraId?: number;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  Guia?: Guia;
  Procedimentos?: Procedimentos;
  Operadora?: Operadora;
  //#endregion
}