//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { GuiaV2Service } from './guiav2.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const GuiaV2ServiceEntitySetConfig = {
  name: 'GuiaV2',
  entityType: 'Uniodonto.Domain.Entities.Guia',
  service: GuiaV2Service
} as EntitySetConfig;
//#endregion