//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { ClinicaMidiaSociais } from './clinicamidiasociais.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const ClinicaMidiaSociaisEntityConfig = {
  name: 'ClinicaMidiaSociais',
  keys: [{name: 'Id'}],
  fields: {
    ClinicaId: {type: 'Edm.Int32'},
    Site: {type: 'Edm.String'},
    TipoSite: {type: 'Uniodonto.Domain.Model.Enum.EnumTipoSite', nullable: false},
    EnderecoURLSite: {type: 'Edm.String'},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    ModificadoPor: {type: 'Edm.String'},
    OperadoraId: {type: 'Edm.Int32'},
    Id: {type: 'Edm.Int32', nullable: false},
    Clinica: {type: 'Uniodonto.Domain.Entities.Clinica', navigation: true, referentials: [{property: 'ClinicaId', referencedProperty: 'Id'}]},
    Operadora: {type: 'Uniodonto.Domain.Entities.Operadora', navigation: true, referentials: [{property: 'OperadoraId', referencedProperty: 'Id'}]}
  }
} as StructuredTypeConfig<ClinicaMidiaSociais>;
//#endregion