//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumProcessamentoRetornoSIB } from '../Model/Enum/enumprocessamentoretornosib.enum';
import { Arquivo } from './arquivo.entity';
import { Operadora } from './operadora.entity';
//#endregion

export interface ProcessamentoRetornoSIB {
  //#region ODataApiGen Properties
  Processado: boolean;
  Resultado?: string;
  EnumProcessamentoRetornoSIB: EnumProcessamentoRetornoSIB;
  ArquivoId?: number;
  OperadoraId?: number;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  Arquivo?: Arquivo;
  Operadora?: Operadora;
  //#endregion
}