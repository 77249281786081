//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AreaGeografica } from './areageografica.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const AreaGeograficaEntityConfig = {
  name: 'AreaGeografica',
  keys: [{name: 'Id'}],
  fields: {
    Nome: {type: 'Edm.String'},
    EnumAreaGeografica: {type: 'Uniodonto.Domain.Model.Enum.EnumAreaGeografica', nullable: false},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'},
    Produtos: {type: 'Uniodonto.Domain.Entities.Produto', collection: true, navigation: true},
    Operadoras: {type: 'Uniodonto.Domain.Entities.Operadora', collection: true, navigation: true}
  }
} as StructuredTypeConfig<AreaGeografica>;
//#endregion