//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { PerfilCarencia } from './perfilcarencia.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const PerfilCarenciaEntityConfig = {
  name: 'PerfilCarencia',
  keys: [{name: 'Id'}],
  fields: {
    PerfilNome: {type: 'Edm.String'},
    OperadoraId: {type: 'Edm.Int32'},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'},
    Linhas: {type: 'Uniodonto.Domain.Entities.PerfilCarenciaLinha', collection: true, navigation: true},
    Titulares: {type: 'Uniodonto.Domain.Entities.Titular', collection: true, navigation: true},
    OperadoraParametros: {type: 'Uniodonto.Domain.Entities.OperadoraParametro', collection: true, navigation: true},
    ImportacaoBeneficiarios: {type: 'Uniodonto.Domain.Entities.ImportacaoBeneficiario', collection: true, navigation: true},
    Operadora: {type: 'Uniodonto.Domain.Entities.Operadora', navigation: true, referentials: [{property: 'OperadoraId', referencedProperty: 'Id'}]}
  }
} as StructuredTypeConfig<PerfilCarencia>;
//#endregion