//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Operadora } from './operadora.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const OperadoraEntityConfig = {
  name: 'Operadora',
  keys: [{name: 'Id'}],
  fields: {
    Ativa: {type: 'Edm.Boolean', nullable: false},
    Codigo: {type: 'Edm.String'},
    TipoCadastro: {type: 'Edm.String'},
    CNES: {type: 'Edm.String'},
    CNPJ: {type: 'Edm.String'},
    CPFResponsavelCNPJ: {type: 'Edm.String'},
    RazaoSocial: {type: 'Edm.String'},
    NomeFantasia: {type: 'Edm.String'},
    Homepage: {type: 'Edm.String'},
    InscricaoEstadual: {type: 'Edm.String'},
    InscricaoMunicipal: {type: 'Edm.String'},
    CRO: {type: 'Edm.String'},
    UFCRO: {type: 'Edm.String'},
    AutorizaEmail: {type: 'Edm.Boolean', nullable: false},
    AceitaAtosAdicionais: {type: 'Edm.Boolean', nullable: false},
    ValorUS: {type: 'Edm.Decimal', nullable: false},
    DataFundacao: {type: 'Edm.DateTimeOffset'},
    DataConvite: {type: 'Edm.DateTimeOffset'},
    DataAssinaturaContrato: {type: 'Edm.DateTimeOffset'},
    DataRecebimentoContrato: {type: 'Edm.DateTimeOffset'},
    DataRecebimentoDocumentacao: {type: 'Edm.DateTimeOffset'},
    ValorCotaParteOperadoraPrestadoras: {type: 'Edm.String'},
    CodOperadoraANS: {type: 'Edm.String'},
    CodigoFederacao: {type: 'Edm.String'},
    EmailCentralAtendimento: {type: 'Edm.String'},
    CelularCentralAtendimento: {type: 'Edm.String'},
    TelefoneCentralAtendimento: {type: 'Edm.String'},
    ObsCentralAtendimento: {type: 'Edm.String'},
    Atende24hrsCentraldeAtendimento: {type: 'Edm.Boolean', nullable: false},
    RepasseId: {type: 'Edm.Int32'},
    AssinaturaEletronica: {type: 'Edm.Boolean', nullable: false},
    PermiteRegraConflitoDuplicado: {type: 'Edm.Boolean', nullable: false, default: 'False'},
    DocumentoId: {type: 'Edm.Int32'},
    ParticipanteId: {type: 'Edm.Int32'},
    OperadoraAreaAtuacaoId: {type: 'Edm.Int32'},
    PagamentoId: {type: 'Edm.Int32'},
    DataAreaId: {type: 'Edm.String'},
    UFastChaveDeAcesso: {type: 'Edm.String'},
    BeneficiarioControleAcessoId: {type: 'Edm.Int32'},
    ClienteControleAcessoId: {type: 'Edm.Int32'},
    UFastAtivo: {type: 'Edm.Boolean', nullable: false},
    UsValorIntercambioUFAST: {type: 'Edm.Decimal', nullable: false},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'},
    Repasse: {type: 'Uniodonto.Domain.Entities.Repasse', navigation: true, referentials: [{property: 'RepasseId', referencedProperty: 'Id'}]},
    Responsaveis: {type: 'Uniodonto.Domain.Entities.Responsavel', collection: true, navigation: true},
    Documento: {type: 'Uniodonto.Domain.Entities.Documentos', navigation: true, referentials: [{property: 'DocumentoId', referencedProperty: 'Id'}]},
    Participante: {type: 'Uniodonto.Domain.Entities.Participante', navigation: true, referentials: [{property: 'ParticipanteId', referencedProperty: 'Id'}]},
    OperadoraAreaAtuacao: {type: 'Uniodonto.Domain.Entities.AreaGeografica', navigation: true, referentials: [{property: 'OperadoraAreaAtuacaoId', referencedProperty: 'Id'}]},
    Pagamento: {type: 'Uniodonto.Domain.Entities.Pagamento', navigation: true, referentials: [{property: 'PagamentoId', referencedProperty: 'Id'}]},
    Ufast: {type: 'Uniodonto.Domain.Entities.Ufast', navigation: true},
    USPagamentoRepasse: {type: 'Uniodonto.Domain.Entities.USPagamentoRepasse', navigation: true},
    Nucleos: {type: 'Uniodonto.Domain.Entities.Nucleo', collection: true, navigation: true},
    AreaAtuacaos: {type: 'Uniodonto.Domain.Entities.AreaAtuacao', collection: true, navigation: true},
    Contatos: {type: 'Uniodonto.Domain.Entities.Contato', collection: true, navigation: true},
    UsuarioOperadoras: {type: 'Uniodonto.Domain.Entities.UsuarioOperadora', collection: true, navigation: true},
    OperadoraUsuarios: {type: 'Uniodonto.Domain.Entities.OperadoraUsuarios', collection: true, navigation: true},
    OperadoraDocumentos: {type: 'Uniodonto.Domain.Entities.OperadoraDocumento', collection: true, navigation: true},
    NegociacaoOperadoras: {type: 'Uniodonto.Domain.Entities.NegociacaoOperadora', collection: true, navigation: true},
    Grupos: {type: 'Uniodonto.Domain.Entities.Grupo', collection: true, navigation: true},
    AtosOdontologicos: {type: 'Uniodonto.Domain.Entities.AtoOdontologico', collection: true, navigation: true},
    Glosas: {type: 'Uniodonto.Domain.Entities.Glosa', collection: true, navigation: true},
    MotivoGlosas: {type: 'Uniodonto.Domain.Entities.MotivoGlosa', collection: true, navigation: true},
    TRMAtoOperadoras: {type: 'Uniodonto.Domain.Entities.TRMAtoOperadora', collection: true, navigation: true},
    AtoRestricaoIdades: {type: 'Uniodonto.Domain.Entities.AtoRestricaoIdade', collection: true, navigation: true},
    Produtos: {type: 'Uniodonto.Domain.Entities.Produto', collection: true, navigation: true},
    ContratoOperadoraRepasses: {type: 'Uniodonto.Domain.Entities.ContratoOperadoraRepasse', collection: true, navigation: true},
    ContratoProdutoAtoPrecos: {type: 'Uniodonto.Domain.Entities.ContratoProdutoAtoPreco', collection: true, navigation: true},
    OperadoraParametro: {type: 'Uniodonto.Domain.Entities.OperadoraParametro', navigation: true},
    SubContratos: {type: 'Uniodonto.Domain.Entities.SubContrato', collection: true, navigation: true},
    BeneficiarioControleAcesso: {type: 'Uniodonto.Domain.Entities.ControleAcesso', navigation: true},
    ClienteControleAcesso: {type: 'Uniodonto.Domain.Entities.ControleAcesso', navigation: true}
  }
} as StructuredTypeConfig<Operadora>;
//#endregion