//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { HistoricoAtivacaoInativacaoOperadora } from './historicoativacaoinativacaooperadora.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const HistoricoAtivacaoInativacaoOperadoraEntityConfig = {
  name: 'HistoricoAtivacaoInativacaoOperadora',
  keys: [{name: 'Id'}],
  fields: {
    SituacaoAnterior: {type: 'Edm.Boolean', nullable: false},
    SituacaoCorrente: {type: 'Edm.Boolean', nullable: false},
    ClinicaId: {type: 'Edm.Int32'},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'},
    Clinica: {type: 'Uniodonto.Domain.Entities.Clinica', navigation: true, referentials: [{property: 'ClinicaId', referencedProperty: 'Id'}]}
  }
} as StructuredTypeConfig<HistoricoAtivacaoInativacaoOperadora>;
//#endregion