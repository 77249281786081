//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { ClienteV2Service } from './clientev2.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const ClienteV2ServiceEntitySetConfig = {
  name: 'ClienteV2',
  entityType: 'Uniodonto.Domain.Entities.Cliente',
  service: ClienteV2Service
} as EntitySetConfig;
//#endregion