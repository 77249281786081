//#region ODataApiGen ODataImports
import {
  EnumTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumFormacaoPrecoProduto } from './enumformacaoprecoproduto.enum';
//#endregion

//#region ODataApiGen EnumTypeConfig
export const EnumFormacaoPrecoProdutoConfig = {
  name: 'EnumFormacaoPrecoProduto',
  members: EnumFormacaoPrecoProduto,
  fields: {
    PRE_PAGAMENTO: {value: 1, annotations: [{"term":"Default.Description","string":"Pr\u00E9-pagamento"}]},
    POS_PAGAMENTO: {value: 2, annotations: [{"term":"Default.Description","string":"P\u00F3s-pagamento"}]},
    MISTO: {value: 3, annotations: [{"term":"Default.Description","string":"Misto"}]}
  }
} as EnumTypeConfig<EnumFormacaoPrecoProduto>;
//#endregion