//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Responsavel } from './responsavel.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const ResponsavelEntityConfig = {
  name: 'Responsavel',
  keys: [{name: 'Id'}],
  fields: {
    CPF: {type: 'Edm.String'},
    RG: {type: 'Edm.String'},
    Tipo: {type: 'Edm.String'},
    Coordenador: {type: 'Edm.String'},
    Nome: {type: 'Edm.String'},
    Telefone: {type: 'Edm.String'},
    Ramal: {type: 'Edm.String'},
    DDD: {type: 'Edm.String'},
    Fax: {type: 'Edm.String'},
    CRO: {type: 'Edm.String'},
    UFCRO: {type: 'Edm.String'},
    Email: {type: 'Edm.String'},
    AutorizaEmail: {type: 'Edm.Boolean', nullable: false},
    OperadoraId: {type: 'Edm.Int32'},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'},
    Operadora: {type: 'Uniodonto.Domain.Entities.Operadora', navigation: true, referentials: [{property: 'OperadoraId', referencedProperty: 'Id'}]}
  }
} as StructuredTypeConfig<Responsavel>;
//#endregion