import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//#region ODataApiGen ODataImports
import {
  ODataClient,
  ODataEntitySetService,
  ODataEntity,
  ODataEntities,
  ODataProperty,
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  ODataOptions,
  ODataQueryArgumentsOptions
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { DependenteGrupoFamiliar } from '../Uniodonto/Domain/Entities/dependentegrupofamiliar.entity';
import { Operadora } from '../Uniodonto/Domain/Entities/operadora.entity';
import { Dependente } from '../Uniodonto/Domain/Entities/dependente.entity';
//#endregion

@Injectable()
export class DependenteGrupoFamiliarV2Service extends ODataEntitySetService<DependenteGrupoFamiliar> {
  constructor(client: ODataClient) {
    super(client, 'DependenteGrupoFamiliarV2', 'Uniodonto.Domain.Entities.DependenteGrupoFamiliar');
  }
  //#region ODataApiGen Actions
  //#endregion
  //#region ODataApiGen Functions
  //#endregion
  //#region ODataApiGen Navigations
  public dependente(key: EntityKey<DependenteGrupoFamiliar>): ODataNavigationPropertyResource<Dependente> { 
    return this.entity(key).navigationProperty<Dependente>('Dependente'); 
  }
  public fetchDependente(key: EntityKey<DependenteGrupoFamiliar>, options?: ODataQueryArgumentsOptions<Dependente>) {
    return this.fetchNavigationProperty<Dependente>(
      this.dependente(key), 
      'entity', options) as Observable<ODataEntity<Dependente>>;
  }
  public setDependenteAsDependente(key: EntityKey<DependenteGrupoFamiliar>, target: ODataEntityResource<ODataEntity<Dependente>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.dependente(key).reference()
      .set(target, {etag});
  }
  public unsetDependenteAsDependente(key: EntityKey<DependenteGrupoFamiliar>, {target, etag}: {target?: ODataEntityResource<ODataEntity<Dependente>>, etag?: string} = {}): Observable<any> {
    return this.dependente(key).reference()
      .unset({etag});
  }
  public operadora(key: EntityKey<DependenteGrupoFamiliar>): ODataNavigationPropertyResource<Operadora> { 
    return this.entity(key).navigationProperty<Operadora>('Operadora'); 
  }
  public fetchOperadora(key: EntityKey<DependenteGrupoFamiliar>, options?: ODataQueryArgumentsOptions<Operadora>) {
    return this.fetchNavigationProperty<Operadora>(
      this.operadora(key), 
      'entity', options) as Observable<ODataEntity<Operadora>>;
  }
  public setOperadoraAsOperadora(key: EntityKey<DependenteGrupoFamiliar>, target: ODataEntityResource<ODataEntity<Operadora>>, {etag}: {etag?: string} = {}): Observable<any> {
    return this.operadora(key).reference()
      .set(target, {etag});
  }
  public unsetOperadoraAsOperadora(key: EntityKey<DependenteGrupoFamiliar>, {target, etag}: {target?: ODataEntityResource<ODataEntity<Operadora>>, etag?: string} = {}): Observable<any> {
    return this.operadora(key).reference()
      .unset({etag});
  }
  //#endregion
}
