//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Clinica } from './clinica.entity';
//#endregion

export interface HistoricoAtivacaoInativacaoOperadora {
  //#region ODataApiGen Properties
  SituacaoAnterior: boolean;
  SituacaoCorrente: boolean;
  ClinicaId?: number;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  Clinica?: Clinica;
  //#endregion
}