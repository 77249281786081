//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Bairro } from './bairro.entity';
//#endregion

export interface CEP {
  //#region ODataApiGen Properties
  CEPCode?: string;
  CEPRua?: string;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  Bairro?: Bairro;
  //#endregion
}