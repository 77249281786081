//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { StatusJob } from './statusjob.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const StatusJobEntityConfig = {
  name: 'StatusJob',
  keys: [{name: 'Id'}],
  fields: {
    NomeStatus: {type: 'Edm.String'},
    EnumStatus: {type: 'Edm.Int32', nullable: false},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'}
  }
} as StructuredTypeConfig<StatusJob>;
//#endregion