//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { ArquivoV2Service } from './arquivov2.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const ArquivoV2ServiceEntitySetConfig = {
  name: 'ArquivoV2',
  entityType: 'Uniodonto.Domain.Entities.Arquivo',
  service: ArquivoV2Service
} as EntitySetConfig;
//#endregion