//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { IntegracaoEntidadePortalERPLogService } from './integracaoentidadeportalerplog.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const IntegracaoEntidadePortalERPLogServiceEntitySetConfig = {
  name: 'IntegracaoEntidadePortalERPLog',
  entityType: 'Uniodonto.Domain.Entities.IntegracaoEntidadePortalERPLog',
  service: IntegracaoEntidadePortalERPLogService
} as EntitySetConfig;
//#endregion