//#region ODataApiGen Imports
//#endregion

export enum SituacaoAutorizacao {
  //#region ODataApiGen Members
  AT = 0,
  AP = 1,
  NE = 2,
  EA = 3,
  CA = 4,
  //#endregion
}
