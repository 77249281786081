//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { ArquivoService } from './arquivo.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const ArquivoServiceEntitySetConfig = {
  name: 'Arquivo',
  entityType: 'Uniodonto.Domain.Entities.Arquivo',
  service: ArquivoService
} as EntitySetConfig;
//#endregion