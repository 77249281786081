//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Dentista } from './dentista.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const DentistaEntityConfig = {
  name: 'Dentista',
  keys: [{name: 'Id'}],
  fields: {
    Nome: {type: 'Edm.String'},
    Idade: {type: 'Edm.Int32', nullable: false},
    NomeMae: {type: 'Edm.String'},
    RG: {type: 'Edm.String'},
    OrgaoEmissor: {type: 'Edm.String'},
    CPF: {type: 'Edm.String'},
    CBO: {type: 'Edm.String'},
    CRO: {type: 'Edm.Int32', nullable: false},
    UFCRO: {type: 'Edm.String'},
    Sexo: {type: 'Edm.String'},
    DataNascimento: {type: 'Edm.DateTimeOffset', nullable: false},
    EstadoCivil: {type: 'Uniodonto.Domain.Model.Enum.EnumEstadoCivil', nullable: false},
    InscricaoINSS: {type: 'Edm.String'},
    InscricaoISS: {type: 'Edm.String'},
    PISPASEP: {type: 'Edm.String'},
    ParticipanteId: {type: 'Edm.Int32'},
    ClinicaId: {type: 'Edm.Int32'},
    HabilidadesId: {type: 'Edm.Int32'},
    OperadoraId: {type: 'Edm.Int32'},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'},
    Participante: {type: 'Uniodonto.Domain.Entities.Participante', navigation: true, referentials: [{property: 'ParticipanteId', referencedProperty: 'Id'}]},
    Clinica: {type: 'Uniodonto.Domain.Entities.Clinica', navigation: true, referentials: [{property: 'ClinicaId', referencedProperty: 'Id'}]},
    Habilidades: {type: 'Uniodonto.Domain.Entities.Habilidades', navigation: true, referentials: [{property: 'HabilidadesId', referencedProperty: 'Id'}]},
    DentistaEspecialidades: {type: 'Uniodonto.Domain.Entities.DentistaEspecialidades', navigation: true},
    DentistaEspecialidadeEAtendimentos: {type: 'Uniodonto.Domain.Entities.DentistaEspecialidadeEAtendimento', collection: true, navigation: true},
    DentistaFormacaoEHabilidades: {type: 'Uniodonto.Domain.Entities.DentistaFormacaoEHabilidade', collection: true, navigation: true},
    DentistaDocumentos: {type: 'Uniodonto.Domain.Entities.DentistaDocumento', collection: true, navigation: true},
    UsuarioClinicas: {type: 'Uniodonto.Domain.Entities.UsuarioClinica', collection: true, navigation: true},
    Operadora: {type: 'Uniodonto.Domain.Entities.Operadora', navigation: true, referentials: [{property: 'OperadoraId', referencedProperty: 'Id'}]}
  }
} as StructuredTypeConfig<Dentista>;
//#endregion