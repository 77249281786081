//#region ODataApiGen ODataImports
import {
  EnumTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumNumberSeqRef } from './enumnumberseqref.enum';
//#endregion

//#region ODataApiGen EnumTypeConfig
export const EnumNumberSeqRefConfig = {
  name: 'EnumNumberSeqRef',
  members: EnumNumberSeqRef,
  fields: {
    NumeroGuia: {value: 1, annotations: [{"term":"Default.Description","string":"N\u00FAmero da guia"}]},
    SeqContrato: {value: 2, annotations: [{"term":"Default.Description","string":"Benefici\u00E1rio do contrato"}]},
    SubContrato: {value: 3, annotations: [{"term":"Default.Description","string":"Sub contrato"}]},
    PreEspelho: {value: 4, annotations: [{"term":"Default.Description","string":"Pr\u00E9-espelho"}]},
    PerfilCobranca: {value: 5, annotations: [{"term":"Default.Description","string":"Perfil Cobran\u00E7a"}]}
  }
} as EnumTypeConfig<EnumNumberSeqRef>;
//#endregion