//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Operadora } from './operadora.entity';
import { Procedimentos } from './procedimentos.entity';
//#endregion

export interface ProcedimentoFaces {
  //#region ODataApiGen Properties
  ProcedimentoId: number;
  Face?: string;
  OperadoraId?: number;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  Procedimentos?: Procedimentos;
  Operadora?: Operadora;
  //#endregion
}