//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { ClinicaV2Service } from './clinicav2.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const ClinicaV2ServiceEntitySetConfig = {
  name: 'ClinicaV2',
  entityType: 'Uniodonto.Domain.Entities.Clinica',
  service: ClinicaV2Service
} as EntitySetConfig;
//#endregion