//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { AtoOdontologicoV2Service } from './atoodontologicov2.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const AtoOdontologicoV2ServiceEntitySetConfig = {
  name: 'AtoOdontologicoV2',
  entityType: 'Uniodonto.Domain.Entities.AtoOdontologico',
  service: AtoOdontologicoV2Service
} as EntitySetConfig;
//#endregion