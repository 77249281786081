//#region ODataApiGen ODataImports
import {
  EnumTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumEstadoCivil } from './enumestadocivil.enum';
//#endregion

//#region ODataApiGen EnumTypeConfig
export const EnumEstadoCivilConfig = {
  name: 'EnumEstadoCivil',
  members: EnumEstadoCivil,
  fields: {
    Solteiro: {value: 1, annotations: [{"term":"Default.Description","string":"Solteiro (a)"}]},
    Casado: {value: 2, annotations: [{"term":"Default.Description","string":"Casado (a)"}]},
    Divorciado: {value: 3, annotations: [{"term":"Default.Description","string":"Divorciado (a)"}]},
    Viuvo: {value: 4, annotations: [{"term":"Default.Description","string":"Vi\u00FAvo (a)"}]}
  }
} as EnumTypeConfig<EnumEstadoCivil>;
//#endregion