//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { DependenteClinica } from './dependenteclinica.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const DependenteClinicaEntityConfig = {
  name: 'DependenteClinica',
  keys: [{name: 'Id'}],
  fields: {
    ClinicaId: {type: 'Edm.Int32'},
    NomeDependente: {type: 'Edm.String'},
    DependenteDataNascimento: {type: 'Edm.DateTimeOffset', nullable: false},
    DependenteCPF: {type: 'Edm.String'},
    DependenteCBO: {type: 'Edm.String'},
    DependenteMatricula: {type: 'Edm.String'},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoPor: {type: 'Edm.String'},
    OperadoraId: {type: 'Edm.Int32'},
    Id: {type: 'Edm.Int32', nullable: false},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    Clinica: {type: 'Uniodonto.Domain.Entities.Clinica', navigation: true, referentials: [{property: 'ClinicaId', referencedProperty: 'Id'}]},
    Operadora: {type: 'Uniodonto.Domain.Entities.Operadora', navigation: true, referentials: [{property: 'OperadoraId', referencedProperty: 'Id'}]}
  }
} as StructuredTypeConfig<DependenteClinica>;
//#endregion