//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumEntidadesIntegradasERP } from '../Model/Enum/enumentidadesintegradaserp.enum';
//#endregion

export interface IntegracaoEntidadePortalERPLog {
  //#region ODataApiGen Properties
  Mensagem: string;
  Entidade: EnumEntidadesIntegradasERP;
  EntidadeId: number;
  Ativo: boolean;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  //#endregion
}