//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Operadora } from './operadora.entity';
import { Clinica } from './clinica.entity';
import { Dentista } from './dentista.entity';
import { Especialidade } from './especialidade.entity';
//#endregion

export interface DentistaEspecialidadeEAtendimento {
  //#region ODataApiGen Properties
  DentistaId?: number;
  EspecialidadeId?: number;
  IsEspecialista: boolean;
  AnexoNomeArquivo?: string;
  AnexoURL?: string;
  ClinicaId?: number;
  OperadoraId?: number;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  Dentista?: Dentista;
  Especialidade?: Especialidade;
  Clinica?: Clinica;
  Operadora?: Operadora;
  //#endregion
}