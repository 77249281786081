//#region ODataApiGen ODataImports
import {
  StructuredTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { ContratoProdutoAtoPreco } from './contratoprodutoatopreco.entity';
//#endregion

//#region ODataApiGen StrucutredTypeConfig
export const ContratoProdutoAtoPrecoEntityConfig = {
  name: 'ContratoProdutoAtoPreco',
  keys: [{name: 'Id'}],
  fields: {
    AtoOdontologicoId: {type: 'Edm.Int32'},
    ProdutoId: {type: 'Edm.Int32'},
    ContratoId: {type: 'Edm.Int32'},
    Valor: {type: 'Edm.Decimal', nullable: false},
    ValorSecundarioQuantidade: {type: 'Edm.Decimal'},
    TipoPreco: {type: 'Uniodonto.Domain.Model.Enum.EnumTipoPrecoContratoProdutoAto', nullable: false},
    OperadoraId: {type: 'Edm.Int32'},
    Id: {type: 'Edm.Int32', nullable: false},
    CriadoEm: {type: 'Edm.DateTimeOffset', nullable: false},
    CriadoPor: {type: 'Edm.String'},
    ModificadoEm: {type: 'Edm.DateTimeOffset'},
    ModificadoPor: {type: 'Edm.String'},
    AtoOdontologico: {type: 'Uniodonto.Domain.Entities.AtoOdontologico', navigation: true, referentials: [{property: 'AtoOdontologicoId', referencedProperty: 'Id'}]},
    Produto: {type: 'Uniodonto.Domain.Entities.Produto', navigation: true, referentials: [{property: 'ProdutoId', referencedProperty: 'Id'}]},
    Contrato: {type: 'Uniodonto.Domain.Entities.Contrato', navigation: true, referentials: [{property: 'ContratoId', referencedProperty: 'Id'}]},
    Operadora: {type: 'Uniodonto.Domain.Entities.Operadora', navigation: true, referentials: [{property: 'OperadoraId', referencedProperty: 'Id'}]}
  }
} as StructuredTypeConfig<ContratoProdutoAtoPreco>;
//#endregion