//#region ODataApiGen ODataImports
import {
  EnumTypeConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { EnumStatusProcedimentoGuia } from './enumstatusprocedimentoguia.enum';
//#endregion

//#region ODataApiGen EnumTypeConfig
export const EnumStatusProcedimentoGuiaConfig = {
  name: 'EnumStatusProcedimentoGuia',
  members: EnumStatusProcedimentoGuia,
  fields: {
    Nenhum: {value: 0, annotations: [{"term":"Default.Description","string":"Nenhum"}]},
    AguardandoAutorizacao: {value: 10, annotations: [{"term":"Default.Description","string":"Aguardando autoriza\u00E7\u00E3o"}]},
    Autorizado: {value: 20, annotations: [{"term":"Default.Description","string":"Autorizado"}]},
    NaoAutorizado: {value: 30, annotations: [{"term":"Default.Description","string":"N\u00E3o autorizado"}]},
    Executado: {value: 40, annotations: [{"term":"Default.Description","string":"Executado"}]},
    Cancelado: {value: 50, annotations: [{"term":"Default.Description","string":"Cancelado"}]},
    Glosado: {value: 60, annotations: [{"term":"Default.Description","string":"Glosado"}]},
    Concluido: {value: 999, annotations: [{"term":"Default.Description","string":"Conclu\u00EDdo"}]}
  }
} as EnumTypeConfig<EnumStatusProcedimentoGuia>;
//#endregion