//#region ODataApiGen ODataImports
import {
  Duration
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { Contrato } from './contrato.entity';
import { Operadora } from './operadora.entity';
//#endregion

export interface ContratoOperadoraRepasse {
  //#region ODataApiGen Properties
  ContratoId?: number;
  QuantidadeRepasse: number;
  DataInicioRepasse: Date;
  DataFimRepasse?: Date;
  FaturamentoConjunto: boolean;
  OperadoraId?: number;
  Id: number;
  CriadoEm: Date;
  CriadoPor?: string;
  ModificadoEm?: Date;
  ModificadoPor?: string;
  Contrato?: Contrato;
  Operadora?: Operadora;
  //#endregion
}