//#region ODataApiGen ODataImports
import {
  EntitySetConfig
} from 'angular-odata';//#endregion

//#region ODataApiGen Imports
import { SubclienteV2Service } from './subclientev2.service';
//#endregion

//#region ODataApiGen EntitySetConfig
export const SubclienteV2ServiceEntitySetConfig = {
  name: 'SubclienteV2',
  entityType: 'Uniodonto.Domain.Entities.Subcliente',
  service: SubclienteV2Service
} as EntitySetConfig;
//#endregion